import React, { Component } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';

import withAuthorization from '../withAuthorization';

import StripeDonation from './StripeDonation';

class Donation extends Component {
  constructor(props) {
    super(props);
    this.state = { stripe: null };
  }

  componentDidMount() {
    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeKey) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({ stripe: window.Stripe(stripeKey) });
      });
    }
  }

  render() {
    const { stripe } = this.state;
    return (
      <StripeProvider stripe={stripe}>
        <Elements>
          <StripeDonation {...this.props} />
        </Elements>
      </StripeProvider>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Donation);
