import React from 'react';
import { injectStripe } from 'react-stripe-elements';

import AuthUserContext from './AuthUserContext';

const withStripeContext = (Component) => {
  class WithStripeContext extends React.Component {
    render() {
      return (
        <AuthUserContext.Consumer>
            {authUser => <Component {...this.props} authUser={authUser} />}
        </AuthUserContext.Consumer>
      );
    }
  }

  return injectStripe(WithStripeContext);
};

export default withStripeContext;
