import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';

import { database } from '../firebase';
import { stringHelper } from '../helpers';

import * as routes from '../constants/routes';
import withNonprofitDetail from '../withNonprofitDetail';

import NonprofitLeftDetail from '../Components/NonprofitLeftDetail';
import SocialShareComponent from '../Components/SocialShare';
import NonprofitProgramCard from '../Components/NonprofitProgramCard';
import NonprofitHeaderMobile from '../Components/NonprofitHeaderMobile';
import NonprofitAboutMobile from '../Components/NonprofitAboutMobile';
import NonprofitProgramsMobile from '../Components/NonprofitProgramsMobile';
import NonprofitCampaignsMobile from '../Components/NonprofitCampaignsMobile';
import NonprofitDonorsMobile from '../Components/NonprofitDonorsMobile';
import NonprofitFinancialsMobile from '../Components/NonprofitFinancialsMobile';
import NonprofitStickyButton from '../Components/NonprofitStickyButton';

import ImgSearch from '../Assets/Images/Fill10.svg';

import '../Assets/Styles/Nonprofits-Programs.css';

class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        agent: null,
        address: '',
        category: '',
        charityNavigatorURL: '',
        city: '',
        collect: '',
        contact: '',
        description: '',
        favorite: false,
        federal: '',
        img: null,
        key: '',
        name: '',
        slugName: '',
        publish: '',
        rating: [],
        ratingStar: [],
        tagline: '',
        state: '',
        type: '',
        website: '',
        zipcode: '',
        viewUrl: '',
        viewUrlProgram: '',
        hideDonation: false,
        stripe_account: null,
      },
      programs: [],
      searchQuery: '',
      filteredPrograms: [],
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.searchEnter = this.searchEnter.bind(this);

    this.sidebarRef = React.createRef();
    this.bodyContentRef = React.createRef();
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, nonprofit } = this.props;
    updateNavStatus('');
    showFooterLinkContainer(false);

    this.setState((prevState) => {
      const { item } = prevState;
      Object.keys(item).forEach((key) => {
        item[key] = nonprofit[key];
      });

      return { item };
    });
    this.getProgramList(nonprofit.key);
  }

  getProgramList(id) {
    database.getRef('/nonprofit_programs').child(id).once('value').then((snap) => {
      const { item } = this.state;
      const programs = [];
      snap.forEach((childSnap) => {
        const slugName = stringHelper.slugify(childSnap.val().name);
        programs.push({
          id: childSnap.val().id,
          name: childSnap.val().name,
          slugName,
          cover: childSnap.val().cover,
          description: childSnap.val().description,
          viewUrl: routes.PROGRAMS_ABOUT.replace(':id', childSnap.val().id).replace(':nonprofitid', id).replace(':name', slugName),
          nonprofit: {
            id: item.key,
            name: item.name,
            city: item.city,
            state: item.state,
            hideDonation: item.hideDonation,
          },
        });
      });

      this.setState({
        programs,
        filteredPrograms: programs,
      });
    });
  }

  handleFilterChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      const { programs } = this.state;
      const filteredPrograms = programs.filter(program => program.name.toLowerCase()
        .indexOf(event.target.value.toLowerCase()) !== -1);

      this.setState({ filteredPrograms });
    }
  }

  render() {
    const { item, programs, filteredPrograms } = this.state;
    const { authUser } = this.props;
    const metaDescription = (item.description.length > 160) ? item.description.substring(0, 160).concat('...') : item.description;

    return (
      <div className="NonprofitProgramSection">
        {item.name !== '' ? (
          <Helmet>
            <title>{`Fundra - ${item.name} :: Programs`}</title>
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={`Fundra - ${item.name} :: Programs`} />
            <meta property="og:description" content={metaDescription} />
          </Helmet>
        ) : null}
        <div className="sectionhero">
          <div className="contentwrapper herosectionfeatures nonprofitsection">
            <div className="w-row nonprofit-detail">
              <div className="w-col w-col-3 w-col-stack f-text-center leftnav" ref={this.sidebarRef}>
                <NonprofitLeftDetail item={item} authUser={authUser} withHash />
              </div>
              <div className="w-col w-col-9 w-col-stack nonprofitbodycontent" ref={this.bodyContentRef}>
                <div className="w-row">
                  <div className="w-col w-col-4 w-col-stack">
                    <h3 className="h3 nonprofitabouttitle">List of programs</h3>
                    <label className="joined">{`${programs.length} ${programs.length > 1 ? 'programs' : 'program'}`}</label>
                  </div>
                  <div className="w-col w-col-5 w-col-stack">
                    {item.name !== '' ? <SocialShareComponent url={item.viewUrlProgram} title={item.name} description={item.description} noCount /> : null}
                  </div>
                  <div className="w-col w-col-3 w-col-stack donorsearch">
                    <div className="graygroupinput">
                      <div className="searchinput"><input name="searchQuery" type="text" placeholder="Search programs" onChange={this.handleFilterChange} onKeyPress={this.searchEnter} /></div>
                      <div className="searchicon"><img src={ImgSearch} alt="" /></div>
                    </div>
                  </div>
                </div>
                <div className="w-row" style={{ marginTop: 43 }}>
                  <div className="w-col w-col-12 w-col-stack">
                    <ProgramList items={filteredPrograms} />
                  </div>
                </div>
              </div>
            </div>
            {item.key !== '' ? (
              <div className="nonprofit-mobile-detail">
                <NonprofitHeaderMobile item={item} />
                <Tabs defaultIndex={1}>
                  <TabList>
                    <Tab>About</Tab>
                    <Tab>Programs</Tab>
                    <Tab>Campaigns</Tab>
                    <Tab>Donors</Tab>
                    <Tab>Financials</Tab>
                  </TabList>
                  <TabPanel>
                    <NonprofitAboutMobile item={item} />
                  </TabPanel>
                  <TabPanel>
                    {filteredPrograms.length > 0 ? (
                      <NonprofitProgramsMobile programs={filteredPrograms} />
                    ) : null}
                  </TabPanel>
                  <TabPanel>
                    <NonprofitCampaignsMobile nid={item.key} hideRaised={item.hideDonation} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitDonorsMobile nid={item.key.toString()} hideRaised={item.hideDonation} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitFinancialsMobile item={item} />
                  </TabPanel>
                </Tabs>
                <NonprofitStickyButton nid={item.key.toString()} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

Programs.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

Programs.defaultProps = {
  authUser: null,
};

function ProgramList(props) {
  const programRow = [];
  let programCol = [];
  let rowIndex = 1;
  props.items.forEach((item, index) => {
    programCol.push(<div className="w-col w-col-4 program-col" key={item.id}><NonprofitProgramCard item={item} /></div>);

    const counter = index + 1;
    if (counter % 3 === 0) {
      if (programCol.length > 0) {
        programRow.push(<div className="w-row" key={rowIndex}>{programCol}</div>);
      }
      programCol = [];
      rowIndex += 1;
    }
  });

  if (programCol.length > 0) {
    programRow.push(<div className="w-row" key={rowIndex}>{programCol}</div>);
  }

  return (programRow);
}

export default withNonprofitDetail(Programs);
