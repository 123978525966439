import React from 'react';
import PropTypes from 'prop-types';

import { stringHelper, numberHelper } from '../helpers';

import EditIcon from '../Assets/Images/edit-2.svg';
import DeleteIcon from '../Assets/Images/Trash.svg';

const MetricsTable = ({ items, rowClick, editMetric, removeMetric }) => (
  <table className="nonprofit-table-small f-table-hover">
    <thead>
      <tr>
        <th style={{ width: 160 }}>Metric Name</th>
        <th>Category / Type</th>
        <th style={{ width: 370 }}>Description Template</th>
        <th>Metric Cost / Number</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {items.map(item => (
        <tr key={item.id} onClick={() => rowClick(item.id)}>
          <td className="text-bold">{item.name}</td>
          <td>{`${item.category ? item.category.name : ''} - ${item.type ? item.type.name : ''}`}</td>
          <td>{item.description}</td>
          <td className="f-lowercase">
            {`${numberHelper.getThousandFormat(item.cost, '$')} / ${numberHelper.getThousandFormat(
              Number.parseInt(item.costNumber, 10)
            )} ${stringHelper.makePluralize(item.unitName.trim(), Number.parseInt(item.costNumber, 10))}`}
          </td>
          <td className="f-action-column">
            <button type="button" onClick={e => editMetric(item.id, e)}>
              <img src={EditIcon} alt="Edit" title="Edit" />
            </button>
            <button type="button" onClick={e => removeMetric(item.id, e)}>
              <img src={DeleteIcon} alt="Delete" title="Delete" />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

MetricsTable.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  rowClick: PropTypes.func.isRequired,
  editMetric: PropTypes.func.isRequired,
  removeMetric: PropTypes.func.isRequired,
};

export default MetricsTable;
