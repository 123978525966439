import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';

import * as routes from './constants/routes';
import withUserContext from './withUserContext';

import SiteMeta from './Components/SiteMeta';
import FaqComponent from './Components/Faq';
import LaunchingCharityComponent from './Components/LaunchingCharity';

import ImgHeroIllustration from './Assets/Images/Hero-Illustration.svg';
import ImgAraha from './Assets/Images/araha.png';
import ImgAHA from './Assets/Images/AHA_Full.svg';
import ImgDirectRelief from './Assets/Images/DirectRelief_Logo_RGB.png';
import ImgUnitedWay from './Assets/Images/united-way-lock-up-cmyk.png';
import ImgYMCA from './Assets/Images/ymca_blu_rgb_r.png';
import ImgHomeA from './Assets/Images/TrackAndGetBack.png';
import ImgHomeB from './Assets/Images/CampaignWeb.png';

import 'animate.css/animate.min.css';
import './Assets/Styles/Home.css';

function Home({ updateNavStatus }) {
  useEffect(() => {
    updateNavStatus('');
  }, [updateNavStatus]);

  return (
    <div className="HomeSection">
      <SiteMeta title="Transparent and accountable fundraising for nonprofits" />
      <div className="sectionhero blob-homepage">
        <div className="contentwrapper herosectionfeatures">
          <div className="herocolumn w-row">
            <div className="herocolumntext herocolumntexthome w-col w-col-6 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <h2 className="h2">Transparent and accountable fundraising</h2>
                <div className="herodescription">
                  Fundra was created to help donors track the impact their contributions are making.
                </div>
                <div className="herocta">
                  <HashLink to={routes.CAMPAIGNS} className="f-btn f-btn-success f-btn-space">
                    <div>Explore Causes</div>
                  </HashLink>
                  <Link to={routes.ABOUT} className="f-link">
                    Learn more
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage herohomepageimage w-col w-col-6 w-col-stack">
              <img src={ImgHeroIllustration} className="heroimage" alt="Fundra" />
            </div>
          </div>
        </div>
      </div>
      <div className="sectionpartnership">
        <div className="contentwrapper">
          <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <h3 className="h3partnership">Explore a network of trusted nonprofits and charities!</h3>
            <div className="herocolumn w-row columnpartnershiplogo">
              <div className="w-col w-col-2 w-col-stack trustedcharitylogo">
                <img src={ImgAraha} alt="" />
              </div>
              <div className="w-col w-col-2 w-col-stack trustedcharitylogo">
                <img src={ImgAHA} alt="" />
              </div>
              <div className="w-col w-col-4 w-col-stack trustedcharitylogo">
                <img src={ImgDirectRelief} alt="" />
              </div>
              <div className="w-col w-col-2 w-col-stack trustedcharitylogo">
                <img src={ImgUnitedWay} alt="" />
              </div>
              <div className="w-col w-col-2 w-col-stack trustedcharitylogo">
                <img src={ImgYMCA} alt="" />
              </div>
            </div>
            <div className="w-row f-text-center">
              <Link to={routes.CAMPAIGNS} className="f-link">
                Explore Causes
              </Link>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div className="sectionfeatured">
        <div className="contentwrapper">
          <div
            className="herocolumn rowdisablemobilelandscape herocolumnsecondrow w-row"
            style={{ marginTop: 0, paddingTop: 0 }}
          >
            <div className="w-col w-col-8 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgHomeA} className="f1" alt="" />
              </ScrollAnimation>
            </div>
            <div className="w-col w-col-4 w-col-stack">
              <div className="herocolumntext-smallfit">
                <ScrollAnimation animateIn="fadeInRight" animateOnce delay={50}>
                  <h3 className="h3">Track &amp; Get Back</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" animateOnce delay={100}>
                  <div className="herosecondarydescription">
                    Revisit your giving decisions periodically and track the impact you are making.
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInRight" animateOnce delay={150}>
                  <div className="herocta">
                    <Link to={routes.CAMPAIGNS} className="f-link">
                      Explore Causes
                    </Link>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="herocolumn f1responsiverow w-row">
            <div className="herocolumntext f2text f2texthomepage w-col w-col-4 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={50}>
                <h3 className="h3">Track &amp; Get Back</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                <div className="herosecondarydescription">
                  Revisit your giving decisions periodically and track the impact you are making.
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={150}>
                <div className="herocta">
                  <Link to={routes.CAMPAIGNS} className="f-link">
                    Explore Causes
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage f1responsiveimage w-col w-col-8 w-col-stack">
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <img src={ImgHomeA} className="f1" alt="" />
              </ScrollAnimation>
            </div>
          </div>
          <div className="herocolumn rowdisablemobilelandscape w-row">
            <div className="w-col w-col-4 w-col-stack">
              <div className="herocolumntext-smallfit right middle">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={50}>
                  <h3 className="h3">Stay connected to what matters</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                  <div className="herosecondarydescription">
                    {"Connect with those you are supporting and see how you're changing their lives!"}
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={150}>
                  <div className="herocta">
                    <Link to={routes.CAMPAIGNS} className="f-link">
                      Explore Causes
                    </Link>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="w-col w-col-8 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgHomeB} alt="" />
              </ScrollAnimation>
            </div>
          </div>
          <div className="herocolumn f1responsiverow w-row">
            <div className="herocolumntext f2text f2texthomepage w-col w-col-4 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={50}>
                <h3 className="h3">Stay connected to what matters</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                <div className="herosecondarydescription">
                  {"Connect with those you are supporting and see how you're changing their lives!"}
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={150}>
                <div className="herocta">
                  <Link to={routes.CAMPAIGNS} className="f-link">
                    Explore Causes
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage f1responsiveimage w-col w-col-8 w-col-stack">
              <ScrollAnimation animateIn="fadeInRight" animateOnce>
                <img src={ImgHomeB} className="f2" alt="" />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <LaunchingCharityComponent />
      <FaqComponent />
    </div>
  );
}

Home.propTypes = { updateNavStatus: PropTypes.func.isRequired };

export default withUserContext(Home);
