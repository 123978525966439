import { database, storage } from '../firebase';
import { dateTimeHelper, stringHelper } from '../helpers';
import * as CampaignProvider from './campaign';
import * as MetricProvider from './metric';

const DB_PATH = process.env.REACT_APP_FIREBASE_DB_PATH;
const LOGO_PATH = process.env.REACT_APP_FIREBASE_NONPROFIT_LOGO_IMAGE_PATH;
const COVER_PATH = process.env.REACT_APP_FIREBASE_NONPROFIT_COVER_IMAGE_PATH;
const COVER_PROG_PATH = process.env.REACT_APP_FIREBASE_NONPROFIT_PROGRAMS_IMAGE_PATH;

export const getDetail = nid => database.getRef(`/nonprofit/${nid}`).once('value');
export const getAgentNonprofit = uid =>
  database
    .getRef('/nonprofit')
    .orderByChild('agent')
    .equalTo(uid.toString())
    .limitToFirst(1)
    .once('value');
export const getNonprofitPrograms = nid =>
  database
    .getRef('/nonprofit_programs')
    .child(nid)
    .once('value');
export const getCategories = () => database.getRef('/category').once('value');
export const getCategoryOptions = () =>
  new Promise(resolve => {
    getCategories().then(snaps => {
      const categories = [];
      snaps.forEach(childSnapshot => {
        const types = [];
        Object.keys(childSnapshot.val().types).forEach(key => {
          types.push(childSnapshot.val().types[key]);
        });

        categories.push({ id: childSnapshot.val().id, name: childSnapshot.val().name, types });
      });

      resolve({ categories });
    });
  });
export const userIsAgent = uid =>
  new Promise(resolve => {
    getAgentNonprofit(uid).then(snap => {
      let isAgent = false;
      let nid = '';
      let slugName = '';
      const mAccess = { f_m_statement: true };
      if (snap.val()) {
        isAgent = true;
        snap.forEach(childSnap => {
          nid = childSnap.val().key;
          slugName = stringHelper.slugify(childSnap.val().name);
          mAccess.f_m_statement = childSnap.val().hideFinancialManagement !== false;
        });
      }

      resolve({ isAgent, nid, slugName, mAccess });
    });
  });
export const getHideDonation = nid =>
  new Promise(resolve => {
    database
      .getRef(`/nonprofit/${nid}/hideDonation`)
      .once('value')
      .then(nonprofitSnap => {
        const isHideDonation = nonprofitSnap.val() ? nonprofitSnap.val() : false;
        resolve({ isHideDonation });
      });
  });
export const toggleFavorite = (key, uid, data) => {
  const updates = {};
  updates[`${DB_PATH}/nonprofit/${key}/likes/${uid}`] = data;
  updates[`${DB_PATH}/profile/${uid}/favorite/nonprofit/${key}`] = data;

  database.updateRef(updates);
};
export const uploadImg = async (nid, mediaFile, mediaFileExt, type) => {
  const imageFileInfo = {
    file: mediaFile,
    meta: mediaFile ? { contentType: mediaFile.type } : '',
    name: '',
    ext: mediaFileExt,
  };

  if (imageFileInfo.file) {
    const IMG_PATH = type === 1 ? LOGO_PATH : COVER_PATH;
    const F_IMG_PATH = type === 1 ? 'logo' : 'cover';
    const logoSnap = await storage.put(
      `${IMG_PATH}/${nid}.${imageFileInfo.ext}`,
      imageFileInfo.file,
      imageFileInfo.meta
    );
    const logoUrl = await logoSnap.ref.getDownloadURL();

    imageFileInfo.name = logoUrl;

    await database.setRef(`/nonprofit/${nid}/img/${F_IMG_PATH}`, imageFileInfo.name);
  }

  return { mediaUrl: imageFileInfo.name };
};
export const UpdateNonprofit = (nid, nonprofitEdit) => {
  const updates = {};
  updates[`${DB_PATH}/nonprofit/${nid}/name`] = nonprofitEdit.name;
  updates[`${DB_PATH}/nonprofit/${nid}/address`] = nonprofitEdit.street;
  updates[`${DB_PATH}/nonprofit/${nid}/city`] = nonprofitEdit.city;
  updates[`${DB_PATH}/nonprofit/${nid}/state`] = nonprofitEdit.countryState;
  updates[`${DB_PATH}/nonprofit/${nid}/zipcode`] = nonprofitEdit.zipcode;
  updates[`${DB_PATH}/nonprofit/${nid}/federal`] = nonprofitEdit.ein;
  updates[`${DB_PATH}/nonprofit/${nid}/category`] = nonprofitEdit.categoryName;
  updates[`${DB_PATH}/nonprofit/${nid}/type`] = nonprofitEdit.type;
  updates[`${DB_PATH}/nonprofit/${nid}/website`] = nonprofitEdit.website;
  updates[`${DB_PATH}/nonprofit/${nid}/contact/email`] = nonprofitEdit.email;
  updates[`${DB_PATH}/nonprofit/${nid}/contact/phone`] = nonprofitEdit.phone;
  updates[`${DB_PATH}/nonprofit/${nid}/description`] = nonprofitEdit.description;

  return database.updateRef(updates);
};

export const saveProgram = async (nid, program) => {
  try {
    const programKey = program.programId === '' ? dateTimeHelper.getCurrentTimeMs() : program.programId;
    const programMediaCoverFile = {
      file: program.programMediaCover,
      meta: program.programMediaCover ? { contentType: program.programMediaCover.type } : '',
      name: '',
      ext: program.programMediaCoverExt,
    };

    if (programMediaCoverFile.file) {
      const coverSnap = await storage.put(
        `${COVER_PROG_PATH}/${programKey}.${programMediaCoverFile.ext}`,
        programMediaCoverFile.file,
        programMediaCoverFile.meta
      );
      const coverUrl = await coverSnap.ref.getDownloadURL();

      programMediaCoverFile.name = coverUrl;
    } else if (program.programMediaCoverPreview !== '') {
      programMediaCoverFile.name = program.programMediaCoverPreview;
    }

    const programData = {
      id: programKey,
      name: program.programName,
      description: program.programDescription,
      cover: programMediaCoverFile.name,
    };

    await database.setRef(`/nonprofit_programs/${nid}/${programKey}`, programData);

    return { success: true };
  } catch (error) {
    return { success: false, err: error };
  }
};
export const removeProgram = (nid, pid) => database.removeRef(`/nonprofit_programs/${nid}/${pid}`);
export const getSubscription = nid =>
  new Promise((resolve, reject) => {
    const key = Number.parseInt(nid, 10);
    const subscriptionDetail = {
      key,
      programLimit: 0,
      programs: 0,
      campaignLimit: 0,
      campaigns: 0,
      metricLimit: 0,
      metrics: 0,
      outputLimit: 0,
      outputs: 0,
      outcomeLimit: 0,
      outcomes: 0,
    };

    getDetail(key)
      .then(detailSnap => {
        getNonprofitPrograms(key).then(programSnap => {
          CampaignProvider.getList(key).then(campaignSnap => {
            MetricProvider.getList(key)
              .then(metricSnap => {
                return { detailSnap, programSnap, campaignSnap, metricSnap };
              })
              .then(result => {
                const { subscribe } = result.detailSnap.val();
                if (subscribe) {
                  const programObject = result.programSnap.val();
                  const campaignObject = result.campaignSnap.val();
                  const metricObject = result.metricSnap.val();
                  subscriptionDetail.programLimit = subscribe.programLimit ? subscribe.programLimit : 0;
                  subscriptionDetail.programs = programObject ? Object.keys(programObject).length : 0;
                  subscriptionDetail.campaignLimit = subscribe.campaignLimit ? subscribe.campaignLimit : 0;
                  subscriptionDetail.campaigns = campaignObject ? Object.keys(campaignObject).length : 0;
                  subscriptionDetail.metricLimit = subscribe.metricLimit ? subscribe.metricLimit : 0;
                  subscriptionDetail.metrics = metricObject ? Object.keys(metricObject).length : 0;
                  subscriptionDetail.outputLimit = subscribe.outputLimit ? subscribe.outputLimit : 0;
                  subscriptionDetail.outcomeLimit = subscribe.outcomeLimit ? subscribe.outcomeLimit : 0;
                }

                resolve(subscriptionDetail);
              });
          });
        });
      })
      .catch(e => {
        reject(e.message);
      });
  });
