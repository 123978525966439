import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as routes from '../constants/routes';

import { database } from '../firebase';
import { stringHelper } from '../helpers';

import ImgSearch from '../Assets/Images/Fill10.svg';

export default class NonprofitProgramsMobile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      programs: [],
      searchQuery: '',
      filteredPrograms: [],
      showFilter: false,
    };

    this.searchEnter = this.searchEnter.bind(this);
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      let filteredPrograms = this.state.programs.filter(program => {
        return program.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
      });

      this.setState({ filteredPrograms });
    }
  }

  getProgramList(id) {
    database
      .getRef('/nonprofit_programs')
      .child(id)
      .once('value')
      .then(snap => {
        let programs = [];
        snap.forEach(childSnap => {
          const slugName = stringHelper.slugify(childSnap.val().name);
          programs.push({
            id: childSnap.val().id,
            name: childSnap.val().name,
            slugName: slugName,
            viewUrl: routes.PROGRAMS_ABOUT.replace(':id', childSnap.val().id)
              .replace(':nonprofitid', id)
              .replace(':name', slugName),
          });
        });

        if (this._isMounted) {
          this.setState({
            programs,
            filteredPrograms: programs,
            showFilter: programs.length > 5,
          });
        }
      });
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.programs) {
      this.setState({
        programs: this.props.programs,
        filteredPrograms: this.props.programs,
        showFilter: this.props.programs.length > 5,
      });
    } else {
      this.getProgramList(this.props.nid);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        {this.state.showFilter ? (
          <div className="donorsearch">
            <div className="graygroupinput">
              <div className="searchinput">
                <input
                  name="searchQuery"
                  type="text"
                  placeholder="Search programs"
                  onChange={this.handleFilterChange}
                  onKeyPress={this.searchEnter}
                />
              </div>
              <div className="searchicon">
                <img src={ImgSearch} alt="" />
              </div>
            </div>
          </div>
        ) : null}
        <div className="programs-mobile-list">
          {this.state.filteredPrograms.map((item, index) => (
            <Link to={item.viewUrl} className="program-button" key={index}>
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}
