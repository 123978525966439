import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route, Link } from 'react-router-dom';
import { ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap';

import AuthUserContext from '../AuthUserContext';
import { UserProvider } from '../Providers';

import * as routes from '../constants/routes';

import logo from '../Assets/Images/logo-Fundra.svg';
import imgSearch from '../Assets/Images/Search.svg';
import ImgDownArrow from '../Assets/Images/DownArrow.png';

import '../Assets/Styles/TopNavigation.css';

const linkStyle = 'navigationtext w-nav-link';

const NavigationMenuLink = ({ label, to, activeOnlyWhenExact, styleClass }) => (
  <Route path={to} exact={activeOnlyWhenExact}>
    {({ match }) => (
      <Link
        to={to}
        className={
          match
            ? `${linkStyle}${styleClass !== '' ? ` ${styleClass}` : ''} active w--current`
            : `${linkStyle}${styleClass !== '' ? ` ${styleClass}` : ''}`
        }
      >
        {label}
      </Link>
    )}
  </Route>
);

NavigationMenuLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  activeOnlyWhenExact: PropTypes.bool,
  styleClass: PropTypes.string,
};

NavigationMenuLink.defaultProps = {
  activeOnlyWhenExact: false,
  styleClass: '',
};

function TopNavigation({ navStatus, history }) {
  const financialUrl = process.env.REACT_APP_FINANCIAL_STATEMENT_URL;
  const signOutClick = event => {
    event.preventDefault();

    UserProvider.signOut();
    history.push(routes.LANDING);
  };

  let navView = null;
  if (navStatus === 'empty') {
    navView = null;
  } else if (navStatus === 'onlylogo') {
    navView = (
      <div className="top-logo">
        <Link to={routes.LANDING} className="logo">
          <img src={logo} alt="Fundra" />
        </Link>
      </div>
    );
  } else if (navStatus === 'logosupport') {
    navView = (
      <div className="logosupport">
        <div className="contentwrapper">
          <div className="logosupport-link">
            <AuthUserContext.Consumer>
              {authUser => {
                const redirectTo = authUser && !authUser.profile.agentStatus ? routes.CAMPAIGNS : routes.LANDING;

                return (
                  <Link to={redirectTo}>
                    <img src={logo} alt="logo" />
                  </Link>
                );
              }}
            </AuthUserContext.Consumer>
          </div>
          <div className="logosupport-email">
            <a href="mailto:info@gofundra.com">Support: info@gofundra.com</a>
          </div>
        </div>
      </div>
    );
  } else if (navStatus === 'management') {
    navView = (
      <div className="logosupport">
        <div className="contentwrapper">
          <div className="logosupport-link">
            <Link to={routes.LANDING}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="logosupport-email">
            <a href="#sign-out" className="navigationtext w-nav-link signin-link" onClick={signOutClick}>
              Sign Out
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    navView = (
      <div className="sectionnavigation">
        <div className="contentwrapper">
          <div data-collapse="medium" data-animation="default" data-duration="500" className="navigation w-nav">
            <Link to="/" className="logo w-nav-brand">
              <img src={logo} alt="logo" />
            </Link>
            <nav className="navigationmenu w-nav-menu">
              <div className="navigationmenu-left">
                <AuthUserContext.Consumer>
                  {authUser => {
                    if (authUser) {
                      const { agentStatus } = authUser.profile;
                      return (
                        <>
                          {!agentStatus ? (
                            <>
                              <NavigationMenuLink activeOnlyWhenExact to={routes.LANDING} label="Home" />
                              {/* <NavigationMenuLink
                                to={routes.NONPROFITS}
                                label="Nonprofits"
                              /> */}
                              <NavigationMenuLink to={routes.CAMPAIGNS} label="Campaigns" />
                              <NavigationMenuLink
                                to={routes.FEATURES}
                                label="I'm a Nonprofit"
                                styleClass="web-user-nav"
                              />
                              <NavigationMenuLink to={routes.ABOUT} label="Our Story" styleClass="web-user-nav" />
                              {/* <NavigationMenuLink to={routes.PRICING} label="Pricing" /> */}
                            </>
                          ) : null}
                          <div className="mobile-user-nav">
                            {agentStatus ? (
                              <>
                                <NavigationMenuLink
                                  to={routes.METRICS_MANAGEMENT_LIST.replace(':id', agentStatus.nid).replace(
                                    ':name',
                                    agentStatus.slugName
                                  )}
                                  label="Metrics Management"
                                />
                                <NavigationMenuLink
                                  to={routes.DONORS_MANAGEMENT.replace(':id', agentStatus.nid).replace(
                                    ':name',
                                    agentStatus.slugName
                                  )}
                                  label="Donor Management"
                                />
                                {!agentStatus.mAccess.f_m_statement ? (
                                  <a
                                    href={`${financialUrl}${agentStatus.nid}/${authUser.user.idToken}`}
                                    className="navigationtext w-nav-link"
                                    rel="noopener noreferrer"
                                  >
                                    Financial Statement
                                  </a>
                                ) : null}
                                <NavigationMenuLink
                                  to={routes.NONPROFITS_ABOUT.replace(':id', agentStatus.nid).replace(
                                    ':name',
                                    agentStatus.slugName
                                  )}
                                  label="Profile Management"
                                />
                                <NavigationMenuLink to={routes.PROFILE_AGENT} label="My Account" />
                              </>
                            ) : (
                              <>
                                <NavigationMenuLink to={routes.PROFILE_DONOR} label="My Impact" />
                              </>
                            )}
                            <a
                              href="#sign-out"
                              className="navigationtext w-nav-link signin-link"
                              onClick={signOutClick}
                            >
                              Sign Out
                            </a>
                          </div>
                        </>
                      );
                    }

                    return (
                      <>
                        <NavigationMenuLink activeOnlyWhenExact to={routes.LANDING} label="Home" />
                        {/* <NavigationMenuLink
                          to={routes.NONPROFITS}
                          label="Nonprofits"
                        /> */}
                        <NavigationMenuLink to={routes.CAMPAIGNS} label="Campaigns" />
                        <NavigationMenuLink to={routes.FEATURES} label="I'm a Nonprofit" styleClass="web-user-nav" />
                        <NavigationMenuLink to={routes.ABOUT} label="Our Story" styleClass="web-user-nav" />
                        {/* <NavigationMenuLink to={routes.PRICING} label="Pricing" /> */}
                        <div className="mobile-user-nav">
                          <NavigationMenuLink to={routes.FEATURES} label="I'm a Nonprofit" />
                          <NavigationMenuLink to={routes.ABOUT} label="Our Story" />
                        </div>
                        <Link to={routes.SIGN_IN} className="navigationtext w-nav-link signin-link">
                          Sign In
                        </Link>
                      </>
                    );
                  }}
                </AuthUserContext.Consumer>
              </div>
              <div className="navigationmenu-right">
                <Link to={`${routes.CAMPAIGNS}?ref=menu`} className="navigationtext w-nav-link search-link">
                  <img src={imgSearch} alt="Search" />
                </Link>
                <AuthUserContext.Consumer>
                  {authUser => {
                    if (authUser) {
                      const { agentStatus, firstName, lastName } = authUser.profile;
                      const firstInitial = firstName.substring(0, 1);
                      const lastInitial = lastName.substring(0, 1);

                      return (
                        <>
                          {agentStatus ? (
                            <Link
                              to={routes.CAMPAIGNS_REGISTER}
                              className="navigationtext w-nav-link register-campaigns-link"
                            >
                              Create Campaign
                            </Link>
                          ) : null}
                          <div className="dropdown-nav">
                            <ButtonToolbar>
                              <Dropdown id="dropdown-no-caret" pullRight>
                                <Dropdown.Toggle noCaret className="f-dropdown-user">
                                  <div className="flexbox flexbox-center f-dropdown-button user-menu">
                                    <span>{`${firstInitial}${lastInitial}`}</span>
                                  </div>
                                  <img src={ImgDownArrow} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="f-dropdown-menu">
                                  {agentStatus ? (
                                    <>
                                      <MenuItem
                                        href={routes.METRICS_MANAGEMENT_LIST.replace(':id', agentStatus.nid).replace(
                                          ':name',
                                          agentStatus.slugName
                                        )}
                                      >
                                        Metrics Management
                                      </MenuItem>
                                      <MenuItem
                                        href={routes.DONORS_MANAGEMENT.replace(':id', agentStatus.nid).replace(
                                          ':name',
                                          agentStatus.slugName
                                        )}
                                      >
                                        Donor Management
                                      </MenuItem>
                                      {!agentStatus.mAccess.f_m_statement ? (
                                        <MenuItem href={`${financialUrl}${agentStatus.nid}/${authUser.user.idToken}`}>
                                          Financial Statement
                                        </MenuItem>
                                      ) : null}
                                      <MenuItem
                                        href={routes.NONPROFITS_ABOUT.replace(':id', agentStatus.nid).replace(
                                          ':name',
                                          agentStatus.slugName
                                        )}
                                      >
                                        Profile Management
                                      </MenuItem>
                                    </>
                                  ) : (
                                    <>
                                      <MenuItem href={routes.PROFILE_DONOR}>My Impact</MenuItem>
                                    </>
                                  )}
                                  <MenuItem divider />
                                  {agentStatus ? (
                                    <>
                                      <MenuItem href={routes.PROFILE_AGENT}>My Account</MenuItem>
                                    </>
                                  ) : null}
                                  <MenuItem className="delete-campaign" onClick={signOutClick}>
                                    Sign Out
                                  </MenuItem>
                                </Dropdown.Menu>
                              </Dropdown>
                            </ButtonToolbar>
                          </div>
                        </>
                      );
                    }

                    return (
                      <Link
                        to={routes.NONPROFITS_REGISTER}
                        className="navigationtext w-nav-link register-nonprofit-link"
                      >
                        Get Started Free
                      </Link>
                    );
                  }}
                </AuthUserContext.Consumer>
              </div>
            </nav>
            <div className="menubutton w-nav-button">
              <div className="navigationicon w-icon-nav-menu" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return navView;
}

TopNavigation.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  navStatus: PropTypes.string,
};

TopNavigation.defaultProps = {
  navStatus: 'empty',
};

export default withRouter(TopNavigation);
