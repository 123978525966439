import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import * as routes from './constants/routes';
import { dateTimeHelper } from './helpers';

// import ImgAppleStore from './Assets/Images/downloadAppStore-Fundra.svg';

import './Assets/Styles/Footer.css';

function FooterContent(props) {
  const showLink = props.showLinkContainer;
  const yearNow = dateTimeHelper.getUTCNow().year();

  if (showLink) {
    return (
      <div className="contentwrapper">
        <div className="footer-container">
          <div className="footernavigation">
            <div className="footertitle">Navigation</div>
            <Link to={routes.LANDING} className="footerlink">
              Home
            </Link>
            <Link to={routes.CAMPAIGNS} className="footerlink">
              Campaigns
            </Link>
            <Link to={routes.FEATURES} className="footerlink">
              For Nonprofits
            </Link>
            <HashLink to={`${routes.ABOUT}#contact`} className="footerlink">
              Contact
            </HashLink>
            {/* <Link to={routes.PRICING} className="footerlink">
              Pricing
            </Link> */}
          </div>
          <div className="footernavigation">
            <div className="footertitle">Company</div>
            <Link to={routes.ABOUT} className="footerlink">
              Our Story
            </Link>
            <a href="https://blogs.gofundra.com" className="footerlink">
              Blog
            </a>
            <Link to="/terms" className="footerlink">
              Terms of Use
            </Link>
            <Link to="/privacy-policy" className="footerlink">
              Privacy policy
            </Link>
          </div>
          <div className="footernavigation">
            <div className="footertitle">Social</div>
            <a
              href="https://twitter.com/gofundra"
              target="_blank"
              rel="noopener noreferrer"
              className="footerlink"
            >
              Twitter
            </a>
            <a
              href="https://www.facebook.com/gofundra"
              target="_blank"
              rel="noopener noreferrer"
              className="footerlink"
            >
              Facebook
            </a>
            <a
              href="https://www.instagram.com/gofundra/"
              target="_blank"
              rel="noopener noreferrer"
              className="footerlink"
            >
              Instagram
            </a>
          </div>
          {/* <div className="footernavigation w-col w-col-4">
            <div className="footertitle">Download our free mobile app now!</div>
            <div className="footertext">
              Donate & track your favorite nonprofits through our easy-to-use
              mobile app
            </div>
            <a
              href="https://itunes.apple.com/us/app/fundra/id1315414940?ls=1&mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ImgAppleStore} alt="" />
            </a>
          </div> */}
        </div>
        <div className="footerdesc">
          <div className="footercopyright">
            © {yearNow} Fundra Inc. All rights reserved. Terms, conditions,
            features, availability, pricing, fees, service and support options
            subject to change without notice
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="contentwrapper">
      <div className="footerdesc lessfooterdesc">
        <div className="footercopyright">
          © {yearNow} Fundra Inc. All rights reserved. Terms, conditions,
          features, availability, pricing, fees, service and support options
          subject to change without notice
        </div>
      </div>
    </div>
  );
}

class Footer extends Component {
  render() {
    const { showLinkContainer } = this.props;
    let sectionfooterCss = "sectionfooter";
    if (!showLinkContainer) {
      sectionfooterCss += " sectionfooter-white";
    }

    return (
      <div className={sectionfooterCss}>
        <FooterContent showLinkContainer={showLinkContainer} />
      </div>
    );
  }
}

export default Footer;
