import { database } from '../firebase';

export const getDetail = key => database.getRef(`/subscription_packages/${key}`).once('value');
export const setSubscriptionPackage = () =>
  database.setRef('/subscription_packages', {
    lite: {
      annualPrice: 0,
      campaignLimit: 1,
      fee: 0.05,
      key: 'lite',
      metricLimit: 1,
      monthlyPrice: 0,
      name: 'Lite Package',
      outcomeLimit: 1,
      outputLimit: 1,
      programLimit: 1,
    },
    pro: {
      annualPrice: 2200,
      campaignLimit: -1,
      fee: 0.02,
      key: 'pro',
      metricLimit: -1,
      monthlyPrice: 200,
      name: 'Professional',
      outcomeLimit: -1,
      outputLimit: -1,
      programLimit: -1,
    },
  });
