import axios from 'axios';
import qs from 'qs';
import { auth, database } from '../firebase';
import { stringHelper } from '../helpers';

export const signIn = (email, password) =>
  new Promise((resolve, reject) => {
    auth
      .signInWithEmailPassword(email, password)
      .then(user => {
        const userInfo = {
          uid: user.user.uid,
          name: user.user.displayName,
          email: user.user.email,
          photo: user.user.photoURL,
        };

        database
          .getRef(`/profile/${userInfo.uid}`)
          .once('value')
          .then(snapshot => {
            const profileInfo = {
              avatar: snapshot.val().avatar,
              email: snapshot.val().email,
              firstName: snapshot.val().firstName,
              lastName: snapshot.val().lastName,
              emailVerified: snapshot.val().emailVerified,
              scid: snapshot.val().scid,
              uid: snapshot.val().uid ? snapshot.val().uid : userInfo.uid,
              token: snapshot.val().token,
              social: snapshot.val().social,
            };

            database
              .getRef('/nonprofit')
              .orderByChild('agent')
              .equalTo(userInfo.uid.toString())
              .limitToFirst(1)
              .once('value')
              .then(snap => {
                let isAgent = false;
                let nid = '';
                let slugName = '';
                const mAccess = { f_m_statement: true };
                if (snap.val()) {
                  isAgent = true;
                  snap.forEach(childSnap => {
                    nid = childSnap.val().key;
                    slugName = stringHelper.slugify(childSnap.val().name);
                    mAccess.f_m_statement = childSnap.val().hideFinancialManagement !== false;
                  });
                }

                profileInfo.agentStatus = isAgent ? { isAgent, nid, slugName, mAccess } : null;

                resolve({ userInfo, profileInfo });
              });
          });
      })
      .catch(err => {
        reject(err.message);
      });
  });

export const signOut = () => {
  auth.signOut();
  localStorage.setItem('user-logout', 'ok');
  localStorage.removeItem('user-info');
};

export const registerToken = uid =>
  new Promise(resolve => {
    const token = Math.random()
      .toString(36)
      .slice(-8);
    database.setRef(`/profile/${uid}/token`, token).then(() => {
      resolve({ status: 'registered', token });
    });
  });

export const sendVerify = (profile, id = 1) =>
  new Promise((resolve, reject) => {
    const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const mode = process.env.REACT_APP_FIREBASE_DB_PATH === 'dev' ? 'DEV' : 'PROD';
    const VERIFY_URL = `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/sendVerify`;
    const formData = {
      id,
      mode,
      data: JSON.stringify(profile),
      token: '8pS3d6GxECa9hNQd',
    };

    axios
      .post(VERIFY_URL, qs.stringify(formData), axiosConfig)
      .then(() => {
        resolve({ status: 'send' });
      })
      .catch(err => {
        reject(err.message);
      });
  });

export const registerUser = async (data, userForm = 1) => {
  try {
    const { firstName, lastName, email, password, phone, payment } = data;
    const newUser = await auth.signUpWithEmailPassword(email, password);
    const { uid } = newUser.user;

    await auth.updateProfile({ displayName: `${firstName} ${lastName}` });

    const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
    const stripeData = {
      email,
      description: `Customer for ${email}`,
      metadata: {
        'first name': firstName,
        'last name': lastName,
        uid,
        phone,
        currency: 'USD',
      },
    };

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${stripeKey}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const res = await axios.post('https://api.stripe.com/v1/customers', qs.stringify(stripeData), axiosConfig);

    const token = Math.random()
      .toString(36)
      .slice(-8);
    const userProfile = {
      uid,
      scid: res.data.id,
      firstName,
      lastName,
      email,
      phone,
      token,
      emailVerified: false,
    };
    if (payment) {
      userProfile.payment = payment;
    }

    await database.setRef(`/profile/${uid}`, userProfile);
    await sendVerify(userProfile, userForm);
    // await auth.resendActivation();
    signOut();

    return { status: 'ok', uid };
  } catch (error) {
    signOut();

    return { status: 'error', err: error };
  }
};

export const getDonorImpact = uid =>
  database
    .getRef(`/profile/${uid}/donations`)
    .orderByChild('date')
    .once('value');
