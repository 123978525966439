import axios from 'axios';

import { database } from '../firebase';

export const getList = nid =>
  database
    .getRef('/campaigns')
    .orderByChild('nonprofit/key')
    .equalTo(nid)
    .once('value');
export const getDetail = key => database.getRef(`/campaigns/${key}`).once('value');
export const remove = key => database.removeRef(`/campaigns/${key}`);
export const togglePublished = (key, status) => database.setRef(`/campaigns/${key}/published`, status);
export const toggleFavorite = (key, uid, data) => {
  const dbPath = process.env.REACT_APP_FIREBASE_DB_PATH;
  const updates = {};
  updates[`${dbPath}/campaigns/${key}/likes/${uid}`] = data;
  updates[`${dbPath}/profile/${uid}/favorite/campaigns/${key}`] = data;

  database.updateRef(updates);
};
export const createDynamicLink = campaign =>
  new Promise((resolve, reject) => {
    const axiosConfig = { headers: { 'Content-Type': 'application/json' } };
    const SITE_URL = process.env.REACT_APP_FIREBASE_SITEURL;
    const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;
    const LINK_PREFIX = process.env.REACT_APP_FIREBASE_LINK_PREFIX;

    const ENDPOINT_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_KEY}`;
    const formData = {
      dynamicLinkInfo: {
        domainUriPrefix: LINK_PREFIX,
        link: `${SITE_URL}${campaign.viewUrl}`,
        socialMetaTagInfo: {
          socialTitle: campaign.name,
          socialDescription: campaign.story.replace(/\\r\\n|\\n|\\r/g, ' '),
          socialImageLink: campaign.cover,
        },
      },
      suffix: { option: 'SHORT' },
    };

    axios
      .post(ENDPOINT_URL, formData, axiosConfig)
      .then(result => {
        const { status, data } = result;
        database.setRef(`/campaigns/${campaign.id}/dynamicLink`, data.shortLink).then(() => {
          resolve({ status, data });
        });
      })
      .catch(err => {
        reject(err.message);
      });
  });
export const getCountByNonprofit = nid =>
  new Promise(resolve => {
    const key = Number.parseInt(nid, 10);
    const campaignDetail = { key, totalCampaign: 0 };

    getList(key).then(snap => {
      const campaignObject = snap.val();
      if (campaignObject) {
        campaignDetail.totalCampaign = Object.keys(campaignObject).length;
      }

      resolve(campaignDetail);
    });
  });
