import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Progress } from 'react-sweet-progress';
import { Flipper, Flipped } from 'react-flip-toolkit';

import * as routes from '../constants/routes';

import { database } from '../firebase';
import { stringHelper, numberHelper } from '../helpers';
import { MetricProvider } from '../Providers';

import ImgEmptyCampaign from '../Assets/Images/emptyCampaign.svg';

import 'react-sweet-progress/lib/style.css';

export default class NonprofitCampaignsMobile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      searchQuery: '',
      filteredCampaigns: [],
      emptyCampaign: false,
      doneInit: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { campaigns, nid } = this.props;
    if (campaigns) {
      this.setState({
        campaigns,
        filteredCampaigns: campaigns,
        emptyCampaign: campaigns.length === 0,
        doneInit: true,
      });
    } else {
      this.getCampaignList(nid);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const { campaigns } = this.props;
    const { doneInit } = this.state;
    if (campaigns && nextProps.campaigns && doneInit) {
      this.setState({
        campaigns: nextProps.campaigns,
        filteredCampaigns: nextProps.campaigns,
        emptyCampaign: nextProps.campaigns.length === 0,
      });
    }
  }

  getCampaignList(key) {
    database
      .getRef('/campaigns')
      .orderByChild('nonprofit/key')
      .equalTo(key)
      .once('value')
      .then(campaignSnap => {
        let campaigns = [];
        campaignSnap.forEach(snap => {
          if (snap.val().published) {
            const slugName = stringHelper.slugify(snap.val().name);
            campaigns.push({
              id: snap.val().id,
              name: snap.val().name,
              slugName: slugName,
              user: snap.val().user,
              goal: snap.val().goal,
              metric: snap.val().metric,
              viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', snap.val().id).replace(':name', slugName),
            });
          }
        });

        if (campaigns.length > 0) {
          campaigns.reverse();
        }

        if (this._isMounted) {
          this.setState({
            campaigns,
            filteredCampaigns: campaigns,
            emptyCampaign: campaigns.length === 0,
          });
        }
      });
  }

  render() {
    const { filteredCampaigns, emptyCampaign } = this.state;
    const { title, showMetric, hideRaised, nid, nonprofitName, hideOrganizer } = this.props;
    const labelTitle = title || 'All';
    const plural = filteredCampaigns.length > 1 ? 's' : '';
    let viewCampaignUrl = '';
    if (labelTitle === 'Similar' && nonprofitName) {
      const nonprofitSlugName = stringHelper.slugify(nonprofitName);
      viewCampaignUrl = routes.NONPROFITS_CAMPAIGNS.replace(':id', nid).replace(':name', nonprofitSlugName);
    }

    return (
      <div>
        {emptyCampaign ? (
          <div className="donors-empty">
            <img src={ImgEmptyCampaign} alt="" />
            <label className="empty-header">There are no active campaigns</label>
            <label className="empty-label">Let's start a new campaign!</label>
          </div>
        ) : (
          <div className="campaigns-mobile-list">
            {viewCampaignUrl !== '' ? (
              <div className="flexbox-space">
                <div className="item">
                  <label className="tab-label">{`${labelTitle} campaign${plural} (${filteredCampaigns.length})`}</label>
                </div>
                <div className="item">
                  <Link to={viewCampaignUrl} className="show-all-campaign">
                    Show all campaigns
                  </Link>
                </div>
              </div>
            ) : (
              <label className="tab-label">{`${labelTitle} campaign${plural} (${filteredCampaigns.length})`}</label>
            )}
            <Flipper flipKey={filteredCampaigns.map(item => item.id).join('')} spring="wobbly">
              {filteredCampaigns.map((item, index) => (
                <CampaignDetail
                  key={index}
                  item={item}
                  nid={nid}
                  hideRaised={hideRaised}
                  showMetric={showMetric}
                  hideOrganizer={hideOrganizer}
                />
              ))}
            </Flipper>
          </div>
        )}
      </div>
    );
  }
}

NonprofitCampaignsMobile.propTypes = {
  nid: PropTypes.any.isRequired,
  campaigns: PropTypes.instanceOf(Array),
  nonprofitName: PropTypes.string,
  showMetric: PropTypes.bool,
  hideOrganizer: PropTypes.bool,
  title: PropTypes.string,
  hideRaised: PropTypes.bool,
};
NonprofitCampaignsMobile.defaultProps = {
  campaigns: null,
  nonprofitName: null,
  showMetric: false,
  hideOrganizer: false,
  title: null,
  hideRaised: false,
};

class CampaignDetail extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      name: '',
      slugName: '',
      user: [],
      userFullName: '',
      avatar: null,
      goal: 0,
      goalText: '',
      raised: 0,
      raisedText: '',
      percent: 0,
      percentForBar: 0,
      viewUrl: '',
      metric: null,
      metricUnit: null,
      doneInit: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.composeData(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAvatar(uid) {
    database
      .getRef(`/profile/${uid}/avatar`)
      .once('value')
      .then(avatarSnap => {
        if (this._isMounted && avatarSnap.val()) {
          this.setState({ avatar: avatarSnap.val() });
        }
      });
  }

  getDonation(key, id) {
    let totalRaised = 0.0;
    let percent = 0;
    let percentForBar = 0;
    const { showMetric } = this.props;
    const { goal, metric } = this.state;
    database
      .getRef(`/donations/${key}`)
      .orderByChild('campaign/id')
      .equalTo(String(id))
      .once('value')
      .then(donationSnap => {
        donationSnap.forEach(snap => {
          totalRaised += parseFloat(snap.val().total.amount);
        });
        if (goal > 0) {
          percent = (totalRaised / goal) * 100;
          percentForBar = percent;
          if (percent > 100) {
            percentForBar = 100;
          }
        }

        if (this._isMounted) {
          this.setState(
            {
              percent: percent.toFixed(2),
              percentForBar: percentForBar.toFixed(),
              raised: totalRaised,
              raisedText: numberHelper.getThousandString(totalRaised.toFixed(2)),
            },
            () => {
              if (showMetric && metric) {
                this.getMetric();
              }
            }
          );
        }
      });
  }

  getMetric() {
    const { metric, raised } = this.state;
    const { nid } = this.props;
    MetricProvider.getDetail(nid, metric).then(snap => {
      if (snap.val()) {
        const totalRaised = Number.parseFloat(raised);
        const metricInfoCost = Number.parseFloat(snap.val().cost);
        const metricInfoCostNumber = Number.parseInt(snap.val().costNumber, 10);
        const metricInfoUnitName = snap.val().unitName ? snap.val().unitName.trim() : '';

        let raisedPack = 0;
        if (metricInfoCost > 0) {
          raisedPack = Math.floor((totalRaised / metricInfoCost) * metricInfoCostNumber);
        }

        const metricUnit = `${numberHelper.getThousandFormat(raisedPack.toFixed(2))} ${stringHelper.makePluralize(
          metricInfoUnitName,
          raisedPack
        )}`;

        this.setState({ metricUnit, doneInit: true });
      }
    });
  }

  composeData(itemProps) {
    const { item, hideOrganizer, nid, showMetric } = itemProps;
    const firstInitial = item.user.firstName.substring(0, 1);
    const lastInitial = item.user.lastName.substring(0, 1);
    const avatar = `https://via.placeholder.com/150.png?text=${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;

    this.setState(
      {
        id: item.id,
        name: item.name,
        slugName: item.slugName,
        user: item.user,
        userFullName: `${item.user.firstName} ${item.user.lastName}`,
        avatar: avatar,
        raised: item.raised ? item.raised : 0.0,
        raisedText: item.raised ? numberHelper.getThousandString(item.raised.toFixed(2)) : '0.00',
        goal: item.goal ? item.goal : 0.0,
        goalText: item.goal ? numberHelper.getThousandString(item.goal.toFixed(2)) : '0.00',
        metric: item.metric,
        viewUrl: item.viewUrl,
      },
      () => {
        if (!hideOrganizer) {
          this.getAvatar(item.user.uid);
        }
        if (item.raised === 0) {
          this.getDonation(nid, item.id);
        } else if (showMetric && item.metric) {
          this.getMetric();
        }
      }
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { doneInit } = this.state;
    if (doneInit) {
      this.composeData(nextProps);
    }
  }

  render() {
    const {
      id,
      avatar,
      userFullName,
      viewUrl,
      name,
      raisedText,
      goal,
      goalText,
      percentForBar,
      percent,
      metricUnit,
    } = this.state;
    const { hideOrganizer, hideRaised, showMetric } = this.props;
    return (
      <Flipped flipId={id}>
        <div className="campaigns-item">
          <Link to={viewUrl}>
            {!hideOrganizer ? (
              <div className="campaign-user">
                <img src={avatar} alt="" title={userFullName} /> {userFullName}
              </div>
            ) : null}
            <label className="campaign-title">{name}</label>
            {!hideRaised ? (
              <div className="campaign-total-money">
                <div className="item-border-finance">
                  <div className="finance-item" style={{ marginBottom: goal > 0 ? 0 : 10 }}>
                    <span className="f-text-bold">$ {raisedText}</span>
                    {goal > 0 ? <span> of $ {goalText} Goal</span> : null}
                    {showMetric && metricUnit ? <span>{` provided ${metricUnit}`}</span> : null}
                  </div>
                </div>
                {goal > 0 ? (
                  <React.Fragment>
                    <div className="progressbar program">
                      <Progress percent={percentForBar} theme={{ success: { symbol: ' ', color: '#63D8B5' } }} />
                    </div>
                    <div className="raised-note">
                      <label>{percent} % Raised</label>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            ) : null}
          </Link>
        </div>
      </Flipped>
    );
  }
}

CampaignDetail.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  nid: PropTypes.any.isRequired,
  hideRaised: PropTypes.bool,
  hideOrganizer: PropTypes.bool,
  showMetric: PropTypes.bool,
};
CampaignDetail.defaultProps = {
  showMetric: false,
  hideOrganizer: false,
  hideRaised: false,
};
