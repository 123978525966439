import React from 'react';
import PropTypes from 'prop-types';

import { stringHelper, numberHelper } from '../helpers';

const DonationManualTable = props => {
  const { items, rowClick } = props;

  return (
    <table className="nonprofit-table-small f-table-hover">
      <thead>
        <tr>
          <th>Payer Name</th>
          <th>Amount</th>
          <th>Program</th>
          <th>Campaign</th>
          <th>Metric Unit</th>
          <th>Transaction Date</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => {
          let metricText = '';
          if (item.metric && item.total) {
            const totalImpact = Number.parseFloat(item.total.amount) / Number.parseFloat(item.metric.cost);
            const totalPack = Math.floor(totalImpact * Number.parseInt(item.metric.costNumber, 10));
            metricText = `${numberHelper.getThousandFormat(totalPack.toFixed(2))} ${stringHelper.makePluralize(
              item.metric.unitName,
              totalPack
            )}`;
          }

          return (
            <tr key={item.id} onClick={() => rowClick(item.id)}>
              <td className="text-bold">{item.fullName}</td>
              {item.total ? (
                <td>
                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(item.total.amount)}`}
                  <br />
                  {item.dedication ? (
                    <span>
                      <i>{`${item.dedication.type.title} ${item.dedication.value}`}</i>
                    </span>
                  ) : null}
                </td>
              ) : (
                <td>{`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(item.balanceOwed)}`}</td>
              )}
              <td>{item.program && item.program.id ? item.program.name : ''}</td>
              <td>{item.campaign && item.campaign.id ? item.campaign.name : ''}</td>
              <td>{metricText}</td>
              <td>{item.date.toLocaleDateString()}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

DonationManualTable.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  rowClick: PropTypes.func.isRequired,
};

export default DonationManualTable;
