import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import withAuthorization from '../withAuthorization';

import HeaderInfo from '../Components/Profile/HeaderInfo';
import MyImpact from '../Components/Profile/MyImpact';

class Donor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalDonation: 0,
    };

    this.updateTotalDonation = this.updateTotalDonation.bind(this);
  }

  updateTotalDonation(totalDonation) {
    this.setState({ totalDonation });
  }

  render() {
    document.title = 'Donor Dashboard - Fundra';

    const { authUser } = this.props;
    const { totalDonation } = this.state;

    return (
      <div className="ProfileSection">
        <div className="sectionhero">
          <div className="contentwrapper herosectionfeatures">
            <HeaderInfo authUser={authUser} totalDonation={totalDonation} withTotal withSendReport />
            <div className="profile-tabs">
              <Tabs>
                <TabList>
                  <Tab>My Impact</Tab>
                  <Tab disabled>My Account</Tab>
                  <Tab disabled>Favorited Nonprofits</Tab>
                </TabList>
                <TabPanel>
                  <MyImpact authUser={authUser} updateTotal={this.updateTotalDonation} />
                </TabPanel>
                <TabPanel>
                  <div>My Account</div>
                </TabPanel>
                <TabPanel>
                  <div>Favorited Nonprofits</div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Donor.propTypes = {
  authUser: PropTypes.instanceOf(Object).isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Donor);
