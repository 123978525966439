import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'react-sweet-progress';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from 'react-loader-spinner';

import { database } from '../firebase';
import { stringHelper, numberHelper, dateTimeHelper } from '../helpers';

import * as routes from '../constants/routes';
import { DonationProvider, MetricProvider } from '../Providers';
import withNonprofitDetail from '../withNonprofitDetail';

import SiteMeta from '../Components/SiteMeta';
import NonprofitTopNav from '../Components/NonprofitTopNav';
import DonorTable from '../Components/DonorTable';
import CampaignTable from '../Components/CampaignTable';
import { ChartDateOption } from '../Components/HtmlTag';
import ProgramHeaderMobile from '../Components/ProgramHeaderMobile';
import NonprofitCampaignsMobile from '../Components/NonprofitCampaignsMobile';
import NonprofitDonorsMobile from '../Components/NonprofitDonorsMobile';
import NonprofitStickyButton from '../Components/NonprofitStickyButton';

import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';
import ImgEmptyDonors from '../Assets/Images/Illustration-3.svg';
import ImgEmptyCampaign from '../Assets/Images/emptyCampaign.svg';
import ImgSearch from '../Assets/Images/Fill10.svg';
import { ReactComponent as ImgRefresh } from '../Assets/Images/icon_refresh.svg';

import 'react-tabs/style/react-tabs.css';
import '../Assets/Styles/Nonprofits-Donor.css';

class ProgramDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        charityNavigatorURL: '',
        collect: '',
        agent: null,
        city: '',
        description: '',
        favorite: false,
        federal: '',
        img: null,
        key: '',
        name: '',
        slugName: '',
        publish: '',
        rating: [],
        ratingStar: [],
        state: '',
        tagline: '',
        hideDonation: false,
        stripe_account: null,
      },
      program: {
        id: '',
        name: '',
        slugName: '',
        cover: null,
        description: '',
        viewUrl: '',
      },
      goal: 0,
      goalText: '',
      raisedText: '',
      percent: '0',
      donorLoad: true,
      donors: [],
      filteredDonors: [],
      donorsEmpty: false,
      campaignLoad: true,
      campaigns: [],
      filteredCampaigns: [],
      campaignEmpty: false,
      tabIndex: 0,
      dailyDonation: { until: '', totalRaised: 0.0 },
      weeklyDonation: { until: '', totalRaised: 0.0 },
      monthlyDonation: { until: '', totalRaised: 0.0 },
      yearToDateDonation: { until: '', totalRaised: 0.0 },
      totalDonation: { until: '', totalRaised: 0.0 },
      selectedDate: 1,
      chartData: {
        labels: [],
        datasets: [],
      },
      chartLoading: true,
      metricInfo: {},
    };

    this.handleDonation = this.handleDonation.bind(this);
    this.handleFundraise = this.handleFundraise.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.searchEnter = this.searchEnter.bind(this);
    this.dateButtonOnSelect = this.dateButtonOnSelect.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.refreshTimer = null;
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, match, location, nonprofit } = this.props;
    updateNavStatus('empty');
    showFooterLinkContainer(false);

    const { id } = match.params;
    const urlHash = location.hash;

    this.setState(
      prevState => {
        const { item } = prevState;
        Object.keys(nonprofit).forEach(key => {
          item[key] = nonprofit[key];
        });
        const tabIndex = urlHash === '#campaigns' ? 1 : 0;
        return { item, tabIndex };
      },
      () => {
        this.getProgramDetail(Number.parseInt(nonprofit.key, 10), id);
        this.getFinancial(nonprofit.key, id);
      }
    );
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  getProgramDetail(nid, id) {
    database
      .getRef(`/nonprofit_programs/${nid}/${id}`)
      .once('value')
      .then(snap => {
        if (snap.val()) {
          const { item: nonprofit } = this.state;
          const slugName = stringHelper.slugify(snap.val().name);
          const program = {
            id: snap.val().id,
            name: snap.val().name,
            slugName,
            cover: snap.val().cover,
            description: snap.val().description,
            viewUrl: routes.PROGRAMS_ABOUT.replace(':id', id)
              .replace(':nonprofitid', nid)
              .replace(':name', slugName),
          };

          this.setState(
            {
              program,
            },
            () => {
              if (!nonprofit.hideDonation) {
                this.getFinancialTabInfo();
                this.getChartData();
              }
            }
          );
        }
      });
  }

  async getFinancial(key, id) {
    let totalGoal = 0.0;
    let totalRaised = 0.0;
    let percent = 0;
    let since = '';
    const donors = [];
    const campaigns = [];
    const metricId = [];
    const metricInfoPromises = [];
    const metricInfo = {};
    const { item: nonprofit } = this.state;

    const campaignSnap = await database
      .getRef('/campaigns')
      .orderByChild('program/id')
      .equalTo(Number.parseInt(id, 10))
      .once('value');
    campaignSnap.forEach(snap => {
      if (snap.val().published) {
        if (snap.val().goal) {
          totalGoal += parseFloat(snap.val().goal);
        }

        let dateObj = null;
        if (snap.val().date) {
          dateObj = new Date(snap.val().date);
        }

        const slugName = stringHelper.slugify(snap.val().name);
        const campaignInfo = {
          id: snap.val().id,
          name: snap.val().name,
          slugName,
          nonprofit: { id: key },
          date: dateObj,
          goal: snap.val().goal ? snap.val().goal : 0,
          raised: 0.0,
          metric: snap.val().metric,
          viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', snap.val().id).replace(':name', slugName),
        };

        const userInfo = snap.val().user;
        userInfo.fullName = `${userInfo.firstName} ${userInfo.lastName}`;
        campaignInfo.user = userInfo;
        campaigns.push(campaignInfo);
      }
    });

    const donationSnap = await database
      .getRef(`/donations/${key}`)
      .orderByChild('program/id')
      .equalTo(String(id))
      .once('value');
    donationSnap.forEach(snap => {
      let dateObj = null;
      if (snap.val().date) {
        dateObj = new Date(snap.val().date);
      }

      if (!nonprofit.hideDonation) {
        const donorTo = snap.val().program ? snap.val().program.name : snap.val().nonprofit.name;
        const userInfo = snap.val().user;
        userInfo.fullName = userInfo.anonymous ? 'Anonymous Donor' : `${userInfo.firstName} ${userInfo.lastName}`;
        userInfo.dedication = snap.val().dedication;
        userInfo.program = snap.val().program;
        userInfo.metric = snap.val().metric;
        userInfo.campaign = snap.val().campaign;
        userInfo.donorTo = snap.val().campaign ? snap.val().campaign.name : donorTo;
        userInfo.date = dateObj;
        userInfo.total = snap.val().total;
        if (snap.val().recurring) {
          switch (snap.val().recurring.when) {
            case 3:
              userInfo.recurring = 'Monthly';
              userInfo.recurringStyle = 'f-info-box';
              break;
            case 2:
              userInfo.recurring = 'Bi-Weekly';
              userInfo.recurringStyle = 'f-success-box';
              break;
            default:
              userInfo.recurring = 'Weekly';
              userInfo.recurringStyle = 'f-default-box';
              break;
          }
        } else {
          userInfo.recurring = '-';
          userInfo.recurringStyle = 'empty';
        }
        userInfo.idTrx = snap.val().idTrx;
        donors.push(userInfo);
      }

      if (snap.val().metric && !metricId.includes(snap.val().metric.id)) {
        metricId.push(snap.val().metric.id);
      }

      if (snap.val().campaign) {
        const campaignIndex = campaigns.findIndex(c => c.id.toString() === snap.val().campaign.id.toString());
        if (campaignIndex !== -1) {
          campaigns[campaignIndex].raised += parseFloat(snap.val().total.amount);
        }
      }

      totalRaised += parseFloat(snap.val().total.amount);

      if (since === '' && dateObj) {
        since = `Since ${dateTimeHelper.getMonthDateYear(dateObj)}`;
      }
    });

    campaigns.sort((a, b) => (a.raised < b.raised ? 1 : -1));

    if (donors.length > 0) {
      donors.reverse();
    }

    if (totalGoal > 0) {
      percent = (totalRaised / totalGoal) * 100;
      if (percent > 100) {
        percent = 100;
      }
    }

    metricId.forEach(mid => {
      metricInfoPromises.push(MetricProvider.getShowHumanUnit(key, mid));
    });

    if (metricInfoPromises.length > 0) {
      const promisesSnap = await Promise.all(metricInfoPromises);
      promisesSnap.forEach(snap => {
        metricInfo[snap.id] = snap;
      });
    }

    this.setState(
      {
        donors,
        donorsEmpty: donors.length === 0,
        filteredDonors: donors,
        donorLoad: false,
        percent: percent.toFixed(),
        goal: totalGoal,
        goalText: numberHelper.getThousandString(totalGoal.toFixed(2)),
        raisedText: numberHelper.getThousandString(totalRaised.toFixed(2)),
        campaigns,
        campaignEmpty: campaigns.length === 0,
        filteredCampaigns: campaigns,
        campaignLoad: false,
        metricInfo,
      },
      () => {
        if (!this.refreshTimer) {
          this.refreshTimer = setInterval(() => {
            this.handleIntervalRefresh(nonprofit.key, id);
          }, 20000);
        }
      }
    );
  }

  getFinancialTabInfo() {
    const { item, program } = this.state;

    DonationProvider.todayNonprofitDonation(item.key, program.id).then(result => {
      this.setState({ dailyDonation: result });
    });

    DonationProvider.weeklyNonprofitDonation(item.key, program.id).then(result => {
      this.setState({ weeklyDonation: result });
    });

    DonationProvider.monthlyNonprofitDonation(item.key, program.id).then(result => {
      this.setState({ monthlyDonation: result });
    });

    DonationProvider.yearNonprofitDonation(item.key, program.id).then(result => {
      this.setState({ yearToDateDonation: result });
    });

    DonationProvider.totalProgramDonation(item.key, program.id).then(result => {
      this.setState({ totalDonation: result });
    });
  }

  getChartData() {
    const { selectedDate, item, program } = this.state;

    this.setState(
      {
        chartLoading: true,
      },
      () => {
        const rangeValue = ChartDateOption[selectedDate].value;
        DonationProvider.getChartData(item.key, rangeValue, program.id).then(result => {
          if (result.status === 'ok') {
            this.setState({
              chartLoading: false,
              chartData: {
                labels: result.labels,
                datasets: [
                  {
                    label: 'Last 7 days',
                    fillColor: 'rgba(151,187,205,0.2)',
                    strokeColor: 'rgba(151,187,205,1)',
                    pointColor: 'rgba(151,187,205,1)',
                    pointStrokeColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(151,187,205,1)',
                    data: result.chartData,
                  },
                ],
              },
            });
          }
        });
      }
    );
  }

  dateButtonOnSelect(eventKey) {
    const { chartLoading } = this.state;

    if (!chartLoading) {
      this.setState(
        {
          selectedDate: Number.parseInt(eventKey, 10),
        },
        () => {
          this.getChartData();
        }
      );
    }
  }

  handleDonation() {
    const { history } = this.props;
    const { item } = this.state;

    history.push(routes.NONPROFITS_DONATION.replace(':id', item.key).replace(':type', 'b'));
  }

  handleFundraise() {
    const { history } = this.props;
    const { item, program } = this.state;

    history.push(`${routes.CAMPAIGNS_REGISTER}?b=${item.key}&p=${program.id}`);
  }

  handleTabChange(tabIndex) {
    this.setState({ tabIndex });
  }

  handleRefresh() {
    const { match, nonprofit } = this.props;
    const { id } = match.params;
    this.setState({ campaignLoad: true, donorLoad: true }, () => {
      this.getFinancial(nonprofit.key, id);
    });
  }

  handleIntervalRefresh(key, id) {
    this.getFinancial(key, id);
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      const { tabIndex, campaigns, donors } = this.state;
      if (tabIndex === 1) {
        const filteredCampaigns = campaigns.filter(
          campaign => campaign.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
        );

        this.setState({ filteredCampaigns });
      } else if (tabIndex === 2) {
        const filteredDonors = donors.filter(
          donor => donor.fullName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
        );

        this.setState({ filteredDonors });
      }
    }
  }

  render() {
    const {
      program,
      tabIndex,
      item,
      goal,
      raisedText,
      goalText,
      percent,
      campaignLoad,
      campaignEmpty,
      filteredCampaigns,
      filteredDonors,
      donorLoad,
      donorsEmpty,
      metricInfo,
    } = this.state;
    const { authUser } = this.props;
    const programCover = program.cover ? program.cover : ImgCoverDefault;
    const metaDescription =
      program.description.length > 160 ? program.description.substring(0, 160).concat('...') : program.description;
    const noRaisedCss = item.hideDonation
      ? 'w-col-12 w-col-stack nonprofitbodycontentdetail no-border'
      : 'w-col-9 w-col-stack nonprofitbodycontentdetail';

    let searchView;
    switch (tabIndex) {
      case 1:
        searchView = (
          <div className="donorsearch">
            <div className="graygroupinput right">
              <div className="searchinput">
                <input
                  name="searchCampaign"
                  type="text"
                  placeholder="Search campaigns"
                  onChange={this.handleFilterChange}
                  onKeyPress={this.searchEnter}
                />
              </div>
              <div className="searchicon">
                <img src={ImgSearch} alt="" />
              </div>
            </div>
          </div>
        );
        break;

      case 2:
        searchView = (
          <div className="donorsearch">
            <div className="graygroupinput right">
              <div className="searchinput">
                <input
                  name="searchDonor"
                  type="text"
                  placeholder="Search donors"
                  onChange={this.handleFilterChange}
                  onKeyPress={this.searchEnter}
                />
              </div>
              <div className="searchicon">
                <img src={ImgSearch} alt="" />
              </div>
            </div>
          </div>
        );
        break;

      default:
        break;
    }

    return (
      <div className="NonprofitAboutSection">
        {program.name !== '' ? <SiteMeta title={`${program.name} :: Program`} description={metaDescription} /> : null}
        <div className="sectionhero">
          <div className="contentwrapper herosectionfeatures nonprofitsection">
            <div className="nonprofit-detail">
              <NonprofitTopNav item={item} authUser={authUser} noNavBottom />
              <div className="program-section">
                <h3 className="h3 nonprofitabouttitle">{program.name}</h3>
                <label className="joined">{`${item.city}, ${item.state}`}</label>
                <div id="campaigns" className="w-row">
                  <div className="w-col w-col-8 w-col-stack">
                    {searchView ? (
                      <div className="campaign-btn-refresh">
                        <button type="button" className="buttondefault f-btn-default-m" onClick={this.handleRefresh}>
                          <ImgRefresh />
                          Refresh Data
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="w-col w-col-4 w-col-stack">{searchView}</div>
                </div>
                <Tabs selectedIndex={tabIndex} onSelect={this.handleTabChange}>
                  <TabList>
                    <Tab>About</Tab>
                    <Tab>Campaigns</Tab>
                    <Tab>Donors</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="w-row nonprofit-body-content-left" style={{ marginTop: 33 }}>
                      <div className={`w-col ${noRaisedCss}`}>
                        <div className="nonprofit-cover-big">
                          <img src={programCover} alt={`${program.name} Cover`} />
                        </div>
                        <div className="w-row nonprofit-description">
                          <div className="w-col w-col-12 w-col-stack">{program.description}</div>
                        </div>
                      </div>
                      {!item.hideDonation ? (
                        <RightSideComponent
                          raisedText={raisedText}
                          goal={goal}
                          goalText={goalText}
                          percent={percent}
                          handleDonation={this.handleDonation}
                        />
                      ) : null}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {campaignLoad ? (
                      <div className="section-loader-data">
                        <div className="section-loader-container">
                          <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {!campaignEmpty ? (
                          <CampaignTable
                            items={filteredCampaigns}
                            tblClass="nonprofit-table f-table-ms"
                            hideRaised={item.hideDonation}
                            hideOrganizer
                            hideDate
                            showMetric
                          />
                        ) : (
                          <div className="donors-empty">
                            <img src={ImgEmptyCampaign} alt="" />
                            <h3 className="h3 nonprofitabouttitle">There is no active campaigns</h3>
                            <label className="joined">Let&apos;s start a new campaign!</label>
                            <button type="button" className="w-button f-btn-warning" onClick={this.handleFundraise}>
                              Create Campaign
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {donorLoad ? (
                      <div className="section-loader-data">
                        <div className="section-loader-container">
                          <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {!donorsEmpty ? (
                          <div className="w-row" style={{ marginTop: 33 }}>
                            <div className="w-col w-col-9 w-col-stack nonprofitbodycontentdetail">
                              <DonorTable
                                items={filteredDonors}
                                tblClass="nonprofit-table-small"
                                metricInfo={metricInfo}
                              />
                            </div>
                            {!item.hideDonation ? (
                              <RightSideComponent
                                raisedText={raisedText}
                                goal={goal}
                                goalText={goalText}
                                percent={percent}
                                handleDonation={this.handleDonation}
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div className="donors-empty">
                            <img src={ImgEmptyDonors} alt="" />
                            <h3 className="h3 nonprofitabouttitle">Giving is good</h3>
                            <label className="joined">Donate and make a difference today!</label>
                            <button type="button" className="w-button" onClick={this.handleDonation}>
                              Donate
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            {item.key !== '' ? (
              <div className="nonprofit-mobile-detail program">
                <ProgramHeaderMobile item={item} program={program} />
                <Tabs defaultIndex={tabIndex}>
                  <TabList>
                    <Tab>About</Tab>
                    <Tab>Campaigns</Tab>
                    <Tab>Donors</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="w-row nonprofit-description" style={{ marginBottom: 20 }}>
                      <div className="w-col w-col-12 w-col-stack">{program.description}</div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {campaignEmpty ? (
                      <div className="donors-empty">
                        <img src={ImgEmptyCampaign} alt="" />
                        <label className="empty-header">There are no active campaigns</label>
                        <label className="empty-label">Let&apos;s start a new campaign!</label>
                      </div>
                    ) : (
                      <>
                        {filteredCampaigns.length > 0 ? (
                          <NonprofitCampaignsMobile
                            campaigns={filteredCampaigns}
                            title="Program"
                            nid={item.key}
                            hideRaised={item.hideDonation}
                            hideOrganizer
                            showMetric
                          />
                        ) : null}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {donorsEmpty ? (
                      <div className="donors-empty">
                        <img src={ImgEmptyDonors} alt="" />
                        <label className="empty-header">Giving is good</label>
                        <label className="empty-label">Donate and make a difference today!</label>
                      </div>
                    ) : (
                      <>
                        {filteredDonors.length > 0 ? (
                          <NonprofitDonorsMobile donors={filteredDonors} title="Program" />
                        ) : null}
                      </>
                    )}
                  </TabPanel>
                </Tabs>
                <NonprofitStickyButton nid={item.key.toString()} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

ProgramDetail.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

ProgramDetail.defaultProps = {
  authUser: null,
};

const RightSideComponent = ({ raisedText, goal, goalText, percent, handleDonation }) => (
  <div className="w-col w-col-3 w-col-stack nonprofitfinancialcontent">
    <div className="w-row">
      <div className="w-col w-col-12 w-col-stack">
        <h3 className="h3 nonprofitraisedtitle">Amount raised</h3>
      </div>
    </div>
    {goal > 0 ? (
      <>
        <div className="flexbox-space-around">
          <div>
            <h3 className="raisedtext">{`$ ${raisedText}`}</h3>
            <label className="financiallabel">Raised</label>
          </div>
          <div>
            <h3 className="goaltext">{`$ ${goalText}`}</h3>
            <label className="financiallabel">Goal</label>
          </div>
        </div>
        <div className="w-row">
          <div className="w-col w-col-12 w-col-stack">
            <div className="progressbar">
              <Progress percent={percent} theme={{ success: { symbol: ' ', color: '#63D8B5' } }} status="success" />
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className="w-row">
        <div className="w-col w-col-12 w-col-stack">
          <h3 className="raisedtext" style={{ fontSize: 30 }}>{`$ ${raisedText}`}</h3>
          <label className="financiallabel" style={{ marginTop: 10 }}>
            Raised
          </label>
        </div>
      </div>
    )}
    <div className="w-row" style={goal > 0 ? {} : { marginTop: 35 }}>
      <div className="w-col w-col-12 w-col-stack">
        <button type="button" className="w-button f-btn-danger" style={{ width: '100%' }} onClick={handleDonation}>
          Support
        </button>
      </div>
    </div>
  </div>
);

RightSideComponent.propTypes = {
  raisedText: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  goalText: PropTypes.string.isRequired,
  percent: PropTypes.string.isRequired,
  handleDonation: PropTypes.func.isRequired,
};

export default withNonprofitDetail(ProgramDetail);
