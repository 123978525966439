/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { database } from '../firebase';
import DonorInfo from './DonorInfo';

import ImgEmptyDonors from '../Assets/Images/Illustration-3.svg';

export default class NonprofitDonorsMobile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      filteredDonors: [],
      donorsEmpty: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { nid, donors, hideRaised } = this.props;

    if (!hideRaised) {
      if (donors) {
        this.setState({
          filteredDonors: donors,
          donorsEmpty: donors.length === 0,
        });
      } else {
        this.getDonorList(nid);
      }
    } else {
      this.setState({ donorsEmpty: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDonorList(id) {
    database
      .getRef(`/donations/${id}`)
      .once('value')
      .then(donationSnap => {
        const donors = [];
        donationSnap.forEach(snap => {
          let dateObj = null;
          if (snap.val().date) {
            dateObj = new Date(snap.val().date);
          }

          const donorTo = snap.val().program ? snap.val().program.name : snap.val().nonprofit.name;
          const userInfo = snap.val().user;
          userInfo.fullName = userInfo.anonymous ? 'Anonymous Donor' : `${userInfo.firstName} ${userInfo.lastName}`;
          userInfo.dedication = snap.val().dedication;
          userInfo.donorTo = snap.val().campaign ? snap.val().campaign.name : donorTo;
          userInfo.date = dateObj;
          userInfo.total = snap.val().total;
          userInfo.idTrx = snap.val().idTrx;
          donors.push(userInfo);
        });

        if (donors.length > 0) {
          donors.reverse();
        }

        if (this._isMounted) {
          this.setState({
            filteredDonors: donors,
            donorsEmpty: donors.length === 0,
          });
        }
      });
  }

  render() {
    const { filteredDonors, donorsEmpty } = this.state;
    const { title } = this.props;
    const plural = filteredDonors.length > 1 ? 's' : '';

    return (
      <div>
        {donorsEmpty ? (
          <div className="donors-empty">
            <img src={ImgEmptyDonors} alt="" />
            <label className="empty-header">Giving is good</label>
            <label className="empty-label">Donate and make a difference today!</label>
          </div>
        ) : (
          <div className="donors-mobile-list">
            <label className="tab-label">{`${title} Donor${plural} (${filteredDonors.length})`}</label>
            {filteredDonors.map(item => (
              <DonorInfo key={item.idTrx} item={item} viewType="mobile-row" simple={title === 'Campaign'} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

NonprofitDonorsMobile.propTypes = {
  donors: PropTypes.instanceOf(Object),
  nid: PropTypes.string,
  title: PropTypes.string,
  hideRaised: PropTypes.bool,
};

NonprofitDonorsMobile.defaultProps = {
  donors: null,
  nid: null,
  title: 'Community Of',
  hideRaised: false,
};
