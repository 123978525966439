import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import Script from 'react-load-script';
import ScrollAnimation from 'react-animate-on-scroll';
import { CSSTransition } from 'react-transition-group';
import { Progress } from 'react-sweet-progress';
import CurrencyInput from 'react-currency-masked-input';
import DatePicker from 'react-16-bootstrap-date-picker';
import queryString from 'query-string';
import Loader from 'react-loader-spinner';

import { database, storage } from '../firebase';
import { stringHelper, dateTimeHelper, numberHelper } from '../helpers';
import { CampaignProvider, MetricProvider, NonprofitProvider } from '../Providers';
import { BackButton, UploadButton } from '../Components/HtmlTag';
import WaitLoader from '../Components/Waiting';

import * as routes from '../constants/routes';
import withAuthorization from '../withAuthorization';

import imgPlaceHolder from '../Assets/Images/PlaceHolder.svg';
import DateIcon from '../Assets/Images/DateIcon.svg';
import ImgSearch from '../Assets/Images/Fill10.svg';
import ImgCampaignChainNonprofit from '../Assets/Images/Campaign-Chain-Nonprofit.svg';
import ImgCampaignChainProgram from '../Assets/Images/Campaign-Chain-program.svg';

import 'animate.css/animate.min.css';
import 'react-sweet-progress/lib/style.css';
import '../Assets/Styles/Campaign-Register.css';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: null,
      mediaCover: null,
      mediaCoverPreview: imgPlaceHolder,
      title: '',
      location: '',
      campaignDate: new Date().toISOString(),
      campaignEndDate: null,
      goal: '0.00',
      beneficiary: '',
      beneficiaryDetail: null,
      campaignProgram: { id: '', name: '' },
      nonprofits: [],
      nonprofitsSelectOptions: [],
      metric: '',
      metricSelectOptions: [],
      story: '',
      programs: [],
      filteredPrograms: [],
      programLoad: true,
      primaryOrganizer: null,
      step: 0,
      percent: 33,
      showBack: false,
      showNonprofit: true,
      showProgram: false,
      showMetric: false,
      error: false,
      errorMessage: '',
      buttonText: 'Save',
      disabledContinue: false,
      waiting: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.searchEnter = this.searchEnter.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, location, history, authUser } = this.props;
    updateNavStatus('logosupport');
    showFooterLinkContainer(false);

    const urlQuery = queryString.parse(location.search);
    if (urlQuery.j) {
      //this.getPrimaryOrganizerDetail(urlQuery.j);
      history.push(routes.CAMPAIGNS);
    } else {
      const params = { beneficiary: null, program: null, primary: null };
      if (urlQuery.b) {
        params.beneficiary = urlQuery.b;
      }
      if (urlQuery.p) {
        params.program = urlQuery.p;
      }

      NonprofitProvider.userIsAgent(authUser.user.uid).then(agentChecking => {
        let showNonprofit = true;
        if (agentChecking.isAgent) {
          params.beneficiary = agentChecking.nid;
          params.program = null;
          showNonprofit = false;

          NonprofitProvider.getSubscription(params.beneficiary).then(result => {
            if (result.campaignLimit === -1 || (result.campaignLimit > 0 && result.campaignLimit > result.campaigns)) {
              this.setState({ params, showNonprofit }, () => {
                this.loadBeneficiary();
              });
            } else {
              history.push(routes.CAMPAIGNS);
            }
          });
        } else {
          history.push(routes.CAMPAIGNS);
        }
      });
    }
  }

  getProgramList(id) {
    this.setState({ programLoad: true });
    database
      .getRef('/nonprofit_programs')
      .child(id)
      .once('value')
      .then(snap => {
        const programs = [];
        const campaignProgram = { id: '', name: '' };
        const { params, primaryOrganizer } = this.state;

        snap.forEach(childSnap => {
          programs.push({
            id: childSnap.val().id,
            name: childSnap.val().name,
          });

          if (params.program && childSnap.val().id.toString() === params.program.toString()) {
            campaignProgram.id = childSnap.val().id;
            campaignProgram.name = childSnap.val().name;
          }
        });

        this.setState({
          campaignProgram,
          programs,
          filteredPrograms: programs,
          programLoad: false,
          showProgram: !primaryOrganizer || primaryOrganizer.showProgram,
        });
      });
  }

  async getNonprofitMetric() {
    const { beneficiary, campaignProgram, primaryOrganizer } = this.state;
    const metricSnapshot = await MetricProvider.getListByProgram(beneficiary, campaignProgram.id);

    let metric = '';
    const metricSelectOptions = [];
    metricSnapshot.forEach(item => {
      metricSelectOptions.push({ value: item.key, label: item.val().name });
      if (primaryOrganizer && primaryOrganizer.metric === item.key.toString()) {
        metric = item.key;
      }
    });

    this.setState({ metric });

    return { metricSelectOptions };
  }

  async getBeneficiaryDonation(id) {
    let totalGoal = 0.0;
    const campaignSnap = await database
      .getRef('/campaigns')
      .orderByChild('nonprofit/key')
      .equalTo(id)
      .once('value');
    campaignSnap.forEach(snap => {
      if (snap.val().published) {
        if (snap.val().goal) {
          totalGoal += parseFloat(snap.val().goal);
        }
      }
    });

    let totalRaised = 0.0;
    const donationSnap = await database.getRef(`/donations/${id}`).once('value');
    donationSnap.forEach(snap => {
      totalRaised += parseFloat(snap.val().total.amount);
    });

    let percent = 0;
    if (totalGoal > 0) {
      percent = (totalRaised / totalGoal) * 100;
      if (percent > 100) {
        percent = 100;
      }
    }

    this.setState(prevState => {
      const { beneficiaryDetail } = prevState;
      beneficiaryDetail.raised = numberHelper.getThousandString(totalRaised.toFixed(0), 0);
      beneficiaryDetail.goal = numberHelper.getThousandString(totalGoal.toFixed(0), 0);
      beneficiaryDetail.percent = percent;

      return { beneficiaryDetail };
    });
  }

  async getPrimaryOrganizerDetail(id) {
    const snap = await database.getRef(`/campaigns/${id}`).once('value');
    if (snap.val()) {
      const { history } = this.props;
      const subscribe = await NonprofitProvider.getSubscription(snap.val().nonprofit.key);
      if (
        subscribe.campaignLimit === -1 ||
        (subscribe.campaignLimit > 0 && subscribe.campaignLimit > subscribe.campaigns)
      ) {
        const totalGoal = snap.val().goal ? Number.parseFloat(snap.val().goal).toFixed(2) : 0.0;
        const primary = snap.val().primary ? snap.val().primary.toString() : id;
        const primaryOrganizer = {
          id,
          name: snap.val().name,
          goal: totalGoal.toString(),
          program: snap.val().program,
          metric: snap.val().metric,
          showProgram: !snap.val().program,
          showMetric: !snap.val().metric,
        };
        const params = {
          beneficiary: snap.val().nonprofit.key.toString(),
          program: snap.val().program ? snap.val().program.id.toString() : null,
          primary,
        };

        this.setState(
          {
            params,
            primaryOrganizer,
            showNonprofit: false,
            title: snap.val().name,
            location: snap.val().location,
            story: snap.val().story,
            goal: totalGoal.toString(),
          },
          () => {
            this.loadBeneficiary();
          }
        );
      } else {
        history.push(routes.CAMPAIGNS_ABOUT.replace(':id', id).replace(':name', stringHelper.slugify(snap.val().name)));
      }
    }
  }

  loadBeneficiary() {
    const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
    const nonprofitsIndex = client.initIndex('nonprofit');
    const { params, primaryOrganizer } = this.state;
    let { beneficiaryDetail } = this.state;

    nonprofitsIndex.search(
      {
        query: '',
        attributesToRetrieve: [
          'name',
          'city',
          'state',
          'description',
          'img',
          'key',
          'agent',
          'hideDonation',
          'subscribe',
        ],
        hitsPerPage: 999,
      },
      (err, content) => {
        if (err) throw err;

        const items = [];
        const selectOptions = [];
        const promises = [];
        let beneficiary = '';
        content.hits.forEach(item => {
          if (item.name && item.subscribe) {
            items[item.key] = {
              name: item.name,
              city: item.city,
              state: item.state,
              description: item.description,
              img: item.img,
              agent: item.agent,
              key: item.key,
              hideDonation: item.hideDonation ? item.hideDonation : false,
              subscribe: item.subscribe,
            };

            promises.push(CampaignProvider.getCountByNonprofit(item.key));
          }
        });

        Promise.all(promises).then(result => {
          result.forEach(snap => {
            const nonprofitInfo = items[snap.key];
            const campaignLimit = nonprofitInfo.subscribe.campaignLimit ? nonprofitInfo.subscribe.campaignLimit : 0;
            if (campaignLimit === -1 || (campaignLimit > 0 && campaignLimit > snap.totalCampaign)) {
              selectOptions.push({ value: nonprofitInfo.key, label: nonprofitInfo.name });
              if (params.beneficiary && nonprofitInfo.key.toString() === params.beneficiary.toString()) {
                beneficiary = nonprofitInfo.key.toString();
              }
            }
          });

          if (primaryOrganizer && !primaryOrganizer.showProgram) {
            beneficiaryDetail = {
              ...items[beneficiary],
              agentProfile: null,
              raised: '0.00',
              goal: '0.00',
              percent: 0,
            };
          }

          if (selectOptions.length) {
            selectOptions.reverse();
          }

          this.setState(
            {
              beneficiary,
              beneficiaryDetail,
              nonprofits: items,
              nonprofitsSelectOptions: selectOptions,
              waiting: false,
            },
            () => {
              if (primaryOrganizer && !primaryOrganizer.showProgram) {
                this.getBeneficiaryDonation(beneficiary);
              }
              if (beneficiary !== '') {
                this.getProgramList(beneficiary);
              }
            }
          );
        });
      }
    );
  }

  handleBackClick(e) {
    e.preventDefault();

    this.setState({
      step: 0,
      percent: 33,
      showBack: false,
    });
  }

  handleChange(event) {
    const { target } = event;
    const { name, type } = target;
    const value = type === 'checkbox' ? target.checked : target.value;

    if (name === 'beneficiary') {
      this.setState({
        campaignProgram: { id: '', name: '' },
        beneficiaryDetail: null,
        metric: '',
        error: false,
        errorMessage: '',
      });

      if (value) {
        this.getProgramList(value);
      }
    }

    if (name === 'metric') {
      this.setState({
        error: false,
        errorMessage: '',
      });
    }

    this.setState({ [name]: value });
  }

  handleScriptLoad() {
    // Declare Options For Autocomplete
    const options = {
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /* global google */ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('location'),
      options
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect() {
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      this.setState({ location: addressObject.formatted_address });
    }
  }

  handleAmountChange(event, maskValue) {
    const { target } = event;
    const { name } = target;

    this.setState({ [name]: maskValue });
  }

  handleDateChange(date) {
    this.setState({ campaignDate: date });
  }

  handleEndDateChange(date) {
    this.setState({ campaignEndDate: date });
  }

  handleProgramSelected(item, event) {
    event.preventDefault();

    this.setState({
      campaignProgram: item,
      metric: '',
      error: false,
      errorMessage: '',
    });
  }

  handleFileChange(event) {
    const { name } = event.target;
    const previewImg = `${name}Preview`;
    const extImg = `${name}Ext`;
    // const ext = event.target.files[0].name.split('.').pop();

    this.setState({
      [name]: event.target.files[0],
      [previewImg]: URL.createObjectURL(event.target.files[0]),
      [extImg]: 'jpg', // set all extension to jpg, easy when removing from storage
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      step,
      beneficiary,
      nonprofits,
      campaignProgram,
      story,
      metric,
      metricSelectOptions,
      primaryOrganizer,
    } = this.state;
    let { beneficiaryDetail } = this.state;

    if (step === 0) {
      if (beneficiary === '') {
        this.setState({ error: true, errorMessage: 'Please choose beneficiary' });
      } else if (campaignProgram.id === '') {
        this.setState({ error: true, errorMessage: 'Please choose one program' });
      } else {
        document.location.href = '#top';

        let loadDonation = false;
        if (!beneficiaryDetail) {
          loadDonation = true;
          beneficiaryDetail = {
            ...nonprofits[beneficiary],
            agentProfile: null,
            raised: '0.00',
            goal: '0.00',
            percent: 0,
          };
        }

        this.getNonprofitMetric().then(result => {
          const showMetric =
            (!primaryOrganizer && result.metricSelectOptions.length > 0) ||
            (primaryOrganizer && primaryOrganizer.showMetric);
          this.setState(
            {
              step: 1,
              percent: 66,
              beneficiaryDetail,
              metricSelectOptions: result.metricSelectOptions,
              showMetric,
              showBack: true,
              error: false,
              errorMessage: '',
            },
            () => {
              if (loadDonation) {
                this.getBeneficiaryDonation(beneficiary);
              }
            }
          );
        });
      }
    } else if (metricSelectOptions.length > 0 && metric === '') {
      this.setState({ error: true, errorMessage: 'Please choose one metric' });
    } else if (story.trim() === '') {
      this.setState({ error: true, errorMessage: 'Campaign Story is required' });
    } else {
      this.setState({
        showBack: false,
        buttonText: 'Please wait...',
        disabledContinue: true,
      });

      this.saveCampaign();
    }
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const { programs } = this.state;
      const filteredPrograms = programs.filter(
        program => program.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
      );

      this.setState({
        filteredPrograms,
        campaignProgram: { id: '', name: '' },
        metric: '',
      });
    }
  }

  async saveCampaign() {
    try {
      const {
        campaignDate,
        campaignEndDate,
        goal,
        location,
        title,
        beneficiaryDetail,
        story,
        campaignProgram,
        params,
        mediaCover,
        mediaCoverExt,
        metric,
        primaryOrganizer,
      } = this.state;
      const { authUser, history } = this.props;
      const campaignKey = dateTimeHelper.getCurrentTimeMs();
      const campaignData = {
        date: dateTimeHelper.setToTime(campaignDate).valueOf(),
        goal: goal !== '' ? Number.parseFloat(goal) : 0.0,
        id: campaignKey,
        location,
        name: title,
        nonprofit: {
          img: beneficiaryDetail.img,
          key: beneficiaryDetail.key,
          name: beneficiaryDetail.name,
        },
        published: true,
        story,
        time: campaignKey,
        user: {
          email: authUser.profile.email,
          firstName: authUser.profile.firstName,
          lastName: authUser.profile.lastName,
          scid: authUser.profile.scid,
          uid: authUser.profile.uid,
        },
      };

      if (campaignEndDate) {
        campaignData.endDate = dateTimeHelper.setToTime(campaignEndDate, 23, 59, 59).valueOf();
      }

      if (campaignProgram.id !== '') {
        campaignData.program = campaignProgram;
      }

      if (params.primary) {
        campaignData.primary = Number.parseInt(params.primary, 10);
      }

      if (metric !== '') {
        campaignData.metric = metric;
      }

      if (primaryOrganizer && primaryOrganizer.goal === goal) {
        const totalGoal = campaignData.goal + Number.parseFloat(primaryOrganizer.goal);
        await database.setRef(`/campaigns/${primaryOrganizer.id}/goal`, totalGoal);

        history.push(
          routes.CAMPAIGNS_ABOUT.replace(':id', primaryOrganizer.id).replace(
            ':name',
            stringHelper.slugify(primaryOrganizer.name)
          )
        );
      } else {
        await database.setRef(`/campaigns/${campaignKey}`, campaignData);

        // store the image
        const coverRefPath = process.env.REACT_APP_FIREBASE_CAMPAIGN_COVER_IMAGE_PATH;
        const mediaCoverFile = {
          file: mediaCover,
          meta: mediaCover ? { contentType: mediaCover.type } : '',
          name: '',
          ext: mediaCoverExt,
        };

        if (mediaCoverFile.file) {
          const coverSnap = await storage.put(
            `${coverRefPath}/${campaignKey}.${mediaCoverFile.ext}`,
            mediaCoverFile.file,
            mediaCoverFile.meta
          );
          const coverUrl = await coverSnap.ref.getDownloadURL();

          mediaCoverFile.name = coverUrl;

          await database.setRef(`/campaigns/${campaignKey}/cover`, mediaCoverFile.name);
        }

        // create dynamic link
        const slugName = stringHelper.slugify(title);
        const viewUrl = routes.CAMPAIGNS_ABOUT.replace(':id', campaignKey).replace(':name', slugName);
        const campaignInfo = {
          id: campaignKey,
          name: title,
          story,
          cover: mediaCoverFile.name,
          viewUrl,
        };
        await CampaignProvider.createDynamicLink(campaignInfo);

        history.push(routes.CAMPAIGNS_ABOUT.replace(':id', campaignKey).replace(':name', stringHelper.slugify(title)));
      }
    } catch (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        showBack: true,
        buttonText: 'Save',
        disabledContinue: false,
      });
    }
  }

  render() {
    document.title = 'Start Your Campaign - Fundra';
    const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const {
      showBack,
      waiting,
      step,
      percent,
      title,
      location,
      campaignDate,
      campaignEndDate,
      goal,
      beneficiary,
      nonprofitsSelectOptions,
      error,
      errorMessage,
      programLoad,
      filteredPrograms,
      campaignProgram,
      story,
      mediaCover,
      mediaCoverPreview,
      buttonText,
      disabledContinue,
      beneficiaryDetail,
      metric,
      metricSelectOptions,
      showNonprofit,
      showProgram,
      showMetric,
    } = this.state;

    return (
      <div id="top" className="DonationSection">
        <div className="contentwrapper">
          <div className="backlink">
            <BackButton showBackButton={showBack} handleClick={this.handleBackClick} />
          </div>
          {waiting ? (
            <WaitLoader clsStyle="section-loader-ss" />
          ) : (
            <React.Fragment>
              {step === 0 ? (
                <ScrollAnimation animateIn="fadeInUp" animateOnce delay={400}>
                  <form
                    id="register-form"
                    name="register-form"
                    action="submitform"
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                  >
                    <div className="w-row donationRow">
                      <div className="w-col w-col-8 campaign-form">
                        <Script
                          url={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`}
                          onLoad={this.handleScriptLoad}
                        />
                        <h3 className="h3 donor-title">Start campaign</h3>
                        <div className="progressbar">
                          <Progress percent={percent} theme={{ default: { symbol: ' ' } }} />
                        </div>

                        <label htmlFor="title" className="formfieldlabel">
                          Campaign Title
                        </label>
                        <input
                          type="text"
                          className="formtextfield small w-input"
                          maxLength="256"
                          name="title"
                          placeholder="Enter your campaign title"
                          value={title}
                          onChange={this.handleChange}
                          required
                        />
                        <label htmlFor="location" className="formfieldlabel">
                          Location
                        </label>
                        <input
                          type="text"
                          className="formtextfield small w-input"
                          id="location"
                          name="location"
                          placeholder="Search your location"
                          value={location}
                          onChange={this.handleChange}
                          required
                        />
                        <label htmlFor="campaignDate" className="formfieldlabel">
                          Start Date
                        </label>
                        <div className="form-group">
                          <div className="substring-right">
                            <img src={DateIcon} alt="" />
                          </div>
                          <DatePicker
                            id="campaign_date"
                            className="formtextfield small w-input"
                            value={campaignDate}
                            onChange={this.handleDateChange}
                            minDate={new Date().toISOString()}
                            showClearButton={false}
                          />
                        </div>
                        <label htmlFor="campaignDate" className="formfieldlabel">
                          End Date
                        </label>
                        <div className="form-group">
                          <div className="substring-right pad-2">
                            <img src={DateIcon} alt="" />
                          </div>
                          <DatePicker
                            id="campaign_end_date"
                            className="formtextfield small w-input"
                            value={campaignEndDate}
                            onChange={this.handleEndDateChange}
                            minDate={new Date().toISOString()}
                            showClearButton
                          />
                        </div>
                        <label htmlFor="goal" className="formfieldlabel">
                          Campaign goal
                        </label>
                        <div className="form-group">
                          <span className="substring-left">$</span>
                          <CurrencyInput
                            className="formtextfield small w-input currencyinput textfieldright"
                            name="goal"
                            value={goal}
                            inputMode="numeric"
                            placeholder="Enter your campaign goal"
                            onChange={this.handleAmountChange}
                          />
                        </div>
                        {showNonprofit ? (
                          <React.Fragment>
                            <label htmlFor="goal" className="formfieldlabel">
                              Beneficiary
                            </label>
                            <select
                              name="beneficiary"
                              className="formdropdown small w-select"
                              value={beneficiary}
                              onChange={this.handleChange}
                              required
                            >
                              <option value="">Select one</option>
                              {nonprofitsSelectOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </React.Fragment>
                        ) : null}
                        <CSSTransition in={error} timeout={500} classNames="errormessage" unmountOnExit>
                          {() => (
                            <div className="error-block" style={{ marginTop: 30 }}>
                              <div className="error-block-text">{errorMessage}</div>
                            </div>
                          )}
                        </CSSTransition>
                        <input type="submit" value="Save" className="formsubmit w-button campaign-submit-desktop" />
                      </div>
                      {showProgram ? (
                        <div className="w-col w-col-4">
                          <h3 className="h3 select-title">Choose a Program</h3>
                          {beneficiary !== '' ? (
                            <div className="campaign-program-search">
                              <div className="graygroupinput">
                                <div className="searchinput">
                                  <input
                                    name="searchQuery"
                                    type="text"
                                    placeholder="Search programs"
                                    onKeyPress={this.searchEnter}
                                  />
                                </div>
                                <div className="searchicon">
                                  <img src={ImgSearch} alt="" />
                                </div>
                              </div>
                              {programLoad ? (
                                <div className="section-loader-container-right">
                                  <Loader color="#3898EC" type="ThreeDots" height={60} width={60} />
                                </div>
                              ) : (
                                <div className="donation-program">
                                  <div className="float-left-section">
                                    {filteredPrograms.map(item => {
                                      let clsName = 'w-button f-btn-primary';
                                      if (campaignProgram.id === item.id) {
                                        clsName += ' selected2';
                                      }

                                      return (
                                        <div className="float-item" key={item.id}>
                                          <button
                                            type="button"
                                            className={clsName}
                                            onClick={this.handleProgramSelected.bind(this, item)}
                                          >
                                            {item.name}
                                          </button>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <label className="noted f-font-regular">
                              Program will load after you select beneficiary
                            </label>
                          )}
                        </div>
                      ) : (
                        <div className="w-col w-col-4 beneficiary-detail">
                          {beneficiaryDetail ? (
                            <BeneficiaryInfo beneficiaryDetail={beneficiaryDetail} campaignProgram={campaignProgram} />
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="campaign-submit-mobile">
                      <input type="submit" value="Save" className="formsubmit w-button" />
                    </div>
                  </form>
                </ScrollAnimation>
              ) : null}
              <CSSTransition in={step === 1} timeout={500} classNames="registerstep" unmountOnExit>
                {() => (
                  <div className="w-row donationRow">
                    <div className="w-col w-col-8 campaign-form">
                      <h3 className="h3 donor-title">Start campaign</h3>
                      <div className="progressbar">
                        <Progress percent={percent} theme={{ default: { symbol: ' ' } }} />
                      </div>
                      <form
                        id="register-form"
                        name="register-form"
                        action="submitform"
                        onSubmit={this.handleSubmit}
                        autoComplete="off"
                      >
                        {showMetric > 0 ? (
                          <React.Fragment>
                            <label htmlFor="metric" className="formfieldlabel">
                              Metric
                            </label>
                            <select
                              name="metric"
                              className="formdropdown small w-select"
                              value={metric}
                              onChange={this.handleChange}
                              required
                            >
                              <option value="">Select one</option>
                              {metricSelectOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </React.Fragment>
                        ) : null}
                        <label htmlFor="story" className="formfieldlabel">
                          Campaign Story
                        </label>
                        <textarea
                          className="formtextfield w-input"
                          rows={5}
                          name="story"
                          value={story}
                          placeholder="Tell us your story"
                          onChange={this.handleChange}
                          required
                        />
                        <div className="w-row">
                          <div className="w-col w-col-6 w-col-stack">
                            <label className="formfieldlabel">Upload cover photo</label>
                            <img src={mediaCoverPreview} className="nonprofitcoverpreview" alt="" />
                          </div>
                          <div className="w-col w-col-6 w-col-stack">
                            <label className="coverlabel1 cover">Add photos or videos</label>
                            <label className="coverlabel2">{mediaCover ? '' : 'no file uploaded yet'}</label>
                            <div className="upload-btn-wrapper">
                              <UploadButton />
                              <input type="file" name="mediaCover" onChange={this.handleFileChange} accept="image/*" />
                            </div>
                          </div>
                        </div>
                        <CSSTransition in={error} timeout={500} classNames="errormessage" unmountOnExit>
                          {() => (
                            <div className="error-block" style={{ marginTop: 30 }}>
                              <div className="error-block-text">{errorMessage}</div>
                            </div>
                          )}
                        </CSSTransition>
                        <input
                          type="submit"
                          value={buttonText}
                          className="formsubmit w-button"
                          disabled={disabledContinue}
                        />
                      </form>
                    </div>
                    <div className="w-col w-col-4 beneficiary-detail">
                      <BeneficiaryInfo beneficiaryDetail={beneficiaryDetail} campaignProgram={campaignProgram} />
                    </div>
                  </div>
                )}
              </CSSTransition>
              <CSSTransition in={step === 2} timeout={500} classNames="registerstep" unmountOnExit>
                {() => (
                  <div className="claimsuccess">
                    <div className="success-block">
                      <div className="success-block-text">
                        {`Thank you for registering your campaign.\nYou will be contacted by Fundra to validate your application!`}
                      </div>
                    </div>
                  </div>
                )}
              </CSSTransition>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  updateNavStatus: PropTypes.func.isRequired,
  showFooterLinkContainer: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object).isRequired,
};

const BeneficiaryInfo = ({ beneficiaryDetail, campaignProgram }) => (
  <React.Fragment>
    <h3 className="h3 select-title">Beneficiary detail</h3>
    <div className="nonprofit-small-detail">
      <div className="program-header">
        <div className="program-name">
          <span className="title">{beneficiaryDetail.name}</span>
          <span className="street">{`${beneficiaryDetail.city}, ${beneficiaryDetail.state}`}</span>
        </div>
      </div>
      <div className="nonprofit-cover">
        <img
          src={beneficiaryDetail.img ? beneficiaryDetail.img.logo : imgPlaceHolder}
          alt={`${beneficiaryDetail.name} Cover`}
        />
      </div>
      <div className="nonprofit-body">
        {beneficiaryDetail.agentProfile ? (
          <div className="campaignuser">
            <div className="item profile">
              <img
                src={beneficiaryDetail.agentProfile.avatar}
                alt=""
                title={beneficiaryDetail.agentProfile.userFullName}
              />
              {` ${beneficiaryDetail.agentProfile.userFullName}`}
            </div>
          </div>
        ) : null}
        <div className="description no-raised">{beneficiaryDetail.description}</div>
        <div className="campaignchain">
          <div className="item truncate-text f-warning-box">
            <Link
              to={routes.NONPROFITS_ABOUT.replace(':id', beneficiaryDetail.key).replace(
                ':name',
                stringHelper.slugify(beneficiaryDetail.name)
              )}
            >
              <img src={ImgCampaignChainNonprofit} alt="" />
              <span title={beneficiaryDetail.name}>{beneficiaryDetail.name}</span>
            </Link>
          </div>
          {campaignProgram.id !== '' ? (
            <div className="item truncate-text f-success-box">
              <Link
                to={routes.PROGRAMS_ABOUT.replace(':id', campaignProgram.id)
                  .replace(':nonprofitid', beneficiaryDetail.key)
                  .replace(':name', stringHelper.slugify(campaignProgram.name))}
              >
                <img src={ImgCampaignChainProgram} alt="" />
                <span title={campaignProgram.name}>{campaignProgram.name}</span>
              </Link>
            </div>
          ) : null}
        </div>
        {!beneficiaryDetail.hideDonation ? (
          <div className="totalmoney">
            <div className="item-full program">
              <div className="item-border-finance">
                <div className="finance-item">
                  <span className="f-text-bold">{`$ ${beneficiaryDetail.raised}`}</span>
                  <span>{` of $ ${beneficiaryDetail.goal}`}</span>
                </div>
              </div>
              <div className="progressbar program">
                <Progress percent={beneficiaryDetail.percent} theme={{ success: { symbol: ' ', color: '#63D8B5' } }} />
              </div>
              <div className="raised-note">
                <label>{`${beneficiaryDetail.percent.toFixed(0)} % Raised`}</label>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  </React.Fragment>
);

BeneficiaryInfo.propTypes = {
  beneficiaryDetail: PropTypes.instanceOf(Object).isRequired,
  campaignProgram: PropTypes.instanceOf(Object).isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Register);
