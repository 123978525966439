import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { stringHelper } from '../helpers';

import * as routes from '../constants/routes';

import ImgLogoDefault from '../Assets/Images/Media_Icon.svg';
import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';

class NonprofitCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      city: '',
      state: '',
      description: '',
      img: null,
      viewUrl: '',
    };

    this.handleDonation = this.handleDonation.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    const slugName = stringHelper.slugify(item.name);
    const viewUrl = routes.NONPROFITS_ABOUT;

    this.setState({
      name: item.name,
      city: item.city,
      state: item.state,
      description: item.description,
      key: item.key,
      img: item.img,
      viewUrl: viewUrl.replace(':id', item.key).replace(':name', slugName),
    });
  }

  handleDonation() {
    const { history } = this.props;
    const { key } = this.state;

    history.push(routes.NONPROFITS_DONATION.replace(':id', key).replace(':type', 'b'));
  }

  render() {
    const {
      img,
      name,
      city,
      state,
      viewUrl,
      description,
    } = this.state;
    const { color, tinyColor } = this.props;
    const nonprofitLogo = (img) ? img.logo : ImgLogoDefault;
    const nonprofitCover = (img) ? img.cover : ImgCoverDefault;

    return (
      <div className="nonprofit-card-metric">
        <div className="nonprofit-card-metric-header">
          <Link to={viewUrl}>
            <div className="nonprofit-logo" style={{ backgroundImage: `url("${nonprofitLogo}")` }} />
          </Link>
          <div className="nonprofit-info">
            <div className="title">{name}</div>
            <div className="location">{`${city}, ${state}`}</div>
          </div>
        </div>
        <Link to={viewUrl}>
          <div className="nonprofit-card-metric-cover" style={{ backgroundImage: `url("${nonprofitCover}")` }} />
        </Link>
        <div className="nonprofit-card-metric-info" style={{ backgroundColor: color }}>
          <div className="truncate-description three-line">{description}</div>
          <button type="button" className="w-button" style={{ backgroundColor: tinyColor }} onClick={this.handleDonation}>Support</button>
        </div>
      </div>
    );
  }
}

NonprofitCard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  color: PropTypes.string.isRequired,
  tinyColor: PropTypes.string,
};

NonprofitCard.defaultProps = {
  tinyColor: '#EB7557',
};

export default withRouter(NonprofitCard);
