import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import { auth } from './firebase';

import SocialMediaButton from './Components/SocialMediaButton';

import 'animate.css/animate.min.css';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      email: '',
      buttonText: 'Reset Password',
      buttonDisabled: false,
    };

    this.state = this.initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer } = this.props;

    updateNavStatus('onlylogo');
    showFooterLinkContainer(false);
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ buttonText: 'Please wait...', buttonDisabled: true });

    const { history } = this.props;
    const { email } = this.state;
    auth
      .passwordReset(email)
      .then(() => {
        history.push('/signin?action=reset-password-success');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { email, buttonText, buttonDisabled } = this.state;
    document.title = 'Forgot Password - Fundra';

    return (
      <div className="ForgotPasswordSection">
        <div className="contentwrapper">
          <h2 className="h2 featuretitle signintitle">Forgot Password</h2>
          <div className="featuretitledesc">
            Enter your email below and we will send you a magic link to reset
            your password.
          </div>
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={400}>
            <div className="formlogin w-form">
              <form
                action="submitcontact"
                onSubmit={this.handleSubmit}
                autoComplete="off"
              >
                <label htmlFor="email" className="formfieldlabel">
                  Email address
                </label>
                <input
                  type="email"
                  className="formtextfield small w-input"
                  maxLength="256"
                  name="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="submit"
                  value={buttonText}
                  disabled={buttonDisabled}
                  className="formsubmit w-button"
                />
                <div className="registerlinkcontainer">
                  {"Don't have an account? "}
                  <Link className="registerlink" to="/register">
                    Register
                  </Link>
                </div>
              </form>
              <SocialMediaButton caption="Sign In" />
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  updateNavStatus: PropTypes.func.isRequired,
  showFooterLinkContainer: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default ForgotPassword;
