import { database } from '../firebase';

const dbName = 'nonprofit_impacts';
export const getList = (nid, mid) =>
  database
    .getRef(`/${dbName}/${nid}/${mid}`)
    .orderByChild('date')
    .once('value');
export const getListLive = (nid, mid) => database.getRef(`/${dbName}/${nid}/${mid}`).orderByChild('date');
export const getPromiseList = (nid, mid) =>
  new Promise(resolve => {
    const impacts = [];
    getList(nid, mid).then(impactSnapshot => {
      impactSnapshot.forEach(item => {
        impacts.push({ id: item.key, parent: mid, ...item.val() });
      });

      if (impacts.length > 0) {
        impacts.reverse();
      }

      resolve(impacts);
    });
  });
export const getDetail = (nid, mid, id) => database.getRef(`/${dbName}/${nid}/${mid}/${id}`).once('value');
export const pushData = (nid, mid, data) => database.pushRef(`/${dbName}/${nid}/${mid}`, data);
export const updateData = (nid, mid, id, data) => database.setRef(`/${dbName}/${nid}/${mid}/${id}`, data);
export const updatePartialData = (nid, mid, id, path, data) =>
  database.setRef(`/${dbName}/${nid}/${mid}/${id}/${path}`, data);
export const remove = (nid, mid, id) => database.removeRef(`/${dbName}/${nid}/${mid}/${id}`);
export const removeChild = async (nid, mid, clearList = false) => {
  await database.removeRef(`/${dbName}/${nid}/${mid}`);
  return clearList ? 'clear' : 'done';
};
