import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import queryString from 'query-string';

import { database } from '../firebase';
import { stringHelper } from '../helpers';

import * as routes from '../constants/routes';
import withNonprofitDetail from '../withNonprofitDetail';

import NonprofitLeftDetail from '../Components/NonprofitLeftDetail';
import SocialShareComponent from '../Components/SocialShare';
import NonprofitCampaignCard from '../Components/NonprofitCampaignCard';
import NonprofitHeaderMobile from '../Components/NonprofitHeaderMobile';
import NonprofitAboutMobile from '../Components/NonprofitAboutMobile';
import NonprofitProgramsMobile from '../Components/NonprofitProgramsMobile';
import NonprofitCampaignsMobile from '../Components/NonprofitCampaignsMobile';
import NonprofitDonorsMobile from '../Components/NonprofitDonorsMobile';
import NonprofitFinancialsMobile from '../Components/NonprofitFinancialsMobile';
import NonprofitStickyButton from '../Components/NonprofitStickyButton';

import ImgEmptyCampaign from '../Assets/Images/emptyCampaign.svg';

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        agent: null,
        address: '',
        category: '',
        charityNavigatorURL: '',
        city: '',
        collect: '',
        contact: '',
        description: '',
        favorite: false,
        federal: '',
        img: null,
        key: '',
        name: '',
        slugName: '',
        publish: '',
        rating: [],
        ratingStar: [],
        tagline: '',
        state: '',
        type: '',
        zipcode: '',
        viewUrl: '',
        website: '',
        viewUrlCampaign: '',
        hideDonation: false,
        stripe_account: null,
      },
      campaigns: [],
      filteredCampaigns: [],
      emptyCampaign: false,
      noProgram: false,
    };

    this.searchEnter = this.searchEnter.bind(this);
    this.handleFundraise = this.handleFundraise.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, location, showFooterLinkContainer } = this.props;
    updateNavStatus('');
    showFooterLinkContainer(false);
    const urlQuery = queryString.parse(location.search);
    const noProgram = urlQuery.search && urlQuery.search.toLowerCase() === 'noprogram';

    const { nonprofit } = this.props;
    this.setState(
      prevState => {
        const { item } = prevState;
        Object.keys(item).forEach(key => {
          item[key] = nonprofit[key];
        });

        return {
          item,
          noProgram,
        };
      },
      () => {
        this.getCampaignList(nonprofit.key);
      }
    );
  }

  getCampaignList(key) {
    database
      .getRef('/campaigns')
      .orderByChild('nonprofit/key')
      .equalTo(key)
      .once('value')
      .then(campaignSnap => {
        const { noProgram, item } = this.state;
        const campaigns = [];
        campaignSnap.forEach(snap => {
          if (snap.val().published) {
            let pushItem = true;
            const slugName = stringHelper.slugify(snap.val().name);
            if (noProgram && snap.val().program) {
              pushItem = false;
            }

            if (pushItem) {
              campaigns.push({
                id: snap.val().id,
                name: snap.val().name,
                slugName,
                location: snap.val().location,
                likes: snap.val().likes,
                user: snap.val().user,
                time: snap.val().time,
                cover: snap.val().cover,
                goal: snap.val().goal,
                story: snap.val().story,
                nonprofit: {
                  ...snap.val().nonprofit,
                  hideDonation: item.hideDonation,
                },
                program: snap.val().program,
                metric: snap.val().metric,
                viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', snap.val().id).replace(':name', slugName),
              });
            }
          }
        });

        if (campaigns.length > 0) {
          campaigns.reverse();
        }

        this.setState({
          campaigns,
          filteredCampaigns: campaigns,
          emptyCampaign: campaigns.length === 0,
        });
      });
  }

  handleFundraise() {
    const { history } = this.props;
    const { item } = this.state;

    history.push(`${routes.CAMPAIGNS_REGISTER}?b=${item.key}`);
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      const { campaigns } = this.state;
      const filteredCampaigns = campaigns.filter(
        campaign => campaign.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
      );

      this.setState({ filteredCampaigns });
    }
  }

  render() {
    const { item, filteredCampaigns, emptyCampaign, noProgram } = this.state;
    const { authUser } = this.props;
    const metaDescription =
      item.description.length > 160 ? item.description.substring(0, 160).concat('...') : item.description;

    return (
      <div className="NonprofitCampaignSection">
        {item.name !== '' ? (
          <Helmet>
            <title>{`Fundra - ${item.name} :: Campaigns`}</title>
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={`Fundra - ${item.name} :: Campaigns`} />
            <meta property="og:description" content={metaDescription} />
          </Helmet>
        ) : null}
        <div className="sectionhero">
          <div className="contentwrapper herosectionfeatures nonprofitsection">
            <div className="w-row nonprofit-detail">
              <div className="w-col w-col-3 w-col-stack f-text-center leftnav">
                <NonprofitLeftDetail item={item} authUser={authUser} withHash />
              </div>
              <div className="w-col w-col-9 w-col-stack nonprofitbodycontent">
                {item.name !== '' ? (
                  <SocialShareComponent
                    url={item.viewUrlCampaign}
                    title={item.name}
                    description={item.description}
                    webLink={item.website}
                    noCount
                    onlyIcon
                  />
                ) : null}
                <div className="heading-with-line full">
                  <h2>OUR PROJECTS & CAMPAIGNS</h2>
                </div>
                <div className="w-row">
                  <div className="w-col w-col-12 w-col-stack">
                    {emptyCampaign ? (
                      <div className="donors-empty">
                        <img src={ImgEmptyCampaign} alt="" />
                        <h3 className="h3 nonprofitabouttitle">There is no active campaigns</h3>
                        <label className="joined">Let's start a new campaign!</label>
                        <button type="button" className="w-button f-btn-warning" onClick={this.handleFundraise}>
                          Create Campaign
                        </button>
                      </div>
                    ) : (
                      <CampaignList items={filteredCampaigns} authUser={authUser} noProgram={noProgram} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {item.key !== '' ? (
              <div className="nonprofit-mobile-detail">
                <NonprofitHeaderMobile item={item} />
                <Tabs defaultIndex={2}>
                  <TabList>
                    <Tab>About</Tab>
                    <Tab>Programs</Tab>
                    <Tab>Campaigns</Tab>
                    <Tab>Donors</Tab>
                    <Tab>Financials</Tab>
                  </TabList>
                  <TabPanel>
                    <NonprofitAboutMobile item={item} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitProgramsMobile nid={item.key} />
                  </TabPanel>
                  <TabPanel>
                    {filteredCampaigns.length > 0 ? (
                      <NonprofitCampaignsMobile
                        campaigns={filteredCampaigns}
                        nid={item.key}
                        nonprofitName={item.name}
                        title={noProgram ? 'Similar' : 'All'}
                        hideRaised={item.hideDonation}
                      />
                    ) : null}
                  </TabPanel>
                  <TabPanel>
                    <NonprofitDonorsMobile nid={item.key.toString()} hideRaised={item.hideDonation} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitFinancialsMobile item={item} />
                  </TabPanel>
                </Tabs>
                <NonprofitStickyButton nid={item.key.toString()} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

Campaigns.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

Campaigns.defaultProps = {
  authUser: null,
};

function CampaignList(props) {
  let colorList = ['#E8891D', '#BFD5C9', '#05A3A4'];
  let tinyColorList = ['#BFD5C9', '#05A3A4', '#E8891D'];
  const campaignRow = [];
  let campaignCol = [];
  let rowIndex = 1;
  let columnIndex = 0;
  props.items.forEach((item, index) => {
    campaignCol.push(
      <div className="w-col w-col-4 program-col" key={item.id}>
        <NonprofitCampaignCard
          item={item}
          authUser={props.authUser}
          noSimilar={props.noProgram}
          color={colorList[columnIndex]}
          tinyColor={tinyColorList[columnIndex]}
        />
      </div>
    );
    columnIndex += 1;

    const counter = index + 1;
    if (counter % 3 === 0) {
      if (campaignCol.length > 0) {
        campaignRow.push(
          <div className="w-row" key={rowIndex}>
            {campaignCol}
          </div>
        );
        [colorList, tinyColorList] = [tinyColorList, colorList];
      }
      campaignCol = [];
      rowIndex += 1;
      columnIndex = 0;
    }
  });

  if (campaignCol.length > 0) {
    campaignRow.push(
      <div className="w-row" key={rowIndex}>
        {campaignCol}
      </div>
    );
  }

  return campaignRow;
}

export default withNonprofitDetail(Campaigns);
