import { database } from '../firebase';

const dbName = 'nonprofit_metrics';
export const getList = nid =>
  database
    .getRef(`/${dbName}/${nid}`)
    .orderByChild('date')
    .once('value');
export const getListOff = nid => database.getRef(`/${dbName}/${nid}`).off();
export const getListByProgram = (nid, pid) =>
  database
    .getRef(`/${dbName}/${nid}`)
    .orderByChild('program/id')
    .equalTo(pid.toString())
    .once('value');
export const getDetail = (nid, id) => database.getRef(`/${dbName}/${nid}/${id}`).once('value');
export const getDetailByPath = (nid, id, path) => database.getRef(`/${dbName}/${nid}/${id}/${path}`).once('value');
export const getDetailByPathOff = (nid, id, path) => database.getRef(`/${dbName}/${nid}/${id}/${path}`).off();
export const getShowHumanUnit = (nid, id) =>
  new Promise(resolve => {
    getDetailByPath(nid, id, 'showHumanUnit').then(snap => {
      resolve({ id, showHumanUnit: snap.val() ? snap.val() : 'Y' });
    });
  });
export const pushData = (nid, data) => database.pushRef(`/${dbName}/${nid}`, data);
export const updateData = (nid, id, data) => database.setRef(`/${dbName}/${nid}/${id}`, data);
export const updatePartialData = (nid, id, path, data) => database.setRef(`/${dbName}/${nid}/${id}/${path}`, data);
export const remove = (nid, id) => database.removeRef(`/${dbName}/${nid}/${id}`);
