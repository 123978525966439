const SITE_URL = process.env.REACT_APP_FIREBASE_SITEURL;
const SHARE_TEXT = 'Check out :name! They are raising money for their programs on Fundra!';
const showPopup = (url, title) => {
    const w = 600;
    const h = 300;

    // Fixes dual-screen position                         Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight;

    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;
    const newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
}

export const facebookShare = (url, title) => {
    let fbShareUrl = 'https://www.facebook.com/sharer/sharer.php?';
    fbShareUrl += `u=${SITE_URL}${url}`;
    fbShareUrl +=  `&quote=${SHARE_TEXT.replace(":name", title)}`;
    
    showPopup(encodeURI(fbShareUrl), title);
}

export const twitterShare = (url, title) => {
    let twitterShareUrl = 'https://twitter.com/intent/tweet?';
    twitterShareUrl += `text=${SHARE_TEXT.replace(":name", title)}`;
    twitterShareUrl += `&url=${SITE_URL}${url}`;
    
    showPopup(encodeURI(twitterShareUrl), title);
}
