import React from 'react';
import PropTypes from 'prop-types';
import { Flipper } from 'react-flip-toolkit';

import CampaignInfo from './CampaignInfo';

const CampaignTable = ({ items, tblClass, hideRaised, hideOrganizer, hideDate, showMetric }) => (
  <table className={tblClass}>
    <thead>
      <tr>
        {!hideOrganizer ? <th>Organizer</th> : null}
        <th>Campaign Name</th>
        {!hideRaised ? <th>Raised</th> : null}
        {showMetric ? <th>Metric Unit</th> : null}
        {!hideDate ? <th>Date</th> : null}
        <th />
      </tr>
    </thead>
    <Flipper flipKey={items.map(item => item.id).join('')} element="tbody" spring="wobbly">
      {items.map(item => (
        <CampaignInfo
          key={item.id}
          item={item}
          viewType="row"
          hideRaised={hideRaised}
          hideOrganizer={hideOrganizer}
          hideDate={hideDate}
          showMetric={showMetric}
        />
      ))}
    </Flipper>
  </table>
);

CampaignTable.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  tblClass: PropTypes.string,
  hideOrganizer: PropTypes.bool,
  hideRaised: PropTypes.bool,
  hideDate: PropTypes.bool,
  showMetric: PropTypes.bool,
};

CampaignTable.defaultProps = {
  tblClass: 'nonprofit-table',
  hideRaised: false,
  hideOrganizer: false,
  hideDate: false,
  showMetric: false,
};

export default CampaignTable;
