import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap';

import imgBack from '../Assets/Images/BackArrow.png';
import imgUpload from '../Assets/Images/Upload.svg';
import DateIcon from '../Assets/Images/DateIcon.svg';

const ChartDateOption = {
  1: { label: 'Last 7 days', value: 7 },
  2: { label: 'Last 30 days', value: 30 },
  3: { label: 'Last 60 days', value: 60 },
};

const BackButton = ({ showBackButton, handleClick }) => (
  <React.Fragment>
    {showBackButton ? (
      <a href="#back" onClick={handleClick}>
        <img src={imgBack} alt="Back" />
        {' Back'}
      </a>
    ) : null}
  </React.Fragment>
);

BackButton.propTypes = {
  showBackButton: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

BackButton.defaultProps = {
  showBackButton: false,
};

const CustomBackButton = ({ showBackButton, handleClick, title }) => (
  <React.Fragment>
    {showBackButton ? (
      <a href="#back" onClick={handleClick}>
        <img src={imgBack} alt="Back" />
        {` ${title}`}
      </a>
    ) : null}
  </React.Fragment>
);

CustomBackButton.propTypes = {
  showBackButton: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

CustomBackButton.defaultProps = {
  showBackButton: false,
};

const UploadButton = () => (
  <a href="#upload" className="uploadButton">
    <img src={imgUpload} alt="" />
    {' Upload file'}
  </a>
);

const PaginationButton = ({
  disabledPrev,
  disabledNext,
  handlePaginationPrev,
  handlePaginationNext,
}) => (
  <div className="w-row">
    <div className="wb-pagination">
      <div className="w-col w-col-6">
        <button type="button" className="w-button f-btn-primary f-btn-pagination f-btn-prev" disabled={disabledPrev} onClick={handlePaginationPrev}>Previous</button>
      </div>
      <div className="w-col w-col-6">
        <button type="button" className="w-button f-btn-primary f-btn-pagination f-btn-next" disabled={disabledNext} onClick={handlePaginationNext}>Next</button>
      </div>
    </div>
    <div className="mb-pagination">
      <div className="fluid-button">
        <button type="button" className="w-button f-btn-primary f-btn-pagination f-btn-prev f-text-right" disabled={disabledPrev} onClick={handlePaginationPrev}>Previous &nbsp;&nbsp;&nbsp;</button>
      </div>
      <div className="fluid-button">
        <button type="button" className="w-button f-btn-primary f-btn-pagination f-btn-next" disabled={disabledNext} onClick={handlePaginationNext}>Next &nbsp;&nbsp;&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
);

PaginationButton.propTypes = {
  disabledPrev: PropTypes.bool.isRequired,
  disabledNext: PropTypes.bool.isRequired,
  handlePaginationPrev: PropTypes.func.isRequired,
  handlePaginationNext: PropTypes.func.isRequired,
};

const ChartDateOptionButton = ({ selectedDate, dateButtonOnSelect }) => (
  <ButtonToolbar className="f-pull-right">
    <Dropdown pullRight id="dropdown-chart">
      <Dropdown.Toggle className="f-dropdown-button slim-fit">
        <img src={DateIcon} alt="" />
        {ChartDateOption[selectedDate].label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="f-dropdown-menu">
        <MenuItem eventKey="1" onSelect={dateButtonOnSelect}>{ChartDateOption[1].label}</MenuItem>
        <MenuItem eventKey="2" onSelect={dateButtonOnSelect}>{ChartDateOption[2].label}</MenuItem>
        <MenuItem eventKey="3" onSelect={dateButtonOnSelect}>{ChartDateOption[3].label}</MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  </ButtonToolbar>
);

ChartDateOptionButton.propTypes = {
  selectedDate: PropTypes.number.isRequired,
  dateButtonOnSelect: PropTypes.func.isRequired,
};

export {
  BackButton,
  CustomBackButton,
  UploadButton,
  PaginationButton,
  ChartDateOption,
  ChartDateOptionButton,
};
