import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-masked-input';
import Select from 'react-select';
import DatePicker from 'react-16-bootstrap-date-picker';
import { CSSTransition } from 'react-transition-group';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import {
  CampaignProvider,
  DonationProvider,
  DonationManualProvider,
  MetricProvider,
  SchedulerProvider,
} from '../Providers';
import { database } from '../firebase';
import { stringHelper, dateTimeHelper } from '../helpers';

import * as routes from '../constants/routes';
import withAuthorization from '../withAuthorization';

import FancyRadio from '../Components/FancyRadio';
import DonationManualTable from '../Components/DonationManualTable';
import Waiting from '../Components/Waiting';
import { CustomBackButton } from '../Components/HtmlTag';

import ImgSearch from '../Assets/Images/Fill10.svg';
import DateIcon from '../Assets/Images/DateIcon.svg';

const RecurringText = {
  1: 'Weekly',
  2: 'Bi-Weekly',
  3: 'Monthly',
  100: 'Every Half Hour',
  101: 'Hourly',
  102: 'Daily',
};

const MODE = process.env.REACT_APP_FIREBASE_DB_PATH === 'dev' ? 'dev' : 'prod';

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nid: null,
      donorLoad: true,
      donors: [],
      filteredDonors: [],
      selectedDonor: null,
      totalCount: 0,
      statistic: {
        totalDonations: 0.0,
        totalDonors: 0,
        totalServicePayments: 0.0,
        totalCustomers: 0,
        totalPledgePayments: 0.0,
        totalPledges: 0,
        totalServiceBalance: 0.0,
        totalPledgeBalance: 0.0,
      },
      showModal: false,
      modalTitle: 'Add Transaction',
      nonprofit: null,
      agentProfile: null,
      programsSelectOption: [],
      campaigns: [],
      campaignsSelectOption: [],
      metrics: [],
      selectedMetric: null,
      donationCategory: 'Fundraising',
      donationType: 'Donation',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      anonymous: false,
      comment: '',
      dedication: '',
      dedicationName: '',
      balanceOwed: '0.00',
      totalDonation: '0.00',
      programSelected: null,
      campaignSelected: null,
      donationMethod: 'Cash',
      donationDate: new Date().toISOString(),
      recurring: '',
      error: false,
      errorMessage: '',
      onSaving: false,
      processAR: false,
      userToken: null,
    };

    this.searchEnter = this.searchEnter.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCampaignSelectChange = this.handleCampaignSelectChange.bind(this);
    this.handleSaveModal = this.saveDonation.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
    this.handleDeleteDonation = this.handleDeleteDonation.bind(this);
    this.handleEditDonation = this.handleEditDonation.bind(this);
    this.handleBackProfileClick = this.handleBackProfileClick.bind(this);
    this.handleProcessTransactionClick = this.handleProcessTransactionClick.bind(this);
    this.handleAddTransactionARClick = this.handleAddTransactionARClick.bind(this);
    this.handleProcessTransactionARClick = this.handleProcessTransactionARClick.bind(this);
  }

  componentDidMount() {
    const { showFooterLinkContainer, updateNavStatus, match, authUser } = this.props;
    showFooterLinkContainer(false);
    updateNavStatus('management');

    const nid = match.params.id;
    this.setState({ nid, userToken: authUser.user.idToken }, () => {
      this.getNonprofitMetric();
    });
  }

  getDonorsList() {
    const { nid } = this.state;
    DonationManualProvider.getList(nid).then(donationSnapshot => {
      const donation = [];
      let totalDonations = 0.0;
      let totalServicePayments = 0.0;
      let totalPledgePayments = 0.0;
      let owedService = 0.0;
      let owedPledge = 0.0;
      let totalServiceBalance = 0.0;
      let totalPledgeBalance = 0.0;
      const payersDonation = [];
      const payersService = [];
      const payersPledge = [];

      donationSnapshot.forEach(snap => {
        let dateObj = null;
        if (snap.val().date) {
          dateObj = new Date(snap.val().date);
        }

        const userInfo = snap.val().user;
        const programName = snap.val().program ? snap.val().program.name : snap.val().nonprofit.name;
        const userFullName = userInfo.fullName ? userInfo.fullName : `${userInfo.firstName} ${userInfo.lastName}`;
        userInfo.id = snap.key;
        userInfo.fullName = userInfo.anonymous ? 'Anonymous Donor' : userFullName;
        userInfo.dedication = snap.val().dedication;
        userInfo.program = snap.val().program;
        userInfo.campaign = snap.val().campaign;
        userInfo.metric = snap.val().metric;
        userInfo.donorTo = snap.val().campaign ? snap.val().campaign.name : programName;
        userInfo.date = dateObj;
        userInfo.total = snap.val().total;
        userInfo.balanceOwed = snap.val().balanceOwed;
        userInfo.category = snap.val().category ? snap.val().category : 'Fundraising';
        userInfo.type = snap.val().type ? snap.val().type : 'Donation';
        userInfo.method = snap.val().method;
        userInfo.from = snap.val().from;
        if (snap.val().recurring) {
          userInfo.recurring = snap.val().recurring.when.toString();
          userInfo.recurringText = RecurringText[snap.val().recurring.when];
          userInfo.id_scheduler = snap.val().id_scheduler;
        }
        donation.push(userInfo);
        if (snap.val().total) {
          if (snap.val().category === 'Cash Deposit') {
            let payerValue = '';
            if (userInfo.phone !== '') {
              payerValue = stringHelper.trimPhone(userInfo.phone);
            } else if (userInfo.email !== '') {
              payerValue = userInfo.email;
            } else if (userInfo.fullName && userInfo.fullName.trim() !== '') {
              payerValue = userInfo.fullName;
            }

            if (snap.val().type === 'Service') {
              totalServicePayments += Number.parseFloat(snap.val().total.amount);
              if (payerValue !== '' && !payersService.includes(payerValue)) {
                payersService.push(payerValue);
              }
            } else if (snap.val().type === 'Pledge') {
              totalPledgePayments += Number.parseFloat(snap.val().total.amount);
              if (payerValue !== '' && !payersPledge.includes(payerValue)) {
                payersPledge.push(payerValue);
              }
            } else {
              totalDonations += Number.parseFloat(snap.val().total.amount);
              if (payerValue !== '' && !payersDonation.includes(payerValue)) {
                payersDonation.push(payerValue);
              }
            }
          }
        }
        if (snap.val().balanceOwed) {
          if (snap.val().type === 'Service') {
            owedService += Number.parseFloat(snap.val().balanceOwed);
          } else if (snap.val().type === 'Pledge') {
            owedPledge += Number.parseFloat(snap.val().balanceOwed);
          }
        }
      });

      totalServiceBalance = owedService - totalServicePayments;
      totalPledgeBalance = owedPledge - totalPledgePayments;

      const statistic = {
        totalDonations: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(totalDonations),
        totalDonors: payersDonation.length,
        totalServicePayments: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(totalServicePayments),
        totalCustomers: payersService.length,
        totalPledgePayments: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(totalPledgePayments),
        totalPledges: payersPledge.length,
        totalServiceBalance: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(totalServiceBalance),
        totalPledgeBalance: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(totalPledgeBalance),
      };

      if (donation.length > 0) {
        donation.reverse();
      }

      this.setState({
        donors: donation,
        filteredDonors: donation,
        donorLoad: false,
        totalCount: donation.length,
        statistic,
      });
    });
  }

  getProgramList() {
    const { nid } = this.state;
    database
      .getRef('/nonprofit_programs')
      .child(nid)
      .once('value')
      .then(snap => {
        const selectOptions = [];

        snap.forEach(childSnap => {
          selectOptions.push({ value: childSnap.val().id, label: childSnap.val().name });
        });

        this.setState({ programsSelectOption: selectOptions });
      });
  }

  getCampaignList() {
    const { nid } = this.state;
    CampaignProvider.getList(Number.parseInt(nid, 10)).then(snap => {
      const campaigns = [];
      snap.forEach(childSnap => {
        if (childSnap.val().published) {
          campaigns.push({
            value: childSnap.val().id,
            label: childSnap.val().name,
            program: childSnap.val().program,
            metric: childSnap.val().metric,
          });
        }
      });

      this.setState({ campaigns });
    });
  }

  getNonprofitMetric() {
    const { nid } = this.state;
    MetricProvider.getList(nid).then(metricSnapshot => {
      const metrics = [];
      metricSnapshot.forEach(item => {
        metrics.push({
          id: item.key,
          name: item.val().name,
          cost: item.val().cost,
          costNumber: item.val().costNumber,
          unitName: item.val().unitName ? item.val().unitName.trim() : 'pack',
          humanNumber: item.val().humanNumber ? item.val().humanNumber : 0,
          humanUnit: item.val().humanUnit ? item.val().humanUnit.trim() : '',
        });
      });

      this.setState({ metrics }, () => {
        this.findNonprofit();
      });
    });
  }

  findNonprofit() {
    const { nid } = this.state;
    const { authUser, history } = this.props;
    database
      .getRef('/nonprofit')
      .child(nid)
      .once('value')
      .then(snap => {
        if (authUser.user.uid !== snap.val().agent) {
          history.push(routes.NONPROFITS);
        } else {
          const nonprofit = {
            agent: snap.val().agent,
            img: snap.val().img,
            key: snap.val().key,
            name: snap.val().name,
            hideFinancialManagement: snap.val().hideFinancialManagement !== false,
          };

          if (snap.val().agent) {
            const uid = snap.val().agent;
            database
              .getRef(`/profile/${uid}`)
              .once('value')
              .then(snapshot => {
                const agentProfile = {
                  uid: snapshot.val().uid,
                  email: snapshot.val().email,
                  firstName: snapshot.val().firstName,
                  lastName: snapshot.val().lastName,
                };
                this.setState(
                  {
                    nonprofit,
                    agentProfile,
                  },
                  () => {
                    this.getProgramList();
                    this.getCampaignList();
                    this.getDonorsList();
                  }
                );
              });
          } else {
            this.setState(
              {
                nonprofit,
              },
              () => {
                this.getProgramList();
                this.getCampaignList();
                this.getDonorsList();
              }
            );
          }
        }
      });
  }

  handleRadioChange(event) {
    const { target } = event;
    const { name, value } = target;

    if (name === 'donationCategory' && value === 'Accounts Receivable') {
      this.setState({ [name]: value, donationType: 'Service' });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }

  handleDateChange(date) {
    this.setState({ donationDate: date });
  }

  handleAmountChange(event, maskValue) {
    const { target } = event;
    const { name } = target;

    this.setState({ [name]: maskValue });
  }

  handleModalShow() {
    this.setState({ selectedDonor: null, showModal: true });
  }

  handleModalClose() {
    this.setState({
      donationCategory: 'Fundraising',
      donationType: 'Donation',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      anonymous: false,
      comment: '',
      dedication: '',
      dedicationName: '',
      balanceOwed: '0.00',
      totalDonation: '0.00',
      programSelected: null,
      campaignSelected: null,
      selectedMetric: null,
      campaignsSelectOption: [],
      donationMethod: 'Cash',
      donationDate: new Date().toISOString(),
      recurring: '',
      error: false,
      errorMessage: '',
      showModal: false,
      modalTitle: 'Add Transaction',
      onSaving: false,
      processAR: false,
    });
  }

  handleSelectChange(selectedOption) {
    const { campaigns } = this.state;
    const filteredCampaign = campaigns.filter(
      campaign => campaign.program.id.toString() === selectedOption.value.toString()
    );

    this.setState({
      programSelected: selectedOption,
      campaignsSelectOption: filteredCampaign,
      selectedMetric: null,
      campaignSelected: null,
      error: false,
      errorMessage: '',
      onSaving: false,
    });
  }

  handleCampaignSelectChange(selectedOption) {
    const { metrics } = this.state;
    const filteredMetric = metrics.filter(metric => metric.id === selectedOption.metric);

    this.setState({
      campaignSelected: selectedOption,
      selectedMetric: filteredMetric.length > 0 ? filteredMetric[0] : null,
      error: false,
      errorMessage: '',
      onSaving: false,
    });
  }

  handleRowClick(id) {
    const { donors } = this.state;
    const filter = donors.filter(donor => donor.id === id);
    const selectedDonor = filter.length ? filter[0] : null;

    if (selectedDonor.firstName !== '') {
      if (selectedDonor.category === 'Accounts Receivable') {
        let filteredDonors = [];
        let filteredPayment = [];
        if (selectedDonor.phone && selectedDonor.phone !== '') {
          filteredDonors = donors.filter(
            donor =>
              donor.phone &&
              stringHelper.trimPhone(donor.phone).indexOf(stringHelper.trimPhone(selectedDonor.phone)) !== -1 &&
              donor.category === 'Accounts Receivable' &&
              donor.type === selectedDonor.type
          );
          filteredPayment = donors.filter(
            donor =>
              donor.phone &&
              stringHelper.trimPhone(donor.phone).indexOf(stringHelper.trimPhone(selectedDonor.phone)) !== -1 &&
              donor.category === 'Cash Deposit' &&
              donor.type === selectedDonor.type
          );
        }

        let totalBalanceOwed = filteredDonors.length ? 0.0 : selectedDonor.balanceOwed;
        filteredDonors.forEach(d => {
          totalBalanceOwed += Number.parseFloat(d.balanceOwed);
        });
        let subTotal = 0.0;
        filteredPayment.forEach(d => {
          subTotal += Number.parseFloat(d.total.amount);
        });

        selectedDonor.totalBalanceOwed = totalBalanceOwed - subTotal;
      } else {
        let filteredDonors = [];
        if (selectedDonor.phone && selectedDonor.phone !== '') {
          filteredDonors = donors.filter(
            donor =>
              donor.phone &&
              stringHelper.trimPhone(donor.phone).indexOf(stringHelper.trimPhone(selectedDonor.phone)) !== -1 &&
              donor.category !== 'Accounts Receivable'
          );
        } else if (selectedDonor.email && selectedDonor.email !== '') {
          filteredDonors = donors.filter(
            donor =>
              donor.email &&
              donor.email.toLowerCase().indexOf(selectedDonor.email.toLowerCase()) !== -1 &&
              donor.category !== 'Accounts Receivable'
          );
        } else {
          filteredDonors = donors.filter(
            donor =>
              donor.fullName.toLowerCase().indexOf(selectedDonor.fullName.toLowerCase()) !== -1 &&
              !donor.email &&
              donor.category !== 'Accounts Receivable'
          );
        }

        let subTotal = 0.0;
        let subTotalDonation = 0.0;
        let subTotalService = 0.0;
        let subTotalPledge = 0.0;
        let subTotalOnlineFundraising = 0.0;
        filteredDonors.forEach(d => {
          subTotal += Number.parseFloat(d.total.amount);
          if (d.category === 'Cash Deposit') {
            if (d.type === 'Donation') {
              subTotalDonation += Number.parseFloat(d.total.amount);
            } else if (d.type === 'Service') {
              subTotalService += Number.parseFloat(d.total.amount);
            } else if (d.type === 'Pledge') {
              subTotalPledge += Number.parseFloat(d.total.amount);
            }
          } else if (d.category === 'Fundraising') {
            if (d.type === 'Donation' && d.method === 'Online') {
              subTotalOnlineFundraising += Number.parseFloat(d.total.amount);
            }
          }
        });
        selectedDonor.subTotal = subTotal;
        selectedDonor.subTotalDonation = subTotalDonation;
        selectedDonor.subTotalService = subTotalService;
        selectedDonor.subTotalPledge = subTotalPledge;
        selectedDonor.subTotalOnlineFundraising = subTotalOnlineFundraising;
      }
    } else {
      let filteredDonors = [];
      if (selectedDonor.anonymous) {
        filteredDonors = donors.filter(donor => donor.anonymous);
      } else {
        filteredDonors = donors.filter(donor => donor.fullName.trim() === '');
      }

      let subTotalFundraising = 0.0;
      let subTotalCash = 0.0;
      filteredDonors.forEach(d => {
        if (d.category === 'Fundraising') {
          subTotalFundraising += Number.parseFloat(d.total.amount);
        } else if (d.category === 'Cash Deposit') {
          subTotalCash += Number.parseFloat(d.total.amount);
        }
      });

      selectedDonor.subTotalFundraising = subTotalFundraising;
      selectedDonor.subTotalCash = subTotalCash;
    }

    this.setState({ selectedDonor });
  }

  handleCloseDetail() {
    this.setState({ selectedDonor: null });
  }

  handleDeleteDonation() {
    const { selectedDonor, nonprofit } = this.state;
    swal('Delete donation', `Are you sure you want to delete donation from "${selectedDonor.fullName}"?`, {
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
      .then(willDelete => {
        if (willDelete) {
          if (selectedDonor.id_scheduler) {
            return SchedulerProvider.destroyRecurring(selectedDonor.id_scheduler);
          }

          return DonationManualProvider.remove(nonprofit.key, selectedDonor.id);
        }

        return 'cancel';
      })
      .then(res => {
        if (res && res.removed) {
          return DonationManualProvider.remove(nonprofit.key, selectedDonor.id);
        }

        return res;
      })
      .then(res => {
        if (res !== 'cancel') {
          this.setState({ selectedDonor: null, donorLoad: true }, () => {
            this.getDonorsList();
          });
        }
      });
  }

  handleEditDonation() {
    const { selectedDonor, campaigns, metrics } = this.state;
    let selectedCampaign = null;
    let selectedMetric = null;
    const campaignsOptions = campaigns.filter(
      campaign => campaign.program.id.toString() === selectedDonor.program.id.toString()
    );
    if (selectedDonor.campaign) {
      const filterCampaign = campaignsOptions.filter(
        campaign => campaign.value.toString() === selectedDonor.campaign.id.toString()
      );
      if (filterCampaign.length > 0) {
        selectedCampaign = { ...filterCampaign[0] };
        const filterMetric = metrics.filter(metric => metric.id === selectedCampaign.metric);
        if (filterMetric.length > 0) {
          selectedMetric = { ...filterMetric[0] };
        }
      }
    }

    this.setState({
      donationCategory: selectedDonor.category,
      donationType: selectedDonor.type,
      firstName: selectedDonor.firstName ? selectedDonor.firstName : '',
      lastName: selectedDonor.lastName ? selectedDonor.lastName : '',
      email: selectedDonor.email ? selectedDonor.email : '',
      phone: selectedDonor.phone ? selectedDonor.phone : '',
      anonymous: !!selectedDonor.anonymous,
      comment: selectedDonor.comment,
      dedication: selectedDonor.dedication ? JSON.stringify(selectedDonor.dedication.type) : '',
      dedicationName: selectedDonor.dedication ? selectedDonor.dedication.value : '',
      balanceOwed: selectedDonor.balanceOwed ? selectedDonor.balanceOwed : '0.00',
      totalDonation: selectedDonor.total ? selectedDonor.total.amount : '0.00',
      programSelected: { value: selectedDonor.program.id, label: selectedDonor.program.name },
      campaignsSelectOption: campaignsOptions,
      campaignSelected: selectedCampaign,
      selectedMetric,
      donationMethod: selectedDonor.method,
      donationDate: new Date(selectedDonor.date).toISOString(),
      recurring: selectedDonor.recurring ? selectedDonor.recurring : '',
      error: false,
      errorMessage: '',
      showModal: true,
      modalTitle: 'Edit Transaction',
      onSaving: false,
    });
  }

  handleBackProfileClick(e) {
    e.preventDefault();

    const { nonprofit } = this.state;
    const { history } = this.props;
    const backUrl = routes.NONPROFITS_ABOUT.replace(':id', nonprofit.key).replace(
      ':name',
      stringHelper.slugify(nonprofit.name)
    );
    history.push(backUrl);
  }

  handleProcessTransactionClick(e) {
    e.preventDefault();

    const { nonprofit } = this.state;
    const { history } = this.props;
    const donationUrl = routes.DONORS_MANAGEMENT_DONATION.replace(':id', nonprofit.key);
    history.push(donationUrl);
  }

  handleProcessTransactionARClick(e) {
    e.preventDefault();

    const { nonprofit, selectedDonor } = this.state;
    const { history } = this.props;
    const donationUrl = `${routes.DONORS_MANAGEMENT_DONATION.replace(':id', nonprofit.key)}?ar=${selectedDonor.id}`;
    history.push(donationUrl);
  }

  handleAddTransactionARClick() {
    const { selectedDonor, campaigns, metrics } = this.state;
    let selectedCampaign = null;
    let selectedMetric = null;
    const campaignsOptions = campaigns.filter(
      campaign => campaign.program.id.toString() === selectedDonor.program.id.toString()
    );
    if (selectedDonor.campaign) {
      const filterCampaign = campaignsOptions.filter(
        campaign => campaign.value.toString() === selectedDonor.campaign.id.toString()
      );
      if (filterCampaign.length > 0) {
        selectedCampaign = { ...filterCampaign[0] };
        const filterMetric = metrics.filter(metric => metric.id === selectedCampaign.metric);
        if (filterMetric.length > 0) {
          selectedMetric = { ...filterMetric[0] };
        }
      }
    }

    this.setState({
      donationCategory: 'Cash Deposit',
      donationType: selectedDonor.type,
      firstName: selectedDonor.firstName ? selectedDonor.firstName : '',
      lastName: selectedDonor.lastName ? selectedDonor.lastName : '',
      email: selectedDonor.email ? selectedDonor.email : '',
      phone: selectedDonor.phone ? selectedDonor.phone : '',
      anonymous: false,
      comment: '',
      dedication: '',
      dedicationName: '',
      balanceOwed: '0.00',
      totalDonation: '0.00',
      programSelected: { value: selectedDonor.program.id, label: selectedDonor.program.name },
      campaignsSelectOption: campaignsOptions,
      campaignSelected: selectedCampaign,
      selectedMetric,
      donationMethod: 'Cash',
      donationDate: new Date().toISOString(),
      error: false,
      errorMessage: '',
      showModal: true,
      modalTitle: 'Add Transaction',
      onSaving: false,
      selectedDonor: null,
      processAR: true,
    });
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      const { donors } = this.state;
      let filteredDonors = [];
      if (event.target.value === 'noname') {
        filteredDonors = donors.filter(donor => donor.fullName.trim() === '');
      } else if (event.target.value === 'anonymous') {
        filteredDonors = donors.filter(donor => donor.anonymous);
      } else if (event.target.value.toLowerCase().indexOf('cash') !== -1) {
        if (event.target.value.toLowerCase().indexOf('donation') !== -1) {
          filteredDonors = donors.filter(donor => donor.category === 'Cash Deposit' && donor.type === 'Donation');
        } else if (event.target.value.toLowerCase().indexOf('service') !== -1) {
          filteredDonors = donors.filter(donor => donor.category === 'Cash Deposit' && donor.type === 'Service');
        } else if (event.target.value.toLowerCase().indexOf('pledge') !== -1) {
          filteredDonors = donors.filter(donor => donor.category === 'Cash Deposit' && donor.type === 'Pledge');
        } else {
          filteredDonors = donors.filter(donor => donor.category === 'Cash Deposit');
        }
      } else if (event.target.value === 'donation') {
        filteredDonors = donors.filter(donor => donor.category === 'Cash Deposit' && donor.type === 'Donation');
      } else if (event.target.value === 'service') {
        filteredDonors = donors.filter(donor => donor.category === 'Cash Deposit' && donor.type === 'Service');
      } else if (event.target.value === 'pledge') {
        filteredDonors = donors.filter(donor => donor.category === 'Cash Deposit' && donor.type === 'Pledge');
      } else if (event.target.value.toLowerCase().indexOf('fundraising') !== -1) {
        if (event.target.value.toLowerCase().indexOf('donation') !== -1) {
          filteredDonors = donors.filter(donor => donor.category === 'Fundraising' && donor.type === 'Donation');
        } else if (event.target.value.toLowerCase().indexOf('service') !== -1) {
          filteredDonors = donors.filter(donor => donor.category === 'Fundraising' && donor.type === 'Service');
        } else if (event.target.value.toLowerCase().indexOf('pledge') !== -1) {
          filteredDonors = donors.filter(donor => donor.category === 'Fundraising' && donor.type === 'Pledge');
        } else {
          filteredDonors = donors.filter(donor => donor.category === 'Fundraising');
        }
      } else {
        filteredDonors = donors.filter(
          donor => donor.fullName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
        );
      }

      // Number.isNaN(+"919191919") === false for checking number

      const totalCount = filteredDonors.length;
      this.setState({ filteredDonors, totalCount, selectedDonor: null });
    }
  }

  saveDonation() {
    this.setState({ onSaving: true });

    const result = this.validateForm();
    if (result.status) {
      const {
        email,
        phone,
        firstName,
        lastName,
        comment,
        anonymous,
        balanceOwed,
        totalDonation,
        nonprofit,
        programSelected,
        dedication,
        dedicationName,
        agentProfile,
        donationMethod,
        donationCategory,
        donationType,
        donationDate,
        selectedDonor,
        recurring,
        campaignSelected,
        selectedMetric,
      } = this.state;

      const userInfo = {
        firstName,
        lastName,
        email,
        phone: stringHelper.trimPhone(phone),
        comment,
      };
      if (firstName !== '') {
        userInfo.fullName = `${firstName} ${lastName}`;
      }
      if (anonymous) {
        userInfo.anonymous = true;
      }

      const formData = {
        category: donationCategory,
        type: donationType,
        nonprofit: {
          key: nonprofit.key.toString(),
          name: nonprofit.name,
          logo: nonprofit.img.logo,
        },
        program: {
          id: programSelected.value.toString(),
          name: programSelected.label,
        },
        user: userInfo,
      };

      if (campaignSelected) {
        formData.campaign = {
          id: campaignSelected.value.toString(),
          name: campaignSelected.label,
        };
      }

      if (selectedMetric) {
        formData.metric = {
          id: selectedMetric.id,
          name: selectedMetric.name,
          cost: selectedMetric.cost,
          costNumber: selectedMetric.costNumber,
          unitName: selectedMetric.unitName,
          humanNumber: selectedMetric.humanNumber,
          humanUnit: selectedMetric.humanUnit,
        };
      }

      if (donationCategory === 'Accounts Receivable') {
        if (selectedDonor) {
          formData.date = dateTimeHelper
            .getUTCDateTime(selectedDonor.date)
            .toDate()
            .getTime();
          if (selectedDonor.id_scheduler) {
            formData.id_scheduler = selectedDonor.id_scheduler;
          }
        } else {
          formData.date = dateTimeHelper.getCurrentTimeMs();
        }
        formData.balanceOwed = balanceOwed;
        if (recurring !== '') {
          formData.recurring = { when: Number.parseInt(recurring, 10) };
        }
      } else {
        formData.date = dateTimeHelper
          .getUTCDateTime(donationDate)
          .toDate()
          .getTime();
        formData.total = { amount: totalDonation };
        formData.method = donationMethod;
      }

      if (dedication !== '') {
        formData.dedication = { type: JSON.parse(dedication), value: dedicationName };
      }

      if (nonprofit.agent) {
        formData.agent = agentProfile;
      }

      if (selectedDonor) {
        DonationManualProvider.updateData(nonprofit.key, selectedDonor.id, formData).then(() => {
          if (donationCategory === 'Accounts Receivable' && recurring !== '') {
            if (selectedDonor.id_scheduler) {
              const idSch = selectedDonor.id_scheduler;
              const schedulerData = {
                type: 1,
                when: Number.parseInt(recurring, 10),
              };
              SchedulerProvider.updateRecurring(idSch, schedulerData)
                .then(() => {
                  this.resetFormState();
                })
                .catch(err => {
                  this.setState({
                    error: true,
                    errorMessage: err,
                    onSaving: false,
                  });
                });
            } else {
              const schedulerData = {
                id: nonprofit.key,
                id_trx: selectedDonor.id,
                type: 1,
                when: Number.parseInt(recurring, 10),
                created_on: dateTimeHelper.getCurrentTimeMs(),
              };
              SchedulerProvider.storeRecurring(schedulerData)
                .then(sch => {
                  DonationManualProvider.updatePartialData(
                    nonprofit.key,
                    selectedDonor.id,
                    'id_scheduler',
                    sch.data.id
                  ).then(() => {
                    this.resetFormState();
                  });
                })
                .catch(err => {
                  this.setState({
                    error: true,
                    errorMessage: err,
                    onSaving: false,
                  });
                });
            }
          } else {
            this.resetFormState();
          }
        });
      } else {
        const idTrx = DonationManualProvider.pushData(nonprofit.key, formData).key;
        if (donationCategory === 'Accounts Receivable' && recurring !== '') {
          const schedulerData = {
            id: nonprofit.key,
            id_trx: idTrx,
            type: 1,
            when: Number.parseInt(recurring, 10),
            created_on: formData.date,
          };
          // console.log('schedulerData: ', schedulerData);
          SchedulerProvider.storeRecurring(schedulerData)
            .then(sch => {
              DonationManualProvider.updatePartialData(nonprofit.key, idTrx, 'id_scheduler', sch.data.id).then(() => {
                this.resetFormState();
              });
            })
            .catch(err => {
              this.setState({
                error: true,
                errorMessage: err,
                onSaving: false,
              });
            });
        } else if (formData.method && formData.method === 'Online') {
          swal('Public Donation', 'Do you want to make this donation public?', {
            buttons: ['No, Thanks', 'Yes, Please'],
          })
            .then(agree => {
              if (agree) {
                const publicData = { idTrx, donationManual: true, from: 'web', ...formData };
                return DonationProvider.saveData(nonprofit.key, idTrx, publicData);
              }

              return 'cancel';
            })
            .then(() => {
              this.resetFormState();
            });
        } else {
          this.resetFormState();
        }
      }
    } else {
      this.setState({
        error: true,
        errorMessage: result.message,
        onSaving: false,
      });
    }
  }

  validateForm() {
    const {
      firstName,
      lastName,
      donationCategory,
      donationMethod,
      donationType,
      phone,
      programSelected,
      campaignSelected,
    } = this.state;

    let validateStatus = true;
    let errorMessage = '';
    if (!programSelected) {
      validateStatus = false;
      errorMessage = 'Please select one program';
    } else if (!campaignSelected) {
      validateStatus = false;
      errorMessage = 'Please select one campaign';
    } else if (phone !== '' && !stringHelper.isValidPhoneNumber(phone)) {
      validateStatus = false;
      errorMessage = 'Wrong format for Phone Number (123) 456-7890';
    } else if (donationMethod === 'Online') {
      if (firstName === '') {
        validateStatus = false;
        errorMessage = 'First Name required';
      } else if (lastName === '') {
        validateStatus = false;
        errorMessage = 'Last Name required';
      }
    } else if (donationCategory === 'Accounts Receivable') {
      if (phone === '') {
        validateStatus = false;
        errorMessage = 'Phone number required for Accounts Receivable';
      }
    } else if (donationCategory === 'Cash Deposit') {
      if (phone === '' && (donationType === 'Service' || donationType === 'Pledge')) {
        validateStatus = false;
        errorMessage = 'Phone number required for Service/Pledge transaction';
      }
    }

    return { status: validateStatus, message: errorMessage };
  }

  resetFormState() {
    this.setState(
      {
        donationCategory: 'Fundraising',
        donationType: 'Donation',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        anonymous: false,
        comment: '',
        dedication: '',
        dedicationName: '',
        balanceOwed: '0.00',
        totalDonation: '0.00',
        programSelected: null,
        campaignSelected: null,
        selectedMetric: null,
        campaignsSelectOption: [],
        donationMethod: 'Cash',
        donationDate: new Date().toISOString(),
        selectedDonor: null,
        recurring: '',
        error: false,
        errorMessage: '',
        donorLoad: true,
        showModal: false,
        modalTitle: 'Add Transaction',
        onSaving: false,
        processAR: false,
      },
      () => {
        this.getDonorsList();
      }
    );
  }

  render() {
    const {
      totalCount,
      donorLoad,
      filteredDonors,
      selectedDonor,
      showModal,
      modalTitle,
      donationCategory,
      donationType,
      firstName,
      lastName,
      email,
      phone,
      anonymous,
      comment,
      dedication,
      dedicationName,
      balanceOwed,
      totalDonation,
      programsSelectOption,
      programSelected,
      donationDate,
      donationMethod,
      nonprofit,
      recurring,
      error,
      errorMessage,
      onSaving,
      processAR,
      statistic,
      userToken,
      campaignsSelectOption,
      campaignSelected,
    } = this.state;
    const listCss = selectedDonor ? 'w-col w-col-9 donors-crm-list' : 'w-col w-col-12';
    const financialUrl = process.env.REACT_APP_FINANCIAL_STATEMENT_URL;

    document.title = 'Fundra - Donor Management';

    return (
      <>
        {!nonprofit ? (
          <Waiting clsStyle="section-loader-ss" />
        ) : (
          <div className="DonorsCRMSection">
            <div className="sectionhero donorcrmsection">
              <div className="contentwrapper herosectionfeatures">
                <div className="w-row">
                  <div className="w-col w-col-10 w-col-stack">
                    <div className="backlink">
                      <CustomBackButton
                        showBackButton
                        handleClick={this.handleBackProfileClick}
                        title="Back to profile"
                      />
                    </div>
                  </div>
                  {!nonprofit.hideFinancialManagement ? (
                    <div className="w-col w-col-2 w-col-stack financial-statement">
                      <a
                        href={`${financialUrl}${nonprofit.key}/${userToken}`}
                        className="overall-footer-link"
                        rel="noopener noreferrer"
                      >
                        Financial Statement
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className="w-row" style={{ marginTop: 20 }}>
                  <div className="w-col w-col-4">
                    <h3 className="h3 nonprofitabouttitle">Transaction List</h3>
                    <label className="joined">{`Displaying ${totalCount} transactions`}</label>
                  </div>
                  <div className="w-col w-col-8" style={{ paddingRight: 15 }}>
                    <div className="flexbox-space" style={{ marginBottom: 10 }}>
                      <div className="analytic-item">
                        <span className="analytic-title">Total Donations</span>
                        <span className="analytic-value f-color-lime">{`$${statistic.totalDonations}`}</span>
                      </div>
                      <div className="analytic-item">
                        <span className="analytic-title">Total Donors</span>
                        <span className="analytic-value f-color-info">{statistic.totalDonors}</span>
                      </div>
                      <div className="analytic-item">
                        <span className="analytic-title">Total Service Payments</span>
                        <span className="analytic-value f-color-waiting">{`$${statistic.totalServicePayments}`}</span>
                      </div>
                      <div className="analytic-item">
                        <span className="analytic-title">Total Customers</span>
                        <span className="analytic-value f-color-info">{statistic.totalCustomers}</span>
                      </div>
                    </div>
                    <div className="flexbox-space">
                      <div className="analytic-item">
                        <span className="analytic-title">Total Pledge Payments</span>
                        <span className="analytic-value f-color-waiting">{`$${statistic.totalPledgePayments}`}</span>
                      </div>
                      <div className="analytic-item">
                        <span className="analytic-title">Total Pledges</span>
                        <span className="analytic-value f-color-info">{statistic.totalPledges}</span>
                      </div>
                      <div className="analytic-item">
                        <span className="analytic-title">Total Service Balance</span>
                        <span className="analytic-value f-color-warning">{`$${statistic.totalServiceBalance}`}</span>
                      </div>
                      <div className="analytic-item">
                        <span className="analytic-title">Total Pledge Balance</span>
                        <span className="analytic-value f-color-warning">{`$${statistic.totalPledgeBalance}`}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-row" style={{ marginTop: 20 }}>
                  <div className={listCss}>
                    <div className="w-row" style={{ marginBottom: 20 }}>
                      <div className="w-col w-col-5">
                        <div className="graygroupinput search-m">
                          <div className="searchinput">
                            <input
                              name="filterName"
                              type="text"
                              placeholder="Search transaction"
                              onKeyPress={this.searchEnter}
                            />
                          </div>
                          <div className="searchicon">
                            <img src={ImgSearch} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="w-col w-col-3" style={{ paddingLeft: 17 }}>
                        <button type="button" className="buttondefault f-btn-default-m" onClick={this.handleModalShow}>
                          + Add transaction
                        </button>
                      </div>
                      <div className="w-col w-col-3" style={{ paddingLeft: 17 }}>
                        <button
                          type="button"
                          className="buttondefault f-btn-default-md"
                          onClick={this.handleProcessTransactionClick}
                        >
                          + Process transaction
                        </button>
                      </div>
                    </div>
                    {donorLoad ? (
                      <div className="section-loader-data">
                        <div className="section-loader-container">
                          <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                        </div>
                      </div>
                    ) : (
                      <DonationManualTable items={filteredDonors} rowClick={this.handleRowClick} />
                    )}
                  </div>
                  {selectedDonor ? (
                    <div className="w-col w-col-3 donor-manual-detail">
                      <div className="f-text-right">
                        <Button size="sm" onClick={this.handleCloseDetail}>
                          X
                        </Button>
                      </div>
                      <span className="full-name">{selectedDonor.fullName}</span>
                      <span className="email-address">{selectedDonor.phone}</span>
                      {selectedDonor.total ? (
                        <>
                          {selectedDonor.subTotal ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Total Payments</span>
                                <span className="label-value">
                                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                    selectedDonor.subTotal
                                  )}`}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {selectedDonor.subTotalDonation ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Total Donations</span>
                                <span className="label-value">
                                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                    selectedDonor.subTotalDonation
                                  )}`}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {selectedDonor.subTotalOnlineFundraising ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Total Donations - Fundraising Online</span>
                                <span className="label-value">
                                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                    selectedDonor.subTotalOnlineFundraising
                                  )}`}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {selectedDonor.subTotalService ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Total Payments - Service</span>
                                <span className="label-value">
                                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                    selectedDonor.subTotalService
                                  )}`}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {selectedDonor.subTotalPledge ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Total Payments - Pledge</span>
                                <span className="label-value">
                                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                    selectedDonor.subTotalPledge
                                  )}`}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {selectedDonor.subTotalCash ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Total Donations - Cash Deposit</span>
                                <span className="label-value">
                                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                    selectedDonor.subTotalCash
                                  )}`}
                                </span>
                              </div>
                            </div>
                          ) : null}
                          {selectedDonor.subTotalFundraising ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Total Donations - Fundraising</span>
                                <span className="label-value">
                                  {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                    selectedDonor.subTotalFundraising
                                  )}`}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div className="w-row">
                            <div className="w-col w-col-12 w-col-stack">
                              <span className="label-caption">Balance Owed</span>
                              <span className="label-value">
                                {`$${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                  selectedDonor.totalBalanceOwed
                                )}`}
                              </span>
                            </div>
                          </div>
                          {selectedDonor.recurring ? (
                            <div className="w-row">
                              <div className="w-col w-col-12 w-col-stack">
                                <span className="label-caption">Recurring</span>
                                <span className="label-value">{selectedDonor.recurringText}</span>
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                      {!selectedDonor.from ? (
                        <div className="w-row">
                          <div className="w-col w-col-6 w-col-stack">
                            <button type="button" className="w-button f-btn-danger" onClick={this.handleDeleteDonation}>
                              Remove
                            </button>
                          </div>
                          <div className="w-col w-col-6 w-col-stack">
                            <button
                              type="button"
                              className="w-button f-btn-white f-pull-right"
                              onClick={this.handleEditDonation}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {selectedDonor.category === 'Accounts Receivable' ? (
                        <div className="w-row" style={{ marginTop: 12 }}>
                          <div className="w-col w-col-6 w-col-stack">
                            <button
                              type="button"
                              className="w-button f-btn-info sm-btn"
                              onClick={this.handleAddTransactionARClick}
                            >
                              + Cash
                            </button>
                          </div>
                          <div className="w-col w-col-6 w-col-stack">
                            <button
                              type="button"
                              className="w-button f-btn-warning sm-btn f-pull-right"
                              onClick={this.handleProcessTransactionARClick}
                            >
                              + Credit Card
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <Modal show={showModal} onHide={this.handleModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="donationRow">
                      {!processAR ? (
                        <>
                          <label className="formfieldlabel">Transaction Category</label>
                          <div className="f-radio-group" style={{ marginBottom: 15 }}>
                            <FancyRadio
                              id="donationCategory-1"
                              name="donationCategory"
                              value="Fundraising"
                              checked={donationCategory === 'Fundraising'}
                              onChange={this.handleRadioChange}
                            >
                              <span>Fundraising</span>
                            </FancyRadio>
                            <FancyRadio
                              id="donationCategory-2"
                              name="donationCategory"
                              value="Cash Deposit"
                              checked={donationCategory === 'Cash Deposit'}
                              onChange={this.handleRadioChange}
                            >
                              <span>Cash Deposit</span>
                            </FancyRadio>
                            <FancyRadio
                              id="donationCategory-3"
                              name="donationCategory"
                              value="Accounts Receivable"
                              checked={donationCategory === 'Accounts Receivable'}
                              onChange={this.handleRadioChange}
                            >
                              <span>Accounts Receivable</span>
                            </FancyRadio>
                          </div>
                          <label className="formfieldlabel">Transaction Type</label>
                          <div className="f-radio-group" style={{ marginBottom: 15 }}>
                            {donationCategory !== 'Accounts Receivable' ? (
                              <FancyRadio
                                id="donationType-1"
                                name="donationType"
                                value="Donation"
                                checked={donationType === 'Donation'}
                                onChange={this.handleRadioChange}
                              >
                                <span>Donation</span>
                              </FancyRadio>
                            ) : null}
                            <FancyRadio
                              id="donationType-2"
                              name="donationType"
                              value="Service"
                              checked={donationType === 'Service'}
                              onChange={this.handleRadioChange}
                            >
                              <span>Service</span>
                            </FancyRadio>
                            <FancyRadio
                              id="donationType-3"
                              name="donationType"
                              value="Pledge"
                              checked={donationType === 'Pledge'}
                              onChange={this.handleRadioChange}
                            >
                              <span>Pledge</span>
                            </FancyRadio>
                          </div>
                          <div className="w-row">
                            <div className="w-col w-col-6 w-col-stack" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <label htmlFor="firstName" className="formfieldlabel">
                                First Name
                              </label>
                              <input
                                type="text"
                                className="formtextfield small w-input input90"
                                maxLength="256"
                                name="firstName"
                                placeholder="Enter your first name"
                                value={firstName}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="w-col w-col-6 w-col-stack" style={{ paddingRight: 0 }}>
                              <label htmlFor="lastName" className="formfieldlabel">
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="formtextfield small w-input"
                                maxLength="256"
                                name="lastName"
                                placeholder="Enter your last name"
                                value={lastName}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="w-row">
                            <div className="w-col w-col-6 w-col-stack" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <label htmlFor="email" className="formfieldlabel">
                                Email address
                              </label>
                              <input
                                type="email"
                                className="formtextfield small w-input input90"
                                maxLength="256"
                                name="email"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="w-col w-col-6 w-col-stack" style={{ paddingRight: 0 }}>
                              <label htmlFor="phone" className="formfieldlabel">
                                Phone number
                              </label>
                              <input
                                type="tel"
                                className="formtextfield small w-input"
                                maxLength="256"
                                name="phone"
                                placeholder="Enter your phone number"
                                value={phone}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {donationCategory !== 'Accounts Receivable' ? (
                        <>
                          {processAR ? (
                            <>
                              <label className="formfieldlabel">Donation Method</label>
                              <div className="f-radio-group" style={{ marginBottom: 15 }}>
                                <FancyRadio
                                  id="method-1"
                                  name="donationMethod"
                                  value="Cash"
                                  checked={donationMethod === 'Cash'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Cash</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="method-2"
                                  name="donationMethod"
                                  value="Check"
                                  checked={donationMethod === 'Check'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Check</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="method-3"
                                  name="donationMethod"
                                  value="Online"
                                  checked={donationMethod === 'Online'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Online</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="method-4"
                                  name="donationMethod"
                                  value="Gift"
                                  checked={donationMethod === 'Gift'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Gift</span>
                                </FancyRadio>
                              </div>
                              <label htmlFor="totalDonation" className="formfieldlabel">
                                Total Donation
                              </label>
                              <div className="form-group">
                                <span className="substring-left">$</span>
                                <CurrencyInput
                                  className="formtextfield small w-input currencyinput textfieldright"
                                  name="totalDonation"
                                  value={totalDonation}
                                  inputMode="numeric"
                                  placeholder="Enter total donation"
                                  onChange={this.handleAmountChange}
                                />
                              </div>
                              <label htmlFor="donationDate" className="formfieldlabel">
                                Date
                              </label>
                              <div className="form-group">
                                <div className="substring-right">
                                  <img src={DateIcon} alt="" />
                                </div>
                                <DatePicker
                                  id="donation_date"
                                  className="formtextfield small w-input"
                                  value={donationDate}
                                  onChange={this.handleDateChange}
                                  showClearButton={false}
                                />
                              </div>
                              <label htmlFor="programSelected" className="formfieldlabel">
                                Program
                              </label>
                              <Select
                                name="programSelected"
                                value={programSelected}
                                options={programsSelectOption}
                                onChange={this.handleSelectChange}
                              />
                              <label htmlFor="campaignSelected" className="formfieldlabel">
                                Campaign
                              </label>
                              <Select
                                name="campaignSelected"
                                value={campaignSelected}
                                options={campaignsSelectOption}
                                onChange={this.handleCampaignSelectChange}
                              />
                              <hr />
                              <label htmlFor="comment" className="formfieldlabel">
                                Add comments
                              </label>
                              <textarea
                                className="formtextfield w-input"
                                rows={5}
                                name="comment"
                                placeholder="Add your comment"
                                value={comment}
                                onChange={this.handleChange}
                              />
                              <label className="formfieldlabel">Dedications</label>
                              <div className="f-radio-group" style={{ marginBottom: 15 }}>
                                <FancyRadio
                                  id="dedication-1"
                                  name="dedication"
                                  value='{"id":1,"title":"Dedicated to"}'
                                  checked={dedication === '{"id":1,"title":"Dedicated to"}'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Dedicated to</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="dedication-2"
                                  name="dedication"
                                  value='{"id":2,"title":"In memory of"}'
                                  checked={dedication === '{"id":2,"title":"In memory of"}'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>In memory of</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="dedication-3"
                                  name="dedication"
                                  value='{"id":3,"title":"On behalf of"}'
                                  checked={dedication === '{"id":3,"title":"On behalf of"}'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>On behalf of</span>
                                </FancyRadio>
                              </div>
                              <input
                                type="text"
                                className="formtextfield small w-input"
                                maxLength="256"
                                name="dedicationName"
                                placeholder="Add Dedication Name"
                                value={dedicationName}
                                onChange={this.handleChange}
                              />
                            </>
                          ) : (
                            <>
                              <div className="styled-checkbox-container">
                                <input
                                  type="checkbox"
                                  className="styled-checkbox"
                                  name="anonymous"
                                  id="anonymous"
                                  checked={anonymous}
                                  onChange={this.handleChange}
                                />
                                <label htmlFor="anonymous">Set me anonymous</label>
                              </div>
                              <hr />
                              <label htmlFor="comment" className="formfieldlabel">
                                Add comments
                              </label>
                              <textarea
                                className="formtextfield w-input"
                                rows={5}
                                name="comment"
                                placeholder="Add your comment"
                                value={comment}
                                onChange={this.handleChange}
                              />
                              <label className="formfieldlabel">Dedications</label>
                              <div className="f-radio-group" style={{ marginBottom: 15 }}>
                                <FancyRadio
                                  id="dedication-1"
                                  name="dedication"
                                  value='{"id":1,"title":"Dedicated to"}'
                                  checked={dedication === '{"id":1,"title":"Dedicated to"}'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Dedicated to</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="dedication-2"
                                  name="dedication"
                                  value='{"id":2,"title":"In memory of"}'
                                  checked={dedication === '{"id":2,"title":"In memory of"}'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>In memory of</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="dedication-3"
                                  name="dedication"
                                  value='{"id":3,"title":"On behalf of"}'
                                  checked={dedication === '{"id":3,"title":"On behalf of"}'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>On behalf of</span>
                                </FancyRadio>
                              </div>
                              <input
                                type="text"
                                className="formtextfield small w-input"
                                maxLength="256"
                                name="dedicationName"
                                placeholder="Add Dedication Name"
                                value={dedicationName}
                                onChange={this.handleChange}
                              />
                              <hr />
                              <label className="formfieldlabel">Donation Method</label>
                              <div className="f-radio-group" style={{ marginBottom: 15 }}>
                                <FancyRadio
                                  id="method-1"
                                  name="donationMethod"
                                  value="Cash"
                                  checked={donationMethod === 'Cash'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Cash</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="method-2"
                                  name="donationMethod"
                                  value="Check"
                                  checked={donationMethod === 'Check'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Check</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="method-3"
                                  name="donationMethod"
                                  value="Online"
                                  checked={donationMethod === 'Online'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Online</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="method-4"
                                  name="donationMethod"
                                  value="Gift"
                                  checked={donationMethod === 'Gift'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Gift</span>
                                </FancyRadio>
                              </div>
                              <label htmlFor="totalDonation" className="formfieldlabel">
                                Total Donation
                              </label>
                              <div className="form-group">
                                <span className="substring-left">$</span>
                                <CurrencyInput
                                  className="formtextfield small w-input currencyinput textfieldright"
                                  name="totalDonation"
                                  value={totalDonation}
                                  inputMode="numeric"
                                  placeholder="Enter total donation"
                                  onChange={this.handleAmountChange}
                                />
                              </div>
                              <label htmlFor="donationDate" className="formfieldlabel">
                                Date
                              </label>
                              <div className="form-group">
                                <div className="substring-right">
                                  <img src={DateIcon} alt="" />
                                </div>
                                <DatePicker
                                  id="donation_date"
                                  className="formtextfield small w-input"
                                  value={donationDate}
                                  onChange={this.handleDateChange}
                                  showClearButton={false}
                                />
                              </div>
                              <label htmlFor="programSelected" className="formfieldlabel">
                                Program
                              </label>
                              <Select
                                name="programSelected"
                                value={programSelected}
                                options={programsSelectOption}
                                onChange={this.handleSelectChange}
                              />
                              <label htmlFor="campaignSelected" className="formfieldlabel">
                                Campaign
                              </label>
                              <Select
                                name="campaignSelected"
                                value={campaignSelected}
                                options={campaignsSelectOption}
                                onChange={this.handleCampaignSelectChange}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <hr />
                          {donationType === 'Service' ? (
                            <>
                              <label className="formfieldlabel">Recurring</label>
                              <div className="f-radio-group">
                                <FancyRadio
                                  id="recurring-1"
                                  name="recurring"
                                  value="1"
                                  checked={recurring === '1'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Weekly</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="recurring-2"
                                  name="recurring"
                                  value="2"
                                  checked={recurring === '2'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Bi-Weekly</span>
                                </FancyRadio>
                                <FancyRadio
                                  id="recurring-3"
                                  name="recurring"
                                  value="3"
                                  checked={recurring === '3'}
                                  onChange={this.handleRadioChange}
                                >
                                  <span>Monthly</span>
                                </FancyRadio>
                                {MODE === 'dev' ? (
                                  <>
                                    <FancyRadio
                                      id="recurring-100"
                                      name="recurring"
                                      value="100"
                                      checked={recurring === '100'}
                                      onChange={this.handleRadioChange}
                                    >
                                      <span>Half Hour</span>
                                    </FancyRadio>
                                    <FancyRadio
                                      id="recurring-101"
                                      name="recurring"
                                      value="101"
                                      checked={recurring === '101'}
                                      onChange={this.handleRadioChange}
                                    >
                                      <span>Hourly</span>
                                    </FancyRadio>
                                    <FancyRadio
                                      id="recurring-102"
                                      name="recurring"
                                      value="102"
                                      checked={recurring === '102'}
                                      onChange={this.handleRadioChange}
                                    >
                                      <span>Daily</span>
                                    </FancyRadio>
                                  </>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                          <label htmlFor="balanceOwed" className="formfieldlabel">
                            Balance Owed
                          </label>
                          <div className="form-group">
                            <span className="substring-left">$</span>
                            <CurrencyInput
                              className="formtextfield small w-input currencyinput textfieldright"
                              name="balanceOwed"
                              value={balanceOwed}
                              inputMode="numeric"
                              placeholder="Enter balance owed"
                              onChange={this.handleAmountChange}
                            />
                          </div>
                          <label htmlFor="programSelected" className="formfieldlabel">
                            Program
                          </label>
                          <Select
                            name="programSelected"
                            value={programSelected}
                            options={programsSelectOption}
                            onChange={this.handleSelectChange}
                          />
                          <label htmlFor="campaignSelected" className="formfieldlabel">
                            Campaign
                          </label>
                          <Select
                            name="campaignSelected"
                            value={campaignSelected}
                            options={campaignsSelectOption}
                            onChange={this.handleCampaignSelectChange}
                          />
                        </>
                      )}
                      <CSSTransition in={error} timeout={500} classNames="errormessage" unmountOnExit>
                        {() => (
                          <div className="error-block" style={{ marginTop: 30 }}>
                            <div className="error-block-text">{errorMessage}</div>
                          </div>
                        )}
                      </CSSTransition>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {onSaving ? (
                      <Loader color="#3898EC" type="ThreeDots" height={40} width={40} />
                    ) : (
                      <>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={this.handleSaveModal}>
                          Save Changes
                        </Button>
                      </>
                    )}
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

List.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object).isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(List);
