import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import queryString from 'query-string';
import ScrollAnimation from 'react-animate-on-scroll';

import * as routes from './constants/routes';
import { UserProvider } from './Providers';

import SocialMediaButton from './Components/SocialMediaButton';

import 'animate.css/animate.min.css';
import './Assets/Styles/SignIn.css';

function ActionMessage(props) {
  const msgKey = props.message.replace(/-/g, '');
  const msgList = {
    signupsuccess: 'Email sent successfully! Please verify your email',
    resetpasswordsuccess: 'Your reset password was successfull! Please check your email',
    resendactivationlink: 'Email sent successfully! Please verify your email',
  };

  return msgList[msgKey];
}

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      email: '',
      password: '',
      submitButton: 'Sign In',
      disabled: false,
      errorInLogin: false,
      errorMessage: '',
      resendActivation: false,
      claimed: false,
      claimedKey: null,
      registerNonprofit: false,
      redirectUri: null,
      profileInfo: null,
    };

    this.state = this.initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendActivationLink = this.resendActivationLink.bind(this);
  }

  componentDidMount() {
    const { location, updateNavStatus, showFooterLinkContainer } = this.props;
    updateNavStatus('onlylogo');
    showFooterLinkContainer(false);

    const urlQuery = queryString.parse(location.search);
    if (urlQuery.claim) {
      this.setState({ claimed: true, claimedKey: urlQuery.claim });
    } else if (urlQuery.register) {
      switch (urlQuery.register) {
        case 'nonprofit':
          this.setState({ registerNonprofit: true });
          break;

        default:
      }
    } else if (urlQuery.redirect) {
      this.setState({ redirectUri: urlQuery.redirect });
    }
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
      errorInLogin: false,
      errorMessage: '',
      resendActivation: false,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      submitButton: 'Please wait...',
      disabled: true,
      errorInLogin: false,
      errorMessage: '',
      resendActivation: false,
      profileInfo: null,
    });

    const { email, password, claimed, claimedKey, registerNonprofit, redirectUri } = this.state;
    const { history } = this.props;

    UserProvider.signIn(email, password)
      .then(result => {
        const { userInfo, profileInfo } = result;
        if (profileInfo) {
          if (!profileInfo.emailVerified) {
            UserProvider.signOut();
            this.setState({
              submitButton: 'Sign In',
              disabled: false,
              errorInLogin: true,
              errorMessage: '',
              resendActivation: true,
              profileInfo,
            });
          } else {
            localStorage.setItem('user-info', JSON.stringify({ user: userInfo, profile: profileInfo }));
            if (claimed) {
              history.push(`${routes.NONPROFITS_REGISTER}?callback=claim&id=${claimedKey}&ref=${userInfo.uid}`);
            } else if (registerNonprofit) {
              history.push(`${routes.NONPROFITS_REGISTER}?callback=new`);
            } else if (profileInfo.agentStatus && profileInfo.agentStatus.isAgent) {
              const url = routes.NONPROFITS_ABOUT.replace(':id', profileInfo.agentStatus.nid).replace(
                ':name',
                profileInfo.agentStatus.slugName
              );
              history.push(url);
            } else if (redirectUri) {
              history.push(redirectUri);
            } else {
              history.push(routes.CAMPAIGNS);
            }
          }
        } else {
          UserProvider.signOut();
          this.setState({
            submitButton: 'Sign In',
            disabled: false,
            errorInLogin: true,
            errorMessage:
              'There is no user record corresponding to this identifier. The user may have been deleted or in dev mode.',
            resendActivation: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          submitButton: 'Sign In',
          disabled: false,
          errorInLogin: true,
          errorMessage: err,
          resendActivation: false,
        });
      });
  }

  resendActivationLink() {
    const { profileInfo } = this.state;

    UserProvider.registerToken(profileInfo.uid).then(tokenResult => {
      profileInfo.token = tokenResult.token;
      UserProvider.sendVerify(profileInfo).then(() => {
        document.location = '/signin?action=resend-activation-link';
      });
    });
  }

  render() {
    const {
      email,
      password,
      submitButton,
      disabled,
      errorInLogin,
      errorMessage,
      resendActivation,
      claimed,
      claimedKey,
      registerNonprofit,
      redirectUri,
    } = this.state;
    const { location } = this.props;
    const urlQuery = queryString.parse(location.search);
    const signinTitleCss = urlQuery.action ? 'h2 featuretitle signintitle-message' : 'h2 featuretitle signintitle';

    document.title = 'SignIn - Fundra';

    return (
      <div className="LoginSection">
        <div className="contentwrapper">
          {urlQuery.action ? (
            <div className="alert-base">
              <div className="alert-base-text">
                <ActionMessage message={urlQuery.action} />
              </div>
            </div>
          ) : null}
          <h2 className={signinTitleCss}>Welcome back</h2>
          <div className="featuretitledesc">Just one minute away from experiencing Fundra!</div>
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={400}>
            <div className="formlogin w-form">
              <SocialMediaButton
                caption="Sign In"
                claimed={claimed}
                claimedKey={claimedKey}
                registerNonprofit={registerNonprofit}
                redirectUri={redirectUri}
              />
              <div className="heading-with-line small">
                <h2>Sign In With Email</h2>
              </div>
              <form id="login-form" name="login-form" action="submitcontact" onSubmit={this.handleSubmit}>
                <label htmlFor="email" className="formfieldlabel">
                  Email address
                </label>
                <input
                  type="email"
                  className="formtextfield small w-input"
                  maxLength="256"
                  name="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor="password" className="formfieldlabel">
                  Password
                </label>
                <input
                  type="password"
                  className="formtextfield small w-input"
                  maxLength="256"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={this.handleChange}
                  required
                />
                <div className="forgotlinkcontainer">
                  <Link className="forgotlink" to="/forgot">
                    Forgot your password?
                  </Link>
                </div>
                <CSSTransition in={errorInLogin} timeout={300} classNames="errormessage" unmountOnExit>
                  {() => (
                    <div className="error-block">
                      {resendActivation ? (
                        <div className="error-block-text">
                          {`Please verify your email address\nor click `}
                          <a href="#resend-activation-link" onClick={this.resendActivationLink}>
                            here
                          </a>
                          {' to resend email activation link'}
                        </div>
                      ) : (
                        <div className="error-block-text">{errorMessage}</div>
                      )}
                    </div>
                  )}
                </CSSTransition>
                <input type="submit" value={submitButton} className="formsubmit w-button" disabled={disabled} />
                <div className="registerlinkcontainer">
                  {"Don't have an account? "}
                  <Link className="registerlink" to="/register">
                    Register
                  </Link>
                </div>
              </form>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default SignIn;
