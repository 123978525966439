import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-masked-input';
import Select from 'react-select';
import { CSSTransition } from 'react-transition-group';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import { database, storage } from '../firebase';
import { MetricProvider, ImpactProvider } from '../Providers';
import { stringHelper, dateTimeHelper } from '../helpers';

import * as routes from '../constants/routes';
import withAuthorization from '../withAuthorization';

import MetricsTable from '../Components/MetricsTable';
import ImpactsTable from '../Components/ImpactsTable';
import Waiting from '../Components/Waiting';
import { CustomBackButton, UploadButton } from '../Components/HtmlTag';

import imgPlaceHolder from '../Assets/Images/PlaceHolder.svg';
import ImgSearch from '../Assets/Images/Fill10.svg';

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nid: null,
      nonprofit: null,
      programSelected: null,
      programsSelectOption: [],
      categoryMetricSelected: null,
      typeMetricSelected: null,
      fundraMetricOptions: [],
      fundraTypeOptions: [],
      name: '',
      initiative: '',
      cost: '0.00',
      costNumber: 1,
      description: '',
      unitName: '',
      humanNumber: 1,
      humanUnit: '',
      showHumanUnit: { value: 'Y', label: 'Yes' },
      frequencyName: '',
      frequencyNumber: 0,
      mediaCover: null,
      mediaCoverPreview: imgPlaceHolder,
      mediaCoverExt: '',
      metricsLoad: true,
      metrics: [],
      filteredMetrics: [],
      selectedMetric: null,
      selectedRow: null,
      impactMonth: null,
      impactYear: null,
      impactCost: '0.00',
      impactTarget: '',
      monthSelectOption: dateTimeHelper.getMonthSelectOption(),
      yearSelectOption: dateTimeHelper.getYearSelectOption(2018),
      selectedImpact: null,
      impacts: [],
      impactsLoad: false,
      totalCount: 0,
      showModal: false,
      onSaving: false,
      modalTitle: 'New Metric',
      impactButtonText: 'Add',
      error: false,
      errorMessage: '',
      canAddNew: false,
    };

    this.handleBackProfileClick = this.handleBackProfileClick.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleProgramSelectChange = this.handleProgramSelectChange.bind(this);
    this.handleCategorySelectChange = this.handleCategorySelectChange.bind(this);
    this.handleTypeSelectChange = this.handleTypeSelectChange.bind(this);
    this.handleFrequencySelectChange = this.handleFrequencySelectChange.bind(this);
    this.handleShowHumanUnitSelectChange = this.handleShowHumanUnitSelectChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.saveMetric = this.saveMetric.bind(this);
    this.searchEnter = this.searchEnter.bind(this);
    this.removeMetric = this.removeMetric.bind(this);
    this.editMetric = this.editMetric.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
    this.handleMonthSelectChange = this.handleMonthSelectChange.bind(this);
    this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    this.saveImpact = this.saveImpact.bind(this);
    this.handleImpactRowClick = this.handleImpactRowClick.bind(this);
    this.removeImpact = this.removeImpact.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);

    this.metricListRef = React.createRef();
    this.impactListRef = React.createRef();
  }

  componentDidMount() {
    const { showFooterLinkContainer, updateNavStatus, match } = this.props;
    showFooterLinkContainer(false);
    updateNavStatus('management');

    const nid = match.params.id;
    this.setState({ nid }, () => {
      this.getNonprofit();
    });
  }

  getProgramList() {
    const { nid } = this.state;
    database
      .getRef('/nonprofit_programs')
      .child(nid)
      .once('value')
      .then(snap => {
        const selectOptions = [];

        snap.forEach(childSnap => {
          selectOptions.push({ value: childSnap.val().id, label: childSnap.val().name });
        });

        this.setState({ programsSelectOption: selectOptions });
      });
  }

  getMetricList() {
    const { nid, nonprofit } = this.state;
    let { canAddNew } = this.state;
    MetricProvider.getList(nid).then(metricSnapshot => {
      const metrics = [];
      metricSnapshot.forEach(item => {
        metrics.push({ id: item.key, ...item.val() });
      });

      const totalCount = metrics.length;
      if (totalCount > 1) {
        metrics.reverse();
      }

      if (nonprofit.subscribe) {
        canAddNew =
          nonprofit.subscribe.metricLimit === -1 ||
          (nonprofit.subscribe.metricLimit > 0 && nonprofit.subscribe.metricLimit > totalCount);
      }

      this.setState({
        metrics,
        totalCount,
        filteredMetrics: metrics,
        metricsLoad: false,
        canAddNew,
      });
    });
  }

  getImpactList() {
    const { nid, selectedRow } = this.state;
    ImpactProvider.getList(nid, selectedRow.id).then(impactSnapshot => {
      const impacts = [];
      impactSnapshot.forEach(item => {
        impacts.push({ id: item.key, ...item.val() });
      });

      if (impacts.length > 0) {
        impacts.reverse();
      }

      this.setState({
        impacts,
        impactsLoad: false,
      });
    });
  }

  getNonprofit() {
    const { nid } = this.state;
    const { authUser, history } = this.props;
    database
      .getRef('/nonprofit')
      .child(nid)
      .once('value')
      .then(snap => {
        if (authUser.user.uid !== snap.val().agent) {
          history.push(routes.NONPROFITS);
        } else {
          const nonprofit = {
            agent: snap.val().agent,
            img: snap.val().img,
            key: snap.val().key,
            name: snap.val().name,
            category: snap.val().category,
            type: snap.val().type,
            subscribe: snap.val().subscribe,
          };

          database
            .getRef('/category')
            .orderByChild('name')
            .equalTo(snap.val().category)
            .limitToFirst(1)
            .once('value')
            .then(snapshot => {
              let catId = '';
              snapshot.forEach(snaps => {
                catId = snaps.key;
              });

              database
                .getRef(`/category/${catId}/types`)
                .orderByChild('name')
                .equalTo(snap.val().type)
                .limitToFirst(1)
                .once('value')
                .then(snapChild => {
                  const fundraMetricOptions = [];
                  snapChild.forEach(childSnapshot => {
                    if (childSnapshot.val().metrics) {
                      childSnapshot.val().metrics.forEach(item => {
                        const types = [];
                        Object.keys(item.types).forEach(key => {
                          const type = item.types[key];
                          types.push({
                            value: type.id,
                            label: type.name,
                          });
                        });

                        fundraMetricOptions.push({
                          value: item.id,
                          label: item.name,
                          types,
                        });
                      });
                    }
                  });

                  this.setState(
                    {
                      nonprofit,
                      fundraMetricOptions,
                    },
                    () => {
                      this.getProgramList();
                      this.getMetricList();
                    }
                  );
                });
            });
        }
      });
  }

  editMetric(id, event) {
    event.stopPropagation();

    const { metrics, fundraMetricOptions } = this.state;
    const filter = metrics.filter(metric => metric.id === id);
    const selectedMetric = filter.length ? filter[0] : null;

    if (selectedMetric) {
      let selectedCategory = null;
      if (selectedMetric.category) {
        selectedCategory = fundraMetricOptions.filter(
          c => c.value.toString() === selectedMetric.category.id.toString()
        );
      }

      this.setState({
        modalTitle: 'Edit Metric',
        selectedMetric,
        programSelected: { value: selectedMetric.program.id, label: selectedMetric.program.name },
        categoryMetricSelected: selectedMetric.category
          ? { value: selectedMetric.category.id, label: selectedMetric.category.name }
          : null,
        fundraTypeOptions: selectedCategory ? selectedCategory.types : [],
        typeMetricSelected: selectedMetric.type
          ? { value: selectedMetric.type.id, label: selectedMetric.type.name }
          : null,
        cost: selectedMetric.cost,
        costNumber: selectedMetric.costNumber,
        unitName: selectedMetric.unitName ? selectedMetric.unitName : '',
        name: selectedMetric.name,
        initiative: selectedMetric.initiative ? selectedMetric.initiative : '',
        description: selectedMetric.description,
        humanNumber: selectedMetric.humanNumber ? selectedMetric.humanNumber : 0,
        humanUnit: selectedMetric.humanUnit ? selectedMetric.humanUnit : '',
        showHumanUnit: selectedMetric.showHumanUnit
          ? { value: selectedMetric.showHumanUnit, label: selectedMetric.showHumanUnit === 'Y' ? 'Yes' : 'No' }
          : { value: 'Y', label: 'Yes' },
        mediaCover: null,
        mediaCoverPreview: selectedMetric.cover ? selectedMetric.cover : imgPlaceHolder,
        frequencyNumber: selectedMetric.frequency ? selectedMetric.frequency.number : 0,
        frequencyName: selectedMetric.frequency
          ? { value: selectedMetric.frequency.name, label: selectedMetric.frequency.name }
          : '',
        showModal: true,
      });
    }
  }

  handleBackProfileClick(e) {
    e.preventDefault();

    const { nonprofit } = this.state;
    const { history } = this.props;
    const backUrl = routes.NONPROFITS_ABOUT.replace(':id', nonprofit.key).replace(
      ':name',
      stringHelper.slugify(nonprofit.name)
    );
    history.push(backUrl);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false }, () => {
      this.resetForm();
    });
  }

  handleProgramSelectChange(selectedOption) {
    this.setState({ programSelected: selectedOption });
  }

  handleCategorySelectChange(selectedOption) {
    this.setState({
      categoryMetricSelected: selectedOption,
      typeMetricSelected: null,
      fundraTypeOptions: selectedOption.types,
    });
  }

  handleTypeSelectChange(selectedOption) {
    this.setState({ typeMetricSelected: selectedOption });
  }

  handleMonthSelectChange(selectedOption) {
    this.setState({ impactMonth: selectedOption });
  }

  handleYearSelectChange(selectedOption) {
    this.setState({ impactYear: selectedOption });
  }

  handleFrequencySelectChange(selectedOption) {
    this.setState({ frequencyName: selectedOption });
  }

  handleShowHumanUnitSelectChange(selectedOption) {
    this.setState({ showHumanUnit: selectedOption });
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
  }

  handleAmountChange(event, maskValue) {
    const { target } = event;
    const { name } = target;

    this.setState({ [name]: maskValue });
  }

  handleRowClick(id) {
    const { metrics } = this.state;
    const filter = metrics.filter(metric => metric.id === id);
    const selectedRow = filter.length ? filter[0] : null;

    if (selectedRow) {
      this.metricListRef.current.className = 'm-fadeOut';

      this.setState(
        {
          selectedRow,
          impactCost: '0.00',
          impactTarget: '',
          impactMonth: null,
          impactYear: null,
          impactButtonText: 'Add',
          impacts: [],
          selectedImpact: null,
          impactsLoad: true,
        },
        () => {
          this.impactListRef.current.className = 'm-fadeIn';
          this.getImpactList();
        }
      );
    }
  }

  handleCloseDetail() {
    this.impactListRef.current.className = 'm-fadeOut';

    this.setState(
      {
        selectedRow: null,
        impactCost: '0.00',
        impactTarget: '',
        impactMonth: null,
        impactYear: null,
        impactButtonText: 'Add',
        impacts: [],
        selectedImpact: null,
      },
      () => {
        this.metricListRef.current.className = 'm-fadeIn';
      }
    );
  }

  handleImpactRowClick(id) {
    const { impacts, monthSelectOption, yearSelectOption } = this.state;
    const filter = impacts.filter(impact => impact.id === id);
    const selectedImpact = filter.length ? filter[0] : null;

    if (selectedImpact) {
      const selectedMonth = monthSelectOption.filter(month => month.value === selectedImpact.month);
      const selectedYear = yearSelectOption.filter(year => year.value === selectedImpact.year);
      const humanTarget = selectedImpact.target.human > 0 ? selectedImpact.target.human : selectedImpact.target.unit;
      this.setState({
        selectedImpact,
        impactMonth: selectedMonth[0],
        impactYear: selectedYear[0],
        impactCost: selectedImpact.cost,
        impactTarget: humanTarget,
        impactButtonText: 'Save',
      });
    }
  }

  handleFileChange(event) {
    const { name } = event.target;
    const previewImg = `${name}Preview`;
    const extImg = `${name}Ext`;
    // const ext = event.target.files[0].name.split('.').pop();

    this.setState({
      [name]: event.target.files[0],
      [previewImg]: URL.createObjectURL(event.target.files[0]),
      [extImg]: 'jpg', // set all extension to jpg, easy when removing from storage
    });
  }

  removeMetric(id, event) {
    event.stopPropagation();

    const { metrics, nonprofit } = this.state;
    const filter = metrics.filter(metric => metric.id === id);
    const selectedMetric = filter.length ? filter[0] : null;

    if (selectedMetric) {
      swal('Delete metric', `Are you sure you want to delete metric "${selectedMetric.name}"?`, {
        dangerMode: true,
        buttons: ['Cancel', 'Delete'],
      })
        .then(willDelete => {
          if (willDelete) {
            return ImpactProvider.removeChild(nonprofit.key, selectedMetric.id);
          }

          return 'cancel';
        })
        .then(res => {
          if (res !== 'cancel') {
            return MetricProvider.remove(nonprofit.key, selectedMetric.id);
          }

          return res;
        })
        .then(res => {
          if (res !== 'cancel') {
            this.setState(
              {
                selectedMetric: null,
                metricsLoad: true,
                selectedRow: null,
                impactCost: '0.00',
                impactTarget: '',
                impactMonth: null,
                impactYear: null,
                impactButtonText: 'Add',
                impacts: [],
                selectedImpact: null,
              },
              () => {
                this.getMetricList();
              }
            );
          }
        });
    }
  }

  removeImpact(id, event) {
    event.stopPropagation();

    const { impacts, nonprofit, selectedRow } = this.state;
    const filter = impacts.filter(impact => impact.id === id);
    const selectedImpact = filter.length ? filter[0] : null;

    if (selectedImpact) {
      swal('Delete impact', 'Are you sure you want to delete this impact?', {
        dangerMode: true,
        buttons: ['Cancel', 'Delete'],
      })
        .then(willDelete => {
          if (willDelete) {
            return ImpactProvider.remove(nonprofit.key, selectedRow.id, selectedImpact.id);
          }

          return 'cancel';
        })
        .then(res => {
          if (res !== 'cancel') {
            this.reloadImpact();
          }
        });
    }
  }

  reloadImpact() {
    this.setState(
      {
        selectedImpact: null,
        impactCost: '0.00',
        impactTarget: '',
        impactMonth: null,
        impactYear: null,
        impactButtonText: 'Add',
        impacts: [],
        impactsLoad: true,
      },
      () => {
        this.getImpactList();
      }
    );
  }

  async saveMetric() {
    this.setState({ onSaving: true });

    const result = this.validateForm();
    if (result.status) {
      const {
        nonprofit,
        selectedMetric,
        programSelected,
        categoryMetricSelected,
        typeMetricSelected,
        name,
        initiative,
        cost,
        costNumber,
        description,
        unitName,
        humanNumber,
        humanUnit,
        mediaCover,
        mediaCoverExt,
        frequencyName,
        frequencyNumber,
        showHumanUnit,
      } = this.state;

      const formData = {
        cost,
        costNumber,
        description,
        name,
        initiative,
        nonprofit: {
          key: nonprofit.key.toString(),
          name: nonprofit.name,
          logo: nonprofit.img.logo,
        },
        program: {
          id: programSelected.value.toString(),
          name: programSelected.label,
        },
        unitName: unitName.trim(),
        humanNumber,
        humanUnit: humanUnit.trim(),
        frequency: {
          name: frequencyName ? frequencyName.value.toString() : '',
          number: frequencyNumber,
        },
        showHumanUnit: showHumanUnit.value,
      };

      if (categoryMetricSelected) {
        formData.category = {
          id: categoryMetricSelected.value.toString(),
          name: categoryMetricSelected.label,
        };
      }

      if (typeMetricSelected) {
        formData.type = {
          id: typeMetricSelected.value.toString(),
          name: typeMetricSelected.label,
        };
      }

      if (!selectedMetric) {
        formData.date = dateTimeHelper.getCurrentTimeMs();
      } else {
        formData.date = selectedMetric.date;
        if (selectedMetric.cover) {
          formData.cover = selectedMetric.cover;
        }
      }

      let metricId = null;
      if (selectedMetric) {
        metricId = selectedMetric.id;
        await MetricProvider.updateData(nonprofit.key, selectedMetric.id, formData);
      } else {
        metricId = MetricProvider.pushData(nonprofit.key, formData).key;
      }

      // store the image
      const coverRefPath = process.env.REACT_APP_FIREBASE_NONPROFIT_METRICS_IMAGE_PATH;
      const mediaCoverFile = {
        file: mediaCover,
        meta: mediaCover ? { contentType: mediaCover.type } : '',
        name: '',
        ext: mediaCoverExt,
      };

      if (mediaCoverFile.file) {
        const coverSnap = await storage.put(
          `${coverRefPath}/${metricId}.${mediaCoverFile.ext}`,
          mediaCoverFile.file,
          mediaCoverFile.meta
        );
        const coverUrl = await coverSnap.ref.getDownloadURL();

        mediaCoverFile.name = coverUrl;
        await MetricProvider.updatePartialData(nonprofit.key, metricId, 'cover', mediaCoverFile.name);
      }

      this.afterSave();
    } else {
      this.setState({
        error: true,
        errorMessage: result.message,
        onSaving: false,
      });
    }
  }

  afterSave() {
    this.setState(
      {
        showModal: false,
        metricsLoad: true,
      },
      () => {
        this.getMetricList();
        this.resetForm();
      }
    );
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      const { metrics } = this.state;
      const filteredMetrics = metrics.filter(
        metric => metric.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
      );

      const totalCount = filteredMetrics.length;
      this.setState({ filteredMetrics, totalCount, selectedMetric: null });
    }
  }

  saveImpact() {
    const { impactCost, impactTarget, impactMonth, impactYear, selectedImpact, nonprofit, selectedRow } = this.state;

    if (impactMonth && impactYear) {
      const formData = {
        cost: impactCost,
        month: impactMonth.value,
        year: impactYear.value,
      };

      if (!selectedImpact) {
        formData.date = dateTimeHelper.getCurrentTimeMs();
        formData.metric = {
          cost: selectedRow.cost,
          costNumber: selectedRow.costNumber,
          unitName: selectedRow.unitName,
          humanNumber: selectedRow.humanNumber,
          humanUnit: selectedRow.humanUnit,
        };
      } else {
        formData.date = selectedImpact.date;
        if (!selectedImpact.metric) {
          formData.metric = {
            cost: selectedRow.cost,
            costNumber: selectedRow.costNumber,
            unitName: selectedRow.unitName,
            humanNumber: selectedRow.humanNumber,
            humanUnit: selectedRow.humanUnit,
          };
        } else {
          formData.metric = selectedImpact.metric;
        }
      }

      const totalSpend = Number.parseFloat(impactCost);
      const cost = Number.parseFloat(formData.metric.cost);
      const costNumber = Number.parseInt(formData.metric.costNumber, 10);
      const humanNumber = Number.parseInt(formData.metric.humanNumber, 10);
      const totalImpact = totalSpend / cost;
      const totalPack = Math.ceil(totalImpact * costNumber);
      const totalHuman = Math.ceil(totalImpact * humanNumber);
      formData.result = {
        unit: totalPack,
        human: totalHuman,
      };

      const tmpTarget = impactTarget !== '' ? Number.parseInt(impactTarget, 10) : 0;
      formData.target = {
        unit: humanNumber ? Math.ceil((tmpTarget / humanNumber) * costNumber) : tmpTarget,
        human: humanNumber ? tmpTarget : 0,
      };

      if (selectedImpact) {
        ImpactProvider.updateData(nonprofit.key, selectedRow.id, selectedImpact.id, formData).then(() => {
          this.reloadImpact();
        });
      } else {
        ImpactProvider.pushData(nonprofit.key, selectedRow.id, formData);
        this.reloadImpact();
      }
    } else {
      swal('Save Impact', 'Month or Year still empty!');
    }
  }

  validateForm() {
    const { programSelected, name, initiative, description } = this.state;

    let validateStatus = false;
    let errorMessage = '';
    if (!programSelected) {
      errorMessage = 'Please select one program';
    } else if (name.trim() === '') {
      errorMessage = 'Metric name still empty';
    } else if (initiative.trim() === '') {
      errorMessage = 'Metric initiative still empty';
    } else if (description.trim() === '') {
      errorMessage = 'Metric description still empty';
    } else {
      validateStatus = true;
    }

    return { status: validateStatus, message: errorMessage };
  }

  resetForm() {
    this.setState({
      selectedMetric: null,
      programSelected: null,
      categoryMetricSelected: null,
      typeMetricSelected: null,
      fundraTypeOptions: [],
      name: '',
      initiative: '',
      cost: '0.00',
      costNumber: 1,
      description: '',
      unitName: '',
      humanNumber: 1,
      humanUnit: '',
      showHumanUnit: { value: 'Y', label: 'Yes' },
      frequencyName: '',
      frequencyNumber: 0,
      mediaCover: null,
      mediaCoverPreview: imgPlaceHolder,
      mediaCoverExt: '',
      onSaving: false,
      modalTitle: 'New Metric',
      error: false,
      errorMessage: '',
    });
  }

  render() {
    const {
      nonprofit,
      totalCount,
      metricsLoad,
      selectedRow,
      filteredMetrics,
      programSelected,
      programsSelectOption,
      categoryMetricSelected,
      fundraMetricOptions,
      typeMetricSelected,
      fundraTypeOptions,
      name,
      initiative,
      cost,
      costNumber,
      description,
      unitName,
      humanNumber,
      humanUnit,
      frequencyName,
      frequencyNumber,
      mediaCover,
      mediaCoverPreview,
      impactCost,
      impactTarget,
      impactMonth,
      impactYear,
      yearSelectOption,
      monthSelectOption,
      impacts,
      impactsLoad,
      showModal,
      modalTitle,
      onSaving,
      error,
      errorMessage,
      canAddNew,
      impactButtonText,
      showHumanUnit,
    } = this.state;

    const frequencyOptions = [
      { value: 'Day', label: 'Day' },
      { value: 'Week', label: 'Week' },
      { value: 'Month', label: 'Month' },
      { value: 'Year', label: 'Year' },
    ];

    const showHumanUnitOptions = [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No' }];

    document.title = 'Fundra - Metrics Management';

    return (
      <>
        {!nonprofit ? (
          <Waiting clsStyle="section-loader-ss" />
        ) : (
          <div className="sectionhero donorcrmsection">
            <div className="contentwrapper herosectionfeatures">
              <div className="w-row">
                <div className="w-col w-col-12 w-col-stack">
                  <div className="backlink">
                    {!selectedRow ? (
                      <CustomBackButton
                        showBackButton
                        handleClick={this.handleBackProfileClick}
                        title="Back to profile"
                      />
                    ) : (
                      <CustomBackButton
                        showBackButton
                        handleClick={this.handleCloseDetail}
                        title="Back to metric list"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="m-fade-container">
                <div className="m-fadeIn" ref={this.metricListRef}>
                  <div className="w-row" style={{ marginTop: 20 }}>
                    <div className="w-col w-col-12">
                      <h3 className="h3 nonprofitabouttitle no-margin-top">Metrics List</h3>
                      <div className="joined">{`Displaying ${totalCount} metrics`}</div>
                    </div>
                  </div>
                  <div className="w-row" style={{ marginTop: 20 }}>
                    <div className="w-col w-col-5">
                      <div className="graygroupinput search-m">
                        <div className="searchinput">
                          <input
                            name="filterName"
                            type="text"
                            placeholder="Search metrics"
                            onKeyPress={this.searchEnter}
                          />
                        </div>
                        <div className="searchicon">
                          <img src={ImgSearch} alt="" />
                        </div>
                      </div>
                    </div>
                    {canAddNew ? (
                      <div className="w-col w-col-3" style={{ paddingLeft: 17 }}>
                        <button type="button" className="buttondefault f-btn-default-m" onClick={this.handleOpenModal}>
                          + Add metric
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <div className="w-row" style={{ marginTop: 20 }}>
                    <div className="w-col w-col-12">
                      {metricsLoad ? (
                        <div className="section-loader-data">
                          <div className="section-loader-container">
                            <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                          </div>
                        </div>
                      ) : (
                        <MetricsTable
                          items={filteredMetrics}
                          rowClick={this.handleRowClick}
                          editMetric={this.editMetric}
                          removeMetric={this.removeMetric}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="m-fadeOut" ref={this.impactListRef}>
                  {selectedRow ? (
                    <div className="w-row" style={{ marginTop: 20 }}>
                      <div className="w-col w-col-12 impact-list">
                        <div className="impact-list-header">
                          <div>
                            <span className="full-name">IMPACT</span>
                            <span className="email-address first">{selectedRow.name}</span>
                            <span className="email-address">{selectedRow.program.name}</span>
                          </div>
                        </div>
                        <div className="w-row">
                          <div className="w-col w-col-4" style={{ paddingRight: 15 }}>
                            <div className="w-row">
                              <div className="w-col w-col-6 w-col-stack" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <label htmlFor="impactMonth" className="formfieldlabel" style={{ marginTop: 0 }}>
                                  Month
                                </label>
                                <Select
                                  name="impactMonth"
                                  value={impactMonth}
                                  options={monthSelectOption}
                                  className="f-input-90"
                                  onChange={this.handleMonthSelectChange}
                                />
                              </div>
                              <div className="w-col w-col-6 w-col-stack" style={{ paddingRight: 0 }}>
                                <label htmlFor="impactYear" className="formfieldlabel" style={{ marginTop: 0 }}>
                                  Year
                                </label>
                                <Select
                                  name="impactYear"
                                  value={impactYear}
                                  options={yearSelectOption}
                                  onChange={this.handleYearSelectChange}
                                />
                              </div>
                            </div>
                            <div className="w-row">
                              <div className="w-col w-col-6 w-col-stack" style={{ paddingLeft: 0, paddingRight: 10 }}>
                                <label htmlFor="impactTarget" className="formfieldlabel">
                                  Target
                                </label>
                                <input
                                  type="number"
                                  className="formtextfield small w-input"
                                  name="impactTarget"
                                  placeholder="Enter target"
                                  value={impactTarget}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div className="w-col w-col-6 w-col-stack" style={{ paddingRight: 0 }}>
                                <label htmlFor="cost" className="formfieldlabel">
                                  Cost
                                </label>
                                <div className="form-group" style={{ marginBottom: 0 }}>
                                  <span className="substring-left" style={{ zIndex: 0 }}>
                                    $
                                  </span>
                                  <CurrencyInput
                                    className="formtextfield small w-input currencyinput textfieldright"
                                    name="impactCost"
                                    value={impactCost}
                                    inputMode="numeric"
                                    placeholder="Enter total cost"
                                    onChange={this.handleAmountChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div style={{ marginTop: '1em', textAlign: 'right' }}>
                              <button
                                type="button"
                                className="w-button f-btn-white"
                                style={{ width: '12rem', borderRadius: '0.5rem' }}
                                onClick={this.saveImpact}
                              >
                                {impactButtonText}
                              </button>
                            </div>
                          </div>
                          <div
                            className="w-col w-col-8"
                            style={{ minHeight: 210, paddingLeft: 15, borderLeft: '0.5px solid #EDF0F6' }}
                          >
                            {impactsLoad ? (
                              <div className="section-loader-x">
                                <div className="section-loader-container-right">
                                  <Loader color="#EB7557" type="ThreeDots" height={60} width={60} />
                                </div>
                              </div>
                            ) : (
                              <ImpactsTable
                                metric={selectedRow}
                                items={impacts}
                                rowClick={this.handleImpactRowClick}
                                removeImpact={this.removeImpact}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <Modal
                dialogClassName="f-modal-70w"
                show={showModal}
                onHide={this.handleCloseModal}
                aria-labelledby="metric-modal-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="metric-modal-title">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="donationRow">
                    <div className="w-row">
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="programSelected" className="formfieldlabel" style={{ marginTop: 0 }}>
                          Program
                        </label>
                        <Select
                          name="programSelected"
                          value={programSelected}
                          options={programsSelectOption}
                          onChange={this.handleProgramSelectChange}
                        />
                        <div className="w-row">
                          <div className="w-col w-col-6 w-col-stack" style={{ paddingRight: 5 }}>
                            <label htmlFor="categoryMetricSelected" className="formfieldlabel">
                              Metric Category
                            </label>
                            <Select
                              name="categoryMetricSelected"
                              value={categoryMetricSelected}
                              options={fundraMetricOptions}
                              onChange={this.handleCategorySelectChange}
                            />
                          </div>
                          <div className="w-col w-col-6 w-col-stack" style={{ paddingLeft: 5 }}>
                            <label htmlFor="typeMetricSelected" className="formfieldlabel">
                              Metric Type
                            </label>
                            <Select
                              name="typeMetricSelected"
                              value={typeMetricSelected}
                              options={fundraTypeOptions}
                              onChange={this.handleTypeSelectChange}
                            />
                          </div>
                        </div>
                        <label htmlFor="name" className="formfieldlabel">
                          Metric Name
                        </label>
                        <input
                          type="text"
                          className="formtextfield small w-input"
                          maxLength="256"
                          name="name"
                          placeholder="Enter your metric name"
                          value={name}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="initiative" className="formfieldlabel">
                          Metric Initiative
                        </label>
                        <input
                          type="text"
                          className="formtextfield small w-input"
                          maxLength="256"
                          name="initiative"
                          placeholder="Enter your metric initiative"
                          value={initiative}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="description" className="formfieldlabel">
                          Description
                        </label>
                        <textarea
                          className="formtextfield w-input"
                          rows={4}
                          name="description"
                          value={description}
                          placeholder="Enter your metric description"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="w-col w-col-6">
                        <div className="w-row">
                          <div className="w-col w-col-6 w-col-stack">
                            <label className="formfieldlabel" style={{ marginTop: 0 }}>
                              Upload cover photo
                            </label>
                            <img src={mediaCoverPreview} className="nonprofitcoverpreview" alt="" />
                          </div>
                          <div className="w-col w-col-6 w-col-stack">
                            <label className="coverlabel1 cover">Add photo</label>
                            <label className="coverlabel2">{mediaCover ? '' : 'no file uploaded yet'}</label>
                            <div className="upload-btn-wrapper">
                              <UploadButton />
                              <input type="file" name="mediaCover" onChange={this.handleFileChange} accept="image/*" />
                            </div>
                          </div>
                        </div>
                        <hr style={{ marginTop: '2.5em', marginBottom: '1.5em' }} />
                        <div className="w-row">
                          <div className="w-col w-col-4 w-col-stack" style={{ paddingRight: 5 }}>
                            <label htmlFor="costNumber" className="formfieldlabel">
                              Unit Number
                            </label>
                            <input
                              type="number"
                              className="formtextfield small w-input"
                              name="costNumber"
                              placeholder="Enter cost number"
                              value={costNumber}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="w-col w-col-4 w-col-stack" style={{ paddingLeft: 2.5, paddingRight: 2.5 }}>
                            <label htmlFor="unitName" className="formfieldlabel">
                              Unit Name
                            </label>
                            <input
                              type="text"
                              className="formtextfield small w-input"
                              maxLength="256"
                              name="unitName"
                              placeholder="Enter unit name"
                              value={unitName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="w-col w-col-4 w-col-stack" style={{ paddingLeft: 5 }}>
                            <label htmlFor="cost" className="formfieldlabel">
                              Unit Cost
                            </label>
                            <div className="form-group f-zero-bottom">
                              <span className="substring-left" style={{ zIndex: 0 }}>
                                $
                              </span>
                              <CurrencyInput
                                className="formtextfield small w-input currencyinput textfieldright f-zero-bottom"
                                name="cost"
                                value={cost}
                                inputMode="numeric"
                                placeholder="Enter metric cost"
                                onChange={this.handleAmountChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="w-row">
                          <div className="w-col w-col-4 w-col-stack" style={{ paddingRight: 5 }}>
                            <label htmlFor="humanNumber" className="formfieldlabel">
                              Human Number
                            </label>
                            <input
                              type="number"
                              className="formtextfield small w-input"
                              name="humanNumber"
                              placeholder="Enter human number"
                              value={humanNumber}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="w-col w-col-4 w-col-stack" style={{ paddingLeft: 2.5, paddingRight: 2.5 }}>
                            <label htmlFor="humanUnit" className="formfieldlabel">
                              Human Unit
                            </label>
                            <input
                              type="text"
                              className="formtextfield small w-input"
                              maxLength="256"
                              name="humanUnit"
                              placeholder="Enter human unit"
                              value={humanUnit}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="w-col w-col-4 w-col-stack" style={{ paddingLeft: 5 }}>
                            <label htmlFor="showHumanUnit" className="formfieldlabel">
                              Show Human Unit
                            </label>
                            <Select
                              name="showHumanUnit"
                              value={showHumanUnit}
                              options={showHumanUnitOptions}
                              onChange={this.handleShowHumanUnitSelectChange}
                            />
                          </div>
                        </div>
                        <div className="w-row">
                          <div className="w-col w-col-6 w-col-stack" style={{ paddingRight: 5 }}>
                            <label htmlFor="frequencyNumber" className="formfieldlabel">
                              Frequency Number
                            </label>
                            <input
                              type="number"
                              className="formtextfield small w-input"
                              name="frequencyNumber"
                              placeholder="Enter frequency number"
                              value={frequencyNumber}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="w-col w-col-6 w-col-stack" style={{ paddingLeft: 5 }}>
                            <label htmlFor="frequencyName" className="formfieldlabel">
                              Frequency Name
                            </label>
                            <Select
                              name="frequencyName"
                              value={frequencyName}
                              options={frequencyOptions}
                              onChange={this.handleFrequencySelectChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <CSSTransition in={error} timeout={500} classNames="errormessage" unmountOnExit>
                      {() => (
                        <div className="error-block" style={{ marginTop: 30 }}>
                          <div className="error-block-text">{errorMessage}</div>
                        </div>
                      )}
                    </CSSTransition>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {onSaving ? (
                    <Loader color="#3898EC" type="ThreeDots" height={40} width={40} />
                  ) : (
                    <>
                      <Button variant="secondary" onClick={this.handleCloseModal}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={this.saveMetric}>
                        Save Changes
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </>
    );
  }
}

List.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object).isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(List);
