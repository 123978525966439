import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Progress } from 'react-sweet-progress';
import CurrencyInput from 'react-currency-masked-input';
import { CardElement } from 'react-stripe-elements';
import Loader from 'react-loader-spinner';
import ScrollAnimation from 'react-animate-on-scroll';

import { database } from '../firebase';
import { CampaignProvider, DonationManualProvider, MetricProvider, SocialProvider } from '../Providers';
import { stringHelper, numberHelper, dateTimeHelper } from '../helpers';
import { BackButton } from '../Components/HtmlTag';
import WaitLoader from '../Components/Waiting';

import NonprofitTopNav from '../Components/NonprofitTopNav';
import FancyRadio from '../Components/FancyRadio';
import withStripeContext from '../withStripeContext';
import * as routes from '../constants/routes';

import ImgLogoDefault from '../Assets/Images/Media_Icon.svg';
import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';
import ImgCardAmex from '../Assets/Images/Cards/amex.svg';
import ImgCardDiners from '../Assets/Images/Cards/diners.svg';
import ImgCardDiscover from '../Assets/Images/Cards/discover.svg';
import ImgCardJcb from '../Assets/Images/Cards/jcb.svg';
import ImgCardMaestro from '../Assets/Images/Cards/maestro.svg';
import ImgCardMaster from '../Assets/Images/Cards/mastercard.svg';
import ImgCardUnion from '../Assets/Images/Cards/unionpay.svg';
import ImgCardVisa from '../Assets/Images/Cards/visa.svg';
import ImgCardDefault from '../Assets/Images/Cards/default.svg';
import { ReactComponent as FbLogoComponent } from '../Assets/Images/Facebook-Icon.svg';
import { ReactComponent as TwitterLogoComponent } from '../Assets/Images/Twitter.svg';

import 'animate.css/animate.min.css';
import 'react-sweet-progress/lib/style.css';
import '../Assets/Styles/Nonprofits-Donation.css';

const CardImage = {
  'American Express': ImgCardAmex,
  'Diners Club': ImgCardDiners,
  Discover: ImgCardDiscover,
  JCB: ImgCardJcb,
  Maestro: ImgCardMaestro,
  MasterCard: ImgCardMaster,
  UnionPay: ImgCardUnion,
  Visa: ImgCardVisa,
  Default: ImgCardDefault,
};

class NonprofitStripeDonation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: null,
      step: 0,
      percent: 30,
      disabledButton: true,
      waiting: true,
      nonprofit: {
        name: '',
        slugName: '',
        city: '',
        state: '',
        description: '',
        key: '',
        img: null,
        agent: '',
        goal: 0,
        goalText: '',
        raised: 0,
        raisedText: '',
        viewUrl: '',
        percent: 0,
        programUrl: '',
        subscribe: null,
        hideDonation: false,
        stripe_account: null,
      },
      agentProfile: null,
      programs: [],
      selectedProgram: null,
      metrics: [],
      selectedMetric: null,
      campaigns: [],
      selectedCampaign: null,
      showProgram: false,
      showMetric: false,
      showCampaign: false,
      supportOnCampaign: false,
      showRightInfo: false,
      campaignInfo: null,
      metricImpact: '',
      showStripeLoading: false,
      useMetricCover: false,
      error: false,
      errorMessage: '',
      idTrx: '',
      receiptId: '',
      totalDonation: 0.0,
      totalDonationText: '',
      totalSharing: '0.00',
      coverUs: '1',
      quantity: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      anonymous: false,
      comment: '',
      recurring: '',
      dedication: '',
      dedicationName: '',
      cardComplete: false,
      stripeResource: null,
      selectedCard: null,
      donationDone: false,
      showBack: false,
      donationCategory: 'Cash Deposit',
      donationType: 'Donation',
      donationMethod: 'Online',
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCardElementChange = this.handleCardElementChange.bind(this);
    this.handleConfirmationDonation = this.handleConfirmationDonation.bind(this);
    this.handleFBShareButton = this.handleFBShareButton.bind(this);
    this.handleTwitterButton = this.handleTwitterButton.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleDonationButton = this.handleDonationButton.bind(this);
    this.handleProgramSelected = this.handleProgramSelected.bind(this);
    this.handleMetricSelected = this.handleMetricSelected.bind(this);
    this.handleCampaignSelected = this.handleCampaignSelected.bind(this);
    this.handleDonationMetricButton = this.handleDonationMetricButton.bind(this);
    this.handleClearSelection = this.handleClearSelection.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, match, location } = this.props;
    updateNavStatus('empty');
    showFooterLinkContainer(false);

    const { id, type } = match.params;
    const urlQuery = queryString.parse(location.search);
    const showMetric = type !== 'b';
    this.setState({ showMetric, params: { id, type, metric: urlQuery.m ? urlQuery.m : null } }, () => {
      if (urlQuery.m || type !== 'b') {
        this.getNonprofitMetric(id);
      } else {
        this.getCampaignList(id);
      }
    });
  }

  getCampaignDetail(key, id) {
    database
      .getRef(`/campaigns/${id}`)
      .once('value')
      .then(snap => {
        if (snap.val()) {
          const slugName = stringHelper.slugify(snap.val().name);
          const totalGoal = snap.val().goal ? Number.parseFloat(snap.val().goal) : 0.0;
          const metricId = snap.val().metric;
          const campaign = {
            id: snap.val().id,
            cover: snap.val().cover,
            goal: totalGoal,
            goalText: numberHelper.getThousandFormat(totalGoal.toFixed(2)),
            location: snap.val().location,
            name: snap.val().name,
            slugName,
            story: snap.val().story ? snap.val().story : '',
            viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', id).replace(':name', slugName),
          };

          let totalRaised = 0.0;
          let percent = 0;
          let percentForBar = 0;

          database
            .getRef(`/donations/${key}`)
            .orderByChild('campaign/id')
            .equalTo(String(id))
            .once('value')
            .then(donationSnap => {
              donationSnap.forEach(childSnap => {
                totalRaised += parseFloat(childSnap.val().total.amount);
              });

              if (campaign.goal > 0) {
                percent = (totalRaised / campaign.goal) * 100;
                percentForBar = percent;
                if (percent > 100) {
                  percentForBar = 100;
                }
              }

              campaign.totalRaised = totalRaised;
              campaign.raisedText = numberHelper.getThousandFormat(totalRaised.toFixed(2));
              campaign.percent = percent;
              campaign.percentForBar = percentForBar;

              if (metricId) {
                MetricProvider.getDetail(key, metricId).then(metricSnap => {
                  const cost = Number.parseFloat(metricSnap.val().cost);
                  const costNumber = Number.parseInt(metricSnap.val().costNumber, 10);

                  let raisedPack = 0;
                  let goalPack = 0;
                  if (cost > 0) {
                    raisedPack = Math.floor((campaign.totalRaised / cost) * costNumber);
                    goalPack = Math.ceil((campaign.goal / cost) * costNumber);
                  }

                  campaign.metric = {
                    id: metricId,
                    name: metricSnap.val().name,
                    cost,
                    costNumber,
                    unitName: metricSnap.val().unitName,
                    raisedPack,
                    goalPack,
                  };

                  this.setState({
                    campaignInfo: campaign,
                    showRightInfo: true,
                  });
                });
              } else {
                this.setState({
                  campaignInfo: campaign,
                  showRightInfo: true,
                });
              }
            });
        }
      });
  }

  getOrganizer(key) {
    database
      .getRef(`/profile/${key}`)
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          this.setState(prevState => {
            const { firstName, lastName, avatar } = snapshot.val();
            const userInfo = {
              firstName,
              lastName,
              userFullName: `${firstName} ${lastName}`,
            };

            const firstInitial = firstName.substring(0, 1);
            const lastInitial = lastName.substring(0, 1);
            let userAvatar = `https://via.placeholder.com/150.png?text=${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;

            if (avatar) {
              userAvatar = avatar;
            }
            userInfo.avatar = userAvatar;

            const nonprofit = { ...prevState.nonprofit };
            nonprofit.agentProfile = userInfo;

            return { nonprofit };
          });
        }
      });
  }

  getProgramList(id) {
    database
      .getRef('/nonprofit_programs')
      .child(id)
      .once('value')
      .then(snap => {
        const programs = [];
        snap.forEach(childSnap => {
          programs.push({
            id: childSnap.val().id,
            name: childSnap.val().name,
          });
        });

        this.setState({ programs });
      });
  }

  getCampaignList(id) {
    CampaignProvider.getList(Number.parseInt(id, 10)).then(snap => {
      const campaigns = [];
      snap.forEach(childSnap => {
        if (childSnap.val().published) {
          campaigns.push({
            id: childSnap.val().id,
            name: childSnap.val().name,
            program: childSnap.val().program,
            metric: childSnap.val().metric,
          });
        }
      });

      this.setState({ campaigns, showCampaign: campaigns.length > 0, showMetric: campaigns.length === 0 }, () => {
        this.getNonprofitMetric(id);
      });
    });
  }

  async getFinancial(key) {
    let totalGoal = 0.0;
    const campaignSnap = await database
      .getRef('/campaigns')
      .orderByChild('nonprofit/key')
      .equalTo(key)
      .once('value');
    campaignSnap.forEach(snap => {
      if (snap.val().published) {
        if (snap.val().goal) {
          totalGoal += parseFloat(snap.val().goal);
        }
      }
    });

    let totalRaised = 0.0;
    const donationSnap = await database.getRef(`/donations/${key}`).once('value');
    donationSnap.forEach(snap => {
      if (snap.val().total) {
        totalRaised += parseFloat(snap.val().total.amount);
      }
    });

    this.setState(prevState => {
      let percent = 0;
      if (totalGoal > 0) {
        percent = (totalRaised / totalGoal) * 100;
        if (percent > 100) {
          percent = 100;
        }
      }

      const nonprofit = { ...prevState.nonprofit };
      nonprofit.goal = totalGoal;
      nonprofit.goalText = numberHelper.getThousandFormat(totalGoal.toFixed(2));
      nonprofit.raised = totalRaised;
      nonprofit.raisedText = numberHelper.getThousandFormat(totalRaised.toFixed(2));
      nonprofit.percent = percent;

      return { nonprofit, showRightInfo: true };
    });
  }

  getNonprofitMetric(nid) {
    MetricProvider.getList(nid).then(metricSnapshot => {
      const metrics = [];
      metricSnapshot.forEach(item => {
        metrics.push({
          id: item.key,
          name: item.val().name,
          cost: item.val().cost,
          costNumber: item.val().costNumber,
          unitName: item.val().unitName ? item.val().unitName.trim() : 'pack',
          program: item.val().program,
          humanNumber: item.val().humanNumber ? item.val().humanNumber : 0,
          humanUnit: item.val().humanUnit ? item.val().humanUnit.trim() : '',
          cover: item.val().cover,
          frequency: item.val().frequency,
        });
      });

      this.setState({ metrics }, () => {
        this.findNonprofit();
      });
    });
  }

  async findNonprofit() {
    let {
      showProgram,
      showMetric,
      showCampaign,
      supportOnCampaign,
      selectedProgram,
      selectedMetric,
      selectedCampaign,
      percent,
      useMetricCover,
      quantity,
    } = this.state;
    let loadProgram = true;
    const { params, nonprofit, metrics, campaigns } = this.state;
    const { id, type } = params;
    const snap = await database
      .getRef('/nonprofit')
      .child(id)
      .once('value');

    const viewUrl = routes.NONPROFITS_ABOUT;
    const programUrl = routes.NONPROFITS_PROGRAMS;
    const slugName = stringHelper.slugify(snap.val().name);
    nonprofit.agent = snap.val().agent;
    nonprofit.city = snap.val().city;
    nonprofit.description = snap.val().description;
    nonprofit.img = snap.val().img;
    nonprofit.key = snap.val().key;
    nonprofit.name = snap.val().name;
    nonprofit.slugName = slugName;
    nonprofit.state = snap.val().state;
    nonprofit.viewUrl = viewUrl.replace(':id', snap.val().key).replace(':name', slugName);
    nonprofit.programUrl = programUrl.replace(':id', snap.val().key).replace(':name', slugName);
    nonprofit.hideDonation = snap.val().hideDonation ? snap.val().hideDonation : false;
    nonprofit.stripe_account = snap.val().stripe_account ? snap.val().stripe_account : null;
    nonprofit.subscribe = snap.val().subscribe ? snap.val().subscribe : null;
    if (snap.val().agent) {
      this.getOrganizer(snap.val().agent);
    }

    if (type !== 'b') {
      // check program first
      const programSnap = await database
        .getRef(`/nonprofit_programs/${id}`)
        .child(type)
        .once('value');
      if (programSnap.val()) {
        selectedProgram = {
          id: programSnap.val().id,
          name: programSnap.val().name,
        };
        loadProgram = false;
        if (campaigns.length > 0) {
          const filterCampaign = campaigns.filter(
            campaign => campaign.program.id.toString() === programSnap.val().id.toString()
          );
          showCampaign = filterCampaign.length > 0;
        }

        if (!showCampaign) {
          const filter = metrics.filter(metric => metric.program.id === programSnap.val().id);
          showMetric = filter.length > 0;
        }
      } else {
        const campaignSnap = await database
          .getRef('/campaigns')
          .child(type)
          .once('value');
        if (campaignSnap.val()) {
          selectedCampaign = {
            id: campaignSnap.val().id,
            name: campaignSnap.val().name,
          };
          supportOnCampaign = true;
          showCampaign = false;

          if (campaignSnap.val().metric) {
            loadProgram = false;
            const filter = metrics.filter(metric => metric.id === campaignSnap.val().metric);
            selectedMetric = filter.length ? filter[0] : null;
            if (selectedMetric) {
              showMetric = false;
              selectedProgram = selectedMetric.program;
            }
          } else if (campaignSnap.val().program) {
            loadProgram = false;
            showMetric = false;
            selectedProgram = campaignSnap.val().program;
          }
        }
      }
    } else if (params.metric) {
      const filter = metrics.filter(metric => metric.id === params.metric);
      selectedMetric = filter.length ? filter[0] : null;
      if (selectedMetric) {
        loadProgram = false;
        showMetric = false;
        useMetricCover = true;
        selectedProgram = selectedMetric.program;
      }
    }

    if (loadProgram && campaigns.length === 0 && metrics.length === 0) {
      showProgram = true;
      showMetric = false;
      showCampaign = false;
      this.getProgramList(id);
    }

    if (!showProgram && !showMetric && !showCampaign) {
      percent += 30;
    }

    if (selectedMetric) {
      quantity = 1;
      const totalDonation = quantity * Number.parseFloat(selectedMetric.cost);
      this.updateTotalDonation(totalDonation.toFixed(2));
    }

    const { authUser } = this.props;
    this.setState(
      {
        nonprofit,
        selectedProgram,
        selectedMetric,
        selectedCampaign,
        showProgram,
        showMetric,
        showCampaign,
        supportOnCampaign,
        percent,
        useMetricCover,
        quantity,
        firstName: authUser ? authUser.profile.firstName : '',
        lastName: authUser ? authUser.profile.lastName : '',
        email: authUser ? authUser.profile.email : '',
        waiting: false,
      },
      () => {
        if (!supportOnCampaign) {
          this.getFinancial(nonprofit.key);
        } else {
          this.getCampaignDetail(nonprofit.key, selectedCampaign.id);
        }
      }
    );
  }

  handleAmountChange(event, maskValue) {
    this.updateTotalDonation(maskValue);
  }

  handleDonationButton(key) {
    this.updateTotalDonation(key);
  }

  handleDonationMetricButton(key) {
    const { selectedMetric } = this.state;
    const quantity = Number.parseInt(key, 10);
    const totalDonation = quantity * Number.parseFloat(selectedMetric.cost);

    this.setState({ quantity }, () => {
      this.updateTotalDonation(totalDonation.toFixed(2));
    });
  }

  handleRadioChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  handleClearSelection(event, key) {
    event.preventDefault();
    let { recurring, dedication, dedicationName } = this.state;

    if (key === 1) {
      recurring = '';
    } else if (key === 2) {
      dedication = '';
      dedicationName = '';
    }

    this.setState({
      recurring,
      dedication,
      dedicationName,
    });
  }

  handleBackClick(e) {
    e.preventDefault();

    this.setState(prevState => {
      const { showProgram, showMetric, showCampaign } = prevState;
      const initialPercent = !showProgram && !showMetric && !showCampaign ? 60 : 30;
      const percent = prevState.percent >= 100 ? initialPercent : prevState.percent - 30;
      const step = prevState.percent >= 100 ? 0 : prevState.step - 1;

      return {
        step,
        percent,
        metricImpact: '',
        stripeResource: null,
        selectedCard: null,
        showBack: step !== 0,
        disabledButton: false,
        cardComplete: false,
        showStripeLoading: false,
      };
    });
  }

  async handleNext() {
    const { nonprofit, firstName, lastName, email, phone, donationCategory, donationType, cardComplete } = this.state;
    let { idTrx } = this.state;
    const { stripe } = this.props;

    this.setState({
      error: false,
      errorMessage: '',
      showBack: false,
    });

    if (cardComplete) {
      let goNext = false;
      if (email.trim() === '' || !stringHelper.isValidEmailAddress(email)) {
        this.setState({ error: true, errorMessage: 'Email Address required or in wrong format' });
      } else if (phone !== '' && !stringHelper.isValidPhoneNumber(phone)) {
        this.setState({
          error: true,
          errorMessage: 'Wrong format for Phone Number (123) 456-7890',
        });
      } else if (donationCategory === 'Cash Deposit') {
        if (phone === '' && (donationType === 'Service' || donationType === 'Pledge')) {
          this.setState({
            error: true,
            errorMessage: 'Phone number required for Service/Pledge transaction',
          });
        } else {
          goNext = true;
        }
      } else {
        goNext = true;
      }

      if (goNext) {
        document.location.href = '#top';
        this.setState({ showStripeLoading: true, percent: 100 });

        let agentProfile = null;
        if (nonprofit.agent) {
          const profileRes = await database.getRef(`/profile/${nonprofit.agent}`).once('value');
          if (profileRes) {
            agentProfile = {
              email: profileRes.val().email,
              firstName: profileRes.val().firstName,
              lastName: profileRes.val().lastName,
            };
            if (profileRes.val().uid) {
              agentProfile.uid = profileRes.val().uid;
            }
          }
        }

        if (idTrx === '') {
          idTrx = database.pushEmptyRef('/tmpTrx').key;
        }

        const stripeData = { type: 'card' };
        const owner = {};
        if (firstName !== '') {
          owner.name = `${firstName} ${lastName}`;
        }
        if (email !== '') {
          owner.email = email;
        }
        if (phone !== '') {
          owner.phone = stringHelper.trimPhone(phone);
        }
        if (owner.constructor === Object && Object.keys(owner).length > 0) {
          stripeData.owner = owner;
        }

        const stripeResult = await stripe.createSource(stripeData);
        if (stripeResult.error) {
          this.setState({
            error: true,
            errorMessage: stripeResult.error.message,
            disabledButton: false,
          });
        } else {
          this.setState(prevState => {
            const nextStep = prevState.showProgram || prevState.showMetric || prevState.showCampaign ? 1 : 2;
            const { totalDonation, selectedMetric } = prevState;
            let metricImpact = '';
            if (selectedMetric) {
              // eslint-disable-next-line max-len
              const totalImpact = Number.parseFloat(totalDonation) / Number.parseFloat(selectedMetric.cost);
              const totalPack = totalImpact * Number.parseInt(selectedMetric.costNumber, 10);
              const totalHuman = totalImpact * Number.parseInt(selectedMetric.humanNumber, 10);
              metricImpact = `${numberHelper.getThousandFormat(totalPack.toFixed(2))} ${stringHelper.makePluralize(
                selectedMetric.unitName,
                totalPack
              )}`;
              if (totalHuman > 0) {
                metricImpact += ` to ${numberHelper.getThousandFormat(
                  totalHuman.toFixed(2)
                )} ${stringHelper.makePluralize(selectedMetric.humanUnit, totalHuman)}`;
              }
            }

            return {
              idTrx,
              receiptId: dateTimeHelper.getCurrentTimeMs(),
              agentProfile,
              metricImpact,
              error: false,
              errorMessage: '',
              stripeResource: stripeResult.source,
              step: prevState.step + nextStep,
              disabledButton: true,
              showBack: true,
              showStripeLoading: false,
            };
          });
        }
      }
    } else {
      document.location.href = '#top';

      this.setState(prevState => {
        let disabledButton = true;
        if (prevState.step === 0 && (prevState.showProgram || prevState.showMetric || prevState.showCampaign)) {
          if (prevState.totalDonation > 0) {
            disabledButton = false;
          }
        }

        return {
          // donationDone: true,
          showBack: true,
          disabledButton,
          step: prevState.step + 1,
          percent: prevState.percent + 30,
        };
      });
    }
  }

  handleProgramSelected(item) {
    this.setState({ selectedProgram: item, disabledButton: false });
  }

  handleMetricSelected(item) {
    this.setState(
      {
        quantity: 1,
        selectedMetric: item,
        selectedProgram: item.program,
        disabledButton: false,
      },
      () => {
        const totalDonation = 1 * Number.parseFloat(item.cost);
        this.updateTotalDonation(totalDonation.toFixed(2));
      }
    );
  }

  handleCampaignSelected(item) {
    const { showRightInfo } = this.state;

    if (showRightInfo) {
      this.setState({ showRightInfo: false });

      let hasMetric = false;
      const { nonprofit } = this.state;
      if (item.metric) {
        const { metrics } = this.state;
        const filter = metrics.filter(metric => metric.id === item.metric);
        const selectedMetric = filter.length ? filter[0] : null;
        if (selectedMetric) {
          hasMetric = true;
          this.setState(
            {
              quantity: 1,
              selectedCampaign: item,
              selectedMetric,
              selectedProgram: selectedMetric.program,
              supportOnCampaign: true,
              disabledButton: false,
            },
            () => {
              this.getCampaignDetail(nonprofit.key, item.id);

              const totalDonation = 1 * Number.parseFloat(selectedMetric.cost);
              this.updateTotalDonation(totalDonation.toFixed(2));
            }
          );
        }
      }

      if (!hasMetric) {
        this.setState(
          {
            selectedCampaign: item,
            quantity: 0,
            selectedProgram: item.program,
            selectedMetric: null,
            supportOnCampaign: true,
            disabledButton: false,
          },
          () => {
            this.getCampaignDetail(nonprofit.key, item.id);
          }
        );
      }
    }
  }

  handleChange(event) {
    const { target } = event;
    const { name, type } = target;
    const value = type === 'checkbox' ? target.checked : target.value;
    const { dedication } = this.state;

    if (name === 'dedicationName') {
      if (value !== '' && dedication === '') {
        this.setState({ dedication: '{"id": 1, "title": "Dedicated to"}' });
      } else if (value === '' && dedication !== '') {
        this.setState({ dedication: '' });
      }
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === 'firstName' || name === 'lastName' || name === 'email') {
          this.validateForm();
        }
      }
    );
  }

  updateTotalDonation(key) {
    const { nonprofit } = this.state;
    const totalDonation = Number.parseFloat(key);
    let totalAmount = totalDonation;
    let feeCost = 0.05;
    if (nonprofit.subscribe) {
      feeCost = Number.parseFloat(nonprofit.subscribe.fee);
    }
    if (feeCost > 0) {
      const fundraFee = Number.parseFloat(totalDonation * feeCost).toFixed(2);
      totalAmount += Number.parseFloat(fundraFee);
    }
    const totalSharing = Number.parseFloat((totalAmount + 0.3) / (1 - 0.029)).toFixed(2);

    this.setState({
      totalDonation,
      totalDonationText: key,
      totalSharing,
      disabledButton: totalDonation === 0,
    });
  }

  handleCardElementChange(change) {
    if (change.error) {
      this.setState({
        error: true,
        errorMessage: change.error.message,
        cardComplete: false,
        disabledButton: true,
      });
    } else {
      this.setState(
        {
          error: false,
          errorMessage: '',
          cardComplete: change.complete,
        },
        () => {
          this.validateForm();
        }
      );
    }
  }

  handleConfirmationDonation() {
    document.location.href = '#top';

    this.setState({ showStripeLoading: true, percent: 100, showBack: false });
    const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const PROCESS_URL = process.env.REACT_APP_DONATION_PROCESS_URL;
    const {
      email,
      phone,
      firstName,
      lastName,
      comment,
      anonymous,
      idTrx,
      receiptId,
      totalDonation,
      totalDonationText,
      nonprofit,
      selectedProgram,
      coverUs,
      dedication,
      dedicationName,
      selectedCampaign,
      selectedMetric,
      agentProfile,
      recurring,
      stripeResource,
      selectedCard,
      donationMethod,
      donationCategory,
      donationType,
    } = this.state;
    const { authUser } = this.props;

    const userInfo = {
      firstName,
      lastName,
      phone,
      comment,
    };
    if (email !== '') {
      userInfo.email = email;
    }
    if (firstName !== '') {
      userInfo.fullName = `${firstName} ${lastName}`;
    }
    if (anonymous) {
      userInfo.anonymous = true;
    }

    const userInfoManual = { ...userInfo };

    if (authUser) {
      userInfo.uid = authUser.user.uid;
      userInfo.scid = authUser.profile.scid;
    }

    const nonprofitInfo = {
      key: nonprofit.key.toString(),
      name: nonprofit.name,
      logo: nonprofit.img.logo,
    };
    if (nonprofit.stripe_account) {
      nonprofitInfo.stripe_account = nonprofit.stripe_account;
    }

    const formData = {
      idTrx,
      receipt_id: receiptId,
      total: { amount: totalDonation },
      from: 'web',
      nonprofit: nonprofitInfo,
      program: {
        id: selectedProgram.id,
        name: selectedProgram.name,
      },
      user: userInfo,
      fee: { cover: coverUs },
      dedication: {
        type: dedication,
        value: dedicationName,
      },
    };

    if (selectedCampaign) {
      formData.campaign = {
        id: selectedCampaign.id,
        name: selectedCampaign.name,
      };
    }

    if (selectedMetric) {
      formData.metric = {
        id: selectedMetric.id,
        name: selectedMetric.name,
        cost: selectedMetric.cost,
        costNumber: selectedMetric.costNumber,
        unitName: selectedMetric.unitName,
        humanNumber: selectedMetric.humanNumber,
        humanUnit: selectedMetric.humanUnit,
      };
    }

    if (nonprofit.agent) {
      formData.category = donationCategory;
      formData.type = donationType;
      formData.method = donationMethod;
      formData.agent = agentProfile;
    }

    if (recurring !== '') {
      formData.recurring = { when: Number.parseInt(recurring, 10) };
    }

    if (stripeResource) {
      formData.source = JSON.stringify(stripeResource);
    }
    if (selectedCard) {
      formData.card = selectedCard;
    }

    axios
      .post(PROCESS_URL, qs.stringify(formData), axiosConfig)
      .then(() => {
        if (nonprofit.agent) {
          const manualFormData = {
            category: donationCategory,
            type: donationType,
            nonprofit: nonprofitInfo,
            program: {
              id: selectedProgram.id.toString(),
              name: selectedProgram.name,
            },
            user: userInfoManual,
            date: dateTimeHelper.getCurrentTimeMs(),
            total: { amount: totalDonationText },
            method: donationMethod,
            from: 'web',
          };

          if (selectedCampaign) {
            manualFormData.campaign = {
              id: selectedCampaign.id,
              name: selectedCampaign.name,
            };
          }

          if (selectedMetric) {
            manualFormData.metric = {
              id: selectedMetric.id,
              name: selectedMetric.name,
              cost: selectedMetric.cost,
              costNumber: selectedMetric.costNumber,
              unitName: selectedMetric.unitName,
              humanNumber: selectedMetric.humanNumber,
              humanUnit: selectedMetric.humanUnit,
            };
          }

          if (dedication !== '') {
            manualFormData.dedication = { type: JSON.parse(dedication), value: dedicationName };
          }

          if (nonprofit.agent) {
            manualFormData.agent = agentProfile;
          }

          DonationManualProvider.pushData(nonprofit.key, manualFormData).then(() => {
            this.setState({ donationDone: true });
          });
        } else {
          this.setState({ donationDone: true });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleFBShareButton() {
    const { nonprofit } = this.state;
    const { viewUrl, name } = nonprofit;
    SocialProvider.facebookShare(viewUrl, name);
  }

  handleTwitterButton() {
    const { nonprofit } = this.state;
    const { viewUrl, name } = nonprofit;
    SocialProvider.twitterShare(viewUrl, name);
  }

  validateForm() {
    const { cardComplete } = this.state;
    this.setState({ disabledButton: !cardComplete });
  }

  render() {
    document.title = 'Send Your Donation - Fundra';

    const {
      nonprofit,
      showBack,
      donationDone,
      waiting,
      step,
      percent,
      totalDonation,
      totalDonationText,
      coverUs,
      totalSharing,
      quantity,
      firstName,
      lastName,
      email,
      phone,
      anonymous,
      comment,
      dedication,
      dedicationName,
      recurring,
      programs,
      selectedProgram,
      error,
      errorMessage,
      stripeResource,
      selectedCampaign,
      receiptId,
      donationCategory,
      donationType,
      disabledButton,
      supportOnCampaign,
      showRightInfo,
      campaignInfo,
      campaigns,
      metrics,
      selectedMetric,
      showProgram,
      showMetric,
      showCampaign,
      showStripeLoading,
      metricImpact,
      useMetricCover,
    } = this.state;
    const nonprofitLogo = nonprofit.img ? nonprofit.img.logo : ImgLogoDefault;
    const nonprofitCover = nonprofit.img ? nonprofit.img.cover : ImgCoverDefault;
    const totalCharge = coverUs === '1' ? totalSharing : totalDonationText;
    const { authUser } = this.props;

    let firstView = null;
    let secondView = null;
    let thirdView = null;
    if (!donationDone && !waiting) {
      if (step === 0) {
        if (showProgram) {
          firstView = (
            <HowDonationProgramSection
              programs={programs}
              selectedProgram={selectedProgram}
              handleProgramSelected={this.handleProgramSelected}
            />
          );
        } else if (showMetric) {
          firstView = (
            <HowDonationMetricSection
              metrics={metrics}
              selectedMetric={selectedMetric}
              handleMetricSelected={this.handleMetricSelected}
            />
          );
        } else if (showCampaign) {
          firstView = (
            <HowDonationCampaignSection
              campaigns={campaigns}
              selectedCampaign={selectedCampaign}
              handleCampaignSelected={this.handleCampaignSelected}
            />
          );
        } else {
          firstView = (
            <HowMuchSection
              nonprofit={nonprofit}
              selectedMetric={selectedMetric}
              totalDonation={totalDonation}
              totalDonationText={totalDonationText}
              coverUs={coverUs}
              totalSharing={totalSharing}
              quantity={quantity}
              handleAmountChange={this.handleAmountChange}
              handleDonationButton={this.handleDonationButton}
              handleRadioChange={this.handleRadioChange}
              handleDonationMetricButton={this.handleDonationMetricButton}
            />
          );
        }
      } else if (step === 1) {
        if (showProgram || showMetric || showCampaign) {
          secondView = (
            <HowMuchSection
              nonprofit={nonprofit}
              selectedMetric={selectedMetric}
              totalDonation={totalDonation}
              totalDonationText={totalDonationText}
              coverUs={coverUs}
              totalSharing={totalSharing}
              quantity={quantity}
              handleAmountChange={this.handleAmountChange}
              handleDonationButton={this.handleDonationButton}
              handleRadioChange={this.handleRadioChange}
              handleDonationMetricButton={this.handleDonationMetricButton}
            />
          );
        } else {
          secondView = (
            <DonateProcessSection
              nonprofit={nonprofit}
              authUser={authUser}
              donationCategory={donationCategory}
              donationType={donationType}
              firstName={firstName}
              lastName={lastName}
              email={email}
              phone={phone}
              anonymous={anonymous}
              comment={comment}
              recurring={recurring}
              dedication={dedication}
              dedicationName={dedicationName}
              showStripeLoading={showStripeLoading}
              handleRadioChange={this.handleRadioChange}
              handleChange={this.handleChange}
              handleCardElementChange={this.handleCardElementChange}
              handleClearSelection={this.handleClearSelection}
            />
          );
        }
      } else if (step === 2) {
        thirdView = (
          <DonateProcessSection
            nonprofit={nonprofit}
            authUser={authUser}
            donationCategory={donationCategory}
            donationType={donationType}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            anonymous={anonymous}
            comment={comment}
            recurring={recurring}
            dedication={dedication}
            dedicationName={dedicationName}
            showStripeLoading={showStripeLoading}
            handleRadioChange={this.handleRadioChange}
            handleChange={this.handleChange}
            handleCardElementChange={this.handleCardElementChange}
            handleClearSelection={this.handleClearSelection}
          />
        );
      }
    }

    return (
      <div className="DonationSection">
        <div className="contentwrapper donation-detail">
          <NonprofitTopNav item={nonprofit} authUser={authUser} noNavBottom noSupport />
          <div className={`backlink${showBack ? ' show-link' : ''}`}>
            <BackButton showBackButton={showBack} handleClick={this.handleBackClick} />
          </div>
          {!donationDone ? (
            <>
              {waiting ? (
                <WaitLoader clsStyle="section-loader-ss" />
              ) : (
                <div className="w-row donationRow">
                  {step < 3 ? (
                    <div className="w-col w-col-8 w-col-stack">
                      <h3 className="h3 donor-title">Donate</h3>
                      <div className="progressbar">
                        <Progress percent={percent} theme={{ default: { symbol: ' ' } }} />
                      </div>
                      {step === 0 ? (
                        <ScrollAnimation animateIn="fadeInUp" animateOnce offset={0}>
                          {firstView}
                        </ScrollAnimation>
                      ) : null}
                      {showStripeLoading ? (
                        <div className="section-loader-container-right" style={{ marginTop: 100, marginBottom: 50 }}>
                          <Loader color="#EB7557" type="Oval" height={60} width={60} />
                        </div>
                      ) : null}
                      <CSSTransition in={step === 1} timeout={500} classNames="registerstep" unmountOnExit>
                        {() => secondView}
                      </CSSTransition>
                      <CSSTransition in={step === 2} timeout={500} classNames="registerstep" unmountOnExit>
                        {() => thirdView}
                      </CSSTransition>
                      <CSSTransition in={error} timeout={500} classNames="errormessage" unmountOnExit>
                        {() => (
                          <div className="error-block">
                            <div className="error-block-text">{errorMessage}</div>
                          </div>
                        )}
                      </CSSTransition>
                      {!showStripeLoading ? (
                        <div className="donation-next">
                          <button
                            type="button"
                            className="w-button f-btn f-btn-danger"
                            disabled={disabledButton}
                            onClick={this.handleNext}
                          >
                            Next
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="w-col w-col-8 w-col-stack">
                      <h3 className="h3 donor-title">Confirm donation</h3>
                      {showStripeLoading ? (
                        <div className="section-loader-container-right" style={{ marginTop: 100 }}>
                          <Loader color="#EB7557" type="Oval" height={60} width={60} />
                        </div>
                      ) : (
                        <>
                          <CSSTransition in={step === 3} timeout={500} classNames="registerstep" unmountOnExit>
                            {() => (
                              <>
                                <label className="donation-medium-label">Total charge</label>
                                <label className="donation-amount-label">{`$${totalCharge}`}</label>
                                <label className="donate-question">Pay with this credit card:</label>
                                <label className="donation-small-label">{`${firstName} ${lastName}`}</label>
                                <div className="donation-card-info">
                                  <img
                                    src={
                                      CardImage[stripeResource.card.brand]
                                        ? CardImage[stripeResource.card.brand]
                                        : CardImage.Default
                                    }
                                    alt={stripeResource.card.brand}
                                  />
                                  <label className="donation-small-label">{stripeResource.card.brand}</label>
                                  <label className="donation-small-label">&#8226;&#8226;&#8226;&#8226;</label>
                                  <label className="donation-small-label">{stripeResource.card.last4}</label>
                                </div>
                                <label className="donate-question">Additional information:</label>
                                {selectedMetric ? (
                                  <label className="donation-small-label more-big">
                                    <strong>
                                      {'You are providing '}
                                      <span className="f-lowercase">{metricImpact}</span>
                                    </strong>
                                  </label>
                                ) : (
                                  <>
                                    <label className="donation-small-label">
                                      {'Donate To '}
                                      <strong>{nonprofit.name}</strong>
                                    </label>
                                    <label className="donation-small-label">
                                      {'Program '}
                                      <strong>{selectedProgram.name}</strong>
                                    </label>
                                    {selectedCampaign ? (
                                      <label className="donation-small-label">
                                        {'Campaign '}
                                        <strong>{selectedCampaign.name}</strong>
                                      </label>
                                    ) : null}
                                  </>
                                )}
                              </>
                            )}
                          </CSSTransition>
                          <div className="donation-next">
                            <button
                              type="button"
                              className="w-button f-btn f-btn-danger"
                              onClick={this.handleConfirmationDonation}
                            >
                              Confirm Donation
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="w-col w-col-4 w-col-stack donor-nonprofit-detail">
                    <div className="nonprofit-small-detail">
                      {showRightInfo ? (
                        <>
                          {supportOnCampaign ? (
                            <CampaignProfile
                              nonprofit={nonprofit}
                              nonprofitLogo={nonprofitLogo}
                              campaign={campaignInfo}
                            />
                          ) : (
                            <NonprofitProfile
                              nonprofit={nonprofit}
                              nonprofitLogo={nonprofitLogo}
                              nonprofitCover={nonprofitCover}
                              selectedCampaign={selectedCampaign}
                              selectedMetric={selectedMetric}
                              useMetricCover={useMetricCover}
                            />
                          )}
                        </>
                      ) : (
                        <div className="section-loader-data">
                          <div className="section-loader-container">
                            <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="DonationSuccess">
              <ScrollAnimation animateIn="fadeInDown" animateOnce offset={0} delay={400}>
                <div className="claimsuccess notop">
                  <div className="alert-base">
                    <div className="alert-base-text">
                      {`Thank you for your ${selectedMetric ? 'support' : 'donation'}!`}
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
              <div className="donation-track-button">
                {authUser ? (
                  <div className="track-link">
                    <Link to={authUser.profile.agentStatus ? routes.PROFILE_AGENT : routes.PROFILE_DONOR}>
                      Track Your Donation
                    </Link>
                  </div>
                ) : (
                  <div className="track-link">
                    <Link to={routes.PROFILE_DONOR}>Track Your Donation</Link>
                  </div>
                )}
              </div>
              <div className="w-row donationRow high">
                <div className="w-col w-col-7 w-col-stack">
                  <div className="nonprofit-small-detail">
                    {selectedMetric ? (
                      <label className="donation-program-label metric">
                        <strong>
                          {`${firstName.length > 0 ? `${firstName}, ` : 'Well, '}you just provided `}
                          <span className="f-lowercase">{metricImpact}</span>
                          {'!'}
                        </strong>
                      </label>
                    ) : null}
                    {supportOnCampaign ? (
                      <CampaignProfile
                        nonprofit={nonprofit}
                        nonprofitLogo={nonprofitLogo}
                        campaign={campaignInfo}
                        noBody
                      />
                    ) : (
                      <NonprofitProfile
                        nonprofit={nonprofit}
                        nonprofitLogo={nonprofitLogo}
                        nonprofitCover={nonprofitCover}
                        selectedCampaign={selectedCampaign}
                        selectedMetric={selectedMetric}
                        useMetricCover={useMetricCover}
                        noBody
                      />
                    )}
                    <div className="nonprofit-body no-padding">
                      {!selectedMetric ? (
                        <div className="info">
                          <label className="donation-program-label">
                            {selectedProgram ? selectedProgram.name : ''}
                          </label>
                          {selectedCampaign ? (
                            <label className="donation-program-label">
                              <strong>{selectedCampaign.name}</strong>
                            </label>
                          ) : null}
                        </div>
                      ) : null}
                      <div className="button">
                        <div className="button-item">
                          <button
                            type="button"
                            className="w-button f-btn-info social-btn"
                            onClick={this.handleFBShareButton}
                          >
                            <FbLogoComponent className="svg-fill-logo" />
                            Share to Facebook
                          </button>
                        </div>
                        <div className="button-item">
                          <button type="button" className="w-button social-btn" onClick={this.handleTwitterButton}>
                            <TwitterLogoComponent className="svg-fill-logo" />
                            Share to Twitter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-col w-col-5 w-col-stack">
                  <div className="donation-card">
                    <label className="donation-donated-label">
                      {`$ ${
                        coverUs === '1'
                          ? numberHelper.getThousandFormat(Number.parseFloat(totalSharing))
                          : numberHelper.getThousandFormat(Number.parseFloat(totalDonationText))
                      } donated`}
                    </label>
                    <div className="donation-card-bg">
                      <div className="donation-donate-box">
                        <span className="donation-donorname-label">
                          {`${firstName.length > 0 ? `${firstName} ${lastName}` : '-'}`}
                        </span>
                        <span className="donation-small-ss-label f-text-right">
                          {dateTimeHelper.getFormatedDate(Date.now(), 'MM/DD/YYYY')}
                        </span>
                      </div>
                      <span className="donation-small-s-label">{email}</span>
                      <div className="donation-donate-box nonprofit-section">
                        <span className="donation-donate-title-label">To:</span>
                        <span className="donation-donate-title-label f-text-right">Receipt ID:</span>
                      </div>
                      <div className="donation-donate-box">
                        <span className="donation-donate-description-label truncate-text two-line">
                          {nonprofit.name}
                        </span>
                        <span className="donation-donate-description-label f-text-right">{receiptId}</span>
                      </div>
                      <div className="donation-donate-box program-section">
                        <span className="donation-donate-title-label">Program name:</span>
                        {selectedCampaign ? (
                          <span className="donation-donate-title-label f-text-right">Campaign name:</span>
                        ) : null}
                      </div>
                      <div className="donation-donate-box">
                        <span className="donation-donate-description-label truncate-text three-line">
                          {selectedProgram ? selectedProgram.name : ''}
                        </span>
                        {selectedCampaign ? (
                          <span className="donation-donate-description-label f-text-right truncate-text three-line">
                            {selectedCampaign.name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NonprofitStripeDonation.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
  stripe: PropTypes.instanceOf(Object),
};

NonprofitStripeDonation.defaultProps = {
  authUser: null,
  stripe: null,
};

const HowMuchSection = ({
  nonprofit,
  selectedMetric,
  totalDonation,
  totalDonationText,
  coverUs,
  totalSharing,
  quantity,
  handleAmountChange,
  handleDonationButton,
  handleRadioChange,
  handleDonationMetricButton,
}) => {
  let metricButtonView = null;
  let frequencyText = null;

  if (selectedMetric) {
    const { frequency } = selectedMetric;
    const multiplyOne = 1 * selectedMetric.costNumber;
    const multiplyThree = 3 * selectedMetric.costNumber;
    const multiplyFive = 5 * selectedMetric.costNumber;
    const multiplyTen = 10 * selectedMetric.costNumber;

    if (frequency && Number.parseInt(frequency.number, 10) > 0 && frequency.name !== '') {
      frequencyText = ` / ${Number.parseInt(frequency.number, 10)} ${stringHelper.makePluralize(
        frequency.name,
        Number.parseInt(frequency.number, 10)
      )}`;
    }

    metricButtonView = (
      <>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 1 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(1)}
          >
            <span>{multiplyOne}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyOne)}</span>
          </button>
        </div>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 3 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(3)}
          >
            <span>{multiplyThree}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyThree)}</span>
          </button>
        </div>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 5 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(5)}
          >
            <span>{multiplyFive}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyFive)}</span>
          </button>
        </div>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 10 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(10)}
          >
            <span>{multiplyTen}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyTen)}</span>
          </button>
        </div>
      </>
    );
  }

  const hideCoverUs = nonprofit.subscribe && nonprofit.subscribe.hideCoverUs;

  return (
    <>
      <label className="donate-question">How much do you want to donate?</label>
      <div className="donation-input">
        <div className="field-control">
          <CurrencyInput
            name="totalDonationText"
            value={totalDonationText}
            inputMode="numeric"
            placeholder="input amount here"
            onChange={handleAmountChange}
          />
        </div>
        <div className={totalDonationText === '' ? 'field-caption' : 'field-caption filled'}>$</div>
      </div>
      <div className="donation-money">
        <div className="float-left-section">
          {!selectedMetric ? (
            <>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('5.00')}>
                  $5
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('10.00')}>
                  $10
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('20.00')}>
                  $20
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('30.00')}>
                  $30
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('50.00')}>
                  $50
                </button>
              </div>
            </>
          ) : (
            metricButtonView
          )}
        </div>
      </div>
      {selectedMetric ? (
        <div className="f-lowercase">
          <strong>
            {`($${numberHelper.getThousandFormat(
              Number.parseFloat(selectedMetric.cost).toFixed(2)
            )} supports ${stringHelper.makePluralize(
              selectedMetric.unitName,
              Number.parseFloat(selectedMetric.costNumber),
              true
            )}`}
            {Number.parseFloat(selectedMetric.humanNumber) > 0 ? (
              <>
                {` for ${stringHelper.makePluralize(
                  selectedMetric.humanUnit,
                  Number.parseFloat(selectedMetric.humanNumber),
                  true
                )}`}
              </>
            ) : null}
            {frequencyText}
            {')'}
          </strong>
        </div>
      ) : null}
      <div className="donation-gray-box">
        <label className="helpUs-label">Help us cover!</label>
        {hideCoverUs ? (
          <label className="helpUs-label-caption">
            A small processing fee will be included in your donation to ensure that 100% of your intended donation is
            available for our mission
          </label>
        ) : (
          <>
            <label className="helpUs-label-caption">
              Simply leave “Yes!” checked below to ensure that 100% of your intended donation is available for our
              mission
            </label>
            <div>
              <FancyRadio
                id="coverUs-1"
                name="coverUs"
                value="1"
                checked={coverUs === '1'}
                onChange={handleRadioChange}
              >
                <span>
                  {`Yes! I want $${totalDonation.toFixed(0)} to go to ${
                    nonprofit.name
                  } and I will donate $${totalSharing}`}
                </span>
              </FancyRadio>
              <FancyRadio
                id="coverUs-2"
                name="coverUs"
                value="2"
                checked={coverUs === '2'}
                onChange={handleRadioChange}
              >
                <span>
                  {`No. I will donate $${totalDonation.toFixed(0)} and ${
                    nonprofit.name
                  } will cover the processing costs`}
                </span>
              </FancyRadio>
            </div>
          </>
        )}
      </div>
    </>
  );
};

HowMuchSection.propTypes = {
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  selectedMetric: PropTypes.instanceOf(Object),
  totalDonation: PropTypes.number.isRequired,
  totalDonationText: PropTypes.string.isRequired,
  coverUs: PropTypes.string.isRequired,
  totalSharing: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  handleAmountChange: PropTypes.func.isRequired,
  handleDonationButton: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleDonationMetricButton: PropTypes.func.isRequired,
};

HowMuchSection.defaultProps = { selectedMetric: null, quantity: 0 };

const HowDonationProgramSection = ({ programs, selectedProgram, handleProgramSelected }) => (
  <>
    <label className="donate-question">How would you like your donation to be used?</label>
    <label className="donation-small-label" style={{ marginTop: 32, marginBottom: 24 }}>
      Choose a specific program
    </label>
    <div className="donation-program">
      <div className="float-left-section">
        {programs.map(item => {
          let clsName = 'w-button f-btn-primary';
          if (selectedProgram && selectedProgram.id === item.id) {
            clsName += ' selected';
          }

          return (
            <div className="float-item" key={item.id}>
              <button type="button" className={clsName} onClick={() => handleProgramSelected(item)}>
                {item.name}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  </>
);

HowDonationProgramSection.propTypes = {
  programs: PropTypes.instanceOf(Array).isRequired,
  selectedProgram: PropTypes.instanceOf(Object),
  handleProgramSelected: PropTypes.func.isRequired,
};

HowDonationProgramSection.defaultProps = { selectedProgram: null };

const HowDonationMetricSection = ({ metrics, selectedMetric, handleMetricSelected }) => (
  <>
    <label className="donate-question">How would you like your donation to be used?</label>
    <label className="donation-small-label" style={{ marginTop: 32, marginBottom: 24 }}>
      Choose a specific metric
    </label>
    <div className="donation-program">
      <div className="float-left-section">
        {metrics.map(item => {
          let clsName = 'w-button f-btn-primary';
          if (selectedMetric && selectedMetric.id === item.id) {
            clsName += ' selected';
          }

          return (
            <div className="float-item" key={item.id}>
              <button type="button" className={clsName} onClick={() => handleMetricSelected(item)}>
                {item.name}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  </>
);

HowDonationMetricSection.propTypes = {
  metrics: PropTypes.instanceOf(Array).isRequired,
  selectedMetric: PropTypes.instanceOf(Object),
  handleMetricSelected: PropTypes.func.isRequired,
};

HowDonationMetricSection.defaultProps = { selectedMetric: null };

const HowDonationCampaignSection = ({ campaigns, selectedCampaign, handleCampaignSelected }) => (
  <>
    <label className="donate-question">How would you like your donation to be used?</label>
    <label className="donation-small-label" style={{ marginTop: 32, marginBottom: 24 }}>
      Choose a specific campaign
    </label>
    <div className="donation-program">
      <div className="float-left-section">
        {campaigns.map(item => {
          let clsName = 'w-button f-btn-primary';
          if (selectedCampaign && selectedCampaign.id.toString() === item.id.toString()) {
            clsName += ' selected';
          }

          return (
            <div className="float-item" key={item.id}>
              <button type="button" className={clsName} onClick={() => handleCampaignSelected(item)}>
                {item.name}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  </>
);

HowDonationCampaignSection.propTypes = {
  campaigns: PropTypes.instanceOf(Array).isRequired,
  selectedCampaign: PropTypes.instanceOf(Object),
  handleCampaignSelected: PropTypes.func.isRequired,
};

HowDonationCampaignSection.defaultProps = { selectedCampaign: null };

const DonateProcessSection = ({
  nonprofit,
  authUser,
  donationCategory,
  donationType,
  firstName,
  lastName,
  email,
  phone,
  anonymous,
  comment,
  recurring,
  dedication,
  dedicationName,
  showStripeLoading,
  handleRadioChange,
  handleChange,
  handleCardElementChange,
  handleClearSelection,
}) => {
  const applyStyle = { marginTop: 32 };
  if (showStripeLoading) {
    applyStyle.display = 'none';
  }

  return (
    <div style={applyStyle}>
      {authUser && authUser.user.uid === nonprofit.agent ? (
        <>
          <label className="formfieldlabel">Transaction Category</label>
          <div className="f-radio-group" style={{ marginBottom: 15 }}>
            <FancyRadio
              id="donationCategory-1"
              name="donationCategory"
              value="Fundraising"
              checked={donationCategory === 'Fundraising'}
              onChange={handleRadioChange}
            >
              <span>Fundraising</span>
            </FancyRadio>
            <FancyRadio
              id="donationCategory-2"
              name="donationCategory"
              value="Cash Deposit"
              checked={donationCategory === 'Cash Deposit'}
              onChange={handleRadioChange}
            >
              <span>Cash Deposit</span>
            </FancyRadio>
          </div>
          <label className="formfieldlabel">Transaction Type</label>
          <div className="f-radio-group" style={{ marginBottom: 15 }}>
            <FancyRadio
              id="donationType-1"
              name="donationType"
              value="Donation"
              checked={donationType === 'Donation'}
              onChange={handleRadioChange}
            >
              <span>Donation</span>
            </FancyRadio>
            <FancyRadio
              id="donationType-2"
              name="donationType"
              value="Service"
              checked={donationType === 'Service'}
              onChange={handleRadioChange}
            >
              <span>Service</span>
            </FancyRadio>
            <FancyRadio
              id="donationType-3"
              name="donationType"
              value="Pledge"
              checked={donationType === 'Pledge'}
              onChange={handleRadioChange}
            >
              <span>Pledge</span>
            </FancyRadio>
          </div>
        </>
      ) : null}
      <div className="w-row">
        <div className="w-col w-col-6 w-col-stack">
          <label htmlFor="firstName" className="formfieldlabel">
            First Name
          </label>
          <input
            type="text"
            className="formtextfield small w-input input90"
            maxLength="256"
            name="firstName"
            placeholder="Enter your first name"
            value={firstName}
            onChange={handleChange}
          />
        </div>
        <div className="w-col w-col-6 w-col-stack">
          <label htmlFor="lastName" className="formfieldlabel">
            Last Name
          </label>
          <input
            type="text"
            className="formtextfield small w-input"
            maxLength="256"
            name="lastName"
            placeholder="Enter your last name"
            value={lastName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="w-row">
        <div className="w-col w-col-6 w-col-stack">
          <label htmlFor="email" className="formfieldlabel">
            Email address
          </label>
          <input
            type="email"
            className="formtextfield small w-input input90"
            maxLength="256"
            name="email"
            placeholder="Enter your email address"
            value={email}
            onChange={handleChange}
          />
        </div>
        <div className="w-col w-col-6 w-col-stack">
          <label htmlFor="phone" className="formfieldlabel">
            Phone number
          </label>
          <input
            type="tel"
            className="formtextfield small w-input"
            maxLength="256"
            name="phone"
            placeholder="Enter your phone number"
            value={phone}
            onChange={handleChange}
          />
        </div>
      </div>
      <label className="donate-question">Select payment method</label>
      <label className="formfieldlabel">Add Credit Card</label>
      <CardElement style={{ base: { fontSize: '18px' } }} onChange={handleCardElementChange} />
      <div className="styled-checkbox-container">
        <input
          type="checkbox"
          className="styled-checkbox"
          name="anonymous"
          id="anonymous"
          checked={anonymous}
          onChange={handleChange}
        />
        <label htmlFor="anonymous">Set me anonymous</label>
      </div>
      <hr />
      <label htmlFor="comment" className="formfieldlabel">
        Add comments
      </label>
      <textarea
        className="formtextfield w-input"
        rows={5}
        name="comment"
        placeholder="Add your comment"
        value={comment}
        onChange={handleChange}
      />
      <label className="formfieldlabel">Make this a recurring donation and your support impacts more lives!</label>
      <div className="f-radio-group no-bottom">
        <FancyRadio
          id="recurring-1"
          name="recurring"
          value="1"
          checked={recurring === '1'}
          onChange={handleRadioChange}
        >
          <span>Weekly</span>
        </FancyRadio>
        <FancyRadio
          id="recurring-2"
          name="recurring"
          value="2"
          checked={recurring === '2'}
          onChange={handleRadioChange}
        >
          <span>Bi-Weekly</span>
        </FancyRadio>
        <FancyRadio
          id="recurring-3"
          name="recurring"
          value="3"
          checked={recurring === '3'}
          onChange={handleRadioChange}
        >
          <span>Monthly</span>
        </FancyRadio>
      </div>
      <div className="clear-selection">
        <a href="#clear-recurring" onClick={e => handleClearSelection(e, 1)}>
          Clear Selection
        </a>
      </div>
      <label className="formfieldlabel">Dedications</label>
      <div className="f-radio-group no-bottom">
        <FancyRadio
          id="dedication-1"
          name="dedication"
          value='{"id": 1, "title": "Dedicated to"}'
          checked={dedication === '{"id": 1, "title": "Dedicated to"}'}
          onChange={handleRadioChange}
        >
          <span>Dedicated to</span>
        </FancyRadio>
        <FancyRadio
          id="dedication-2"
          name="dedication"
          value='{"id": 2, "title": "In memory of"}'
          checked={dedication === '{"id": 2, "title": "In memory of"}'}
          onChange={handleRadioChange}
        >
          <span>In memory of</span>
        </FancyRadio>
        <FancyRadio
          id="dedication-3"
          name="dedication"
          value='{"id": 3, "title": "On behalf of"}'
          checked={dedication === '{"id": 3, "title": "On behalf of"}'}
          onChange={handleRadioChange}
        >
          <span>On behalf of</span>
        </FancyRadio>
      </div>
      <div className="clear-selection" style={{ marginBottom: 15 }}>
        <a href="#clear-recurring" onClick={e => handleClearSelection(e, 2)}>
          Clear Selection
        </a>
      </div>
      <input
        type="text"
        className="formtextfield small w-input"
        maxLength="256"
        name="dedicationName"
        placeholder="Add Dedication Name"
        value={dedicationName}
        onChange={handleChange}
      />
    </div>
  );
};

DonateProcessSection.propTypes = {
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
  donationCategory: PropTypes.string.isRequired,
  donationType: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  anonymous: PropTypes.bool.isRequired,
  comment: PropTypes.string.isRequired,
  recurring: PropTypes.string.isRequired,
  dedication: PropTypes.string.isRequired,
  dedicationName: PropTypes.string.isRequired,
  showStripeLoading: PropTypes.bool.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCardElementChange: PropTypes.func.isRequired,
  handleClearSelection: PropTypes.func.isRequired,
};

DonateProcessSection.defaultProps = { authUser: null };

function NonprofitProfile({
  nonprofit,
  nonprofitLogo,
  nonprofitCover,
  selectedCampaign,
  selectedMetric,
  noBody,
  useMetricCover,
}) {
  return (
    <>
      <div className="nonprofit-header">
        <div className="nonprofit-name">
          <span className="title">{nonprofit.name}</span>
          <span className="street">{`${nonprofit.city}, ${nonprofit.state}`}</span>
        </div>
        <div className="nonprofit-logo">
          <a href={nonprofit.viewUrl}>
            <img src={nonprofitLogo} alt={`${nonprofit.name} Logo`} />
          </a>
        </div>
      </div>
      <div className={`nonprofit-cover${noBody ? ' more-height' : ''}`}>
        <a href={nonprofit.viewUrl}>
          <div
            className="campaign-cover"
            style={{ backgroundImage: `url("${useMetricCover ? selectedMetric.cover : nonprofitCover}")` }}
          />
        </a>
      </div>
      {!noBody ? (
        <div className="nonprofit-body">
          <div className="social">
            <div className="item about-the-organizer">About the organizer</div>
            <div className="item right learn-more">
              <Link to={nonprofit.viewUrl}>Learn more</Link>
            </div>
          </div>
          {selectedCampaign && nonprofit.agentProfile ? (
            <div className="campaignuser">
              <div className="item profile">
                <img src={nonprofit.agentProfile.avatar} alt="" title={nonprofit.agentProfile.userFullName} />
                {` ${nonprofit.agentProfile.userFullName}`}
              </div>
            </div>
          ) : null}
          <div className="description no-raised">{stringHelper.nl2br(nonprofit.description)}</div>
        </div>
      ) : null}
    </>
  );
}

NonprofitProfile.propTypes = {
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  nonprofitLogo: PropTypes.string.isRequired,
  nonprofitCover: PropTypes.string.isRequired,
  selectedCampaign: PropTypes.instanceOf(Object),
  selectedMetric: PropTypes.instanceOf(Object),
  noBody: PropTypes.bool,
  useMetricCover: PropTypes.bool,
};

NonprofitProfile.defaultProps = {
  selectedCampaign: null,
  selectedMetric: null,
  noBody: false,
  useMetricCover: false,
};

function CampaignProfile({ campaign, nonprofit, nonprofitLogo, noBody }) {
  return (
    <>
      <div className="nonprofit-header">
        <div className="nonprofit-name">
          <span className="title">{campaign.name}</span>
          <span className="street">{campaign.location}</span>
        </div>
        <div className="nonprofit-logo">
          <a href={nonprofit.viewUrl}>
            <img src={nonprofitLogo} alt={`${nonprofit.name} Logo`} />
          </a>
        </div>
      </div>
      <div className={`nonprofit-cover${noBody ? ' more-height' : ''}`}>
        <a href={campaign.viewUrl}>
          <div className="campaign-cover" style={{ backgroundImage: `url("${campaign.cover}")` }} />
        </a>
      </div>
      {!noBody ? (
        <div className="nonprofit-body">
          <div className="totalmoney">
            <div className="item">
              <span className="money-text">{`$ ${campaign.raisedText}`}</span>
              <span className="money-label">Raised</span>
            </div>
            {campaign.goal > 0 ? (
              <div className="item">
                <span className="money-text goal">{`$ ${campaign.goalText}`}</span>
                <span className="money-label">Goal</span>
              </div>
            ) : (
              <>
                {campaign.metric ? (
                  <div className="item">
                    <span className="money-text goal">
                      {numberHelper.getThousandFormat(campaign.metric.raisedPack.toFixed(0))}
                    </span>
                    <span className="money-label f-text-capitalize">
                      {stringHelper.makePluralize(campaign.metric.unitName, campaign.metric.raisedPack)}
                    </span>
                  </div>
                ) : null}
              </>
            )}
          </div>
          {campaign.goal > 0 ? (
            <div className="progressbar">
              <Progress
                percent={campaign.percentForBar}
                theme={{ success: { symbol: ' ', color: '#63D8B5' } }}
                status="success"
              />
            </div>
          ) : null}
          {campaign.metric && campaign.goal > 0 ? (
            <div className="metric-raised">
              <span className="f-text-bold">
                {`${numberHelper.getThousandFormat(campaign.metric.raisedPack.toFixed(0))} ${stringHelper.makePluralize(
                  campaign.metric.unitName,
                  campaign.metric.raisedPack
                )} `}
              </span>
              <span>
                {`out of ${numberHelper.getThousandFormat(
                  campaign.metric.goalPack.toFixed(0)
                )} ${stringHelper.makePluralize(campaign.metric.unitName, campaign.metric.goalPack)} `}
              </span>
            </div>
          ) : null}
          <div className="campaign-description f-paragraph">{stringHelper.nl2br(campaign.story)}</div>
        </div>
      ) : null}
    </>
  );
}

CampaignProfile.propTypes = {
  campaign: PropTypes.instanceOf(Object).isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  nonprofitLogo: PropTypes.string.isRequired,
  noBody: PropTypes.bool,
};

CampaignProfile.defaultProps = { noBody: false };

export default withStripeContext(NonprofitStripeDonation);
