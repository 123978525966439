import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';

import { auth, database } from '../firebase';
import { NonprofitProvider } from '../Providers';
import * as routes from '../constants/routes';

import { ReactComponent as FbLogoComponent } from '../Assets/Images/Facebook-Icon.svg';
import GoogleLogo from '../Assets/Images/Google.svg';

import '../Assets/Styles/SocialMediaButton.css';

function SocialMediaButton({ caption, claimed, claimedKey, history, redirectUri, registerNonprofit }) {
  const storeNewProfile = async user => {
    try {
      const { uid } = user;
      const fullName = user.displayName.split(' ');
      const stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
      const stripeData = {
        email: user.email,
        description: `Customer for ${user.email}`,
        metadata: {
          'first name': fullName[0],
          'last name': fullName[1] ? fullName[1] : '',
          uid,
          phone: '',
          currency: 'USD',
        },
      };

      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${stripeKey}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const stripeRes = await axios.post('https://api.stripe.com/v1/customers', qs.stringify(stripeData), axiosConfig);

      const userProfile = {
        uid,
        scid: stripeRes.data.id,
        firstName: fullName[0],
        lastName: fullName[1] ? fullName[1] : '',
        email: user.email,
        phone: '',
        emailVerified: true,
        social: true,
      };

      await database.setRef(`/profile/${uid}`, userProfile);
      await auth.updateProfile({ emailVerified: true });

      return userProfile;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const afterSignIn = async user => {
    let profileInfo = null;
    const { uid } = user;
    const userInfo = {
      uid: user.uid,
      name: user.displayName,
      email: user.email,
      photo: user.photoURL,
    };

    const snaps = await database.getRef(`/profile/${uid}`).once('value');
    if (snaps.val() === null) {
      profileInfo = await storeNewProfile(user);
    } else if (!snaps.val().emailVerified) {
      const dbPath = process.env.REACT_APP_FIREBASE_DB_PATH;
      const updates = {};
      updates[`${dbPath}/profile/${uid}/emailVerified`] = true;
      updates[`${dbPath}/profile/${uid}/social`] = true;

      await database.updateRef(updates);
      await auth.updateProfile({ emailVerified: true });
    }

    if (!profileInfo) {
      profileInfo = {
        avatar: snaps.val().avatar,
        email: snaps.val().email,
        firstName: snaps.val().firstName,
        lastName: snaps.val().lastName,
        emailVerified: true,
        scid: snaps.val().scid,
        uid: snaps.val().uid ? snaps.val().uid : uid,
        token: snaps.val().token,
        social: true,
      };
    }

    const agentChecking = await NonprofitProvider.userIsAgent(userInfo.uid);
    profileInfo.agentStatus = agentChecking.isAgent ? agentChecking : null;

    localStorage.setItem('user-info', JSON.stringify({ user: userInfo, profile: profileInfo }));

    if (claimed) {
      history.push(`${routes.NONPROFITS_REGISTER}?callback=claim&id=${claimedKey}&ref=${userInfo.uid}`);
    } else if (registerNonprofit) {
      history.push(`${routes.NONPROFITS_REGISTER}?callback=new`);
    } else if (agentChecking.isAgent) {
      const url = routes.NONPROFITS_ABOUT.replace(':id', agentChecking.nid).replace(':name', agentChecking.slugName);
      history.push(url);
    } else if (redirectUri) {
      history.push(redirectUri);
    } else {
      history.push(routes.CAMPAIGNS);
    }
  };

  const handleGoogleProvider = () => {
    auth
      .signInWithGoogle()
      .then(res => {
        afterSignIn(res.user);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleFBProvider = () => {
    auth
      .signInWithFB()
      .then(res => {
        afterSignIn(res.user);
      })
      .catch(errFB => {
        if (errFB.code === 'auth/account-exists-with-different-credential') {
          auth.fetchProvidersForEmail(errFB.email).then(provider => {
            if (provider[0] === 'google.com') {
              auth
                .signInWithGoogleHidden(errFB.email)
                .then(res => {
                  res.user.linkWithCredential(errFB.credential).then(() => {
                    afterSignIn(res.user);
                  });
                })
                .catch(err => {
                  console.log(err);
                });
            }
          });
        }
      });
  };

  const hashCaption = caption.replace(' ', '-').toLowerCase();
  const fbCaption = `${caption} with Facebook`;
  const googleCaption = `${caption} with Google`;

  return (
    <div className="SocialButton">
      <a href={`#${hashCaption}-facebook`} className="buttonfb" onClick={handleFBProvider}>
        <FbLogoComponent className="svg-fill-logo" />
        {fbCaption}
      </a>
      <a href={`#${hashCaption}-google`} className="buttongoogle" onClick={handleGoogleProvider}>
        <img className="social-logo" src={GoogleLogo} alt="" />
        {googleCaption}
      </a>
    </div>
  );
}

SocialMediaButton.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  caption: PropTypes.string.isRequired,
  claimed: PropTypes.bool,
  claimedKey: PropTypes.string,
  redirectUri: PropTypes.string,
  registerNonprofit: PropTypes.bool,
};

SocialMediaButton.defaultProps = {
  claimed: false,
  claimedKey: null,
  redirectUri: null,
  registerNonprofit: false,
};

export default withRouter(SocialMediaButton);
