import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

const Waiting = (props) => {
  let className = 'contentwrapper ';
  const { clsStyle } = props;

  if (clsStyle !== '') {
    className += clsStyle;
  } else {
    className += 'section-loader';
  }

  return (
    <div className="sectionhero">
      <div className={className}>
        <div className="section-loader-container"><Loader color="#EB7557" type="Circles" height={80} width={80} /></div>
      </div>
    </div>
  );
};

Waiting.propTypes = {
  clsStyle: PropTypes.string,
};

Waiting.defaultProps = {
  clsStyle: '',
};

export default Waiting;
