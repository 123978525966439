import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import algoliasearch from 'algoliasearch';
import swal from 'sweetalert';
import queryString from 'query-string';

import { database } from '../firebase';
import { stringHelper, dateTimeHelper } from '../helpers';
import { BackButton } from '../Components/HtmlTag';

import NonprofitRegisterComponent from './Register';

import withUserContext from '../withUserContext';
import * as routes from '../constants/routes';

import imgLogo from '../Assets/Images/Media_Icon.svg';

import 'animate.css/animate.min.css';
import '../Assets/Styles/Nonprofits-Search.css';

class Search extends Component {
  static storeClaimToFirebase(key, uid) {
    const dbPath = process.env.REACT_APP_FIREBASE_DB_PATH;
    const timeCreated = dateTimeHelper.getCurrentTimeMs();
    const claimedData = { key, uid, time: timeCreated };

    const updates = {};
    updates[`${dbPath}/nonprofit_claimed/${key}/${uid}`] = claimedData;
    updates[`${dbPath}/profile/${uid}/nonprofit/claimed/${key}`] = { key, time: timeCreated };

    return database.updateRef(updates);
  }

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      buttonText: 'Search',
      disabledButton: false,
      showBack: false,
      items: [],
      step: 0,
      claimed: false,
      claimedKey: '',
      claimedMessage: '',
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchCharity = this.searchCharity.bind(this);
    this.claimCharity = this.claimCharity.bind(this);
    this.createNewCharity = this.createNewCharity.bind(this);
  }

  componentDidMount() {
    const {
      updateNavStatus,
      showFooterLinkContainer,
      location,
      history,
    } = this.props;
    updateNavStatus('logosupport');
    showFooterLinkContainer(false);

    const urlQuery = queryString.parse(location.search);
    if (urlQuery.callback) {
      if (urlQuery.callback === 'claim') {
        const claimedKey = urlQuery.id;
        const uid = urlQuery.ref;
        database
          .getRef(`/nonprofit_claimed/${claimedKey}/${uid}`)
          .once('value')
          .then((snaps) => {
            if (snaps.val() === null) {
              this.storeClaimToFirebase(claimedKey, uid).then(() => {
                history.push(`${routes.NONPROFITS_REGISTER}?lastaction=claim&result=success`);
              });
            } else {
              database
                .getRef(`/nonprofit/${claimedKey}`)
                .once('value')
                .then((childSnaps) => {
                  const charityName = childSnaps.val().name;
                  this.storeClaimToFirebase(claimedKey, uid).then(() => {
                    history.push(`${routes.NONPROFITS_REGISTER}?lastaction=claim&result=success&ref=${charityName}`);
                  });
                });
            }
          });
      } else if (urlQuery.callback === 'new') {
        this.setState({ step: 5 });
      }
    } else if (urlQuery.lastaction) {
      let claimedMessage = 'Thank you for claiming your charity or nonprofit organization.\nYou will be contacted by Fundra to validate your application!';
      if (urlQuery.ref) {
        claimedMessage = `Thank you for claiming the following nonprofit profile, [${
          urlQuery.ref
        }]! We are currently reviewing your claim form and will contact you shortly to validate your claim.`;
      }

      this.setState({ step: 4, claimedMessage });
    } else {
      this.setState({ step: 1 });
    }
  }

  handleChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  handleBackClick(e) {
    // document.location.href = "#root";
    e.preventDefault();

    this.setState({ step: 1, showBack: false });
  }

  searchCharity(event) {
    event.preventDefault();

    this.setState({ buttonText: 'Please wait...', disabledButton: true });
    this.findCharity();
  }

  claimCharity(key) {
    swal('Confirmation', 'Are you sure you want to claim this nonprofit?', {
      buttons: ['Cancel', 'Confirm'],
    }).then((value) => {
      if (value) {
        this.setState({ claimed: true, claimedKey: key });
        const { authUser } = this.props;
        if (authUser) {
          const { uid } = authUser.user;
          database
            .getRef(`/nonprofit_claimed/${key}/${uid}`)
            .once('value')
            .then((snaps) => {
              if (snaps.val() === null) {
                this.storeClaimToFirebase(key, uid).then(() => {
                  document.location.href = '#root';
                  this.setState({
                    step: 4,
                    claimedMessage:
                      'Thank you for claiming your charity or nonprofit organization.\nYou will be contacted by Fundra to validate your application!',
                  });
                });
              } else {
                database
                  .getRef(`/nonprofit/${key}`)
                  .once('value')
                  .then((childSnaps) => {
                    const charityName = childSnaps.val().name;
                    this.storeClaimToFirebase(key, uid).then(() => {
                      document.location.href = '#root';
                      this.setState({
                        step: 4,
                        claimedMessage: `Thank you for claiming the following nonprofit profile, [${charityName}]! We are currently reviewing your claim form and will contact you shortly to validate your claim.`,
                      });
                    });
                  });
              }
            });
        } else {
          this.confirmUser(`?claim=${key}`);
        }
      }
    });
  }

  createNewCharity(event) {
    event.preventDefault();

    const { authUser } = this.props;

    if (authUser) {
      document.location.href = '#root';
      this.setState({ step: 5 });
    } else {
      this.confirmUser('?register=nonprofit');
    }
  }

  confirmUser(redirect) {
    const title = redirect === '?register=nonprofit' ? 'register your nonprofit organization' : 'claim this nonprofit';
    swal(
      'Confirmation',
      `Please login first or create a new account to ${title}.`,
      {
        buttons: {
          create: {
            text: 'Already have an account? Sign in',
            value: 'create',
          },
          catch: {
            text: 'Create an account',
            value: 'catch',
          },
        },
        className: 'swal-500',
      },
    ).then((value) => {
      if (value) {
        const { history } = this.props;
        switch (value) {
          case 'catch':
            this.setState({ step: 5 });
            document.location.href = '#top';
            break;

          default:
            history.push(routes.SIGN_IN + redirect);
        }
      }
    });
  }

  findCharity() {
    const { searchQuery } = this.state;
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      process.env.REACT_APP_ALGOLIA_API_KEY,
    );
    const nonprofitsIndex = client.initIndex('nonprofit');

    nonprofitsIndex.search(
      {
        query: searchQuery,
        attributesToRetrieve: ['name', 'federal', 'img', 'key', 'agent'],
      },
      (err, content) => {
        if (err) throw err;

        const items = [];
        content.hits.forEach((item) => {
          const slugName = stringHelper.slugify(item.name);
          items.push({
            key: parseInt(item.key, 10),
            logo: item.img.logo,
            name: item.name,
            federal: item.federal,
            agent: item.agent,
            viewUrl: routes.NONPROFITS_ABOUT.replace(':id', item.key).replace(
              ':name',
              slugName,
            ),
          });
        });

        const step = items.length > 0 ? 2 : 3;
        this.setState({
          items,
          step,
          showBack: true,
          searchQuery: '',
          buttonText: 'Search',
          disabledButton: false,
        });
      },
    );
  }

  /* jshint ignore:start */
  render() {
    document.title = 'Register Your Charity - Fundra';
    const {
      step,
      searchQuery,
      buttonText,
      disabledButton,
      items,
      claimedMessage,
      showBack,
      claimed,
      claimedKey,
    } = this.state;
    let ViewRender;

    if (step < 5) {
      let ChildView;
      if (step === 1) {
        ChildView = (
          <SearchForm
            handleSubmit={this.searchCharity}
            handleChange={this.handleChange}
            searchQuery={searchQuery}
            buttonText={buttonText}
            disabledButton={disabledButton}
          />
        );
      } else if (step === 2) {
        ChildView = (
          <CharityListForm
            claimCharity={this.claimCharity}
            createNewCharity={this.createNewCharity}
            items={items}
          />
        );
      } else if (step === 3) {
        ChildView = <NoResultForm createNewCharity={this.createNewCharity} />;
      } else if (step === 4) {
        ChildView = <ClaimSuccess message={claimedMessage} />;
      }

      ViewRender = (
        <div className="SearchCharity">
          <div className="contentwrapper">
            <div className="backlink">
              <BackButton
                showBackButton={showBack}
                handleClick={this.handleBackClick}
              />
            </div>
            <h3 className="h3 featuretitle registercharitytitle">
              Register Your Charity!
            </h3>
            <div className="featuretitledescnonprofit">
              Help us find your Charity profile in our database
            </div>
            {ChildView}
          </div>
        </div>
      );
    } else {
      ViewRender = (
        <NonprofitRegisterComponent
          claimed={claimed}
          claimedKey={claimedKey}
        />
      );
    }

    return ViewRender;
  }
  /* jshint ignore:end */
}

Search.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

Search.defaultProps = { authUser: null };

const SearchForm = (props) => {
  const {
    handleSubmit,
    searchQuery,
    handleChange,
    buttonText,
    disabledButton,
  } = props;

  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce delay={400}>
      <div className="formlogin w-form register2">
        <form
          id="register-form"
          name="register-form"
          action="submitform"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <label htmlFor="searchQuery" className="formfieldlabel">
            Enter your charity name below
          </label>
          <input
            type="text"
            className="formtextfield small w-input"
            maxLength="256"
            name="searchQuery"
            placeholder="Enter your charity name"
            value={searchQuery}
            onChange={handleChange}
            required
          />
          <span className="block-info">Example. American Relief Agency</span>
          <input
            type="submit"
            value={buttonText}
            disabled={disabledButton}
            className="formsubmit w-button"
          />
        </form>
      </div>
    </ScrollAnimation>
  );
};

SearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  disabledButton: PropTypes.bool,
};

SearchForm.defaultProps = {
  searchQuery: '',
  disabledButton: true,
};

const NoResultForm = (props) => {
  const { createNewCharity } = props;

  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce delay={400}>
      <div className="emptycharity">
        <div className="error-block">
          <div className="error-block-text">
            Looks like your nonprofit or charity is not in our database. Register
            your charity below.
          </div>
        </div>
        <button type="button" className="formsubmit w-button" onClick={createNewCharity}>
          Register your charity
        </button>
      </div>
    </ScrollAnimation>
  );
};

NoResultForm.propTypes = {
  createNewCharity: PropTypes.func.isRequired,
};

const CharityListForm = (props) => {
  const { items, claimCharity, createNewCharity } = props;

  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce delay={400}>
      <div className="charitylistresult">
        <div className="table-group">
          {items.map(item => (
            <div className="table-group-item" key={item.key}>
              <div className="table-group-item-group">
                <div className="top-group">
                  <div className="table-group-item-pic">
                    <img
                      className="logocharity"
                      src={item.logo !== '' ? item.logo : imgLogo}
                      alt=""
                    />
                  </div>
                  <div className="table-group-item-content">
                    <span className="title">{item.name}</span>
                    <span className="description">{`EIN: ${item.federal}`}</span>
                  </div>
                </div>
                <div className="bottom-group">
                  {item.agent ? (
                    <Link to={item.viewUrl} className="claimed">
                      View Profile
                    </Link>
                  ) : (
                    <button
                      type="button"
                      className="formsubmit w-button orange-btn table-group-btn"
                      onClick={() => claimCharity(item.key)}
                    >
                      Claim
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="formsubmit w-button register-charity"
          onClick={createNewCharity}
        >
          Register your charity
        </button>
      </div>
    </ScrollAnimation>
  );
};

CharityListForm.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  createNewCharity: PropTypes.func.isRequired,
  claimCharity: PropTypes.func.isRequired,
};

const ClaimSuccess = (props) => {
  const { message } = props;

  return (
    <ScrollAnimation animateIn="fadeIn" animateOnce delay={400}>
      <div className="claimsuccess">
        <div className="success-block">
          <div className="success-block-text">{message}</div>
        </div>
      </div>
    </ScrollAnimation>
  );
};

ClaimSuccess.propTypes = {
  message: PropTypes.string.isRequired,
};

export default withUserContext(Search);
