import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import StarRatings from 'react-star-ratings';
import { Line as LineChart } from 'react-chartjs';
import Loader from 'react-loader-spinner';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { DonationProvider } from '../Providers';
import withNonprofitDetail from '../withNonprofitDetail';

import NonprofitLeftDetail from '../Components/NonprofitLeftDetail';
import SocialShareComponent from '../Components/SocialShare';
import { ChartDateOption, ChartDateOptionButton } from '../Components/HtmlTag';
import NonprofitHeaderMobile from '../Components/NonprofitHeaderMobile';
import NonprofitAboutMobile from '../Components/NonprofitAboutMobile';
import NonprofitProgramsMobile from '../Components/NonprofitProgramsMobile';
import NonprofitCampaignsMobile from '../Components/NonprofitCampaignsMobile';
import NonprofitDonorsMobile from '../Components/NonprofitDonorsMobile';
import NonprofitStickyButton from '../Components/NonprofitStickyButton';

class Financials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        agent: null,
        address: '',
        category: '',
        charityNavigatorURL: '',
        city: '',
        collect: '',
        contact: '',
        description: '',
        favorite: false,
        federal: '',
        img: null,
        key: '',
        name: '',
        slugName: '',
        publish: '',
        rating: [],
        ratingStar: [],
        state: '',
        tagline: '',
        viewUrl: '',
        viewUrlFinancial: '',
        hideDonation: false,
        stripe_account: null,
      },
      dailyDonation: { until: '', totalRaised: 0.0 },
      weeklyDonation: { until: '', totalRaised: 0.0 },
      monthlyDonation: { until: '', totalRaised: 0.0 },
      yearToDateDonation: { until: '', totalRaised: 0.0 },
      totalDonation: { until: '', totalRaised: 0.0 },
      selectedDate: 1,
      chartData: {
        labels: [],
        datasets: [],
      },
      chartLoading: true,
    };

    this.dateButtonOnSelect = this.dateButtonOnSelect.bind(this);
  }

  componentDidMount() {
    const { nonprofit, updateNavStatus, showFooterLinkContainer } = this.props;
    updateNavStatus('');
    showFooterLinkContainer(false);

    this.setState(
      prevState => {
        const { item } = prevState;
        Object.keys(item).forEach(key => {
          item[key] = nonprofit[key];
        });

        return { item };
      },
      () => {
        if (!nonprofit.hideDonation) {
          this.getFinancialInfo();
          this.getChartData();
        }
      }
    );
  }

  getFinancialInfo() {
    const { item } = this.state;
    DonationProvider.todayNonprofitDonation(item.key).then(result => {
      this.setState({ dailyDonation: result });
    });

    DonationProvider.weeklyNonprofitDonation(item.key).then(result => {
      this.setState({ weeklyDonation: result });
    });

    DonationProvider.monthlyNonprofitDonation(item.key).then(result => {
      this.setState({ monthlyDonation: result });
    });

    DonationProvider.yearNonprofitDonation(item.key).then(result => {
      this.setState({ yearToDateDonation: result });
    });

    DonationProvider.totalNonprofitDonation(item.key).then(result => {
      this.setState({ totalDonation: result });
    });
  }

  getChartData() {
    this.setState(
      {
        chartLoading: true,
      },
      () => {
        const { selectedDate, item } = this.state;
        const rangeValue = ChartDateOption[selectedDate].value;
        DonationProvider.getChartData(item.key, rangeValue).then(result => {
          if (result.status === 'ok') {
            this.setState({
              chartLoading: false,
              chartData: {
                labels: result.labels,
                datasets: [
                  {
                    label: 'Last 7 days',
                    fillColor: 'rgba(151,187,205,0.2)',
                    strokeColor: 'rgba(151,187,205,1)',
                    pointColor: 'rgba(151,187,205,1)',
                    pointStrokeColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(151,187,205,1)',
                    data: result.chartData,
                  },
                ],
              },
            });
          }
        });
      }
    );
  }

  dateButtonOnSelect(eventKey) {
    const { chartLoading } = this.state;
    if (!chartLoading) {
      this.setState(
        {
          selectedDate: Number.parseInt(eventKey, 10),
        },
        () => {
          this.getChartData();
        }
      );
    }
  }

  render() {
    const {
      item,
      selectedDate,
      chartLoading,
      chartData,
      dailyDonation,
      weeklyDonation,
      monthlyDonation,
      yearToDateDonation,
      totalDonation,
    } = this.state;
    const { authUser } = this.props;
    const metaDescription =
      item.description.length > 160 ? item.description.substring(0, 160).concat('...') : item.description;

    return (
      <div className="NonprofitAboutSection">
        {item.name !== '' ? (
          <Helmet>
            <title>{`Fundra - ${item.name} :: Financials`}</title>
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={`Fundra - ${item.name} :: Financials`} />
            <meta property="og:description" content={metaDescription} />
          </Helmet>
        ) : null}
        <div className="sectionhero">
          <div className="contentwrapper herosectionfeatures nonprofitsection">
            <div className="w-row nonprofit-detail">
              <div className="w-col w-col-3 w-col-stack f-text-center leftnav">
                <NonprofitLeftDetail item={item} authUser={authUser} active="financials" />
              </div>
              <div className="w-col w-col-9 w-col-stack nonprofitbodycontent">
                <div className="w-row">
                  <div className="w-col w-col-8 w-col-stack">
                    <h3 className="h3 nonprofitabouttitle">Total raised</h3>
                    <label className="joined">All time amount raised</label>
                  </div>
                  <div className="w-col w-col-4 w-col-stack">
                    {item.name !== '' ? (
                      <SocialShareComponent
                        url={item.viewUrlFinancial}
                        title={item.name}
                        description={item.description}
                        noCount
                      />
                    ) : null}
                  </div>
                </div>
                {!item.hideDonation ? (
                  <React.Fragment>
                    <div className="w-row right-dropdown" style={{ marginTop: 48 }}>
                      <div className="w-col w-col-5 w-col-stack f-pull-right">
                        <span className="f-dropdown-label">Raised</span>
                        <ChartDateOptionButton
                          selectedDate={selectedDate}
                          dateButtonOnSelect={this.dateButtonOnSelect}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 23 }}>
                      {chartLoading ? (
                        <div className="section-loader-data">
                          <div className="section-loader-container">
                            <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                          </div>
                        </div>
                      ) : (
                        <LineChart data={chartData} options={{ responsive: true }} />
                      )}
                    </div>
                  </React.Fragment>
                ) : null}
                <div className="w-row" style={{ marginTop: 40 }}>
                  <div className="w-col w-col-4 financial-block">
                    <div className="f-rounded-gray-box">
                      <label className="donation-header">Daily Donations</label>
                      <label className="donation-date">{dailyDonation.until}</label>
                      <label className="donation-amount">{`$${dailyDonation.totalRaised}`}</label>
                    </div>
                  </div>
                  <div className="w-col w-col-4 financial-block">
                    <div className="f-rounded-gray-box">
                      <label className="donation-header">Weekly Donations</label>
                      <label className="donation-date">{weeklyDonation.until}</label>
                      <label className="donation-amount">{`$${weeklyDonation.totalRaised}`}</label>
                    </div>
                  </div>
                  <div className="w-col w-col-4 financial-block">
                    <div className="f-rounded-gray-box">
                      <label className="donation-header">Monthly Donations</label>
                      <label className="donation-date">{monthlyDonation.until}</label>
                      <label className="donation-amount">{`$${monthlyDonation.totalRaised}`}</label>
                    </div>
                  </div>
                </div>
                <div className="w-row">
                  <div className="w-col w-col-6 financial-block">
                    <div className="f-rounded-gray-box">
                      <label className="donation-header">Year to Date Donations</label>
                      <label className="donation-date">{yearToDateDonation.until}</label>
                      <label className="donation-amount">{`$${yearToDateDonation.totalRaised}`}</label>
                    </div>
                  </div>
                  <div className="w-col w-col-6 financial-block">
                    <div className="f-rounded-gray-box">
                      <label className="donation-header">Total Donations to Date</label>
                      <label className="donation-date">{totalDonation.until}</label>
                      <label className="donation-amount2">{`$${totalDonation.totalRaised}`}</label>
                    </div>
                  </div>
                </div>
                {item.rating && item.rating.overall !== '' ? (
                  <div className="w-row" style={{ marginTop: 22 }}>
                    <div className="w-col w-col-12 financial-block">
                      <div className="f-rounded-gray-box2">
                        <div className="w-row financial-row">
                          <div className="w-col w-col-10">
                            <label className="overall-header">Overall Score & Rating</label>
                          </div>
                          <div className="w-col w-col-2">
                            <label className="overall-header f-text-right">{item.rating.overall}</label>
                          </div>
                        </div>
                        <div className="w-row financial-row">
                          <div className="w-col w-col-10">
                            <label className="overall-text">Financial</label>
                            <StarRatings
                              rating={item.ratingStar.financial}
                              starRatedColor="#F5A623"
                              starDimension="12px"
                              starSpacing="2px"
                              name="financial-rating"
                            />
                          </div>
                          <div className="w-col w-col-2">
                            <label className="overall-text f-text-right">{item.rating.financial}</label>
                          </div>
                        </div>
                        <div className="w-row financial-row">
                          <div className="w-col w-col-10">
                            <label className="overall-text">Accountability & Transparency</label>
                            <StarRatings
                              rating={item.ratingStar.accountability}
                              starRatedColor="#F5A623"
                              starDimension="12px"
                              starSpacing="2px"
                              name="accountability-rating"
                            />
                          </div>
                          <div className="w-col w-col-2">
                            <label className="overall-text f-text-right">{item.rating.accountability}</label>
                          </div>
                        </div>
                        <div className="w-row financial-row">
                          <div className="w-col w-col-12">
                            <label className="overall-footer">{`This rating was published ${
                              item.publish
                            } and includes data from ${item.collect}`}</label>
                            <a
                              href={item.charityNavigatorURL}
                              target="_blank"
                              className="overall-footer-link"
                              rel="noopener noreferrer"
                            >
                              Learn more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {item.key !== '' ? (
              <div className="nonprofit-mobile-detail">
                <NonprofitHeaderMobile item={item} />
                <Tabs defaultIndex={4}>
                  <TabList>
                    <Tab>About</Tab>
                    <Tab>Programs</Tab>
                    <Tab>Campaigns</Tab>
                    <Tab>Donors</Tab>
                    <Tab>Financials</Tab>
                  </TabList>
                  <TabPanel>
                    <NonprofitAboutMobile item={item} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitProgramsMobile nid={item.key} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitCampaignsMobile nid={item.key} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitDonorsMobile nid={item.key.toString()} />
                  </TabPanel>
                  <TabPanel>
                    {item.rating && item.rating.overall !== '' ? (
                      <div className="w-row">
                        <div className="w-col w-col-12 financial-block">
                          <div className="f-rounded-gray-box2">
                            <div className="w-row financial-row">
                              <div className="w-col w-col-10">
                                <label className="overall-header">Overall Score & Rating</label>
                              </div>
                              <div className="w-col w-col-2">
                                <label className="overall-header f-text-right">{item.rating.overall}</label>
                              </div>
                            </div>
                            <div className="w-row financial-row">
                              <div className="w-col w-col-10">
                                <label className="overall-text">Financial</label>
                                <StarRatings
                                  rating={item.ratingStar.financial}
                                  starRatedColor="#F5A623"
                                  starDimension="12px"
                                  starSpacing="2px"
                                  name="financial-rating"
                                />
                              </div>
                              <div className="w-col w-col-2">
                                <label className="overall-text f-text-right">{item.rating.financial}</label>
                              </div>
                            </div>
                            <div className="w-row financial-row">
                              <div className="w-col w-col-10">
                                <label className="overall-text">Accountability & Transparency</label>
                                <StarRatings
                                  rating={item.ratingStar.accountability}
                                  starRatedColor="#F5A623"
                                  starDimension="12px"
                                  starSpacing="2px"
                                  name="accountability-rating"
                                />
                              </div>
                              <div className="w-col w-col-2">
                                <label className="overall-text f-text-right">{item.rating.accountability}</label>
                              </div>
                            </div>
                            <div className="w-row financial-row">
                              <div className="w-col w-col-12">
                                <label className="overall-footer">{`This rating was published ${
                                  item.publish
                                } and includes data from ${item.collect}`}</label>
                                <a
                                  href={item.charityNavigatorURL}
                                  target="_blank"
                                  className="overall-footer-link"
                                  rel="noopener noreferrer"
                                >
                                  Learn more
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="w-row">
                      <div className="w-col w-col-4 financial-block">
                        <div className="f-rounded-gray-box">
                          <label className="donation-header">Daily Donations</label>
                          <label className="donation-date">{dailyDonation.until}</label>
                          <label className="donation-amount">{`$${dailyDonation.totalRaised}`}</label>
                        </div>
                      </div>
                      <div className="w-col w-col-4 financial-block">
                        <div className="f-rounded-gray-box">
                          <label className="donation-header">Weekly Donations</label>
                          <label className="donation-date">{weeklyDonation.until}</label>
                          <label className="donation-amount">{`$${weeklyDonation.totalRaised}`}</label>
                        </div>
                      </div>
                      <div className="w-col w-col-4 financial-block">
                        <div className="f-rounded-gray-box">
                          <label className="donation-header">Monthly Donations</label>
                          <label className="donation-date">{monthlyDonation.until}</label>
                          <label className="donation-amount">{`$${monthlyDonation.totalRaised}`}</label>
                        </div>
                      </div>
                    </div>
                    <div className="w-row">
                      <div className="w-col w-col-6 financial-block">
                        <div className="f-rounded-gray-box">
                          <label className="donation-header">Year to Date Donations</label>
                          <label className="donation-date">{yearToDateDonation.until}</label>
                          <label className="donation-amount">{`$${yearToDateDonation.totalRaised}`}</label>
                        </div>
                      </div>
                      <div className="w-col w-col-6 financial-block">
                        <div className="f-rounded-gray-box">
                          <label className="donation-header">Total Donations to Date</label>
                          <label className="donation-date">{totalDonation.until}</label>
                          <label className="donation-amount2">{`$${totalDonation.totalRaised}`}</label>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
                <NonprofitStickyButton nid={item.key} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

Financials.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

Financials.defaultProps = {
  authUser: null,
};

export default withNonprofitDetail(Financials);
