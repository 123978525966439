import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Progress } from 'react-sweet-progress';

import { database } from '../firebase';
import { numberHelper } from '../helpers';

import * as routes from '../constants/routes';

// import ImgShare from '../Assets/Images/ShareIcon.svg';
import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';
import ImgFlag from '../Assets/Images/flag.svg';

import 'react-sweet-progress/lib/style.css';

class NonprofitProgramCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      cover: '',
      description: '',
      id: '',
      nonprofit: {
        id: '',
        name: '',
        city: '',
        state: '',
        hideDonation: false,
      },
      campaign: 0,
      goal: 0,
      goalText: '',
      raisedText: '',
      percent: 0,
      percentForBar: 0,
      viewUrl: '',
    };

    this.handleDonation = this.handleDonation.bind(this);
    this.handleFundraise = this.handleFundraise.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;

    this.setState({
      name: item.name,
      cover: item.cover,
      description: item.description,
      id: item.id,
      nonprofit: item.nonprofit,
      viewUrl: item.viewUrl,
    });

    this.getCampaign(item.nonprofit.id, item.id);
    this.getDonation(item.nonprofit.id, item.id);
  }

  getCampaign(key, id) {
    database
      .getRef('/campaigns')
      .orderByChild('program/id')
      .equalTo(id)
      .once('value')
      .then(campaignSnap => {
        let counter = 0;
        let totalGoal = 0.0;
        campaignSnap.forEach(snap => {
          if (snap.val().published) {
            if (snap.val().goal) {
              totalGoal += parseFloat(snap.val().goal);
            }
            counter += 1;
          }
        });

        this.setState(
          {
            campaign: counter,
            goal: totalGoal,
            goalText: numberHelper.getThousandString(totalGoal.toFixed(2)),
          },
          () => {
            this.getDonation(key, id);
          }
        );
      });
  }

  async getDonation(key, id) {
    let totalRaised = 0.0;
    let percent = 0;
    let percentForBar = 0;
    const { goal } = this.state;

    const donationSnap = await database
      .getRef(`/donations/${key}`)
      .orderByChild('program/id')
      .equalTo(String(id))
      .once('value');
    donationSnap.forEach(snap => {
      totalRaised += parseFloat(snap.val().total.amount);
    });

    if (goal > 0) {
      percent = (totalRaised / goal) * 100;
      percentForBar = percent;
      if (percent > 100) {
        percentForBar = 100;
      }
    }

    this.setState({
      percent: percent.toFixed(),
      percentForBar: percentForBar.toFixed(),
      raisedText: numberHelper.getThousandString(totalRaised.toFixed(2)),
    });
  }

  handleDonation() {
    const { history } = this.props;
    const { nonprofit } = this.state;

    history.push(routes.NONPROFITS_DONATION.replace(':id', nonprofit.id).replace(':type', 'b'));
  }

  handleFundraise() {
    const { history } = this.props;
    const { id, nonprofit } = this.state;

    history.push(`${routes.CAMPAIGNS_REGISTER}?b=${nonprofit.id}&p=${id}`);
  }

  render() {
    const {
      cover,
      name,
      nonprofit,
      viewUrl,
      campaign,
      description,
      goal,
      raisedText,
      goalText,
      percent,
      percentForBar,
    } = this.state;
    const { hideNonprofitName, hideCampaign } = this.props;
    const programCover = cover !== '' ? cover : ImgCoverDefault;
    const noRaised = nonprofit.hideDonation ? ' no-raised' : '';

    return (
      <div className="nonprofit-card">
        <div className="program-header">
          <div className="program-name">
            <span className="title" style={hideNonprofitName ? { marginBottom: 0 } : null}>
              {name}
            </span>
            {!hideNonprofitName ? <span className="street">{nonprofit.name}</span> : null}
          </div>
        </div>
        <div className="nonprofit-cover program">
          <Link to={viewUrl} className="program">
            <img src={programCover} alt={`${name} Cover`} />
          </Link>
        </div>
        <div className="nonprofit-body">
          {!hideCampaign ? (
            <div className="social">
              {/* <div className="item share">
                <a href="#share">
                  <img src={ImgShare} alt="" />
                </a>
              </div> */}
              {campaign > 0 ? (
                <div className="item right">
                  <HashLink to={`${viewUrl}#campaigns`} className="w-button campaign">
                    <img src={ImgFlag} alt="flag" />
                    {` ${campaign} ${campaign > 1 ? 'Campaigns' : 'Campaign'}`}
                  </HashLink>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className={`program-description${noRaised}`}>{description}</div>
          {!nonprofit.hideDonation ? (
            <div className="totalmoney">
              {goal === 0 ? (
                <div className="item-full program">
                  <div className="item-border">
                    <span className="money-label">Raised</span>
                    <span className="money-text">{`$ ${raisedText}`}</span>
                  </div>
                </div>
              ) : (
                <div className="item-full program">
                  <div className="item-border-finance">
                    <div className="finance-item">
                      <span className="f-text-bold">{`$ ${raisedText}`}</span>
                      <span>{` of $ ${goalText}`}</span>
                    </div>
                    <div className="finance-item right">
                      <span className="f-text-bold">{`${percent} %`}</span>
                    </div>
                  </div>
                  <div className="progressbar program">
                    <Progress percent={percentForBar} theme={{ success: { symbol: ' ', color: '#63D8B5' } }} />
                  </div>
                </div>
              )}
            </div>
          ) : null}
          <div className="button">
            <div className="button-item" style={{ paddingLeft: 0, width: '100%' }}>
              <button type="button" className="w-button program f-btn-danger" onClick={this.handleDonation}>
                Support
              </button>
            </div>
          </div>
        </div>
        <div className="nonprofit-footer program">
          <div className="footer-link">
            <Link to={viewUrl} className="program">
              View Program Profile
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

NonprofitProgramCard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  hideNonprofitName: PropTypes.bool,
  hideCampaign: PropTypes.bool,
};

NonprofitProgramCard.defaultProps = { hideNonprofitName: false, hideCampaign: false };

export default withRouter(NonprofitProgramCard);
