import React from 'react';
import AuthUserContext from './AuthUserContext';
import { firebase, database } from './firebase';
import { NonprofitProvider } from './Providers';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      const hasUserInfo = Object.prototype.hasOwnProperty.call(localStorage, 'user-info');
      if (hasUserInfo) {
        const userInfo = localStorage.getItem('user-info');
        this.setState({ authUser: JSON.parse(userInfo) });
      }

      firebase.auth.onAuthStateChanged(authUserInfo => {
        if (authUserInfo) {
          const userInfo = {
            uid: authUserInfo.uid,
            name: authUserInfo.displayName,
            email: authUserInfo.email,
            photo: authUserInfo.photoURL,
          };

          database
            .getRef(`/profile/${authUserInfo.uid}`)
            .once('value')
            .then(snapshot => {
              if (snapshot.val()) {
                const profileInfo = {
                  avatar: snapshot.val().avatar,
                  email: snapshot.val().email,
                  firstName: snapshot.val().firstName,
                  lastName: snapshot.val().lastName,
                  emailVerified: snapshot.val().emailVerified,
                  scid: snapshot.val().scid,
                  uid: snapshot.val().uid ? snapshot.val().uid : authUserInfo.uid,
                  token: snapshot.val().token,
                  social: snapshot.val().social,
                };

                firebase.auth.currentUser.getIdToken(true).then(idToken => {
                  userInfo.idToken = idToken;
                  NonprofitProvider.userIsAgent(authUserInfo.uid).then(agentChecking => {
                    profileInfo.agentStatus = agentChecking.isAgent ? agentChecking : null;

                    localStorage.setItem('user-info', JSON.stringify({ user: userInfo, profile: profileInfo }));
                    this.setState({ authUser: { user: userInfo, profile: profileInfo } });
                  });
                });
              } else {
                this.setState({ authUser: { user: userInfo, profile: { agentStatus: null } } });
              }
            });
        } else {
          this.setState({ authUser: null });
          localStorage.removeItem('user-info');
          localStorage.removeItem('user-logout');
        }
      });
    }

    render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
