import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';

import * as routes from './constants/routes';

import SiteMeta from './Components/SiteMeta';
import LaunchingCharityComponent from './Components/LaunchingCharity';

import imgHeroAbout from './Assets/Images/heroAbout-Fundra.svg';
import imgContact from './Assets/Images/heroContact-Fundra.svg';

import 'animate.css/animate.min.css';
import './Assets/Styles/About.css';

class About extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      organization: '',
      messageSubject: '',
      messageBody: '',
      submitButton: 'Submit',
      disabled: false,
      formDisplay: { display: 'block' },
      failStyle: { display: 'none' },
      doneStyle: { backgroundColor: '#fff', display: 'none' },
    };

    this.state = this.initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus } = this.props;
    updateNavStatus('');
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitButton: 'Please wait...', disabled: true });

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { firstName, lastName, email, phone, organization, messageSubject, messageBody } = this.state;

    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('organization', organization);
    formData.append('messageSubject', messageSubject);
    formData.append('messageBody', messageBody);

    axios
      .post('/api/ajax.php', formData, axiosConfig)
      .then(res => {
        this.setState(this.initialState);
        if (res.data.code === 200) {
          this.setState({
            formDisplay: { display: 'none' },
            doneStyle: { backgroundColor: '#fff', display: 'block' },
          });
        } else {
          this.setState({ failStyle: { display: 'block' } });
        }
      })
      .catch(err => {
        this.setState({ failStyle: { display: 'block' } });
        console.log(err);
      });
  }

  render() {
    const { firstName, lastName, email, phone, organization, messageSubject, messageBody } = this.state;
    const { submitButton, disabled, formDisplay, failStyle, doneStyle } = this.state;

    return (
      <div className="AboutSection">
        <SiteMeta title="Our Story" />
        <div className="sectionhero blog-aboutpage">
          <div className="contentwrapper herosectionfeatures">
            <div className="herocolumn w-row">
              <div className="herocolumntext herocolumntextabout w-col w-col-6 w-col-stack">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                  <h2 className="h2">
                    Helping nonprofits
                    <br />
                    increase donations.
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" delay={50} animateOnce>
                  <div className="herodescription">
                    We believe that financial transparency can increase nonprofit donations. Fundra helps your
                    organization engage donors by sharing key financial reports, budgets, and performance metrics with
                    the goal of increasing overall contributions.
                  </div>
                  <div className="herocta">
                    <HashLink to={routes.NONPROFITS_REGISTER} className="f-btn f-btn-success f-btn-space">
                      <div>Get Started</div>
                    </HashLink>
                    <HashLink to={routes.CAMPAIGNS} className="f-link f-warning">
                      Explore Causes
                    </HashLink>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="herocolumnimage w-col w-col-6 w-col-stack">
                <ScrollAnimation animateIn="fadeInRight" animateOnce>
                  <img src={imgHeroAbout} className="abouthero" alt="Hero About" />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionfeatured sectionfeaturedfeaturespage">
          <div className="contentwrapper">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <h2 className="h2 featuretitle">Strengthening nonprofits</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="featuretitledesc">
                Fundra was created with the idea of building trust with donors through financial transparency.
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <p className="bodytext">
                We’ve pioneered a breakthrough solution that helps nonprofit organizations increase overall
                contributions through financial planning and transparency. Our fundraising products were designed by
                nonprofits for nonprofits so that our clients can have the necessary tools to clearly communicate the
                need and use of donation dollars.
              </p>
            </ScrollAnimation>
            <div id="faq" className="faqcontentsection">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h2 className="h2 featuretitle">Fundra FAQ</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="faqlist">
                  <div className="faqq">Q: What is Fundra?</div>
                  <div className="faqa">
                    A: Fundra is an enterprise grade solution designed to help nonprofit organizations increase their
                    donations through financial transparency and accountability.
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="faqlist">
                  <div className="faqq">Q: What does it cost to implement Fundra?</div>
                  <div className="faqa">
                    A: Fundra&apos;s subscription price depends on your organization&apos;s size and needs. Please
                    provide us a few details about your organization&apos;s needs by talking to one of our experts so we
                    can send you an accurate quote.
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="faqlist">
                  <div className="faqq">
                    Q: What requirements do nonprofits have to meet in order to raise money on Fundra?
                  </div>
                  <div className="faqa">
                    A: Nonprofit organizations are required to complete a simple application and have a valid federal
                    EIN number to raise money on Fundra.
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="faqlist last">
                  <div className="faqq">
                    Q: Why should I choose Fundra to raise funds for my nonprofit vs. other platforms?
                  </div>
                  <div className="faqa">
                    A: Most donation platforms don’t provide details as to where funding is being applied. With Fundra,
                    you’re never in the dark about where your money is going. Fundra helps donors donate with confidence
                    by giving you the ability to track your donation and see how the nonprofit / program you supported
                    is making an impact in your community. For nonprofits, Fundra helps you raise funding so you can
                    focus on what matters most, your programs.
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div id="contact" className="sectionfaq">
          <div className="contentwrapper">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <h2 className="h2 featuretitle">Can’t find your answer?</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="featuretitledesc">Get in touch and let us know how we can help!</div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="herocolumn w-row">
                <div className="herocolumntext herocolumntextcontact w-col w-col-6 w-col-stack">
                  <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={500}>
                    <div className="formblock w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        action="submitcontact"
                        onSubmit={this.handleSubmit}
                        style={formDisplay}
                      >
                        <div className="w-row">
                          <div className="w-col w-col-6 w-col-stack">
                            <label htmlFor="firstName" className="formfieldlabel">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="formtextfield w-input input90"
                              maxLength="256"
                              name="firstName"
                              placeholder="Enter your first name"
                              value={firstName}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                          <div className="w-col w-col-6 w-col-stack">
                            <label htmlFor="lastName" className="formfieldlabel">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="formtextfield w-input"
                              maxLength="256"
                              name="lastName"
                              placeholder="Enter your last name"
                              value={lastName}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                        <label htmlFor="email" className="formfieldlabel">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="formtextfield w-input"
                          maxLength="256"
                          name="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={this.handleChange}
                          required
                        />
                        <label htmlFor="phone" className="formfieldlabel">
                          Phone number
                        </label>
                        <input
                          type="tel"
                          className="formtextfield w-input"
                          maxLength="256"
                          name="phone"
                          placeholder="Enter your phone"
                          value={phone}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="organization" className="formfieldlabel">
                          Organization
                        </label>
                        <input
                          type="text"
                          className="formtextfield w-input"
                          maxLength="256"
                          name="organization"
                          placeholder="Enter your organization"
                          value={organization}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="messageSubject" className="formfieldlabel">
                          Subject
                        </label>
                        <select
                          name="messageSubject"
                          className="formdropdown w-select"
                          value={messageSubject}
                          onChange={this.handleChange}
                          required
                        >
                          <option value="">Select one</option>
                          <option value="Account issue">Account issue</option>
                          <option value="Report bug">Report bug</option>
                          <option value="Sales Question">Sales Question</option>
                          <option value="Investments">Investments</option>
                          <option value="Other">Other</option>
                        </select>
                        <label htmlFor="messageBody" className="formfieldlabel">
                          Message
                        </label>
                        <input
                          type="text"
                          className="formtextfield w-input"
                          maxLength="256"
                          name="messageBody"
                          placeholder="Type your message here"
                          value={messageBody}
                          onChange={this.handleChange}
                          required
                        />
                        <input type="submit" value={submitButton} className="formsubmit w-button" disabled={disabled} />
                      </form>
                      <div className="w-form-done" style={doneStyle}>
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail" style={failStyle}>
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="herocolumnimage contactheroimage w-col w-col-6 w-col-stack">
                  <ScrollAnimation animateIn="fadeInRight" animateOnce delay={500}>
                    <img src={imgContact} className="contacthero" alt="Contact Us" />
                  </ScrollAnimation>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <LaunchingCharityComponent />
      </div>
    );
  }
}

About.propTypes = { updateNavStatus: PropTypes.func.isRequired };

export default About;
