import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import TopNavigation from './Components/TopNavigation';
import NotMatch from './NotMatch';
import Features from './Features';
import About from './About';
import Pricing from './Pricing';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import Footer from './Footer';
import Home from './Home';
import SignIn from './SignIn';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import Nonprofits from './Nonprofits/List';
import NonprofitsAbout from './Nonprofits/About';
import NonprofitsPrograms from './Nonprofits/Programs';
import NonprofitsCampaigns from './Nonprofits/Campaigns';
import NonprofitsDonors from './Nonprofits/Donors';
import NonprofitsFinancial from './Nonprofits/Financials';
import NonprofitsRegister from './Nonprofits/Search';
import NonprofitsDonation from './Nonprofits/Donation';
import ProgramDetail from './Nonprofits/ProgramDetail';
import CampaignDetail from './Nonprofits/CampaignDetail';
import Campaigns from './Campaigns/List';
import CampaignsRegister from './Campaigns/Register';
import CampaignsEdit from './Campaigns/Edit';
import DonorsCRMList from './DonorsCRM/List';
import DonorsCRMDonation from './DonorsCRM/Donation';
import MetricList from './Metrics/List';
import ProfileDonor from './Profile/Donor';
import ProfileAgent from './Profile/Agent';

import * as routes from './constants/routes';
import withAuthentication from './withAuthentication';

import './Assets/Styles/App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFooterLink: true,
      navStatus: 'empty',
    };

    this.fadeFooterLinkContainer = this.fadeFooterLinkContainer.bind(this);
    this.updateNavStatus = this.updateNavStatus.bind(this);
  }

  fadeFooterLinkContainer(show) {
    this.setState({ showFooterLink: show });
  }

  updateNavStatus(sts) {
    this.setState({ navStatus: sts });
  }

  render() {
    const { showFooterLink, navStatus } = this.state;

    return (
      <Router forceRefresh>
        <div className="App">
          <TopNavigation navStatus={navStatus} />
          <Switch>
            <Route exact path={routes.LANDING} render={() => <Home updateNavStatus={this.updateNavStatus} />} />
            <Route exact path={routes.FEATURES} render={() => <Features updateNavStatus={this.updateNavStatus} />} />
            <Route exact path={routes.ABOUT} render={() => <About updateNavStatus={this.updateNavStatus} />} />
            <Route exact path={routes.PRICING} render={() => <Pricing updateNavStatus={this.updateNavStatus} />} />
            <Route
              exact
              path={routes.PRIVACY_POLICY}
              render={() => <PrivacyPolicy updateNavStatus={this.updateNavStatus} />}
            />
            <Route exact path={routes.TERMS} render={() => <Terms updateNavStatus={this.updateNavStatus} />} />
            <Route
              exact
              path={routes.SIGN_IN}
              render={props => (
                <SignIn
                  {...props}
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.SIGN_UP}
              render={props => (
                <Register
                  {...props}
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.FORGOT_PASSWORD}
              render={props => (
                <ForgotPassword
                  {...props}
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS_ABOUT}
              render={() => (
                <NonprofitsAbout
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS_PROGRAMS}
              render={() => (
                <NonprofitsPrograms
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS_CAMPAIGNS}
              render={() => (
                <NonprofitsCampaigns
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS_DONORS}
              render={() => (
                <NonprofitsDonors
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS_FINANCIALS}
              render={() => (
                <NonprofitsFinancial
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS_REGISTER}
              render={() => (
                <NonprofitsRegister
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS_DONATION}
              render={props => (
                <NonprofitsDonation
                  {...props}
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.NONPROFITS}
              render={() => (
                <Nonprofits
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.PROGRAMS_ABOUT}
              render={() => (
                <ProgramDetail
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.CAMPAIGNS_REGISTER}
              render={() => (
                <CampaignsRegister
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.CAMPAIGNS_EDIT}
              render={() => (
                <CampaignsEdit
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.CAMPAIGNS_ABOUT}
              render={() => (
                <CampaignDetail
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.CAMPAIGNS}
              render={() => (
                <Campaigns
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.DONORS_MANAGEMENT_DONATION}
              render={props => (
                <DonorsCRMDonation
                  {...props}
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.DONORS_MANAGEMENT}
              render={() => (
                <DonorsCRMList
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.METRICS_MANAGEMENT_LIST}
              render={() => (
                <MetricList
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.PROFILE_DONOR}
              render={() => (
                <ProfileDonor
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route
              exact
              path={routes.PROFILE_AGENT}
              render={() => (
                <ProfileAgent
                  updateNavStatus={this.updateNavStatus}
                  showFooterLinkContainer={this.fadeFooterLinkContainer}
                />
              )}
            />
            <Route exact component={NotMatch} />
          </Switch>
          <Footer showLinkContainer={showFooterLink} />
        </div>
      </Router>
    );
  }
}

export default withAuthentication(App);
