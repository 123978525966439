import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

import SiteMeta from './Components/SiteMeta';

import 'animate.css/animate.min.css';
import './Assets/Styles/PrivacyPolicy.css';

const PrivacyPolicy = ({ updateNavStatus }) => {
  useEffect(() => {
    updateNavStatus('');
  }, [updateNavStatus]);

  return (
    <div className="PrivacyPolicySection">
      <SiteMeta title="Privacy Policy" />
      <div className="sectionhero">
        <div className="contentwrapper herosectionfeatures">
          <div className="herocolumn w-row">
            <div className="herocolumntext herocolumntextprivacy w-col w-col-12 w-col-stack">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h2 className="h2 featuretitle featuretitleprivacy">Privacy Policy</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="featuretitledesc privacytitledesc">Version Date: November 12, 2017</div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h4>General</h4>
                <p className="bodytext privacytext">
                  Fundra Inc. (“Company” or “we” or “us” or “our”) respects the privacy of its users (“user” or “you”)
                  that use our website located at www.fundra.io, including other media forms, media channels, mobile
                  website or mobile application related or connected thereto (collectively, the “Website”). The
                  following Company privacy policy (“Privacy Policy”) is designed to inform you, as a user of the
                  Website, about the types of information that Company may gather about or collect from you in
                  connection with your use of the Website. It also is intended to explain the conditions under which
                  Company uses and discloses that information, and your rights in relation to that information. Changes
                  to this Privacy Policy are discussed at the end of this document. Each time you use the Website,
                  however, the current version of this Privacy Policy will apply. Accordingly, each time you use the
                  Website you should check the date of this Privacy Policy (which appears at the beginning of this
                  document) and review any changes since the last time you used the Website. The Website is hosted in
                  the United States of America and is subject to U.S. state and federal law. If you are accessing our
                  Website from other jurisdictions, please be advised that you are transferring your personal
                  information to us in the United States, and by using our Website, you consent to that transfer and use
                  of your personal information in accordance with this Privacy Policy. You also agree to abide by the
                  applicable laws of applicable states and U.S. federal law concerning your use of the Website and your
                  agreements with us. Any persons accessing our Website from any jurisdiction with laws or regulations
                  governing the use of the Internet, including personal data collection, use and disclosure, different
                  from those of the jurisdictions mentioned above may only use the Website in a manner lawful in their
                  jurisdiction. If your use of the Website would be unlawful in your jurisdiction, please do not use the
                  Website.
                  <br />
                  <br />
                  BY USING OR ACCESSING THE WEBSITE, YOU ARE ACCEPTING THE PRACTICES DESCRIBED IN THIS PRIVACY POLICY.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h4>GATHERING, USE AND DISCLOSURE OF NON-PERSONALLY-IDENTIFYING INFORMATION</h4>
                <p className="bodytext privacytext last">
                  “Non-Personally-Identifying Information” is information that, without the aid of additional
                  information, cannot be directly associated with a specific person. “Personally-Identifying
                  Information,” by contrast, is information such as a name or email address that, without more, can be
                  directly associated with a specific person. Like most website operators, Company gathers from users of
                  the Website Non-Personally- Identifying Information of the sort that Web browsers, depending on their
                  settings, may make available. That information includes the user’s Internet Protocol (IP) address,
                  operating system, browser type and the locations of the websites the user views right before arriving
                  at, while navigating and immediately after leaving the Website. Although such information is not
                  Personally-Identifying Information, it may be possible for Company to determine from an IP address a
                  user’s Internet service provider and the geographic location of the visitor’s point of connectivity as
                  well as other statistical usage data. Company analyzes Non-Personally-Identifying Information gathered
                  from users of the Website to help Company better understand how the Website is being used. By
                  identifying patterns and trends in usage, Company is able to better design the Website to improve
                  users’ experiences, both in terms of content and ease of use. From time to time, Company may also
                  release the Non-Personally-Identifying Information gathered from Website users in the aggregate, such
                  as by publishing a report on trends in the usage of the Website.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h4>Web Cookie</h4>
                <p className="bodytext privacytext">
                  A “Web Cookie” is a string of information which assigns you a unique identification that a website
                  stores on a user’s computer, and that the user’s browser provides to the website each time the user
                  submits a query to the website. We use cookies on the Website to keep track of services you have used,
                  to record registration information regarding your login name and password, to record your user
                  preferences, to keep you logged into the Website and to facilitate purchase procedures. Company also
                  uses Web Cookies to track the pages that users visit during each Website session, both to help Company
                  improve users’ experiences and to help Company understand how the Website is being used. As with other
                  Non- Personally-Identifying Information gathered from users of the Website, Company analyzes and
                  discloses in aggregated form information gathered using Web Cookies, so as to help Company, its
                  partners and others better understand how the Website is being used.
                  <br />
                  <br />
                  COMPANY USERS WHO DO NOT WISH TO HAVE WEB COOKIES PLACED ON THEIR COMPUTERS SHOULD SET THEIR BROWSERS
                  TO REFUSE WEB COOKIES BEFORE ACCESSING THE WEBSITE, WITH THE UNDERSTANDING THAT CERTAIN FEATURES OF
                  THE WEBSITE MAY NOT FUNCTION PROPERLY WITHOUT THE AID OF WEB COOKIES. WEBSITE USERS WHO REFUSE WEB
                  COOKIES ASSUME ALL RESPONSIBILITY FOR ANY RESULTING LOSS OF FUNCTIONALITY.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PrivacyPolicy.propTypes = { updateNavStatus: PropTypes.func.isRequired };

export default PrivacyPolicy;
