import React from 'react';
import { withRouter } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';

const withUserContext = (Component) => {
  class WithUserContext extends React.Component {
    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => <Component {...this.props} authUser={authUser} />}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithUserContext);
}

export default withUserContext;
