import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';

import { DonationProvider } from '../Providers';

export default class NonprofitFinancialsMobile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      dailyDonation: { until: '', totalRaised: 0.0 },
      weeklyDonation: { until: '', totalRaised: 0.0 },
      monthlyDonation: { until: '', totalRaised: 0.0 },
      yearToDateDonation: { until: '', totalRaised: 0.0 },
      totalDonation: { until: '', totalRaised: 0.0 },
    };
  }

  getFinancialInfo() {
    DonationProvider.todayNonprofitDonation(this.props.item.key).then(result => {
      if (this._isMounted) {
        this.setState({ dailyDonation: result });
      }
    });

    DonationProvider.weeklyNonprofitDonation(this.props.item.key).then(result => {
      if (this._isMounted) {
        this.setState({ weeklyDonation: result });
      }
    });

    DonationProvider.monthlyNonprofitDonation(this.props.item.key).then(result => {
      if (this._isMounted) {
        this.setState({ monthlyDonation: result });
      }
    });

    DonationProvider.yearNonprofitDonation(this.props.item.key).then(result => {
      if (this._isMounted) {
        this.setState({ yearToDateDonation: result });
      }
    });

    DonationProvider.totalNonprofitDonation(this.props.item.key).then(result => {
      if (this._isMounted) {
        this.setState({ totalDonation: result });
      }
    });
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.item.hideDonation) {
      this.getFinancialInfo();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div>
        {this.props.item.rating && this.props.item.rating.overall !== '' ? (
          <div className="w-row">
            <div className="w-col w-col-12 financial-block">
              <div className="f-rounded-gray-box2">
                <div className="w-row financial-row">
                  <div className="w-col w-col-10">
                    <label className="overall-header">Overall Score & Rating</label>
                  </div>
                  <div className="w-col w-col-2">
                    <label className="overall-header f-text-right">{this.props.item.rating.overall}</label>
                  </div>
                </div>
                <div className="w-row financial-row">
                  <div className="w-col w-col-10">
                    <label className="overall-text">Financial</label>
                    <StarRatings
                      rating={this.props.item.ratingStar.financial}
                      starRatedColor="#F5A623"
                      starDimension="12px"
                      starSpacing="2px"
                      name="financial-rating"
                    />
                  </div>
                  <div className="w-col w-col-2">
                    <label className="overall-text f-text-right">{this.props.item.rating.financial}</label>
                  </div>
                </div>
                <div className="w-row financial-row">
                  <div className="w-col w-col-10">
                    <label className="overall-text">Accountability & Transparency</label>
                    <StarRatings
                      rating={this.props.item.ratingStar.accountability}
                      starRatedColor="#F5A623"
                      starDimension="12px"
                      starSpacing="2px"
                      name="accountability-rating"
                    />
                  </div>
                  <div className="w-col w-col-2">
                    <label className="overall-text f-text-right">{this.props.item.rating.accountability}</label>
                  </div>
                </div>
                <div className="w-row financial-row">
                  <div className="w-col w-col-12">
                    <label className="overall-footer">
                      This rating was published {this.props.item.publish} and includes data from{' '}
                      {this.props.item.collect}
                    </label>
                    <a
                      href={this.props.item.charityNavigatorURL}
                      target="_blank"
                      className="overall-footer-link"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="w-row">
          <div className="w-col w-col-4 financial-block">
            <div className="f-rounded-gray-box">
              <label className="donation-header">Daily Donations</label>
              <label className="donation-date">{this.state.dailyDonation.until}</label>
              <label className="donation-amount">${this.state.dailyDonation.totalRaised}</label>
            </div>
          </div>
          <div className="w-col w-col-4 financial-block">
            <div className="f-rounded-gray-box">
              <label className="donation-header">Weekly Donations</label>
              <label className="donation-date">{this.state.weeklyDonation.until}</label>
              <label className="donation-amount">${this.state.weeklyDonation.totalRaised}</label>
            </div>
          </div>
          <div className="w-col w-col-4 financial-block">
            <div className="f-rounded-gray-box">
              <label className="donation-header">Monthly Donations</label>
              <label className="donation-date">{this.state.monthlyDonation.until}</label>
              <label className="donation-amount">${this.state.monthlyDonation.totalRaised}</label>
            </div>
          </div>
        </div>
        <div className="w-row">
          <div className="w-col w-col-6 financial-block">
            <div className="f-rounded-gray-box">
              <label className="donation-header">Year to Date Donations</label>
              <label className="donation-date">{this.state.yearToDateDonation.until}</label>
              <label className="donation-amount">${this.state.yearToDateDonation.totalRaised}</label>
            </div>
          </div>
          <div className="w-col w-col-6 financial-block">
            <div className="f-rounded-gray-box">
              <label className="donation-header">Total Donations to Date</label>
              <label className="donation-date">{this.state.totalDonation.until}</label>
              <label className="donation-amount2">${this.state.totalDonation.totalRaised}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
