import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { numberHelper } from '../helpers';
import { SocialProvider } from '../Providers';

import { ReactComponent as FbLogoComponent } from '../Assets/Images/Facebook-Icon.svg';
import { ReactComponent as TwitterLogoComponent } from '../Assets/Images/Twitter.svg';
import WebLinkLogo from '../Assets/Images/web-link.png';

import '../Assets/Styles/SocialShare.css';

class SocialShare extends Component {
  constructor(props) {
    super(props);
    this.state = { shareCount: 0 };

    this.handleFBShareButton = this.handleFBShareButton.bind(this);
    this.handleTwitterButton = this.handleTwitterButton.bind(this);
  }

  componentDidMount() {
    const { noCount } = this.props;
    // const firstDot = description.indexOf('.');
    // const quote = description.substring(0, firstDot);
    if (!noCount) {
      this.getTotalShare();
    }
  }

  getTotalShare() {
    const fbAppID = process.env.REACT_APP_FB_APP_ID;
    const fbAppSecret = process.env.REACT_APP_FB_APP_SECRET;
    const token = `${fbAppID}|${fbAppSecret}`;
    const { url } = this.props;
    const pageUrl = encodeURI(url);

    axios.get(`https://graph.facebook.com/v3.2/?id=${pageUrl}&fields=engagement&access_token=${token}`).then(res => {
      this.setState({
        shareCount: numberHelper.getThousandString(res.data.engagement.share_count),
      });
    });
  }

  handleFBShareButton() {
    const { url, title } = this.props;
    SocialProvider.facebookShare(url, title);
  }

  handleTwitterButton() {
    const { url, title } = this.props;
    SocialProvider.twitterShare(url, title);
  }

  render() {
    const { shareCount } = this.state;
    const { noCount, onlyIcon, webLink, campaignCard } = this.props;

    return (
      <>
        {campaignCard ? (
          <div className="campaign-share">
            <button type="button" className="social-btn fb-logo" onClick={this.handleFBShareButton}>
              <FbLogoComponent className="svg-fill-logo" />
              Share
            </button>
            <button type="button" className="social-btn twitter-logo" onClick={this.handleTwitterButton}>
              <TwitterLogoComponent className="svg-fill-logo" />
              Tweet
            </button>
          </div>
        ) : (
          <div className={`${onlyIcon ? 'social-media only-icon' : 'social-media'}`}>
            {onlyIcon ? (
              <>
                {!noCount ? (
                  <div>
                    <span className="total-share">{shareCount}</span>
                    <span className="share-label">Share</span>
                  </div>
                ) : null}
                {webLink !== '' ? (
                  <a className="link-button-icon" href={webLink} target="_blank" rel="noopener noreferrer">
                    <img src={WebLinkLogo} alt="" />
                  </a>
                ) : null}
                <button type="button" className="w-button f-btn-info social-btn" onClick={this.handleFBShareButton}>
                  <FbLogoComponent className="svg-fill-logo" />
                </button>
                <button type="button" className="w-button social-btn pushright" onClick={this.handleTwitterButton}>
                  <TwitterLogoComponent className="svg-fill-logo" />
                </button>
              </>
            ) : (
              <>
                {!noCount ? (
                  <div className="social-count">
                    <span className="total-share">{shareCount}</span>
                    <span className="share-label">Share</span>
                  </div>
                ) : null}
                {webLink !== '' ? (
                  <a className="link-button" href={webLink} target="_blank" rel="noopener noreferrer">
                    <img src={WebLinkLogo} alt="" />
                    Web link
                  </a>
                ) : null}
                <div className={`social-fb${webLink !== '' ? ' with-site' : ''}`}>
                  <button type="button" className="w-button f-btn-info social-btn" onClick={this.handleFBShareButton}>
                    <FbLogoComponent className="svg-fill-logo" />
                    Share
                  </button>
                </div>
                <div className={`social-twitter${webLink !== '' ? ' with-site' : ''}`}>
                  <button type="button" className="w-button social-btn pushright" onClick={this.handleTwitterButton}>
                    <TwitterLogoComponent className="svg-fill-logo" />
                    Tweet
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

SocialShare.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  noCount: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  webLink: PropTypes.string,
  campaignCard: PropTypes.bool,
  // description: PropTypes.string.isRequired,
};

SocialShare.defaultProps = {
  noCount: false,
  onlyIcon: false,
  webLink: '',
  campaignCard: false,
};

export default SocialShare;
