import React from 'react';
import PropTypes from 'prop-types';

import { stringHelper, numberHelper } from '../helpers';

import DeleteIcon from '../Assets/Images/Trash.svg';

const ImpactsTable = ({ items, metric, rowClick, removeImpact }) => (
  <table className="nonprofit-table-small f-table-hover">
    <thead>
      <tr>
        <th>Period</th>
        <th>Target</th>
        <th>Total Spend</th>
        <th>Total Output</th>
        <th>Metric Cost</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {items.map(item => {
        const metricCost = item.metric ? Number.parseFloat(item.metric.cost) : Number.parseFloat(metric.cost);
        const costNumber = item.metric
          ? Number.parseInt(item.metric.costNumber, 10)
          : Number.parseInt(metric.costNumber, 10);
        const humanNumber = item.metric
          ? Number.parseInt(item.metric.humanNumber, 10)
          : Number.parseInt(metric.humanNumber, 10);
        const unitName = item.metric ? item.metric.unitName.trim() : metric.unitName.trim();
        const humanUnit = item.metric ? item.metric.humanUnit.trim() : metric.humanUnit.trim();
        const totalPack =
          item.metric && item.metric.result
            ? item.metric.result.unit
            : Math.ceil((Number.parseFloat(item.cost) / metricCost) * costNumber, 10);
        const totalHuman =
          item.metric && item.metric.result
            ? item.metric.result.human
            : Math.ceil((Number.parseFloat(item.cost) / metricCost) * humanNumber, 10);
        const totalHumanText =
          totalHuman > 0
            ? ` / ${numberHelper.getThousandFormat(totalHuman)} ${stringHelper.makePluralize(humanUnit, totalHuman)}`
            : '';
        const targetHumanText =
          item.target.human > 0
            ? ` / ${numberHelper.getThousandFormat(item.target.human)} ${stringHelper.makePluralize(
                humanUnit,
                item.target.human
              )}`
            : '';

        return (
          <tr key={item.id} onClick={() => rowClick(item.id)}>
            <td className="text-bold">{`${item.month < 10 ? `0${item.month}` : item.month} - ${item.year}`}</td>
            <td className="f-lowercase">
              {`${numberHelper.getThousandFormat(item.target.unit)} ${stringHelper.makePluralize(
                unitName,
                item.target.unit
              )}${targetHumanText}`}
            </td>
            <td>{`${numberHelper.getThousandFormat(item.cost, '$')}`}</td>
            <td className="f-lowercase">
              {`${numberHelper.getThousandFormat(totalPack)} ${stringHelper.makePluralize(
                unitName,
                totalPack
              )}${totalHumanText}`}
            </td>
            <td className="f-lowercase">
              {`${numberHelper.getThousandFormat(metricCost, '$')} / ${numberHelper.getThousandFormat(
                costNumber
              )} ${stringHelper.makePluralize(unitName, costNumber)}`}
            </td>
            <td className="f-action-column">
              <button type="button" onClick={e => removeImpact(item.id, e)}>
                <img src={DeleteIcon} alt="Delete" title="Delete" />
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

ImpactsTable.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  metric: PropTypes.instanceOf(Object).isRequired,
  rowClick: PropTypes.func.isRequired,
  removeImpact: PropTypes.func.isRequired,
};

export default ImpactsTable;
