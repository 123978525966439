import React from 'react';
import { HashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';

import * as routes from '../constants/routes';

import 'animate.css/animate.min.css';

const Faq = () => (
  <div className="sectionfaq">
    <div className="contentwrapper">
      <ScrollAnimation animateIn="fadeInUp" animateOnce delay={50}>
        <h2 className="h2 centertitle">Have something in mind?</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
        <div className="featuretitledesc">Visit our frequently asked questions</div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animateOnce delay={500}>
        <div className="buttondefaultrow w-row">
          <div className="buttondefaultrow-col1 w-clearfix w-col w-col-6 w-col-small-6 w-col-tiny-6">
            <HashLink to={`${routes.ABOUT}#faq`} className="buttondefault column1 w-inline-block">
              <div className="buttondefaulttext">Visit FAQ</div>
            </HashLink>
          </div>
          <div className="buttondefaultrow-col2 w-clearfix w-col w-col-6 w-col-small-6 w-col-tiny-6">
            <HashLink to={`${routes.ABOUT}#contact`} className="buttondefault column2 w-inline-block">
              <div className="buttondefaulttext">Contact us</div>
            </HashLink>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  </div>
);

export default Faq;
