import { database } from '../firebase';

const TABLE_REF = {
  goal: '/strategic_goals',
  objective: '/strategic_objectives',
  core: '/strategic_core_values',
  action: '/strategic_action_plans',
};

export const getList = (nid, ref = 'goal') => database.getRef(`${TABLE_REF[ref]}/${nid}`);
export const getDetail = (nid, key, ref = 'goal') => database.getRef(`${TABLE_REF[ref]}/${nid}/${key}`).once('value');
export const getPlanningDetail = (nid, path) => database.getRef(`/strategic_planning/${nid}/${path}`).once('value');
