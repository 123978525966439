export const LANDING = '/';
export const SIGN_UP = '/register';
export const SIGN_IN = '/signin';
export const NONPROFITS = '/nonprofits';
export const NONPROFITS_REGISTER = '/nonprofits/register';
export const NONPROFITS_DONATION = '/nonprofits/donation/:id/:type';
export const NONPROFITS_PROGRAMS = '/nonprofits/:id/:name/programs';
export const NONPROFITS_CAMPAIGNS = '/nonprofits/:id/:name/campaigns';
export const NONPROFITS_DONORS = '/nonprofits/:id/:name/donors';
export const NONPROFITS_FINANCIALS = '/nonprofits/:id/:name/financials';
export const NONPROFITS_ABOUT = '/nonprofits/:id/:name';
export const PROGRAMS_ABOUT = '/programs/:id/:nonprofitid/:name';
export const CAMPAIGNS = '/campaigns';
export const CAMPAIGNS_REGISTER = '/campaigns/register';
export const CAMPAIGNS_EDIT = '/campaigns/edit/:id';
export const CAMPAIGNS_ABOUT = '/campaigns/:id/:name';
export const DONORS_MANAGEMENT = '/donors-crm/:id/:name';
export const DONORS_MANAGEMENT_DONATION = '/donors-crm/donation/:id';
export const METRICS_MANAGEMENT_LIST = '/metrics/:id/:name/list';
export const METRICS_MANAGEMENT_IMPACT = '/metrics/:id/:name/impact';
export const FEATURES = '/features';
export const ABOUT = '/about';
export const CONTACT = '/contact';
export const PRICING = '/pricing';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS = '/terms';
export const FORGOT_PASSWORD = '/forgot';
export const PROFILE_DONOR = '/profile/donor';
export const PROFILE_AGENT = '/profile/agent';
