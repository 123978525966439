import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ImgLogoDefault from '../Assets/Images/Media_Icon.svg';
import ImgCertified from '../Assets/Images/ico-certified.svg';

export default class NonprofitHeaderMobile extends Component {
    render() {
        const nonprofitLogo = (this.props.item.img) ? this.props.item.img.logo : ImgLogoDefault;
        const nonprofitName = this.props.item.name;

        return (
            <div className="nonprofit-header flexbox-align-center">
                <div className="item">
                    <div className="nonprofit-logo">
                        <Link to={this.props.item.viewUrl}><img src={nonprofitLogo} alt={`${this.props.item.name} Logo`} /></Link>
                    </div>
                </div>
                <div className="item">
                    <div className="nonprofit-name">
                        <span className="title">
                            {nonprofitName}
                            {this.props.item.agent ? <img src={ImgCertified} alt="" className="img-certified" /> : null}
                        </span>
                        <span className="street">{`${this.props.item.city}, ${this.props.item.state}`}</span>
                    </div>
                </div>
            </div>
        )
    }
}
