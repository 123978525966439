import axios from 'axios';
import qs from 'qs';

const MODE = process.env.REACT_APP_FIREBASE_DB_PATH === 'dev' ? 'dev' : 'prod';
const SCHEDULE_API_URL = process.env.REACT_APP_SCHEDULE_MANAGEMENT_URL;
const SCHEDULE_CLIENT_ID = process.env.REACT_APP_SCHEDULE_CLIENT_ID;
const SCHEDULE_CLIENT_SECRET = process.env.REACT_APP_SCHEDULE_CLIENT_SECRET;

const getToken = async () => {
  let refresh = true;
  let token = '';
  if (Object.prototype.hasOwnProperty.call(localStorage, 'schedule-access-token')) {
    const scheduleData = localStorage.getItem('schedule-access-token');
    const scheduleJson = JSON.parse(scheduleData);
    token = scheduleJson.access_token;
    refresh = false;
  }

  // check token first
  if (token !== '') {
    const ENDPOINT_URL = `${SCHEDULE_API_URL}/api/v1/check-token`;
    const axiosConfig = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios.get(ENDPOINT_URL, axiosConfig);
    } catch (error) {
      if (error.response.status === 401) {
        refresh = true;
      }
    }
  }

  if (refresh) {
    const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const TOKEN_URL = `${SCHEDULE_API_URL}/oauth/token`;
    const formData = {
      grant_type: 'client_credentials',
      client_id: SCHEDULE_CLIENT_ID,
      client_secret: SCHEDULE_CLIENT_SECRET,
      scope: '*',
    };

    const result = await axios.post(TOKEN_URL, qs.stringify(formData), axiosConfig);
    token = result.data.access_token;
    localStorage.setItem('schedule-access-token', JSON.stringify({ ...result.data }));
  }

  return { token };
};

export const showRecurring = () => ({ status: 'ok' });

export const storeRecurring = data =>
  new Promise((resolve, reject) => {
    getToken().then(access => {
      const ENDPOINT_URL = `${SCHEDULE_API_URL}/api/v1/donation-recurring`;
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Bearer ${access.token}`,
        },
      };
      const formData = {
        env: MODE,
        id: data.id,
        id_trx: data.id_trx,
        type: data.type,
        when: data.when,
        created_on: data.created_on,
      };

      axios
        .post(ENDPOINT_URL, qs.stringify(formData), axiosConfig)
        .then(result => {
          resolve(result.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  });

export const updateRecurring = (id, data) =>
  new Promise((resolve, reject) => {
    getToken().then(access => {
      const ENDPOINT_URL = `${SCHEDULE_API_URL}/api/v1/donation-recurring/${id}`;
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Bearer ${access.token}`,
        },
      };
      const formData = {
        type: data.type,
        when: data.when,
      };

      axios
        .patch(ENDPOINT_URL, qs.stringify(formData), axiosConfig)
        .then(result => {
          resolve(result.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  });

export const destroyRecurring = id =>
  new Promise((resolve, reject) => {
    getToken().then(access => {
      const ENDPOINT_URL = `${SCHEDULE_API_URL}/api/v1/donation-recurring/${id}`;
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Bearer ${access.token}`,
        },
      };

      axios
        .delete(ENDPOINT_URL, axiosConfig)
        .then(result => {
          resolve(result.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  });
