import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { CampaignProvider, MetricProvider, NonprofitProvider } from '../../Providers';
import { numberHelper, stringHelper } from '../../helpers';

import * as routes from '../../constants/routes';

import ImgCoverDefault from '../../Assets/Images/PlaceHolder.svg';

class MyImpactCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { nonprofitInfo: null, campaignInfo: null, metricCover: null, metricShowHumanUnit: 'Y' };
    this.handleDonation = this.handleDonation.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    NonprofitProvider.getDetail(item.nonprofit.key).then(nonprofitSnap => {
      const slugName = stringHelper.slugify(nonprofitSnap.val().name);
      const nonprofitInfo = {
        key: item.nonprofit.key,
        name: nonprofitSnap.val().name,
        img: nonprofitSnap.val().img,
        city: nonprofitSnap.val().city,
        state: nonprofitSnap.val().state,
        slugName,
        viewUrl: routes.NONPROFITS_ABOUT.replace(':id', item.nonprofit.key).replace(':name', slugName),
      };

      this.setState({ nonprofitInfo });
    });

    if (item.campaign) {
      CampaignProvider.getDetail(item.campaign.id).then(campaignSnap => {
        const slugName = stringHelper.slugify(campaignSnap.val().name);
        const campaignInfo = {
          id: item.campaign.id,
          name: campaignSnap.val().name,
          slugName,
          cover: campaignSnap.val().cover,
          location: campaignSnap.val().location,
          published: campaignSnap.val().published,
          viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', item.campaign.id).replace(':name', slugName),
        };

        this.setState({ campaignInfo });
      });
    }

    if (item.metric) {
      MetricProvider.getShowHumanUnit(item.nonprofit.key, item.metric.id).then(result => {
        this.setState({ metricShowHumanUnit: result.showHumanUnit });
      });
      MetricProvider.getDetailByPath(item.nonprofit.key, item.metric.id, 'cover').then(coverSnap => {
        if (coverSnap.val()) {
          this.setState({ metricCover: coverSnap.val() });
        }
      });
    }
  }

  handleDonation() {
    const { item, history } = this.props;
    const { nonprofitInfo, campaignInfo } = this.state;

    if (campaignInfo && campaignInfo.published) {
      history.push(routes.NONPROFITS_DONATION.replace(':id', nonprofitInfo.key).replace(':type', campaignInfo.id));
    } else {
      let url = routes.NONPROFITS_DONATION.replace(':id', nonprofitInfo.key).replace(':type', 'b');
      if (item.metric) {
        url += `?m=${item.metric.id}`;
      }

      history.push(url);
    }
  }

  render() {
    const { nonprofitInfo, campaignInfo, metricCover, metricShowHumanUnit } = this.state;
    const { item } = this.props;
    let nonprofitCover = null;
    let buttonText = 'Support More';
    let providedText = null;
    let coverUrl = nonprofitInfo ? nonprofitInfo.viewUrl : '/';

    if (item.metric) {
      const cost = Number.parseFloat(item.metric.cost);
      const costNumber = Number.parseInt(item.metric.costNumber, 10);
      const unitName = item.metric.unitName ? item.metric.unitName.trim() : 'pack';
      const humanNumber = item.metric.humanNumber ? Number.parseInt(item.metric.humanNumber, 10) : 0;
      const humanUnit = item.metric.humanUnit ? item.metric.humanUnit.trim() : 'person';

      const totalImpact = Number.parseFloat(item.total.amount) / cost;
      const totalPack = totalImpact * costNumber;
      const totalHuman = totalImpact * humanNumber;

      if (totalHuman > 0) {
        buttonText += ` ${stringHelper.makePluralize(humanUnit)}`;
      } else {
        buttonText += ` ${stringHelper.makePluralize(unitName)}`;
      }

      if (metricCover) {
        nonprofitCover = metricCover;
      }

      providedText = (
        <React.Fragment>
          You provided
          <span className="metric-provided f-lowercase">
            {totalHuman > 0 && metricShowHumanUnit === 'Y' ? (
              <React.Fragment>
                {` ${numberHelper.getThousandFormat(totalHuman.toFixed(2))} ${stringHelper.makePluralize(
                  humanUnit,
                  totalHuman
                )} with`}
              </React.Fragment>
            ) : null}
            {` ${numberHelper.getThousandFormat(totalPack.toFixed(2))} ${stringHelper.makePluralize(
              unitName,
              totalPack
            )}`}
          </span>
        </React.Fragment>
      );
    }

    if (campaignInfo) {
      const { cover } = campaignInfo;
      nonprofitCover = cover;
      coverUrl = campaignInfo.viewUrl;
      if (!campaignInfo.published) {
        buttonText = 'Provide Additional Support';
      }
    }

    if (!nonprofitCover && nonprofitInfo) {
      nonprofitCover = nonprofitInfo.img ? nonprofitInfo.img.cover : ImgCoverDefault;
    }

    return (
      <div className="impact-card">
        <Link to={coverUrl}>
          <div className="impact-card-cover" style={{ backgroundImage: `url("${nonprofitCover}")` }} />
        </Link>
        <div className="impact-card-info">
          <div className="impact-info">
            <div className="metric-cost">{numberHelper.getThousandFormat(item.total.amount, '$')}</div>
            <div className="metric-name">{providedText}</div>
          </div>
          {nonprofitInfo || campaignInfo ? (
            <button type="button" className="w-button" onClick={this.handleDonation}>
              {buttonText}
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

MyImpactCard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(MyImpactCard);
