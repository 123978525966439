import { database } from './firebase';

const dbPath = process.env.REACT_APP_FIREBASE_DB_PATH;

export const getRef = ref => database.ref(dbPath + ref);
export const pushRef = (ref, data) => database.ref(dbPath + ref).push(data);
export const pushEmptyRef = ref => database.ref(dbPath + ref).push();
export const setRef = (ref, data) => database.ref(dbPath + ref).set(data);
export const updateRef = updates => database.ref().update(updates);
export const removeRef = ref => database.ref(dbPath + ref).remove();
