import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { CSSTransition } from 'react-transition-group';
import { Progress } from 'react-sweet-progress';

import { UserProvider } from './Providers';

import SocialMediaButton from './Components/SocialMediaButton';

import * as routes from './constants/routes';

import 'animate.css/animate.min.css';
import 'react-sweet-progress/lib/style.css';
import './Assets/Styles/Register.css';

class Register extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      errorSignUp: false,
      errorMessage: '',
      submitButton: 'Continue',
      disabled: false,
      featureTitle: 'Get Started',
      featureTitleDesc: 'Just one minute away from experiencing Fundra!',
      percent: 30,
    };

    this.state = this.initialState;
    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer } = this.props;
    updateNavStatus('logosupport');
    showFooterLinkContainer(false);
  }

  errorPushingData(err) {
    this.setState({
      errorSignUp: true,
      errorMessage: err.message ? err.message : `Something wrong when pushing the data.\n[${err}]`,
      submitButton: 'Continue',
      disabled: false,
      featureTitle: 'Get Started',
      featureTitleDesc: 'Just one minute away from experiencing Fundra!',
      percent: 30,
    });
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      errorSignUp: false,
      errorMessage: '',
      submitButton: 'Continue',
      disabled: false,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.signUp();
  }

  signUp() {
    const { firstName, lastName, email, phone, password } = this.state;

    this.setState({
      submitButton: 'Please wait...',
      disabled: true,
    });

    const userData = {
      firstName,
      lastName,
      email,
      phone,
      password,
    };

    UserProvider.registerUser(userData).then(result => {
      if (result.status === 'ok') {
        this.setState({ percent: 100 });
        setTimeout(() => {
          const { history } = this.props;
          history.push(`${routes.SIGN_IN}?action=signup-success`);
        }, 3000);
      } else {
        document.location.href = '#top';
        this.errorPushingData(result.err);
      }
    });
  }

  validatePassword(event) {
    const confirmField = event.target;
    const { password } = this.state;

    if (password !== confirmField.value) {
      confirmField.setCustomValidity("Passwords Don't Match");
    } else {
      confirmField.setCustomValidity('');
    }
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      confirmPassword,
      errorSignUp,
      errorMessage,
      submitButton,
      disabled,
      featureTitle,
      featureTitleDesc,
      percent,
    } = this.state;

    document.title = 'Register - Fundra';

    return (
      <div id="top" className="RegisterSection">
        <div className="contentwrapper">
          <h2 className="h2 featuretitle registertitle">{featureTitle}</h2>
          <div className="featuretitledesc">{featureTitleDesc}</div>
          <div className="progressbar">
            <Progress percent={percent} theme={{ default: { symbol: ' ' } }} />
          </div>
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={400}>
            <div className="formlogin w-form register">
              <form id="register-form" name="register-form" action="submitform" onSubmit={this.handleSubmit}>
                <div className="w-row">
                  <div className="w-col w-col-6 w-col-stack">
                    <label htmlFor="firstName" className="formfieldlabel">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="formtextfield small w-input input90"
                      maxLength="256"
                      name="firstName"
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="w-col w-col-6 w-col-stack">
                    <label htmlFor="lastName" className="formfieldlabel">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="formtextfield small w-input"
                      maxLength="256"
                      name="lastName"
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
                <label htmlFor="email" className="formfieldlabel">
                  Email address
                </label>
                <input
                  type="email"
                  className="formtextfield small w-input"
                  maxLength="256"
                  name="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor="phone" className="formfieldlabel">
                  Phone number
                </label>
                <input
                  type="tel"
                  className="formtextfield small w-input"
                  maxLength="256"
                  name="phone"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={this.handleChange}
                />
                <label htmlFor="password" className="formfieldlabel">
                  Password
                </label>
                <input
                  type="password"
                  className="formtextfield small w-input"
                  maxLength="256"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={this.handleChange}
                  required
                />
                <label htmlFor="confirmpassword" className="formfieldlabel">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="formtextfield small w-input"
                  maxLength="256"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={this.handleChange}
                  onKeyUp={this.validatePassword}
                  required
                />
                <CSSTransition in={errorSignUp} timeout={500} classNames="errormessage" unmountOnExit>
                  {() => (
                    <div className="error-block">
                      <div className="error-block-text">{errorMessage}</div>
                    </div>
                  )}
                </CSSTransition>
                <input type="submit" value={submitButton} className="formsubmit w-button" disabled={disabled} />
                <div className="registerlinkcontainer">
                  {'Already have an account? '}
                  <Link className="registerlink" to="/signin">
                    Sign In
                  </Link>
                </div>
              </form>
              <SocialMediaButton caption="Sign Up" />
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default Register;
