import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from 'react-loader-spinner';

import { database } from '../firebase';

import * as routes from '../constants/routes';
import withNonprofitDetail from '../withNonprofitDetail';

import NonprofitLeftDetail from '../Components/NonprofitLeftDetail';
import SocialShareComponent from '../Components/SocialShare';
import DonorTable from '../Components/DonorTable';
import NonprofitHeaderMobile from '../Components/NonprofitHeaderMobile';
import NonprofitAboutMobile from '../Components/NonprofitAboutMobile';
import NonprofitProgramsMobile from '../Components/NonprofitProgramsMobile';
import NonprofitCampaignsMobile from '../Components/NonprofitCampaignsMobile';
import NonprofitDonorsMobile from '../Components/NonprofitDonorsMobile';
import NonprofitFinancialsMobile from '../Components/NonprofitFinancialsMobile';
import NonprofitStickyButton from '../Components/NonprofitStickyButton';

import ImgEmptyDonors from '../Assets/Images/Illustration-3.svg';
import ImgSearch from '../Assets/Images/Fill10.svg';

import 'react-tabs/style/react-tabs.css';
import '../Assets/Styles/Nonprofits-Donor.css';

class Donors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        agent: null,
        address: '',
        category: '',
        charityNavigatorURL: '',
        city: '',
        collect: '',
        contact: '',
        description: '',
        favorite: false,
        federal: '',
        img: null,
        key: '',
        name: '',
        slugName: '',
        publish: '',
        rating: [],
        ratingStar: [],
        tagline: '',
        state: '',
        type: '',
        website: '',
        zipcode: '',
        viewUrl: '',
        viewUrlDonor: '',
        stripe_account: null,
      },
      donorLoad: true,
      donors: [],
      donorsCampaign: [],
      searchQuery: '',
      donorsEmpty: false,
      filteredDonors: [],
      filteredDonorsCampaign: [],
      totalCount: 0,
      totalText: 'community',
      tabIndex: 0,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.searchEnter = this.searchEnter.bind(this);
    this.handleDonation = this.handleDonation.bind(this);

    this.sidebarRef = React.createRef();
    this.bodyContentRef = React.createRef();
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, nonprofit } = this.props;
    updateNavStatus('');
    showFooterLinkContainer(false);

    this.setState(prevState => {
      const { item } = prevState;
      Object.keys(item).forEach(key => {
        item[key] = nonprofit[key];
      });

      return { item };
    });

    if (!nonprofit.hideDonation) {
      this.getFinancial(nonprofit.key);
    } else {
      this.setState({ donorLoad: false });
    }
  }

  async getFinancial(key) {
    const donors = [];
    const donorsCampaign = [];
    const donationSnap = await database
      .getRef(`/donations/${key}`)
      .orderByKey()
      .once('value');
    donationSnap.forEach(snap => {
      let dateObj = null;
      if (snap.val().date) {
        dateObj = new Date(snap.val().date);
      }

      const donorTo = snap.val().program ? snap.val().program.name : snap.val().nonprofit.name;
      const userInfo = snap.val().user;
      userInfo.fullName = userInfo.anonymous ? 'Anonymous Donor' : `${userInfo.firstName} ${userInfo.lastName}`;
      userInfo.dedication = snap.val().dedication;
      userInfo.program = snap.val().program;
      userInfo.campaign = snap.val().campaign;
      userInfo.donorTo = snap.val().campaign ? snap.val().campaign.name : donorTo;
      userInfo.date = dateObj;
      userInfo.total = snap.val().total;
      if (snap.val().recurring) {
        switch (snap.val().recurring.when) {
          case 3:
            userInfo.recurring = 'Monthly';
            userInfo.recurringStyle = 'f-info-box';
            break;
          case 2:
            userInfo.recurring = 'Bi-Weekly';
            userInfo.recurringStyle = 'f-success-box';
            break;
          default:
            userInfo.recurring = 'Weekly';
            userInfo.recurringStyle = 'f-default-box';
            break;
        }
      } else {
        userInfo.recurring = '-';
        userInfo.recurringStyle = 'empty';
      }

      userInfo.idTrx = snap.val().idTrx;

      if (snap.val().program) {
        donors.push(userInfo);
      }
      if (snap.val().campaign) {
        donorsCampaign.push(userInfo);
      }
    });

    if (donors.length > 0) {
      donors.reverse();
    }
    if (donorsCampaign.length > 0) {
      donorsCampaign.reverse();
    }

    this.setState({
      donors,
      donorsCampaign,
      donorsEmpty: donors.length === 0,
      filteredDonors: donors,
      filteredDonorsCampaign: donorsCampaign,
      donorLoad: false,
      totalCount: donors.length,
    });
  }

  handleFilterChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  handleTabChange(tabIndex) {
    const { filteredDonors, filteredDonorsCampaign } = this.state;
    const totalCount = tabIndex === 0 ? filteredDonors.length : filteredDonorsCampaign.length;
    const totalText = tabIndex === 0 ? 'community' : 'campaign';

    this.setState({ tabIndex, totalText, totalCount });
  }

  handleDonation() {
    const { history } = this.props;
    const { item } = this.state;

    history.push(routes.NONPROFITS_DONATION.replace(':id', item.key).replace(':type', 'b'));
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      const { donors, donorsCampaign, tabIndex } = this.state;
      const filteredDonors = donors.filter(
        donor => donor.fullName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
      );
      const filteredDonorsCampaign = donorsCampaign.filter(
        donor => donor.fullName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
      );

      const totalCount = tabIndex === 0 ? filteredDonors.length : filteredDonorsCampaign.length;
      const totalText = tabIndex.tabIndex === 0 ? 'community' : 'campaign';

      this.setState({
        filteredDonors,
        filteredDonorsCampaign,
        totalText,
        totalCount,
      });
    }
  }

  render() {
    const {
      item,
      totalCount,
      totalText,
      tabIndex,
      donorLoad,
      filteredDonors,
      filteredDonorsCampaign,
      donorsEmpty,
    } = this.state;
    const { authUser } = this.props;
    const metaDescription =
      item.description.length > 160 ? item.description.substring(0, 160).concat('...') : item.description;

    return (
      <div className="NonprofitAboutSection">
        {item.name !== '' ? (
          <Helmet>
            <title>{`Fundra - ${item.name} :: Donors`}</title>
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={`Fundra - ${item.name} :: Donors`} />
            <meta property="og:description" content={metaDescription} />
          </Helmet>
        ) : null}
        <div className="sectionhero">
          <div className="contentwrapper herosectionfeatures nonprofitsection">
            <div className="w-row nonprofit-detail">
              <div className="w-col w-col-3 w-col-stack f-text-center leftnav" ref={this.sidebarRef}>
                <NonprofitLeftDetail item={item} authUser={authUser} withHash />
              </div>
              <div className="w-col w-col-9 w-col-stack nonprofitbodycontent" ref={this.bodyContentRef}>
                <div className="w-row">
                  <div className="w-col w-col-5 w-col-stack">
                    <h3 className="h3 nonprofitabouttitle">List of donors</h3>
                    <label className="joined">{`Displaying ${totalCount} ${totalText} donors`}</label>
                  </div>
                  <div className="w-col w-col-4 w-col-stack">
                    {item.name !== '' ? (
                      <SocialShareComponent
                        url={item.viewUrlDonor}
                        title={item.name}
                        description={item.description}
                        noCount
                      />
                    ) : null}
                  </div>
                  <div className="w-col w-col-3 w-col-stack donorsearch">
                    <div className="graygroupinput" style={{ marginTop: 33 }}>
                      <div className="searchinput">
                        <input
                          name="searchQuery"
                          type="text"
                          placeholder="Search donors"
                          onChange={this.handleFilterChange}
                          onKeyPress={this.searchEnter}
                        />
                      </div>
                      <div className="searchicon">
                        <img src={ImgSearch} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-row" style={{ marginTop: 43 }}>
                  <div className="w-col w-col-12 w-col-stack" ref={this.contentDetailRef}>
                    <Tabs selectedIndex={tabIndex} onSelect={this.handleTabChange}>
                      <TabList>
                        <Tab>Community donors</Tab>
                        <Tab>Campaign donors</Tab>
                      </TabList>
                      <TabPanel>
                        {donorLoad ? (
                          <div className="section-loader-data">
                            <div className="section-loader-container">
                              <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {!donorsEmpty ? (
                              <DonorTable items={filteredDonors} />
                            ) : (
                              <div className="donors-empty">
                                <img src={ImgEmptyDonors} alt="" />
                                <h3 className="h3 nonprofitabouttitle">Giving is good</h3>
                                <label className="joined">Donate and make a difference today!</label>
                                <button type="button" className="w-button" onClick={this.handleDonation}>
                                  Donate
                                </button>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </TabPanel>
                      <TabPanel>
                        {donorLoad ? (
                          <div className="section-loader-data">
                            <div className="section-loader-container">
                              <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {!donorsEmpty ? <DonorTable items={filteredDonorsCampaign} /> : null}
                          </React.Fragment>
                        )}
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {item.key !== '' ? (
              <div className="nonprofit-mobile-detail">
                <NonprofitHeaderMobile item={item} />
                <Tabs defaultIndex={3}>
                  <TabList>
                    <Tab>About</Tab>
                    <Tab>Programs</Tab>
                    <Tab>Campaigns</Tab>
                    <Tab>Donors</Tab>
                    <Tab>Financials</Tab>
                  </TabList>
                  <TabPanel>
                    <NonprofitAboutMobile item={item} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitProgramsMobile nid={item.key} />
                  </TabPanel>
                  <TabPanel>
                    <NonprofitCampaignsMobile nid={item.key} />
                  </TabPanel>
                  <TabPanel>
                    {filteredDonors.length > 0 ? <NonprofitDonorsMobile donors={filteredDonors} /> : null}
                  </TabPanel>
                  <TabPanel>
                    <NonprofitFinancialsMobile item={item} />
                  </TabPanel>
                </Tabs>
                <NonprofitStickyButton nid={item.key} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

Donors.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

Donors.defaultProps = {
  authUser: null,
};

export default withNonprofitDetail(Donors);
