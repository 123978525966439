import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { database } from '../firebase';
import { stringHelper, numberHelper } from '../helpers';

import ImgAnonymous from '../Assets/Images/anonymous_1.png';

export default class DonorInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: ImgAnonymous,
    };
  }

  componentDidMount() {
    this.getUserAvatar();
  }

  async getUserAvatar() {
    const { item: user } = this.props;
    const firstInitial = user.firstName.substring(0, 1);
    const lastInitial = user.lastName.substring(0, 1);
    let avatar = `https://via.placeholder.com/150.png?text=${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;

    if (user.anonymous) {
      avatar = ImgAnonymous;
    } else {
      const avatarSnap = await database.getRef(`/profile/${user.uid}/avatar`).once('value');
      if (avatarSnap.val()) {
        avatar = avatarSnap.val();
      }
    }

    this.setState({ avatar });
  }

  render() {
    const { item: user, viewType, donorsURL, simple, showRecurring, showDate, metricInfo } = this.props;
    const { avatar } = this.state;
    const totalAmount = user.total
      ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(user.total.amount)
      : 0;
    let amountText = '';
    if (user.metric) {
      // eslint-disable-next-line max-len
      const showHumanUnit = metricInfo ? metricInfo[user.metric.id].showHumanUnit : 'Y';
      const totalImpact = Number.parseFloat(user.total.amount) / Number.parseFloat(user.metric.cost);
      const totalPack = totalImpact * Number.parseInt(user.metric.costNumber, 10);
      const totalHuman = totalImpact * Number.parseInt(user.metric.humanNumber, 10);
      amountText = `$${totalAmount} supported ${numberHelper.getThousandFormat(
        totalPack.toFixed(2)
      )} ${stringHelper.makePluralize(user.metric.unitName, totalPack)}`;
      if (showHumanUnit === 'Y') {
        amountText += ` to ${numberHelper.getThousandFormat(totalHuman.toFixed(2))} ${stringHelper.makePluralize(
          user.metric.humanUnit,
          totalHuman
        )}`;
      }
    } else {
      amountText = `$${totalAmount} to ${user.donorTo}`;
    }

    let dedicationLabel = null;
    if (user.dedication) {
      let dedicationText = `${user.dedication.type.title} `;
      if (
        user.dedication.type.id === 1 &&
        user.dedication.value
          .toLowerCase()
          .trim()
          .startsWith('to')
      ) {
        dedicationText += user.dedication.value
          .toLowerCase()
          .replace('to', '')
          .trim();
      } else {
        dedicationText += user.dedication.value;
      }
      dedicationLabel = (
        <label className={viewType === 'mobile-row' ? 'donor-amount' : 'f-font-regular'}>
          <i>{dedicationText}</i>
        </label>
      );
    }

    let ViewRender;

    switch (viewType) {
      case 'row':
        ViewRender = (
          <tr>
            <td>
              <div className="flexbox-align-center">
                <div className="item" style={{ marginRight: 5 }}>
                  <img className="donor-pic" src={avatar} alt="" title={user.fullName} />
                </div>
                <div className="item">{user.fullName}</div>
              </div>
            </td>
            <td className="text-bold">
              <span className="f-lowercase">{amountText}</span>
              {dedicationLabel}
            </td>
            {showRecurring ? (
              <td>
                <div className={`donor-recurring ${user.recurringStyle}`}>{user.recurring}</div>
              </td>
            ) : null}
            <td>{user.donorTo}</td>
            {showDate ? <td>{user.date.toLocaleDateString()}</td> : null}
          </tr>
        );
        break;

      case 'div-row':
        ViewRender = (
          <div className="w-row donorprofile">
            <Link to={donorsURL}>
              <div className="w-col w-col-2 w-col-stack">
                <div className="donorprofile-container">
                  <img src={avatar} alt="" title={user.fullName} />
                </div>
              </div>
            </Link>
            <Link to={donorsURL} className="donorlink">
              <div className="w-col w-col-10 w-col-stack donordetail">
                <label className="donorname" title={user.fullName}>
                  {user.fullName.length > 14 ? user.fullName.substring(0, 11).concat('...') : user.fullName}
                </label>
                <label className="donoramount">{`$${totalAmount} to ${user.donorTo}`}</label>
                <label className="donordate">{user.date.toLocaleDateString()}</label>
              </div>
            </Link>
          </div>
        );
        break;

      case 'card':
        ViewRender = (
          <div className="pic-layer">
            <img src={avatar} alt="" title={user.fullName} />
          </div>
        );
        break;

      case 'mobile-row':
        if (simple) {
          amountText = `Donated $${totalAmount}`;
        }

        ViewRender = (
          <div className="donor-profile">
            <div className="donor-detail pic">
              <img src={avatar} alt="" title={user.fullName} />
            </div>
            <div className="donor-detail full-name">
              <label className="donor-name" title={user.fullName}>
                {user.fullName}
              </label>
              <label className="donor-amount">{amountText}</label>
              {dedicationLabel}
            </div>
            <div className="donor-detail date-caption">
              <label className="donor-date">{user.date.toLocaleDateString()}</label>
            </div>
          </div>
        );
        break;

      default:
        break;
    }

    return ViewRender;
  }
}

DonorInfo.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  viewType: PropTypes.string,
  donorsURL: PropTypes.string,
  simple: PropTypes.bool,
  showRecurring: PropTypes.bool,
  showDate: PropTypes.bool,
  metricInfo: PropTypes.instanceOf(Object),
};

DonorInfo.defaultProps = {
  viewType: '',
  donorsURL: '',
  simple: false,
  showRecurring: false,
  showDate: false,
  metricInfo: null,
};
