import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Flipped } from 'react-flip-toolkit';

import { database } from '../firebase';
import { stringHelper, numberHelper } from '../helpers';
import { MetricProvider } from '../Providers';

import * as routes from '../constants/routes';

import ImgAnonymous from '../Assets/Images/anonymous_1.png';

export default class CampaignInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: ImgAnonymous,
      raised: 0.0,
      metricUnit: '',
      viewUrl: '',
    };
  }

  componentDidMount() {
    const { item, showMetric } = this.props;
    const slugName = stringHelper.slugify(item.name);

    this.setState(
      {
        viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', item.id).replace(':name', slugName),
        raised: item.raised ? item.raised : 0.0,
      },
      () => {
        this.getUserAvatar();
        if (item.raised === 0) {
          this.getFinancial();
        } else if (showMetric && item.metric) {
          this.getMetric();
        }
      }
    );
  }

  async getUserAvatar() {
    const { item } = this.props;
    const { user } = item;
    const firstInitial = user.firstName.substring(0, 1);
    const lastInitial = user.lastName.substring(0, 1);
    let avatar = `https://via.placeholder.com/150.png?text=${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;

    if (user.anonymous) {
      avatar = ImgAnonymous;
    } else {
      const avatarSnap = await database.getRef(`/profile/${user.uid}/avatar`).once('value');
      if (avatarSnap.val()) {
        avatar = avatarSnap.val();
      }
    }

    this.setState({ avatar });
  }

  getFinancial() {
    const { item, showMetric } = this.props;
    const { id } = item;
    const key = item.nonprofit.id;
    let totalRaised = 0.0;

    database
      .getRef(`/donations/${key}`)
      .orderByChild('campaign/id')
      .equalTo(String(id))
      .once('value')
      .then(donationSnap => {
        donationSnap.forEach(snap => {
          totalRaised += parseFloat(snap.val().total.amount);
        });

        this.setState({ raised: totalRaised }, () => {
          if (showMetric && item.metric) {
            this.getMetric();
          }
        });
      });
  }

  getMetric() {
    const { raised } = this.state;
    const { item } = this.props;
    MetricProvider.getDetail(item.nonprofit.id, item.metric).then(snap => {
      if (snap.val()) {
        const totalRaised = Number.parseFloat(raised);
        const metricInfoCost = Number.parseFloat(snap.val().cost);
        const metricInfoCostNumber = Number.parseInt(snap.val().costNumber, 10);
        const metricInfoUnitName = snap.val().unitName ? snap.val().unitName.trim() : '';

        let raisedPack = 0;
        if (metricInfoCost > 0) {
          raisedPack = Math.floor((totalRaised / metricInfoCost) * metricInfoCostNumber);
        }

        const metricUnit = `${numberHelper.getThousandFormat(raisedPack.toFixed(2))} ${stringHelper.makePluralize(
          metricInfoUnitName,
          raisedPack
        )}`;

        this.setState({ metricUnit });
      }
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { item } = this.props;
    if (item.raised !== nextProps.item.raised) {
      this.setState({ raised: nextProps.item.raised }, () => {
        this.getMetric();
      });
    }
  }

  render() {
    const { item, viewType, hideRaised, hideOrganizer, hideDate, showMetric } = this.props;
    const { avatar, raised, metricUnit, viewUrl } = this.state;
    let ViewRender;
    switch (viewType) {
      case 'row':
        ViewRender = (
          <Flipped flipId={item.id}>
            <tr>
              {!hideOrganizer ? (
                <td>
                  <img className="donor-pic" src={avatar} alt="" title={item.user.fullName} />
                  {` ${item.user.fullName}`}
                </td>
              ) : null}
              <td>
                <span title={item.name}>
                  {item.name.length > 40 ? item.name.substring(0, 37).concat('...') : item.name}
                </span>
              </td>
              {!hideRaised ? <td>{numberHelper.getThousandFormat(raised.toFixed(2), '$')}</td> : null}
              {showMetric ? <td>{metricUnit}</td> : null}
              {!hideDate ? <td>{item.date.toLocaleDateString()}</td> : null}
              <td>
                <Link to={viewUrl} className="buttondefault f-btn-default-ms">
                  See details
                </Link>
              </td>
            </tr>
          </Flipped>
        );
        break;

      default:
        break;
    }

    return ViewRender;
  }
}

CampaignInfo.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  viewType: PropTypes.string,
  hideOrganizer: PropTypes.bool,
  hideRaised: PropTypes.bool,
  hideDate: PropTypes.bool,
  showMetric: PropTypes.bool,
};

CampaignInfo.defaultProps = {
  viewType: '',
  hideRaised: false,
  hideOrganizer: false,
  hideDate: false,
  showMetric: false,
};
