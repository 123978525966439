import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { dateTimeHelper, numberHelper, stringHelper } from '../helpers';
import { NonprofitProvider, MetricProvider } from '../Providers';

import * as routes from '../constants/routes';

import Favorite from './Favorite';
import SocialShareComponent from './SocialShare';

import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';
import ImgClock from '../Assets/Images/clock.svg';
import { ReactComponent as ImgLoveComponent } from '../Assets/Images/LoveIconSmall-White.svg';

import 'react-sweet-progress/lib/style.css';

class NonprofitCampaignCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      likes: null,
      totalLikes: 0,
      name: '',
      cover: [],
      nonprofit: [],
      nonprofitInfo: null,
      metric: null,
      endDate: null,
      viewUrl: '',
    };

    this.overlayRef = React.createRef();
    this.campaignTrendingRef = React.createRef();
    this.handleDonation = this.handleDonation.bind(this);
    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.handleUpdateLikes = this.handleUpdateLikes.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    const slugName = stringHelper.slugify(item.name);

    this.setState(
      {
        id: item.id,
        likes: item.likes,
        totalLikes: item.likes ? Object.keys(item.likes).length : 0,
        name: item.name,
        cover: item.cover,
        nonprofit: item.nonprofit,
        endDate: item.endDate,
        expired: item.endDate ? dateTimeHelper.isBeforeNow(item.endDate) : false,
        viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', item.id).replace(':name', slugName),
      },
      () => {
        this.getNonprofitInfo();
        this.getMetricInfo(item.nonprofit.key, item.metric);
      }
    );
  }

  getNonprofitInfo() {
    const { nonprofit } = this.state;
    NonprofitProvider.getDetail(nonprofit.key).then(snap => {
      const slugName = stringHelper.slugify(snap.val().name);
      const nonprofitInfo = {
        key: snap.key,
        name: snap.val().name,
        city: snap.val().city,
        img: snap.val().img,
        state: snap.val().state,
        slugName,
        viewUrl: routes.NONPROFITS_ABOUT.replace(':id', snap.key).replace(':name', slugName),
      };

      this.setState({ nonprofitInfo });
    });
  }

  getMetricInfo(nid, id) {
    if (id) {
      MetricProvider.getDetail(nid, id).then(snap => {
        if (snap.val()) {
          const metric = {
            id: snap.key,
            name: snap.val().name,
            cost: Number.parseFloat(snap.val().cost),
            costNumber: Number.parseInt(snap.val().costNumber, 10),
            unitName: snap.val().unitName ? snap.val().unitName.trim() : '',
            humanNumber: snap.val().humanNumber ? Number.parseInt(snap.val().humanNumber, 10) : 0,
            humanUnit: snap.val().humanUnit ? snap.val().humanUnit.trim() : '',
            frequency: snap.val().frequency,
          };

          this.setState({ metric });
        }
      });
    }
  }

  handleDonation() {
    const { history } = this.props;
    const { nonprofit, id } = this.state;

    history.push(routes.NONPROFITS_DONATION.replace(':id', nonprofit.key).replace(':type', id));
  }

  handleOnMouseEnter() {
    this.overlayRef.current.classList.add('hover');
    this.campaignTrendingRef.current.classList.add('hover');
  }

  handleOnMouseLeave() {
    this.overlayRef.current.classList.remove('hover');
    this.campaignTrendingRef.current.classList.remove('hover');
  }

  handleUpdateLikes(increment) {
    this.setState(state => ({
      totalLikes: state.totalLikes + increment,
    }));
  }

  render() {
    const { id, cover, likes, totalLikes, name, viewUrl, metric, nonprofitInfo, endDate, expired } = this.state;
    const { authUser, withHeader, socialShare } = this.props;
    const campaignCover = cover !== '' ? cover : ImgCoverDefault;
    let metricProvide = null;
    let frequencyText = null;

    if (metric && metric.cost > 0) {
      const { frequency } = metric;
      metricProvide = (
        <div>
          Can provide
          <span className="f-lowercase">
            {metric.humanNumber > 0 ? (
              <>
                {` ${numberHelper.getThousandFormat(metric.humanNumber.toFixed(0))} ${stringHelper.makePluralize(
                  metric.humanUnit,
                  metric.humanNumber
                )} with`}
              </>
            ) : null}
            {` ${numberHelper.getThousandFormat(metric.costNumber.toFixed(0))} ${stringHelper.makePluralize(
              metric.unitName,
              metric.costNumber
            )}`}
          </span>
        </div>
      );

      if (frequency && Number.parseInt(frequency.number, 10) > 0 && frequency.name !== '') {
        frequencyText = (
          <div className="frequency">
            {` / ${Number.parseInt(frequency.number, 10)} ${stringHelper.makePluralize(
              frequency.name,
              Number.parseInt(frequency.number, 10)
            )}`}
          </div>
        );
      }
    }

    return (
      <div className={`campaign-card${!withHeader ? ' no-header' : ''}`}>
        {expired ? <div className="f-overlay-bg-white" /> : null}
        {withHeader ? (
          <div className="campaign-card-header">
            {nonprofitInfo ? (
              <>
                <Link to={nonprofitInfo.viewUrl}>
                  <div className="nonprofit-logo" style={{ backgroundImage: `url("${nonprofitInfo.img.logo}")` }} />
                </Link>
                <div className="nonprofit-info">
                  <div className="title">{nonprofitInfo.name}</div>
                  <div className="location">{`${nonprofitInfo.city}, ${nonprofitInfo.state}`}</div>
                </div>
                <div className="nonprofit-favorite" style={expired ? { zIndex: -1 } : null}>
                  <Favorite
                    authUser={authUser}
                    likes={likes}
                    type={2}
                    id={id.toString()}
                    updateLikes={this.handleUpdateLikes}
                    small
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : null}
        <Link to={viewUrl} onMouseEnter={this.handleOnMouseEnter} onMouseLeave={this.handleOnMouseLeave}>
          <div className="campaign-card-cover" style={{ backgroundImage: `url("${campaignCover}")` }}>
            <div className={`f-overlay-bg-hover${expired ? ' bg-white' : ''}`} ref={this.overlayRef} />
            <div className="campaign-trending" ref={this.campaignTrendingRef}>
              <div className="campaign-like">
                <ImgLoveComponent />
                {totalLikes}
              </div>
            </div>
          </div>
        </Link>
        <div className="campaign-card-info">
          <div>
            <div className="metric-info">
              {metric ? (
                <div className="pricing">
                  <div className="metric-cost">{numberHelper.getThousandFormat(metric.cost, '$')}</div>
                  {frequencyText}
                </div>
              ) : null}
              {endDate ? (
                <div className="count-down" style={expired ? { zIndex: 1 } : null}>
                  <img src={ImgClock} alt="" />
                  <span className="time-expired">
                    {expired ? 'Expired' : `Exp. ${dateTimeHelper.getTimeFromNow(endDate)}`}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="can-provide">{metricProvide || name}</div>
          </div>
          {socialShare ? <SocialShareComponent url={viewUrl} title={name} campaignCard noCount /> : null}
          <button type="button" className="w-button f-btn-danger" onClick={this.handleDonation}>
            Support
          </button>
        </div>
      </div>
    );
  }
}

NonprofitCampaignCard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
  withHeader: PropTypes.bool,
  socialShare: PropTypes.bool,
};

NonprofitCampaignCard.defaultProps = {
  authUser: null,
  withHeader: false,
  socialShare: false,
};

export default withRouter(NonprofitCampaignCard);
