import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ImgLogoDefault from '../Assets/Images/Media_Icon.svg';
import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';

export default class ProgramHeaderMobile extends Component {
    render() {
        const nonprofitLogo = (this.props.item.img) ? this.props.item.img.logo : ImgLogoDefault;
        const programCover = (this.props.program.cover) ? this.props.program.cover : ImgCoverDefault;
        const programName = this.props.program.name;

        return (
            <div>
                <div className="program-cover">
                    <img src={programCover} alt={`${this.props.program.name} Cover`} />
                </div>
                <div className="nonprofit-header flexbox-align-center">
                    <div className="item">
                        <div className="nonprofit-logo">
                            <Link to={this.props.item.viewUrl}><img src={nonprofitLogo} alt={`${this.props.item.name} Logo`} /></Link>
                        </div>
                    </div>
                    <div className="item">
                        <div className="nonprofit-name">
                            <span className="street">{this.props.item.name}</span>
                            <span className="title">{programName}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
