import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';

import Favorite from './Favorite';
import SocialShareComponent from './SocialShare';

import * as routes from '../constants/routes';

import ImgLogoDefault from '../Assets/Images/Media_Icon.svg';
import ImgCertified from '../Assets/Images/ico-certified.svg';

import { ReactComponent as ImgAboutInActive } from '../Assets/Images/Icon_About_Inactive.svg';
import { ReactComponent as ImgCampaignInactive } from '../Assets/Images/Icon_Campaigns_Inactive.svg';
import { ReactComponent as ImgProgramsInactive } from '../Assets/Images/Icon_Programs_Inactive.svg';

const ScrollLink = Scroll.Link;

class NonprofitLeftDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      authUser: null,
    };

    this.handleDonation = this.handleDonation.bind(this);
  }

  componentDidMount() {
    const { item, authUser } = this.props;
    this.setState({
      item,
      authUser,
    });
  }

  handleDonation() {
    const { item } = this.state;
    const { campaignId } = this.props;
    const typeKey = campaignId !== '' ? campaignId : 'b';
    window.location = routes.NONPROFITS_DONATION.replace(':id', item.key).replace(':type', typeKey);
  }

  render() {
    const { item, authUser } = this.state;
    const { withHash } = this.props;
    const nonprofitLogo = item && item.img ? item.img.logo : ImgLogoDefault;

    let whyUs = 'whyus';
    let whatFor = 'whatfor';
    let whyNow = 'whynow';
    if (withHash && item) {
      const routeLink = routes.NONPROFITS_ABOUT.replace(':id', item.key).replace(':name', item.slugName);
      whyUs = `${routeLink}#whyus`;
      whatFor = `${routeLink}#whatfor`;
      whyNow = `${routeLink}#whynow`;
    }

    return (
      <div className="NonprofitLeftDetailSection">
        {item ? (
          <>
            {authUser ? (
              <>
                <div className="nonprofit-favorite">
                  <Favorite authUser={authUser} likes={item.likes} type={1} id={item.key.toString()} />
                </div>
                <div className="nonprofit-fav-mobile" style={{ marginTop: 10 }}>
                  <Favorite authUser={authUser} likes={item.likes} type={1} id={item.key.toString()} small />
                </div>
              </>
            ) : null}
            <div className="nonprofit-info-container">
              <div className="nonprofit-logo">
                <img src={nonprofitLogo} alt={`${item.name} Logo`} />
                {item.agent ? (
                  <div className="certified">
                    <img src={ImgCertified} alt="" />
                  </div>
                ) : null}
              </div>
              <div className="nonprofit-info">
                <div className="nonprofit-name category-type">{item.name}</div>
                <div className="nonprofit-city category-type">{item.type}</div>
              </div>
            </div>
            {item.key !== '' ? (
              <div className="nonprofit-donate">
                <button type="button" className="w-button" onClick={this.handleDonation}>
                  Support
                </button>
              </div>
            ) : null}
            <div className="nonprofit-nav-share-left">
              <SocialShareComponent
                url={item.viewUrl}
                title={item.name}
                description={item.description}
                webLink={item.website}
                noCount
              />
            </div>
            {item.agent ? (
              <div className="certified-left">
                <img src={ImgCertified} alt="" />
                {' Certified'}
              </div>
            ) : null}
            {item.key !== '' ? (
              <div className="sidebarmenu">
                <ul>
                  <li>
                    <MenuLink to={whyUs} scroll={!withHash}>
                      <ImgAboutInActive />
                      <span> Why Us ?</span>
                    </MenuLink>
                  </li>
                  <li>
                    <MenuLink to={whyNow} scroll={!withHash}>
                      <ImgProgramsInactive />
                      <span> Why now ?</span>
                    </MenuLink>
                  </li>
                  <li>
                    <MenuLink to={whatFor} scroll={!withHash}>
                      <ImgCampaignInactive />
                      <span> What for ?</span>
                    </MenuLink>
                  </li>
                </ul>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
}

const MenuLink = ({ children, to, scroll }) => {
  if (scroll) {
    return (
      <ScrollLink activeClass="active" containerId="containerElement" to={to} spy smooth duration={1000} delay={300}>
        {children}
      </ScrollLink>
    );
  }

  return <HashLink to={to}>{children}</HashLink>;
};

MenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  scroll: PropTypes.bool,
};

MenuLink.defaultProps = {
  scroll: true,
};

NonprofitLeftDetail.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
  withHash: PropTypes.bool,
  campaignId: PropTypes.string,
};

NonprofitLeftDetail.defaultProps = {
  authUser: null,
  withHash: false,
  campaignId: '',
};

export default NonprofitLeftDetail;
