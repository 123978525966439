import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import Loader from 'react-loader-spinner';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';

import { stringHelper } from '../../helpers';
import { NonprofitProvider } from '../../Providers';

import { UploadButton } from '../HtmlTag';

import imgLogo from '../../Assets/Images/Media_Icon.svg';
import imgPlaceHolder from '../../Assets/Images/PlaceHolder.svg';
import EditIcon from '../../Assets/Images/edit-2.svg';
import DeleteIcon from '../../Assets/Images/Trash.svg';
import ImgStripConnect from '../../Assets/Images/stripe-connect-blue-on-dark.png';
import ImgPoweredStripe from '../../Assets/Images/powered_by_stripe.png';

export default class MyNonprofit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nid: null,
      nonprofit: null,
      nonprofitEdit: {
        name: '',
        street: '',
        city: '',
        countryState: '',
        zipcode: '',
        ein: '',
        category: '',
        categoryName: '',
        type: '',
        website: '',
        email: '',
        phone: '',
        description: '',
      },
      program: {
        programId: '',
        programName: '',
        programDescription: '',
        programMediaCover: null,
        programMediaCoverPreview: imgPlaceHolder,
        programMediaCoverExt: '',
      },
      programs: [],
      mediaLogo: null,
      mediaLogoExt: '',
      mediaLogoPreview: imgLogo,
      mediaCover: null,
      mediaCoverExt: '',
      mediaCoverPreview: imgPlaceHolder,
      fundraCategories: [],
      fundraTypes: [],
      canAddProgram: false,
      loading: true,
      programLoading: true,
      editInfo: false,
      editProgram: false,
      showModal: false,
      onSaving: false,
      modalTitle: 'Edit General Information',
      saveLogoText: 'Save Logo',
      saveLogoDisabled: false,
      saveCoverText: 'Save Cover',
      saveCoverDisabled: false,
      error: false,
      errorMessage: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSaveLogo = this.handleSaveLogo.bind(this);
    this.handleSaveCover = this.handleSaveCover.bind(this);
    this.handleEditInfoModal = this.handleEditInfoModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.handleAddProgramModal = this.handleAddProgramModal.bind(this);
    this.handleProgramChange = this.handleProgramChange.bind(this);
    this.handleProgramFileChange = this.handleProgramFileChange.bind(this);
    this.handleRemoveProgram = this.handleRemoveProgram.bind(this);
    this.handleEditProgram = this.handleEditProgram.bind(this);
  }

  componentDidMount() {
    NonprofitProvider.getCategoryOptions().then(result => {
      this.setState({ fundraCategories: result.categories });
    });

    this.getNonprofit();
  }

  getNonprofit() {
    const { authUser } = this.props;

    NonprofitProvider.getAgentNonprofit(authUser.user.uid).then(snap => {
      let { nid, nonprofit, mediaLogoPreview, mediaCoverPreview } = this.state;

      if (snap.val()) {
        snap.forEach(childSnap => {
          nid = childSnap.val().key;
          nonprofit = {
            address: childSnap.val().address,
            key: childSnap.val().key,
            name: childSnap.val().name,
            category: childSnap.val().category,
            type: childSnap.val().type,
            city: childSnap.val().city,
            state: childSnap.val().state,
            federal: childSnap.val().federal,
            img: childSnap.val().img,
            contact: childSnap.val().contact,
            zipcode: childSnap.val().zipcode,
            website: childSnap.val().website,
            description: childSnap.val().description,
            slugName: stringHelper.slugify(childSnap.val().name),
            subscribe: childSnap.val().subscribe,
            stripe_account: childSnap.val().stripe_account,
          };

          if (childSnap.val().img) {
            if (childSnap.val().img.logo) {
              mediaLogoPreview = childSnap.val().img.logo;
            }
            if (childSnap.val().img.cover) {
              mediaCoverPreview = childSnap.val().img.cover;
            }
          }
        });
      }

      this.setState(
        {
          nid,
          nonprofit,
          mediaLogoPreview,
          mediaCoverPreview,
          loading: false,
        },
        () => {
          this.getProgramList();
        }
      );
    });
  }

  getProgramList() {
    const { nid, nonprofit } = this.state;
    let { canAddProgram } = this.state;

    NonprofitProvider.getNonprofitPrograms(nid).then(snap => {
      const programs = [];
      if (snap.val()) {
        snap.forEach(childSnap => {
          const slugName = stringHelper.slugify(childSnap.val().name);
          programs.push({
            id: childSnap.val().id,
            name: childSnap.val().name,
            slugName,
            cover: childSnap.val().cover,
            description: childSnap.val().description,
          });
        });
      }

      if (nonprofit.subscribe) {
        canAddProgram =
          nonprofit.subscribe.programLimit === -1 ||
          (nonprofit.subscribe.programLimit > 0 && nonprofit.subscribe.programLimit > programs.length);
      }

      this.setState({ canAddProgram, programs, programLoading: false });
    });
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const { nonprofitEdit } = this.state;

    if (name === 'category') {
      this.setState({ fundraTypes: [] });
      nonprofitEdit.categoryName = '';
      nonprofitEdit.type = '';

      if (value) {
        const { fundraCategories } = this.state;
        const selectedCat = fundraCategories[value];
        nonprofitEdit.categoryName = selectedCat.name;
        this.setState({ fundraTypes: selectedCat.types });
      }
    }

    if (name === 'ein') {
      if (nonprofitEdit.ein.length < value.length && value.length === 2) {
        value = `${value}-`;
      }
    }
    nonprofitEdit[name] = value;

    this.setState({ nonprofitEdit });
  }

  handleFileChange(event) {
    const { name } = event.target;
    const previewImg = `${name}Preview`;
    const extImg = `${name}Ext`;

    this.setState({
      [name]: event.target.files[0],
      [previewImg]: URL.createObjectURL(event.target.files[0]),
      [extImg]: 'jpg', // set all extension to jpg, easy when removing from storage
    });
  }

  handleProgramChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(prevState => {
      const program = { ...prevState.program };
      program[name] = value;
      return { program };
    });
  }

  handleProgramFileChange(event) {
    if (event.target.files.length) {
      const { name } = event.target;
      const previewImg = `${name}Preview`;
      const extImg = `${name}Ext`;
      const file = event.target.files[0];
      // const ext = file.name.split('.').pop();

      this.setState(prevState => {
        const program = { ...prevState.program };
        program[name] = file;
        program[previewImg] = URL.createObjectURL(file);
        program[extImg] = 'jpg'; // set all extension to jpg, easy when removing from storage

        return { program };
      });
    }
  }

  handleEditInfoModal() {
    const { fundraCategories, nonprofit } = this.state;
    const catIndex = fundraCategories.findIndex(category => category.name.trim() === nonprofit.category.trim());
    const selectedCat = fundraCategories[catIndex];

    this.setState({
      error: false,
      errorMessage: '',
      showModal: true,
      editInfo: true,
      modalTitle: 'Edit General Information',
      fundraTypes: selectedCat.types,
      nonprofitEdit: {
        name: nonprofit.name,
        street: nonprofit.address,
        city: nonprofit.city,
        countryState: nonprofit.state,
        zipcode: nonprofit.zipcode,
        ein: nonprofit.federal,
        category: catIndex,
        categoryName: nonprofit.category,
        type: nonprofit.type,
        website: nonprofit.website,
        email: nonprofit.contact.email,
        phone: nonprofit.contact.phone,
        description: nonprofit.description,
      },
    });
  }

  handleAddProgramModal() {
    this.setState({
      error: false,
      errorMessage: '',
      showModal: true,
      editProgram: true,
      modalTitle: 'Add Program',
      program: {
        programId: '',
        programName: '',
        programDescription: '',
        programMediaCover: null,
        programMediaCoverPreview: imgPlaceHolder,
        programMediaCoverExt: '',
      },
    });
  }

  handleCloseModal() {
    this.setState({ showModal: false, onSaving: false, editInfo: false, editProgram: false });
  }

  handleSaveLogo() {
    const { nid, mediaLogo, mediaLogoExt } = this.state;

    if (mediaLogo) {
      this.setState(
        {
          saveLogoText: 'Uploading...',
          saveLogoDisabled: true,
        },
        () => {
          NonprofitProvider.uploadImg(nid, mediaLogo, mediaLogoExt, 1).then(result => {
            const mediaLogoPreview = result.mediaUrl !== '' ? result.mediaUrl : imgLogo;
            this.setState({
              mediaLogoPreview,
              mediaLogo: null,
              mediaLogoExt: '',
              saveLogoText: 'Saved',
              saveLogoDisabled: false,
            });

            setTimeout(() => {
              this.setState({ saveLogoText: 'Save Logo' });
            }, 3000);
          });
        }
      );
    }
  }

  handleSaveCover() {
    const { nid, mediaCover, mediaCoverExt } = this.state;

    if (mediaCover) {
      this.setState(
        {
          saveCoverText: 'Uploading...',
          saveCoverDisabled: true,
        },
        () => {
          NonprofitProvider.uploadImg(nid, mediaCover, mediaCoverExt, 2).then(result => {
            const mediaCoverPreview = result.mediaUrl !== '' ? result.mediaUrl : imgPlaceHolder;
            this.setState({
              mediaCoverPreview,
              mediaCover: null,
              mediaCoverExt: '',
              saveCoverText: 'Saved',
              saveCoverDisabled: false,
            });

            setTimeout(() => {
              this.setState({ saveCoverText: 'Save Cover' });
            }, 3000);
          });
        }
      );
    }
  }

  handleSubmitModal() {
    this.setState({ error: false, errorMessage: '' });

    const { nid, nonprofit, editInfo, editProgram, nonprofitEdit, program } = this.state;
    if (editInfo) {
      const validate = this.validateNonprofit();
      if (validate.status) {
        this.setState({ onSaving: true });

        NonprofitProvider.UpdateNonprofit(nid, nonprofitEdit).then(() => {
          nonprofit.address = nonprofitEdit.street;
          nonprofit.name = nonprofitEdit.name;
          nonprofit.category = nonprofitEdit.categoryName;
          nonprofit.type = nonprofitEdit.type;
          nonprofit.city = nonprofitEdit.city;
          nonprofit.state = nonprofitEdit.countryState;
          nonprofit.federal = nonprofitEdit.ein;
          nonprofit.contact = { email: nonprofitEdit.email, phone: nonprofitEdit.phone };
          nonprofit.zipcode = nonprofitEdit.zipcode;
          nonprofit.website = nonprofitEdit.website;
          nonprofit.description = nonprofitEdit.description;
          nonprofit.slugName = stringHelper.slugify(nonprofitEdit.name);

          this.setState({ nonprofit, showModal: false, onSaving: false, editInfo: false });
        });
      } else {
        this.setState({ error: true, errorMessage: validate.message });
      }
    } else if (editProgram) {
      const validate = this.validateProgram();
      if (validate.status) {
        this.setState({ onSaving: true });
        NonprofitProvider.saveProgram(nid, program).then(result => {
          if (result.success) {
            this.setState({ showModal: false, onSaving: false, programLoading: true, editProgram: false }, () => {
              this.getProgramList();
            });
          } else {
            console.log(result.err);
          }
        });
      } else {
        this.setState({ error: true, errorMessage: validate.message });
      }
    }
  }

  handleRemoveProgram(id, event) {
    event.stopPropagation();

    const { nid, programs } = this.state;
    const filter = programs.filter(program => program.id === id);
    const selectedProgram = filter.length ? filter[0] : null;

    if (selectedProgram) {
      swal('Delete program', `Are you sure you want to delete program "${selectedProgram.name}"?`, {
        dangerMode: true,
        buttons: ['Cancel', 'Delete'],
      })
        .then(willDelete => {
          if (willDelete) {
            return NonprofitProvider.removeProgram(nid, id);
          }

          return 'cancel';
        })
        .then(res => {
          if (res !== 'cancel') {
            this.setState({ programLoading: true }, () => {
              this.getProgramList();
            });
          }
        });
    }
  }

  handleEditProgram(id, event) {
    event.stopPropagation();

    const { programs } = this.state;
    const filter = programs.filter(program => program.id === id);
    const selectedProgram = filter.length ? filter[0] : null;

    if (selectedProgram) {
      this.setState({
        error: false,
        errorMessage: '',
        showModal: true,
        editProgram: true,
        modalTitle: 'Edit Program',
        program: {
          programId: selectedProgram.id,
          programName: selectedProgram.name,
          programDescription: selectedProgram.description,
          programMediaCover: null,
          programMediaCoverPreview: selectedProgram.cover,
          programMediaCoverExt: '',
        },
      });
    }
  }

  validateNonprofit() {
    const { nonprofitEdit } = this.state;

    let validateStatus = false;
    let errorMessage = '';
    if (nonprofitEdit.name.trim() === '') {
      errorMessage = 'Name is required';
    } else if (nonprofitEdit.street.trim() === '') {
      errorMessage = 'Address is required';
    } else if (nonprofitEdit.city.trim() === '') {
      errorMessage = 'City is required';
    } else if (nonprofitEdit.countryState.trim() === '') {
      errorMessage = 'State is required';
    } else if (nonprofitEdit.ein.trim() === '') {
      errorMessage = 'Federal EIN is required';
    } else if (nonprofitEdit.categoryName.trim() === '') {
      errorMessage = 'Category is required';
    } else if (nonprofitEdit.type.trim() === '') {
      errorMessage = 'Type is required';
    } else if (nonprofitEdit.email.trim() === '') {
      errorMessage = 'Email is required';
    } else if (nonprofitEdit.phone.trim() === '') {
      errorMessage = 'Phone is required';
    } else {
      validateStatus = true;
    }

    return { status: validateStatus, message: errorMessage };
  }

  validateProgram() {
    const { program } = this.state;

    let validateStatus = false;
    let errorMessage = '';
    if (program.programName.trim() === '') {
      errorMessage = 'Program Name is required';
    } else {
      validateStatus = true;
    }

    return { status: validateStatus, message: errorMessage };
  }

  render() {
    const {
      nonprofit,
      nonprofitEdit,
      programs,
      program,
      mediaLogoPreview,
      mediaCoverPreview,
      loading,
      programLoading,
      saveLogoText,
      saveLogoDisabled,
      saveCoverText,
      saveCoverDisabled,
      editInfo,
      editProgram,
      showModal,
      modalTitle,
      onSaving,
      fundraCategories,
      fundraTypes,
      error,
      errorMessage,
      canAddProgram,
    } = this.state;
    const { authUser } = this.props;
    const btnLogoCss = saveLogoText === 'Saved' ? 'f-btn-default-md' : 'f-btn-default-m';
    const btnCoverCss = saveCoverText === 'Saved' ? 'f-btn-default-md' : 'f-btn-default-m';

    let showStripeConnect = false;
    let showStripeConnectLogin = false;
    let stripeExpressOauth = '';

    const endPoint = process.env.REACT_APP_FIREBASE_DB_PATH === 'dev' ? 'stripeConnectDev' : 'stripeConnect';
    const redirectUri = `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/${endPoint}`;
    const clientID = process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID;
    if (nonprofit) {
      if (nonprofit.stripe_account) {
        showStripeConnectLogin = true;
      } else {
        showStripeConnect = true;
        const { profile } = authUser;
        stripeExpressOauth = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectUri}&client_id=${clientID}&state=${nonprofit.key}&stripe_user[email]=${profile.email}&stripe_user[first_name]=${profile.firstName}&stripe_user[last_name]=${profile.lastName}&stripe_user[business_name]=${nonprofit.name}&stripe_user[business_type]=company`;
        if (profile.phone && profile.phone !== '') {
          stripeExpressOauth += `&stripe_user[phone_number]=${profile.phone}`;
        }
        if (nonprofit.website && nonprofit.website !== '') {
          stripeExpressOauth += `&stripe_user[url]=${nonprofit.website}`;
        }
      }
    }

    return (
      <>
        {loading ? (
          <div className="section-loader-data">
            <div className="section-loader-container">
              <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
            </div>
          </div>
        ) : (
          <>
            <div className="w-row">
              <div className="w-col w-col-5">
                <div className="w-row">
                  <div className="w-col w-col-8 w-col-stack">
                    <span className="formfieldlabel">Logo</span>
                    <img src={mediaLogoPreview} className="nonprofit-edit-logo-preview" alt="" />
                  </div>
                  <div className="w-col w-col-4 w-col-stack">
                    <div className="upload-btn-wrapper" style={{ marginTop: 16 }}>
                      <UploadButton />
                      <input type="file" name="mediaLogo" onChange={this.handleFileChange} accept="image/*" />
                    </div>
                    <button
                      type="button"
                      className={`buttondefault ${btnLogoCss} nonprofit-edit-img-btn`}
                      disabled={saveLogoDisabled}
                      onClick={this.handleSaveLogo}
                    >
                      {saveLogoText}
                    </button>
                  </div>
                </div>
                <div className="w-row" style={{ marginTop: 35 }}>
                  <div className="w-col w-col-8 w-col-stack">
                    <span className="formfieldlabel">Cover</span>
                    <img src={mediaCoverPreview} className="nonprofit-edit-cover-preview" alt="" />
                  </div>
                  <div className="w-col w-col-4 w-col-stack">
                    <div className="upload-btn-wrapper" style={{ marginTop: 16 }}>
                      <UploadButton />
                      <input type="file" name="mediaCover" onChange={this.handleFileChange} accept="image/*" />
                    </div>
                    <button
                      type="button"
                      className={`buttondefault ${btnCoverCss} nonprofit-edit-img-btn`}
                      disabled={saveCoverDisabled}
                      onClick={this.handleSaveCover}
                    >
                      {saveCoverText}
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-col w-col-7 f-border-left" style={{ paddingLeft: 20, paddingBottom: 40 }}>
                <div className="stripe-express-oauth" style={{ textAlign: 'right' }}>
                  {showStripeConnect ? (
                    <a href={stripeExpressOauth}>
                      <img src={ImgStripConnect} alt="" />
                    </a>
                  ) : null}
                  {showStripeConnectLogin ? <img src={ImgPoweredStripe} alt="" /> : null}
                </div>
                <h4 style={{ marginTop: 0, marginBottom: 15 }}>General Information</h4>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">Name</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {nonprofit.name}
                  </div>
                  <div className="w-col w-col-1 w-col-stack">
                    <button type="button" className="btn-edit" onClick={this.handleEditInfoModal}>
                      <img src={EditIcon} alt="Edit" title="Edit" />
                    </button>
                  </div>
                </div>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">Address</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {nonprofit.address}
                  </div>
                  <div className="w-col w-col-1 w-col-stack" />
                </div>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">City, State</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {`${nonprofit.city}, ${nonprofit.state}`}
                  </div>
                  <div className="w-col w-col-1 w-col-stack" />
                </div>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">Zipcode</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {nonprofit.zipcode}
                  </div>
                  <div className="w-col w-col-1 w-col-stack" />
                </div>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">Federal EIN</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {nonprofit.federal}
                  </div>
                  <div className="w-col w-col-1 w-col-stack" />
                </div>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">Category / Type</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {`${nonprofit.category} / ${nonprofit.type}`}
                  </div>
                  <div className="w-col w-col-1 w-col-stack" />
                </div>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">Email / Phone</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {`${nonprofit.contact.email} / ${nonprofit.contact.phone}`}
                  </div>
                  <div className="w-col w-col-1 w-col-stack" />
                </div>
                <div className="w-row nonprofit-edit-general-info">
                  <div className="w-col w-col-3 w-col-stack label-field">Website</div>
                  <div className="w-col w-col-8 w-col-stack value-field">
                    {': '}
                    &nbsp;
                    {nonprofit.website}
                  </div>
                  <div className="w-col w-col-1 w-col-stack" />
                </div>
                <div className="flexbox flexbox-align-center flexbox-space" style={{ marginTop: 25, marginBottom: 15 }}>
                  <h4>Programs</h4>
                  {canAddProgram ? (
                    <button
                      type="button"
                      className="buttondefault f-btn-default-m"
                      style={{ width: 160, marginRight: 0, marginLeft: 0 }}
                      onClick={this.handleAddProgramModal}
                    >
                      + Add program
                    </button>
                  ) : null}
                </div>
                {programLoading ? (
                  <div className="section-loader-x">
                    <div className="section-loader-container-right">
                      <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                    </div>
                  </div>
                ) : (
                  <table className="nonprofit-table-small f-table-hover">
                    <thead>
                      <tr>
                        <th>Program Name</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {programs.map(item => (
                        <tr key={item.id}>
                          <td className="text-bold">{item.name}</td>
                          <td className="f-action-column">
                            <button type="button" onClick={e => this.handleEditProgram(item.id, e)}>
                              <img src={EditIcon} alt="Edit" title="Edit" />
                            </button>
                            <button type="button" onClick={e => this.handleRemoveProgram(item.id, e)}>
                              <img src={DeleteIcon} alt="Delete" title="Delete" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <Modal show={showModal} onHide={this.handleCloseModal} aria-labelledby="nonprofit-modal-title">
              <Modal.Header closeButton>
                <Modal.Title id="nonprofit-modal-title">{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {editInfo ? (
                  <div className="donationRow">
                    <label htmlFor="name" className="formfieldlabel">
                      Nonprofit name
                    </label>
                    <input
                      type="text"
                      className="formtextfield small w-input"
                      maxLength="256"
                      name="name"
                      placeholder="Enter your nonprofit name"
                      value={nonprofitEdit.name}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="street" className="formfieldlabel">
                      Nonprofit street address
                    </label>
                    <input
                      type="text"
                      className="formtextfield small w-input"
                      name="street"
                      placeholder="Enter your nonprofit street address"
                      value={nonprofitEdit.street}
                      onChange={this.handleChange}
                    />
                    <div className="w-row">
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="city" className="formfieldlabel">
                          City
                        </label>
                        <input
                          type="text"
                          className="formtextfield small w-input input90"
                          maxLength="256"
                          name="city"
                          placeholder="Enter your city"
                          value={nonprofitEdit.city}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="countryState" className="formfieldlabel">
                          State
                        </label>
                        <input
                          type="text"
                          className="formtextfield small w-input"
                          maxLength="256"
                          name="countryState"
                          placeholder="Enter your state"
                          value={nonprofitEdit.countryState}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="w-row">
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="zipcode" className="formfieldlabel">
                          Zipcode
                        </label>
                        <input
                          type="text"
                          className="formtextfield small w-input input90"
                          maxLength="256"
                          name="zipcode"
                          placeholder="Enter your zipcode"
                          value={nonprofitEdit.zipcode}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="ein" className="formfieldlabel">
                          Federal EIN
                        </label>
                        <input
                          type="text"
                          pattern="[0-9]{2}-[0-9]{7}"
                          title="Use valid format {12-3456789}"
                          className="formtextfield small w-input"
                          maxLength="10"
                          name="ein"
                          placeholder="Enter your federal ein"
                          value={nonprofitEdit.ein}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="w-row">
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="category" className="formfieldlabel">
                          Category
                        </label>
                        <select
                          name="category"
                          className="formdropdown small w-select input90"
                          value={nonprofitEdit.category}
                          onChange={this.handleChange}
                        >
                          <option value="">Select one</option>
                          {fundraCategories.map((fundraCategory, index) => (
                            <option key={fundraCategory.id} value={index}>
                              {fundraCategory.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="type" className="formfieldlabel">
                          Type
                        </label>
                        <select
                          name="type"
                          className="formdropdown small w-select"
                          value={nonprofitEdit.type}
                          onChange={this.handleChange}
                        >
                          <option value="">Select one</option>
                          {fundraTypes.map(fundraType => (
                            <option key={fundraType.id} value={fundraType.name}>
                              {fundraType.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="w-row">
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="email" className="formfieldlabel">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="formtextfield small w-input input90"
                          maxLength="256"
                          name="email"
                          placeholder="Enter your nonprofit email address"
                          value={nonprofitEdit.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="w-col w-col-6 w-col-stack">
                        <label htmlFor="phone" className="formfieldlabel">
                          Phone number
                        </label>
                        <input
                          type="tel"
                          className="formtextfield small w-input"
                          maxLength="256"
                          name="phone"
                          placeholder="Enter your nonprofit phone number"
                          value={nonprofitEdit.phone}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <label htmlFor="website" className="formfieldlabel">
                      Website
                    </label>
                    <input
                      type="url"
                      className="formtextfield small w-input"
                      maxLength="256"
                      name="website"
                      placeholder="Enter your website"
                      value={nonprofitEdit.website}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="description" className="formfieldlabel">
                      Description
                    </label>
                    <textarea
                      className="formtextfield w-input"
                      rows={5}
                      name="description"
                      value={nonprofitEdit.description}
                      placeholder="Enter your nonprofit description"
                      onChange={this.handleChange}
                    />
                  </div>
                ) : null}
                {editProgram ? (
                  <div className="donationRow">
                    <label htmlFor="programName" className="formfieldlabel">
                      Program name
                    </label>
                    <input
                      type="text"
                      className="formtextfield small w-input"
                      maxLength="256"
                      id="programName"
                      name="programName"
                      placeholder="Enter your program name"
                      value={program.programName}
                      onChange={this.handleProgramChange}
                    />
                    <label htmlFor="programDescription" className="formfieldlabel">
                      Program description
                    </label>
                    <textarea
                      className="formtextfield w-input"
                      rows={5}
                      name="programDescription"
                      value={program.programDescription}
                      placeholder="Enter your program description"
                      onChange={this.handleProgramChange}
                    />
                    <div className="w-row">
                      <div className="w-col w-col-6 w-col-stack">
                        <label className="formfieldlabel">Upload cover photo</label>
                        <img src={program.programMediaCoverPreview} className="nonprofitcoverpreview" alt="" />
                      </div>
                      <div className="w-col w-col-6 w-col-stack">
                        <label className="coverlabel1 cover">Add photos or videos</label>
                        <label className="coverlabel2">{program.programMediaCover ? '' : 'no file uploaded yet'}</label>
                        <div className="upload-btn-wrapper">
                          <UploadButton />
                          <input
                            type="file"
                            name="programMediaCover"
                            onChange={this.handleProgramFileChange}
                            accept="image/*"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <CSSTransition in={error} timeout={500} classNames="errormessage" unmountOnExit>
                  {() => (
                    <div className="error-block" style={{ marginTop: 30 }}>
                      <div className="error-block-text">{errorMessage}</div>
                    </div>
                  )}
                </CSSTransition>
              </Modal.Body>
              <Modal.Footer>
                {onSaving ? (
                  <Loader color="#3898EC" type="ThreeDots" height={40} width={40} />
                ) : (
                  <>
                    <Button variant="secondary" onClick={this.handleCloseModal}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmitModal}>
                      Save Changes
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
          </>
        )}
      </>
    );
  }
}

MyNonprofit.propTypes = {
  authUser: PropTypes.instanceOf(Object).isRequired,
};
