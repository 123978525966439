import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import { UserProvider } from '../../Providers';

import MyImpactCard from './MyImpactCard';

import * as routes from '../../constants/routes';

import ImgEmptyDonors from '../../Assets/Images/Illustration-3.svg';

class MyImpact extends React.Component {
  constructor(props) {
    super(props);

    this.state = { items: [], loading: true };
    this.handleDonation = this.handleDonation.bind(this);
  }

  componentDidMount() {
    this.getDonorImpact();
  }

  getDonorImpact() {
    const { authUser, updateTotal } = this.props;
    UserProvider.getDonorImpact(authUser.user.uid).then((donationSnaps) => {
      const items = [];
      let totalDonation = 0.00;
      donationSnaps.forEach((item) => {
        items.push({
          campaign: item.val().campaign,
          date: item.val().date,
          dedication: item.val().dedication,
          idTrx: item.val().idTrx,
          metric: item.val().metric,
          nonprofit: item.val().nonprofit,
          program: item.val().program,
          receipt_id: item.val().receipt_id,
          total: item.val().total,
        });

        totalDonation += Number.parseFloat(item.val().total.amount);
      });

      if (items.length) {
        items.reverse();
        updateTotal(totalDonation);
      }

      this.setState({ items, loading: false });
    });
  }

  handleDonation() {
    const { history } = this.props;
    history.push(routes.CAMPAIGNS);
  }

  render() {
    const { items, loading } = this.state;

    return (
      <React.Fragment>
        {loading ? (
          <div className="section-loader-data">
            <div className="section-loader-container"><Loader color="#3898EC" type="ThreeDots" height={80} width={80} /></div>
          </div>
        ) : (
          <React.Fragment>
            {items.length > 0 ? (
              <ImpactList items={items} />
            ) : (
              <div className="donors-empty">
                <img src={ImgEmptyDonors} alt="" />
                <h3 className="h3 nonprofitabouttitle">Giving is good</h3>
                <label className="joined">Donate and make a difference today!</label>
                <button type="button" className="w-button" onClick={this.handleDonation}>Donate</button>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

MyImpact.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object).isRequired,
  updateTotal: PropTypes.func.isRequired,
};

function ImpactList(props) {
  const impactRow = [];
  let impactCol = [];
  let rowIndex = 1;
  props.items.forEach((item, index) => {
    impactCol.push(<div className="w-col w-col-4" key={item.idTrx}><MyImpactCard item={item} /></div>);

    const counter = index + 1;
    if (counter % 3 === 0) {
      if (impactCol.length > 0) {
        impactRow.push(<div className="w-row" key={rowIndex}>{impactCol}</div>);
      }
      impactCol = [];
      rowIndex += 1;
    }
  });

  if (impactCol.length > 0) {
    impactRow.push(<div className="w-row" key={rowIndex}>{impactCol}</div>);
  }

  return (impactRow);
}

export default withRouter(MyImpact);
