import React from 'react';
import PropTypes from 'prop-types';
import DonorInfo from './DonorInfo';

const DonorTable = ({ tblClass, items, showRecurring, showDate, metricInfo }) => (
  <table className={tblClass}>
    <thead>
      <tr>
        <th>Donors</th>
        <th>Amount</th>
        {showRecurring ? <th>Recurring</th> : null}
        <th>Donated To</th>
        {showDate ? <th>Date</th> : null}
      </tr>
    </thead>
    <tbody>
      {items.map(item => (
        <DonorInfo
          key={item.idTrx}
          showRecurring={showRecurring}
          showDate={showDate}
          item={item}
          viewType="row"
          metricInfo={metricInfo}
        />
      ))}
    </tbody>
  </table>
);

DonorTable.propTypes = {
  tblClass: PropTypes.string,
  items: PropTypes.instanceOf(Array).isRequired,
  showRecurring: PropTypes.bool,
  showDate: PropTypes.bool,
  metricInfo: PropTypes.instanceOf(Object),
};

DonorTable.defaultProps = { tblClass: 'nonprofit-table', showRecurring: false, showDate: false, metricInfo: null };

export default DonorTable;
