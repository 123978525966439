/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { stringHelper, numberHelper, dateTimeHelper } from '../helpers';
import { CampaignProvider, NonprofitProvider, StrategicProvider } from '../Providers';

import * as routes from '../constants/routes';
import withNonprofitDetail from '../withNonprofitDetail';

import SiteMeta from '../Components/SiteMeta';
import NonprofitTopNav from '../Components/NonprofitTopNav';
import NonprofitProgramCard from '../Components/NonprofitProgramCard';
import NonprofitCampaignCard from '../Components/NonprofitCampaignCard';

import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';

import 'react-sweet-progress/lib/style.css';
import '../Assets/Styles/Nonprofits-About.css';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        address: '',
        agent: null,
        category: '',
        charityNavigatorURL: '',
        city: '',
        collect: '',
        contact: null,
        description: '',
        likes: null,
        federal: '',
        img: null,
        key: '',
        name: '',
        publish: '',
        rating: [],
        ratingStar: [],
        slugName: '',
        state: '',
        type: '',
        viewUrl: '',
        website: '',
        zipcode: '',
        hideDonation: false,
        stripe_account: null,
        subscribe: null,
      },
      programs: [],
      campaigns: [],
      vision: null,
      mission: null,
      coreValues: [],
      longTermGoals: [],
    };

    this.handleDonation = this.handleDonation.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, nonprofit } = this.props;
    updateNavStatus('empty');
    showFooterLinkContainer(false);

    this.setState(
      prevState => {
        const { item } = prevState;
        Object.keys(item).forEach(key => {
          item[key] = nonprofit[key];
        });

        return { item };
      },
      () => {
        this.loadPrograms();
        this.loadCampaigns();
        this.loadStrategicPlanning();
      }
    );
  }

  loadPrograms() {
    const { item } = this.state;

    NonprofitProvider.getNonprofitPrograms(item.key).then(snap => {
      const programs = [];
      snap.forEach(childSnap => {
        const slugName = stringHelper.slugify(childSnap.val().name);
        programs.push({
          id: childSnap.val().id,
          name: childSnap.val().name,
          slugName,
          cover: childSnap.val().cover,
          description: childSnap.val().description,
          viewUrl: routes.PROGRAMS_ABOUT.replace(':id', childSnap.val().id)
            .replace(':nonprofitid', item.key)
            .replace(':name', slugName),
          nonprofit: {
            id: item.key,
            name: item.name,
            city: item.city,
            state: item.state,
            hideDonation: item.hideDonation,
          },
        });
      });

      this.setState({ programs });
    });
  }

  loadCampaigns() {
    const { item } = this.state;
    CampaignProvider.getList(item.key).then(campaignSnap => {
      const campaigns = [];
      campaignSnap.forEach(snap => {
        if (snap.val().published) {
          const slugName = stringHelper.slugify(snap.val().name);

          campaigns.push({
            id: snap.val().id,
            name: snap.val().name,
            slugName,
            date: snap.val().date,
            endDate: snap.val().endDate,
            location: snap.val().location,
            likes: snap.val().likes,
            user: snap.val().user,
            time: snap.val().time,
            cover: snap.val().cover,
            goal: snap.val().goal,
            story: snap.val().story,
            nonprofit: {
              ...snap.val().nonprofit,
              hideDonation: item.hideDonation,
            },
            program: snap.val().program,
            metric: snap.val().metric,
            viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', snap.val().id).replace(':name', slugName),
          });
        }
      });

      if (campaigns.length > 0) {
        campaigns.reverse();
      }

      this.setState({ campaigns });
    });
  }

  loadStrategicPlanning() {
    const {
      item: { key },
    } = this.state;
    StrategicProvider.getPlanningDetail(key, 'vision_statement').then(snapshot => {
      if (snapshot.val()) {
        this.setState({ vision: snapshot.val() });
      }
    });

    StrategicProvider.getPlanningDetail(key, 'mission_statement').then(snapshot => {
      if (snapshot.val()) {
        this.setState({ mission: snapshot.val() });
      }
    });

    StrategicProvider.getList(key, 'core')
      .once('value')
      .then(snapshot => {
        const coreValues = [];
        snapshot.forEach(snap => {
          coreValues.push({ ...snap.val() });
        });

        if (coreValues.length > 0) {
          coreValues.reverse();
        }

        this.setState({ coreValues });
      });

    StrategicProvider.getList(key)
      .once('value')
      .then(snapshot => {
        const longTermGoals = [];
        snapshot.forEach(snap => {
          longTermGoals.push({ ...snap.val() });
        });

        if (longTermGoals.length > 0) {
          longTermGoals.reverse();
        }

        this.setState({ longTermGoals });
      });
  }

  handleDonation() {
    const { history } = this.props;
    const { item } = this.state;

    history.push(routes.NONPROFITS_DONATION.replace(':id', item.key).replace(':type', 'b'));
  }

  render() {
    const { item, programs, campaigns, vision, mission, coreValues, longTermGoals } = this.state;
    const { authUser } = this.props;
    const nonprofitCover = item.img ? item.img.cover : ImgCoverDefault;
    const metaDescription =
      item.description.length > 160 ? item.description.substring(0, 160).concat('...') : item.description;

    return (
      <div className="NonprofitAboutSection">
        {item.name !== '' ? (
          <SiteMeta
            title={`${item.name} :: Our Transparency Story`}
            description={metaDescription}
            image={nonprofitCover}
          />
        ) : null}
        <div className="sectionhero no-padding">
          <div className="contentwrapper herosectionfeatures nonprofitsection">
            <div className="nonprofit-detail">
              <NonprofitTopNav item={item} authUser={authUser} noNavBottom />
              <div className="nonprofit-body-content fix-top-nav">
                <div className="nonprofit-body-content-left">
                  <div className="nonprofit-cover-big">
                    <img src={nonprofitCover} alt={`${item.name} Cover`} />
                  </div>
                  <h1 className="header-section">Who We Are</h1>
                  <h2 className="header-caption" style={{ marginBottom: 10 }}>
                    Our Organization
                  </h2>
                  <div className="nonprofit-description f-paragraph">{stringHelper.nl2br(item.description)}</div>
                  {vision ? (
                    <>
                      <h2 className="header-caption" style={{ marginBottom: 10 }}>
                        Our Vision
                      </h2>
                      <div className="nonprofit-description f-paragraph" dangerouslySetInnerHTML={{ __html: vision }} />
                    </>
                  ) : null}
                  {mission ? (
                    <>
                      <h2 className="header-caption" style={{ marginBottom: 10 }}>
                        Our Mission
                      </h2>
                      <div
                        className="nonprofit-description f-paragraph"
                        dangerouslySetInnerHTML={{ __html: mission }}
                      />
                    </>
                  ) : null}
                  {coreValues.length > 0 ? (
                    <>
                      <h2 className="header-caption" style={{ marginBottom: 10 }}>
                        Our Core Values
                      </h2>
                      {React.Children.toArray(
                        coreValues.map(core => (
                          <>
                            <h5>{core.name}</h5>
                            <div
                              className="nonprofit-description f-paragraph"
                              dangerouslySetInnerHTML={{ __html: core.description }}
                            />
                          </>
                        ))
                      )}
                    </>
                  ) : null}
                  {longTermGoals.length > 0 ? (
                    <>
                      <h2 className="header-caption" style={{ marginBottom: 10 }}>
                        Our Long-Term Goals
                      </h2>
                      {React.Children.toArray(
                        longTermGoals.map(goal => {
                          const startDate = dateTimeHelper.getFormatedDate(goal.startDate, 'MM/DD/YYYY');
                          const endDate = dateTimeHelper.getFormatedDate(goal.endDate, 'MM/DD/YYYY');
                          const goalPeriod = `${startDate} - ${endDate}`;

                          return (
                            <>
                              <h5>{`${goal.name} (${goalPeriod})`}</h5>
                              <div className="header-sub-caption">
                                <div style={{ marginRight: 5 }}>Target</div>
                                <div className="text-lowercase">
                                  {`${numberHelper.getThousandFormat(goal.unitNumber)} ${stringHelper.makePluralize(
                                    goal.unitName,
                                    goal.unitNumber,
                                    false
                                  )}`}
                                </div>
                              </div>
                              <div
                                className="nonprofit-description f-paragraph"
                                dangerouslySetInnerHTML={{ __html: goal.description }}
                              />
                            </>
                          );
                        })
                      )}
                    </>
                  ) : null}
                  <h1 className="header-section">What We Do</h1>
                  <h2 className="header-caption">Our programs</h2>
                  {programs.length > 0 ? <ProgramList items={programs} /> : null}
                  <h2 className="header-caption">Our projects & campaigns</h2>
                  <div className="nonprofit-campaign">
                    {campaigns.length > 0 ? <CampaignList items={campaigns} /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

About.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

About.defaultProps = {
  authUser: null,
};

function CampaignList(props) {
  const campaignRow = [];
  let campaignCol = [];
  let rowIndex = 1;
  props.items.forEach((item, index) => {
    const counter = index + 1;
    const padStyle = counter % 4 === 0 ? { paddingRight: 0 } : { paddingLeft: 0 };

    campaignCol.push(
      <div className="w-col w-col-3 program-col" key={item.id} style={padStyle}>
        <NonprofitCampaignCard item={item} socialShare />
      </div>
    );

    if (counter % 4 === 0) {
      if (campaignCol.length > 0) {
        campaignRow.push(
          <div className="w-row" key={rowIndex}>
            {campaignCol}
          </div>
        );
      }
      campaignCol = [];
      rowIndex += 1;
    }
  });

  if (campaignCol.length > 0) {
    campaignRow.push(
      <div className="w-row" key={rowIndex}>
        {campaignCol}
      </div>
    );
  }

  return campaignRow;
}

function ProgramList(props) {
  const programRow = [];
  let programCol = [];
  let rowIndex = 1;
  props.items.forEach((item, index) => {
    const counter = index + 1;
    const padStyle = counter % 4 === 0 ? { paddingRight: 0 } : { paddingLeft: 0 };

    programCol.push(
      <div className="w-col w-col-3 program-col" key={item.id} style={padStyle}>
        <NonprofitProgramCard item={item} hideNonprofitName hideCampaign />
      </div>
    );

    if (counter % 4 === 0) {
      if (programCol.length > 0) {
        programRow.push(
          <div className="w-row" key={rowIndex}>
            {programCol}
          </div>
        );
      }
      programCol = [];
      rowIndex += 1;
    }
  });

  if (programCol.length > 0) {
    programRow.push(
      <div className="w-row" key={rowIndex}>
        {programCol}
      </div>
    );
  }

  return programRow;
}

export default withNonprofitDetail(About);
