import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';

import Favorite from './Favorite';
import SocialShareComponent from './SocialShare';

import * as routes from '../constants/routes';

import ImgLogoDefault from '../Assets/Images/Media_Icon.svg';
import { ReactComponent as ImgAboutInActive } from '../Assets/Images/Icon_About_Inactive.svg';
import { ReactComponent as ImgCampaignInactive } from '../Assets/Images/Icon_Campaigns_Inactive.svg';
import { ReactComponent as ImgProgramsInactive } from '../Assets/Images/Icon_Programs_Inactive.svg';

const ScrollLink = Scroll.Link;

class NonprofitTopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      authUser: null,
    };

    this.handleDonation = this.handleDonation.bind(this);
  }

  componentDidMount() {
    const { item, authUser } = this.props;
    this.setState({
      item,
      authUser,
    });
  }

  handleDonation() {
    const { item } = this.state;
    window.location = routes.NONPROFITS_DONATION.replace(':id', item.key).replace(':type', 'b');
  }

  render() {
    const { item, authUser } = this.state;
    const { withHash, noNavBottom, noSupport } = this.props;
    const nonprofitLogo = item && item.img ? item.img.logo : ImgLogoDefault;
    let routeLink = '#';
    let whyUs = 'whyus';
    let whatFor = 'whatfor';
    let whyNow = 'whynow';

    if (item) {
      routeLink = routes.NONPROFITS_ABOUT.replace(':id', item.key).replace(':name', item.slugName);

      if (withHash) {
        whyUs = `${routeLink}#whyus`;
        whatFor = `${routeLink}#whatfor`;
        whyNow = `${routeLink}#whynow`;
      }
    }

    return (
      <>
        {item ? (
          <>
            {noNavBottom ? (
              <div className="nonprofit-nav-top-fixed">
                <div className="contentwrapper">
                  <div className="nonprofit-nav-top">
                    {authUser ? (
                      <div className="nonprofit-fav-mobile">
                        <Favorite authUser={authUser} likes={item.likes} type={1} id={item.key.toString()} small />
                      </div>
                    ) : null}
                    <div className="nonprofit-nav-detail">
                      <div className="nonprofit-cover">
                        <Link to={routeLink}>
                          <img className="cover-image" src={nonprofitLogo} alt={`${item.name} Logo`} />
                        </Link>
                      </div>
                      <div className="nonprofit-info">
                        <div className="title-top">
                          <div className="name-title">{item.name}</div>
                          <div className="favorite">
                            {authUser ? (
                              <Favorite
                                authUser={authUser}
                                likes={item.likes}
                                type={1}
                                id={item.key.toString()}
                                small
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="type-title">{item.type}</div>
                      </div>
                    </div>
                    <div className="nonprofit-nav-share top-nav-fixed">
                      <SocialShareComponent
                        url={item.viewUrl}
                        title={item.name}
                        description={item.description}
                        noCount
                      />
                      {item.key !== '' && !noSupport ? (
                        <div className="nonprofit-nav-donation nonprofit-support-button">
                          <button type="button" className="w-button f-btn-danger" onClick={this.handleDonation}>
                            Support
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="nonprofit-nav-top">
                  {authUser ? (
                    <div className="nonprofit-fav-mobile">
                      <Favorite authUser={authUser} likes={item.likes} type={1} id={item.key.toString()} small />
                    </div>
                  ) : null}
                  <div className="nonprofit-nav-detail">
                    <div className="nonprofit-cover">
                      <img className="cover-image" src={nonprofitLogo} alt={`${item.name} Logo`} />
                    </div>
                    <div className="nonprofit-info">
                      <div className="title-top">
                        <div className="name-title">{item.name}</div>
                        <div className="favorite">
                          {authUser ? (
                            <Favorite authUser={authUser} likes={item.likes} type={1} id={item.key.toString()} small />
                          ) : null}
                        </div>
                      </div>
                      <div className="type-title">{item.type}</div>
                    </div>
                  </div>
                  <div className="nonprofit-nav-share">
                    <SocialShareComponent
                      url={item.viewUrl}
                      title={item.name}
                      description={item.description}
                      webLink={item.website}
                      noCount
                    />
                  </div>
                </div>
                <div className="nonprofit-nav-bottom">
                  <div className="nonprofit-nav-link">
                    {item.key !== '' ? (
                      <ul>
                        <li>
                          <MenuLink to={whyUs} scroll={!withHash}>
                            <ImgAboutInActive />
                            <span> Why Us ?</span>
                          </MenuLink>
                        </li>
                        <li>
                          <MenuLink to={whyNow} scroll={!withHash}>
                            <ImgProgramsInactive />
                            <span> Why now ?</span>
                          </MenuLink>
                        </li>
                        <li>
                          <MenuLink to={whatFor} scroll={!withHash}>
                            <ImgCampaignInactive />
                            <span> What for ?</span>
                          </MenuLink>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                  <div className="nonprofit-nav-share">
                    <SocialShareComponent
                      url={item.viewUrl}
                      title={item.name}
                      description={item.description}
                      webLink={item.website}
                      noCount
                    />
                  </div>
                  {item.key !== '' ? (
                    <div className="nonprofit-nav-donation">
                      <button type="button" className="w-button f-btn-danger" onClick={this.handleDonation}>
                        Support
                      </button>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
}

const MenuLink = ({ children, to, scroll }) => {
  if (scroll) {
    return (
      <ScrollLink
        activeClass="active"
        containerId="containerElement"
        to={to}
        spy
        smooth
        duration={1000}
        delay={300}
        offset={window.innerWidth >= 479 ? -265 : -415}
      >
        {children}
      </ScrollLink>
    );
  }

  return <HashLink to={to}>{children}</HashLink>;
};

MenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  scroll: PropTypes.bool,
};

MenuLink.defaultProps = {
  scroll: true,
};

NonprofitTopNav.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
  withHash: PropTypes.bool,
  noNavBottom: PropTypes.bool,
  noSupport: PropTypes.bool,
};

NonprofitTopNav.defaultProps = {
  authUser: null,
  withHash: false,
  noNavBottom: false,
  noSupport: false,
};

export default NonprofitTopNav;
