import React from 'react';
import { HashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';

import * as routes from '../constants/routes';

import ImgLaunchingCampaign from '../Assets/Images/heroPricing.svg';

import 'animate.css/animate.min.css';

function LaunchingCharity() {
  return (
    <div className="sectionpricing">
      <div className="contentwrapper">
        <div className="w-row">
          <div className="herocolumnimage-charity w-col w-col-3 w-col-stack">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <img src={ImgLaunchingCampaign} height={364} alt="" />
            </ScrollAnimation>
          </div>
          <div className="w-col w-col-9 w-col-stack">
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOnce
              delay={50}
            >
              <h3 className="h3">Giving should be straight forward and easy!</h3>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOnce
              delay={100}
            >
              <div className="herosecondarydescription">
                Problems may seem complicated but the act of giving should not be!
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOnce
              delay={150}
            >
              <div className="herocta">
                <HashLink
                  to={routes.CAMPAIGNS}
                  className="f-btn f-btn-success f-btn-space"
                >
                  <div>Explore Causes</div>
                </HashLink>
                <HashLink
                  to={routes.CAMPAIGNS}
                  className="f-link f-warning"
                >
                  Get Started
                </HashLink>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaunchingCharity;
