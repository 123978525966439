import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import queryString from 'query-string';
import { CSSTransition } from 'react-transition-group';
import { Progress } from 'react-sweet-progress';
import CurrencyInput from 'react-currency-masked-input';
import { CardElement } from 'react-stripe-elements';
import Loader from 'react-loader-spinner';
import ScrollAnimation from 'react-animate-on-scroll';
import WaitLoader from '../Components/Waiting';

import * as routes from '../constants/routes';
import withStripeContext from '../withStripeContext';
import { database } from '../firebase';
import { CampaignProvider, DonationManualProvider, MetricProvider } from '../Providers';
import { stringHelper, numberHelper, dateTimeHelper } from '../helpers';

import { BackButton, CustomBackButton } from '../Components/HtmlTag';
import FancyRadio from '../Components/FancyRadio';

import ImgCardAmex from '../Assets/Images/Cards/amex.svg';
import ImgCardDiners from '../Assets/Images/Cards/diners.svg';
import ImgCardDiscover from '../Assets/Images/Cards/discover.svg';
import ImgCardJcb from '../Assets/Images/Cards/jcb.svg';
import ImgCardMaestro from '../Assets/Images/Cards/maestro.svg';
import ImgCardMaster from '../Assets/Images/Cards/mastercard.svg';
import ImgCardUnion from '../Assets/Images/Cards/unionpay.svg';
import ImgCardVisa from '../Assets/Images/Cards/visa.svg';
import ImgCardDefault from '../Assets/Images/Cards/default.svg';

import 'animate.css/animate.min.css';
import 'react-sweet-progress/lib/style.css';

const CardImage = {
  'American Express': ImgCardAmex,
  'Diners Club': ImgCardDiners,
  Discover: ImgCardDiscover,
  JCB: ImgCardJcb,
  Maestro: ImgCardMaestro,
  MasterCard: ImgCardMaster,
  UnionPay: ImgCardUnion,
  Visa: ImgCardVisa,
  Default: ImgCardDefault,
};

class StripeDonation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountReceivableId: '',
      step: 0,
      percent: 33,
      disabledButton: true,
      waiting: true,
      nonprofit: null,
      agentProfile: null,
      programs: [],
      selectedProgram: null,
      metrics: [],
      selectedMetric: null,
      campaigns: [],
      selectedCampaign: null,
      showStripeLoading: false,
      idTrx: '',
      receiptId: '',
      showProgram: false,
      showMetric: false,
      showCampaign: false,
      metricImpact: '',
      quantity: 0,
      totalDonation: 0.0,
      totalDonationText: '',
      totalSharing: 0,
      coverUs: '1',
      donationCategory: 'Fundraising',
      donationType: 'Donation',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      anonymous: true,
      comment: '',
      recurring: '',
      dedication: '',
      dedicationName: '',
      donationMethod: 'Online',
      error: false,
      errorMessage: '',
      cardComplete: false,
      stripeResource: null,
      selectedCard: null,
      donationDone: false,
      showBack: false,
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleDonationButton = this.handleDonationButton.bind(this);
    this.handleDonationMetricButton = this.handleDonationMetricButton.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCardElementChange = this.handleCardElementChange.bind(this);
    this.handleConfirmationDonation = this.handleConfirmationDonation.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleBackProfileClick = this.handleBackProfileClick.bind(this);
    this.handleCampaignSelected = this.handleCampaignSelected.bind(this);
    this.handleMetricSelected = this.handleMetricSelected.bind(this);
    this.handleClearSelection = this.handleClearSelection.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, match, location } = this.props;
    updateNavStatus('management');
    showFooterLinkContainer(false);

    let accountReceivableId = '';
    const { id } = match.params;
    const urlQuery = queryString.parse(location.search);
    if (urlQuery.ar) {
      accountReceivableId = urlQuery.ar;
    }

    this.setState({ accountReceivableId }, () => {
      this.getCampaignList(id);
    });
  }

  getProgramList(id) {
    database
      .getRef('/nonprofit_programs')
      .child(id)
      .once('value')
      .then(snap => {
        const programs = [];
        snap.forEach(childSnap => {
          programs.push({
            id: childSnap.val().id,
            name: childSnap.val().name,
          });
        });

        this.setState({ programs });
      });
  }

  getCampaignList(id) {
    CampaignProvider.getList(Number.parseInt(id, 10)).then(snap => {
      const campaigns = [];
      snap.forEach(childSnap => {
        if (childSnap.val().published) {
          campaigns.push({
            id: childSnap.val().id,
            name: childSnap.val().name,
            program: childSnap.val().program,
            metric: childSnap.val().metric,
          });
        }
      });

      this.setState({ campaigns, showCampaign: campaigns.length > 0, showMetric: campaigns.length === 0 }, () => {
        this.getNonprofitMetric(id);
      });
    });
  }

  getNonprofitMetric(nid) {
    MetricProvider.getList(nid).then(metricSnapshot => {
      const metrics = [];
      metricSnapshot.forEach(item => {
        metrics.push({
          id: item.key,
          name: item.val().name,
          cost: item.val().cost,
          costNumber: item.val().costNumber,
          unitName: item.val().unitName ? item.val().unitName.trim() : 'pack',
          program: item.val().program,
          humanNumber: item.val().humanNumber ? item.val().humanNumber : 0,
          humanUnit: item.val().humanUnit ? item.val().humanUnit.trim() : '',
          cover: item.val().cover,
          frequency: item.val().frequency,
        });
      });

      this.setState({ metrics }, () => {
        this.findNonprofit(nid);
      });
    });
  }

  async findNonprofit(nid) {
    const { accountReceivableId, campaigns, metrics } = this.state;
    let {
      donationCategory,
      donationType,
      firstName,
      lastName,
      email,
      phone,
      anonymous,
      showProgram,
      showMetric,
      showCampaign,
      selectedProgram,
      selectedCampaign,
      selectedMetric,
      percent,
      quantity,
    } = this.state;
    const snap = await database
      .getRef('/nonprofit')
      .child(nid)
      .once('value');
    const viewUrl = routes.NONPROFITS_ABOUT;
    const programUrl = routes.NONPROFITS_PROGRAMS;
    const slugName = stringHelper.slugify(snap.val().name);
    const nonprofit = {
      agent: snap.val().agent,
      img: snap.val().img,
      key: snap.val().key,
      name: snap.val().name,
      slugName,
      viewUrl: viewUrl.replace(':id', snap.val().key).replace(':name', slugName),
      programUrl: programUrl.replace(':id', snap.val().key).replace(':name', slugName),
      stripe_account: snap.val().stripe_account ? snap.val().stripe_account : null,
      subscribe: snap.val().subscribe ? snap.val().subscribe : null,
    };

    this.setState({ nonprofit });

    if (accountReceivableId !== '') {
      const arSnap = await DonationManualProvider.getDetail(nid, accountReceivableId);
      donationCategory = 'Cash Deposit';
      donationType = arSnap.val().type;
      firstName = arSnap.val().user.firstName ? arSnap.val().user.firstName : '';
      lastName = arSnap.val().user.lastName ? arSnap.val().user.lastName : '';
      email = arSnap.val().user.email ? arSnap.val().user.email : '';
      phone = arSnap.val().user.phone ? arSnap.val().user.phone : '';
      anonymous = false;
      showProgram = false;
      selectedProgram = arSnap.val().program;
      if (arSnap.val().campaign) {
        selectedCampaign = arSnap.val().campaign;
        showCampaign = false;
      }
      if (arSnap.val().metric) {
        selectedMetric = arSnap.val().metric;
        showMetric = false;
      }
    } else if (campaigns.length === 0 && metrics.length === 0) {
      showProgram = true;
      showMetric = false;
      showCampaign = false;
      this.getProgramList(nid);
    }

    if (!showProgram && !showMetric && !showCampaign) {
      percent += 30;
    }

    if (selectedMetric) {
      quantity = 1;
      const totalDonation = quantity * Number.parseFloat(selectedMetric.cost);
      this.updateTotalDonation(totalDonation.toFixed(2));
    }

    this.setState({
      percent,
      quantity,
      waiting: false,
      donationCategory,
      donationType,
      firstName,
      lastName,
      email,
      phone,
      anonymous,
      showProgram,
      showCampaign,
      showMetric,
      selectedProgram,
      selectedCampaign,
      selectedMetric,
    });
  }

  updateTotalDonation(key) {
    const { nonprofit } = this.state;
    const totalDonation = Number.parseFloat(key);
    let totalAmount = totalDonation;
    let feeCost = 0.05;
    if (nonprofit.subscribe) {
      feeCost = Number.parseFloat(nonprofit.subscribe.fee);
    }
    if (feeCost > 0) {
      const fundraFee = Number.parseFloat(totalDonation * feeCost).toFixed(2);
      totalAmount += Number.parseFloat(fundraFee);
    }
    const totalSharing = Number.parseFloat((totalAmount + 0.3) / (1 - 0.029)).toFixed(2);

    this.setState({
      totalDonation,
      totalDonationText: key,
      totalSharing,
      disabledButton: totalDonation === 0,
    });
  }

  handleAmountChange(event, maskValue) {
    this.updateTotalDonation(maskValue);
  }

  handleDonationButton(key) {
    this.updateTotalDonation(key);
  }

  handleDonationMetricButton(key) {
    const { selectedMetric } = this.state;
    const quantity = Number.parseInt(key, 10);
    const totalDonation = quantity * Number.parseFloat(selectedMetric.cost);

    this.setState({ quantity }, () => {
      this.updateTotalDonation(totalDonation.toFixed(2));
    });
  }

  handleRadioChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({
      [name]: value,
    });
  }

  handleBackClick(e) {
    e.preventDefault();

    this.setState(prevState => {
      const { showProgram, showMetric, showCampaign } = prevState;
      const initialPercent = !showProgram && !showMetric && !showCampaign ? 60 : 30;
      const percent = prevState.percent >= 100 ? initialPercent : prevState.percent - 30;
      const step = prevState.percent >= 100 ? 0 : prevState.step - 1;

      return {
        step,
        percent,
        metricImpact: '',
        stripeResource: null,
        selectedCard: null,
        showBack: step !== 0,
        disabledButton: false,
        cardComplete: false,
        showStripeLoading: false,
      };
    });
  }

  handleBackProfileClick(e) {
    e.preventDefault();

    const { nonprofit } = this.state;
    const { history } = this.props;
    const backUrl = routes.DONORS_MANAGEMENT.replace(':id', nonprofit.key).replace(
      ':name',
      stringHelper.slugify(nonprofit.name)
    );
    history.push(backUrl);
  }

  async handleNext() {
    const {
      accountReceivableId,
      nonprofit,
      firstName,
      lastName,
      email,
      phone,
      donationCategory,
      donationType,
      cardComplete,
    } = this.state;
    let { idTrx } = this.state;
    const { stripe } = this.props;

    this.setState({
      error: false,
      errorMessage: '',
      showBack: false,
    });

    if (cardComplete) {
      let goNext = false;
      if (accountReceivableId === '') {
        if (email.trim() === '' || !stringHelper.isValidEmailAddress(email)) {
          this.setState({ error: true, errorMessage: 'Email Address required or in wrong format' });
        } else if (phone !== '' && !stringHelper.isValidPhoneNumber(phone)) {
          this.setState({
            error: true,
            errorMessage: 'Wrong format for Phone Number (123) 456-7890',
          });
        } else if (donationCategory === 'Cash Deposit') {
          if (phone === '' && (donationType === 'Service' || donationType === 'Pledge')) {
            this.setState({
              error: true,
              errorMessage: 'Phone number required for Service/Pledge transaction',
            });
          } else {
            goNext = true;
          }
        } else {
          goNext = true;
        }
      } else {
        goNext = true;
      }

      if (goNext) {
        document.location.href = '#top';
        this.setState({ showStripeLoading: true, percent: 100 });

        let agentProfile = null;
        if (nonprofit.agent) {
          const { authUser } = this.props;
          agentProfile = {
            uid: authUser.profile.uid ? authUser.profile.uid : authUser.user.uid,
            email: authUser.profile.email,
            firstName: authUser.profile.firstName,
            lastName: authUser.profile.lastName,
          };
        }

        if (idTrx === '') {
          idTrx = database.pushEmptyRef('/tmpTrx').key;
        }

        const stripeData = { type: 'card' };
        const owner = {};
        if (firstName !== '') {
          owner.name = `${firstName} ${lastName}`;
        }
        if (email !== '') {
          owner.email = email;
        }
        if (phone !== '') {
          owner.phone = stringHelper.trimPhone(phone);
        }
        if (owner.constructor === Object && Object.keys(owner).length > 0) {
          stripeData.owner = owner;
        }

        const stripeResult = await stripe.createSource(stripeData);
        if (stripeResult.error) {
          this.setState({
            error: true,
            errorMessage: stripeResult.error.message,
            disabledButton: false,
          });
        } else {
          this.setState(prevState => {
            const nextStep = prevState.showProgram || prevState.showMetric || prevState.showCampaign ? 1 : 2;
            const { totalDonation, selectedMetric } = prevState;
            let metricImpact = '';
            if (selectedMetric) {
              // eslint-disable-next-line max-len
              const totalImpact = Number.parseFloat(totalDonation) / Number.parseFloat(selectedMetric.cost);
              const totalPack = totalImpact * Number.parseInt(selectedMetric.costNumber, 10);
              const totalHuman = totalImpact * Number.parseInt(selectedMetric.humanNumber, 10);
              metricImpact = `${numberHelper.getThousandFormat(totalPack.toFixed(2))} ${stringHelper.makePluralize(
                selectedMetric.unitName,
                totalPack
              )}`;
              if (totalHuman > 0) {
                metricImpact += ` to ${numberHelper.getThousandFormat(
                  totalHuman.toFixed(2)
                )} ${stringHelper.makePluralize(selectedMetric.humanUnit, totalHuman)}`;
              }
            }

            return {
              idTrx,
              receiptId: dateTimeHelper.getCurrentTimeMs(),
              agentProfile,
              metricImpact,
              error: false,
              errorMessage: '',
              stripeResource: stripeResult.source,
              step: prevState.step + nextStep,
              disabledButton: true,
              showBack: true,
              showStripeLoading: false,
            };
          });
        }
      }
    } else {
      document.location.href = '#top';

      this.setState(prevState => {
        let disabledButton = true;
        if (prevState.step === 0 && (prevState.showProgram || prevState.showMetric || prevState.showCampaign)) {
          if (prevState.totalDonation > 0) {
            disabledButton = false;
          }
        }

        return {
          // donationDone: true,
          showBack: true,
          disabledButton,
          step: prevState.step + 1,
          percent: prevState.percent + 30,
        };
      });
    }
  }

  handleProgramSelected(item) {
    this.setState({ selectedProgram: item, disabledButton: false });
  }

  handleMetricSelected(item) {
    this.setState(
      {
        quantity: 1,
        selectedMetric: item,
        selectedProgram: item.program,
        disabledButton: false,
      },
      () => {
        const totalDonation = 1 * Number.parseFloat(item.cost);
        this.updateTotalDonation(totalDonation.toFixed(2));
      }
    );
  }

  handleCampaignSelected(item) {
    let hasMetric = false;
    if (item.metric) {
      const { metrics } = this.state;
      const filter = metrics.filter(metric => metric.id === item.metric);
      const selectedMetric = filter.length ? filter[0] : null;
      if (selectedMetric) {
        hasMetric = true;
        this.setState(
          {
            quantity: 1,
            selectedCampaign: item,
            selectedMetric,
            selectedProgram: selectedMetric.program,
            disabledButton: false,
          },
          () => {
            const totalDonation = 1 * Number.parseFloat(selectedMetric.cost);
            this.updateTotalDonation(totalDonation.toFixed(2));
          }
        );
      }
    }

    if (!hasMetric) {
      this.setState({
        selectedCampaign: item,
        quantity: 0,
        selectedProgram: item.program,
        selectedMetric: null,
        disabledButton: false,
      });
    }
  }

  handleClearSelection(event, key) {
    event.preventDefault();
    let { recurring, dedication, dedicationName } = this.state;

    if (key === 1) {
      recurring = '';
    } else if (key === 2) {
      dedication = '';
      dedicationName = '';
    }

    this.setState({
      recurring,
      dedication,
      dedicationName,
    });
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { dedication } = this.state;

    if (name === 'dedicationName') {
      if (value !== '' && dedication === '') {
        this.setState({ dedication: '{"id": 1, "title": "Dedicated to"}' });
      } else if (value === '' && dedication !== '') {
        this.setState({ dedication: '' });
      }
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === 'firstName' || name === 'lastName' || name === 'email') {
          this.validateForm();
        }
      }
    );
  }

  handleCardElementChange(change) {
    if (change.error) {
      this.setState({
        error: true,
        errorMessage: change.error.message,
        cardComplete: false,
        disabledButton: true,
      });
    } else {
      this.setState(
        {
          error: false,
          errorMessage: '',
          cardComplete: change.complete,
        },
        () => {
          this.validateForm();
        }
      );
    }
  }

  handleConfirmationDonation() {
    document.location.href = '#top';

    this.setState({ showStripeLoading: true, percent: 100, showBack: false });
    const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const PROCESS_URL = process.env.REACT_APP_DONATION_PROCESS_URL;
    const {
      email,
      phone,
      firstName,
      lastName,
      comment,
      anonymous,
      idTrx,
      receiptId,
      totalDonation,
      totalDonationText,
      nonprofit,
      selectedProgram,
      selectedCampaign,
      selectedMetric,
      coverUs,
      dedication,
      dedicationName,
      agentProfile,
      stripeResource,
      selectedCard,
      donationMethod,
      recurring,
      donationCategory,
      donationType,
    } = this.state;

    const userInfo = {
      firstName,
      lastName,
      phone: stringHelper.trimPhone(phone),
      comment,
    };
    if (email !== '') {
      userInfo.email = email;
    }
    if (firstName !== '') {
      userInfo.fullName = `${firstName} ${lastName}`;
    }
    if (anonymous) {
      userInfo.anonymous = true;
    }

    const nonprofitInfo = {
      key: nonprofit.key.toString(),
      name: nonprofit.name,
      logo: nonprofit.img.logo,
    };
    if (nonprofit.stripe_account) {
      nonprofitInfo.stripe_account = nonprofit.stripe_account;
    }

    const formData = {
      idTrx,
      receipt_id: receiptId,
      total: { amount: totalDonation },
      from: 'web',
      category: donationCategory,
      type: donationType,
      method: donationMethod,
      nonprofit: nonprofitInfo,
      program: {
        id: selectedProgram.id,
        name: selectedProgram.name,
      },
      user: userInfo,
      fee: { cover: coverUs },
      dedication: {
        type: dedication,
        value: dedicationName,
      },
    };

    if (selectedCampaign) {
      formData.campaign = {
        id: selectedCampaign.id,
        name: selectedCampaign.name,
      };
    }

    if (selectedMetric) {
      formData.metric = {
        id: selectedMetric.id,
        name: selectedMetric.name,
        cost: selectedMetric.cost,
        costNumber: selectedMetric.costNumber,
        unitName: selectedMetric.unitName,
        humanNumber: selectedMetric.humanNumber,
        humanUnit: selectedMetric.humanUnit,
      };
    }

    if (nonprofit.agent) {
      formData.agent = agentProfile;
    }

    if (recurring !== '') {
      formData.recurring = { when: Number.parseInt(recurring, 10) };
    }

    if (stripeResource) {
      formData.source = JSON.stringify(stripeResource);
    }
    if (selectedCard) {
      formData.card = selectedCard;
    }

    axios
      .post(PROCESS_URL, qs.stringify(formData), axiosConfig)
      .then(() => {
        const manualFormData = {
          category: donationCategory,
          type: donationType,
          nonprofit: nonprofitInfo,
          program: {
            id: selectedProgram.id.toString(),
            name: selectedProgram.name,
          },
          user: userInfo,
          date: dateTimeHelper.getCurrentTimeMs(),
          total: { amount: totalDonationText },
          method: donationMethod,
        };

        if (selectedCampaign) {
          manualFormData.campaign = {
            id: selectedCampaign.id,
            name: selectedCampaign.name,
          };
        }

        if (selectedMetric) {
          manualFormData.metric = {
            id: selectedMetric.id,
            name: selectedMetric.name,
            cost: selectedMetric.cost,
            costNumber: selectedMetric.costNumber,
            unitName: selectedMetric.unitName,
            humanNumber: selectedMetric.humanNumber,
            humanUnit: selectedMetric.humanUnit,
          };
        }

        if (dedication !== '') {
          manualFormData.dedication = { type: JSON.parse(dedication), value: dedicationName };
        }

        if (nonprofit.agent) {
          manualFormData.agent = agentProfile;
        }

        DonationManualProvider.pushData(nonprofit.key, manualFormData).then(() => {
          this.setState({ donationDone: true, showStripeLoading: false });
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  validateForm() {
    const { cardComplete } = this.state;
    this.setState({ disabledButton: !cardComplete });
  }

  render() {
    document.title = 'Send Your Donation - Fundra';

    const {
      accountReceivableId,
      donationDone,
      waiting,
      step,
      coverUs,
      firstName,
      lastName,
      email,
      phone,
      totalSharing,
      totalDonation,
      totalDonationText,
      stripeResource,
      nonprofit,
      programs,
      selectedProgram,
      percent,
      anonymous,
      comment,
      dedication,
      dedicationName,
      error,
      errorMessage,
      disabledButton,
      showBack,
      recurring,
      donationCategory,
      donationType,
      selectedCampaign,
      selectedMetric,
      metrics,
      campaigns,
      showProgram,
      showMetric,
      showCampaign,
      showStripeLoading,
      quantity,
      metricImpact,
    } = this.state;
    const totalCharge = coverUs === '1' ? totalSharing : totalDonationText;

    let firstView = null;
    let secondView = null;
    let thirdView = null;
    if (!donationDone && !waiting) {
      if (step === 0) {
        if (showProgram) {
          firstView = (
            <HowDonationProgramSection
              programs={programs}
              selectedProgram={selectedProgram}
              handleProgramSelected={this.handleProgramSelected}
            />
          );
        } else if (showMetric) {
          firstView = (
            <HowDonationMetricSection
              metrics={metrics}
              selectedMetric={selectedMetric}
              handleMetricSelected={this.handleMetricSelected}
            />
          );
        } else if (showCampaign) {
          firstView = (
            <HowDonationCampaignSection
              campaigns={campaigns}
              selectedCampaign={selectedCampaign}
              handleCampaignSelected={this.handleCampaignSelected}
            />
          );
        } else {
          firstView = (
            <HowMuchSection
              nonprofit={nonprofit}
              selectedMetric={selectedMetric}
              totalDonation={totalDonation}
              totalDonationText={totalDonationText}
              coverUs={coverUs}
              totalSharing={totalSharing.toString()}
              quantity={quantity}
              handleAmountChange={this.handleAmountChange}
              handleDonationButton={this.handleDonationButton}
              handleRadioChange={this.handleRadioChange}
              handleDonationMetricButton={this.handleDonationMetricButton}
            />
          );
        }
      } else if (step === 1) {
        if (showProgram || showMetric || showCampaign) {
          secondView = (
            <HowMuchSection
              nonprofit={nonprofit}
              selectedMetric={selectedMetric}
              totalDonation={totalDonation}
              totalDonationText={totalDonationText}
              coverUs={coverUs}
              totalSharing={totalSharing.toString()}
              quantity={quantity}
              handleAmountChange={this.handleAmountChange}
              handleDonationButton={this.handleDonationButton}
              handleRadioChange={this.handleRadioChange}
              handleDonationMetricButton={this.handleDonationMetricButton}
            />
          );
        } else {
          secondView = (
            <DonateProcessSection
              accountReceivableId={accountReceivableId}
              donationCategory={donationCategory}
              donationType={donationType}
              firstName={firstName}
              lastName={lastName}
              email={email}
              phone={phone}
              anonymous={anonymous}
              comment={comment}
              recurring={recurring}
              dedication={dedication}
              dedicationName={dedicationName}
              showStripeLoading={showStripeLoading}
              handleRadioChange={this.handleRadioChange}
              handleChange={this.handleChange}
              handleCardElementChange={this.handleCardElementChange}
              handleClearSelection={this.handleClearSelection}
            />
          );
        }
      } else if (step === 2) {
        thirdView = (
          <DonateProcessSection
            accountReceivableId={accountReceivableId}
            donationCategory={donationCategory}
            donationType={donationType}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            anonymous={anonymous}
            comment={comment}
            recurring={recurring}
            dedication={dedication}
            dedicationName={dedicationName}
            showStripeLoading={showStripeLoading}
            handleRadioChange={this.handleRadioChange}
            handleChange={this.handleChange}
            handleCardElementChange={this.handleCardElementChange}
            handleClearSelection={this.handleClearSelection}
          />
        );
      }
    }

    return (
      <div className="DonationSection">
        <div className="contentwrapper">
          <div className="backlink">
            <BackButton showBackButton={showBack} handleClick={this.handleBackClick} />
            <CustomBackButton
              showBackButton={!showBack && !showStripeLoading}
              handleClick={this.handleBackProfileClick}
              title="Back to donor management"
            />
          </div>
          {!donationDone ? (
            <React.Fragment>
              {waiting ? (
                <WaitLoader clsStyle="section-loader-ss" />
              ) : (
                <div className="w-row donationRow">
                  {step < 3 ? (
                    <div className="w-col w-col-8 w-col-stack">
                      <h3 className="h3 donor-title">Donate</h3>
                      <div className="progressbar">
                        <Progress percent={percent} theme={{ default: { symbol: ' ' } }} />
                      </div>
                      {step === 0 ? (
                        <ScrollAnimation animateIn="fadeInUp" animateOnce offset={0}>
                          {firstView}
                        </ScrollAnimation>
                      ) : null}
                      {showStripeLoading ? (
                        <div className="section-loader-container-right" style={{ marginTop: 100, marginBottom: 50 }}>
                          <Loader color="#EB7557" type="Oval" height={60} width={60} />
                        </div>
                      ) : null}
                      <CSSTransition in={step === 1} timeout={500} classNames="registerstep" unmountOnExit>
                        {() => secondView}
                      </CSSTransition>
                      <CSSTransition in={step === 2} timeout={500} classNames="registerstep" unmountOnExit>
                        {() => thirdView}
                      </CSSTransition>
                      <CSSTransition in={error} timeout={500} classNames="errormessage" unmountOnExit>
                        {() => (
                          <div className="error-block">
                            <div className="error-block-text">{errorMessage}</div>
                          </div>
                        )}
                      </CSSTransition>
                      {!showStripeLoading ? (
                        <div className="donation-next">
                          <button
                            type="button"
                            className="w-button f-btn f-btn-danger"
                            disabled={disabledButton}
                            onClick={this.handleNext}
                          >
                            Next
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="w-col w-col-8 w-col-stack">
                      <h3 className="h3 donor-title">Confirm donation</h3>
                      {showStripeLoading ? (
                        <div className="section-loader-container-right" style={{ marginTop: 100 }}>
                          <Loader color="#EB7557" type="Oval" height={60} width={60} />
                        </div>
                      ) : (
                        <React.Fragment>
                          <CSSTransition in={step === 3} timeout={500} classNames="registerstep" unmountOnExit>
                            {() => (
                              <React.Fragment>
                                <label className="donation-medium-label">Total charge</label>
                                <label className="donation-amount-label">{`$${totalCharge}`}</label>
                                <label className="donate-question">Pay with this credit card:</label>
                                <label className="donation-small-label">{`${firstName} ${lastName}`}</label>
                                <div className="donation-card-info">
                                  <img
                                    src={
                                      CardImage[stripeResource.card.brand]
                                        ? CardImage[stripeResource.card.brand]
                                        : CardImage.Default
                                    }
                                    alt={stripeResource.card.brand}
                                  />
                                  <label className="donation-small-label">{stripeResource.card.brand}</label>
                                  <label className="donation-small-label">&#8226;&#8226;&#8226;&#8226;</label>
                                  <label className="donation-small-label">{stripeResource.card.last4}</label>
                                </div>
                                <label className="donate-question">Additional information:</label>
                                {selectedMetric ? (
                                  <label className="donation-small-label more-big">
                                    <strong>
                                      {'You are providing '}
                                      <span className="f-lowercase">{metricImpact}</span>
                                    </strong>
                                  </label>
                                ) : (
                                  <React.Fragment>
                                    <label className="donation-small-label">
                                      {'Donate To '}
                                      <strong>{nonprofit.name}</strong>
                                    </label>
                                    <label className="donation-small-label">
                                      {'Program '}
                                      <strong>{selectedProgram.name}</strong>
                                    </label>
                                    {selectedCampaign ? (
                                      <label className="donation-small-label">
                                        {'Campaign '}
                                        <strong>{selectedCampaign.name}</strong>
                                      </label>
                                    ) : null}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                          </CSSTransition>
                          <div className="donation-next">
                            <button
                              type="button"
                              className="w-button f-btn f-btn-danger"
                              onClick={this.handleConfirmationDonation}
                            >
                              Confirm Donation
                            </button>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="DonationSuccess" style={{ marginTop: 40 }}>
              <ScrollAnimation animateIn="fadeInDown" animateOnce offset={0} delay={400}>
                <div className="claimsuccess notop">
                  <div className="alert-base">
                    <div className="alert-base-text">Thank you for your donation!</div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          )}
        </div>
      </div>
    );
  }
}

StripeDonation.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
  stripe: PropTypes.instanceOf(Object),
};

StripeDonation.defaultProps = {
  authUser: null,
  stripe: null,
};

const HowMuchSection = ({
  nonprofit,
  selectedMetric,
  totalDonation,
  totalDonationText,
  coverUs,
  totalSharing,
  quantity,
  handleAmountChange,
  handleDonationButton,
  handleRadioChange,
  handleDonationMetricButton,
}) => {
  let metricButtonView = null;
  let frequencyText = null;

  if (selectedMetric) {
    const { frequency } = selectedMetric;
    const multiplyOne = 1 * selectedMetric.costNumber;
    const multiplyThree = 3 * selectedMetric.costNumber;
    const multiplyFive = 5 * selectedMetric.costNumber;
    const multiplyTen = 10 * selectedMetric.costNumber;

    if (frequency && Number.parseInt(frequency.number, 10) > 0 && frequency.name !== '') {
      frequencyText = ` / ${Number.parseInt(frequency.number, 10)} ${stringHelper.makePluralize(
        frequency.name,
        Number.parseInt(frequency.number, 10)
      )}`;
    }

    metricButtonView = (
      <React.Fragment>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 1 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(1)}
          >
            <span>{multiplyOne}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyOne)}</span>
          </button>
        </div>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 3 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(3)}
          >
            <span>{multiplyThree}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyThree)}</span>
          </button>
        </div>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 5 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(5)}
          >
            <span>{multiplyFive}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyFive)}</span>
          </button>
        </div>
        <div className="float-item btn-metric">
          <button
            type="button"
            className={`w-button f-btn-primary${quantity === 10 ? ' f-w-button-selected' : ''}`}
            onClick={() => handleDonationMetricButton(10)}
          >
            <span>{multiplyTen}</span>
            <span className="f-font-12">{stringHelper.makePluralize(selectedMetric.unitName, multiplyTen)}</span>
          </button>
        </div>
      </React.Fragment>
    );
  }

  const hideCoverUs = nonprofit.subscribe && nonprofit.subscribe.hideCoverUs;

  return (
    <React.Fragment>
      <label className="donate-question">How much do you want to donate?</label>
      <div className="donation-input">
        <div className="field-control">
          <CurrencyInput
            name="totalDonationText"
            value={totalDonationText}
            inputMode="numeric"
            placeholder="input amount here"
            onChange={handleAmountChange}
          />
        </div>
        <div className={totalDonationText === '' ? 'field-caption' : 'field-caption filled'}>$</div>
      </div>
      <div className="donation-money">
        <div className="float-left-section">
          {!selectedMetric ? (
            <React.Fragment>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('5.00')}>
                  $5
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('10.00')}>
                  $10
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('20.00')}>
                  $20
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('30.00')}>
                  $30
                </button>
              </div>
              <div className="float-item btn-money">
                <button type="button" className="w-button f-btn-primary" onClick={() => handleDonationButton('50.00')}>
                  $50
                </button>
              </div>
            </React.Fragment>
          ) : (
            metricButtonView
          )}
        </div>
      </div>
      {selectedMetric ? (
        <div className="f-lowercase">
          <strong>
            {`($${numberHelper.getThousandFormat(
              Number.parseFloat(selectedMetric.cost).toFixed(2)
            )} supports ${stringHelper.makePluralize(
              selectedMetric.unitName,
              Number.parseFloat(selectedMetric.costNumber),
              true
            )}`}
            {Number.parseFloat(selectedMetric.humanNumber) > 0 ? (
              <React.Fragment>
                {` for ${stringHelper.makePluralize(
                  selectedMetric.humanUnit,
                  Number.parseFloat(selectedMetric.humanNumber),
                  true
                )}`}
              </React.Fragment>
            ) : null}
            {frequencyText}
            {')'}
          </strong>
        </div>
      ) : null}
      <div className="donation-gray-box">
        <label className="helpUs-label">Help us cover!</label>
        {hideCoverUs ? (
          <label className="helpUs-label-caption">
            A small processing fee will be included in your donation to ensure that 100% of your intended donation is
            available for our mission
          </label>
        ) : (
          <React.Fragment>
            <label className="helpUs-label-caption">
              Simply leave “Yes!” checked below to ensure that 100% of your intended donation is available for our
              mission
            </label>
            <div>
              <FancyRadio
                id="coverUs-1"
                name="coverUs"
                value="1"
                checked={coverUs === '1'}
                onChange={handleRadioChange}
              >
                <span>
                  {`Yes! I want $${totalDonation.toFixed(0)} to go to ${
                    nonprofit.name
                  } and I will donate $${totalSharing}`}
                </span>
              </FancyRadio>
              <FancyRadio
                id="coverUs-2"
                name="coverUs"
                value="2"
                checked={coverUs === '2'}
                onChange={handleRadioChange}
              >
                <span>
                  {`No. I will donate $${totalDonation.toFixed(0)} and ${
                    nonprofit.name
                  } will cover the processing costs`}
                </span>
              </FancyRadio>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

HowMuchSection.propTypes = {
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  selectedMetric: PropTypes.instanceOf(Object),
  totalDonation: PropTypes.number.isRequired,
  totalDonationText: PropTypes.string.isRequired,
  coverUs: PropTypes.string.isRequired,
  totalSharing: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  handleAmountChange: PropTypes.func.isRequired,
  handleDonationButton: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleDonationMetricButton: PropTypes.func.isRequired,
};

HowMuchSection.defaultProps = { selectedMetric: null, quantity: 0 };

const HowDonationProgramSection = ({ programs, selectedProgram, handleProgramSelected }) => (
  <React.Fragment>
    <label className="donate-question">How would you like your donation to be used?</label>
    <label className="donation-small-label" style={{ marginTop: 32, marginBottom: 24 }}>
      Choose a specific program
    </label>
    <div className="donation-program">
      <div className="float-left-section">
        {programs.map(item => {
          let clsName = 'w-button f-btn-primary';
          if (selectedProgram && selectedProgram.id === item.id) {
            clsName += ' selected';
          }

          return (
            <div className="float-item" key={item.id}>
              <button type="button" className={clsName} onClick={() => handleProgramSelected(item)}>
                {item.name}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  </React.Fragment>
);

HowDonationProgramSection.propTypes = {
  programs: PropTypes.instanceOf(Array).isRequired,
  selectedProgram: PropTypes.instanceOf(Object),
  handleProgramSelected: PropTypes.func.isRequired,
};

HowDonationProgramSection.defaultProps = { selectedProgram: null };

const HowDonationMetricSection = ({ metrics, selectedMetric, handleMetricSelected }) => (
  <React.Fragment>
    <label className="donate-question">How would you like your donation to be used?</label>
    <label className="donation-small-label" style={{ marginTop: 32, marginBottom: 24 }}>
      Choose a specific metric
    </label>
    <div className="donation-program">
      <div className="float-left-section">
        {metrics.map(item => {
          let clsName = 'w-button f-btn-primary';
          if (selectedMetric && selectedMetric.id === item.id) {
            clsName += ' selected';
          }

          return (
            <div className="float-item" key={item.id}>
              <button type="button" className={clsName} onClick={() => handleMetricSelected(item)}>
                {item.name}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  </React.Fragment>
);

HowDonationMetricSection.propTypes = {
  metrics: PropTypes.instanceOf(Array).isRequired,
  selectedMetric: PropTypes.instanceOf(Object),
  handleMetricSelected: PropTypes.func.isRequired,
};

HowDonationMetricSection.defaultProps = { selectedMetric: null };

const HowDonationCampaignSection = ({ campaigns, selectedCampaign, handleCampaignSelected }) => (
  <React.Fragment>
    <label className="donate-question">How would you like your donation to be used?</label>
    <label className="donation-small-label" style={{ marginTop: 32, marginBottom: 24 }}>
      Choose a specific campaign
    </label>
    <div className="donation-program">
      <div className="float-left-section">
        {campaigns.map(item => {
          let clsName = 'w-button f-btn-primary';
          if (selectedCampaign && selectedCampaign.id.toString() === item.id.toString()) {
            clsName += ' selected';
          }

          return (
            <div className="float-item" key={item.id}>
              <button type="button" className={clsName} onClick={() => handleCampaignSelected(item)}>
                {item.name}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  </React.Fragment>
);

HowDonationCampaignSection.propTypes = {
  campaigns: PropTypes.instanceOf(Array).isRequired,
  selectedCampaign: PropTypes.instanceOf(Object),
  handleCampaignSelected: PropTypes.func.isRequired,
};

HowDonationCampaignSection.defaultProps = { selectedCampaign: null };

const DonateProcessSection = ({
  accountReceivableId,
  donationCategory,
  donationType,
  firstName,
  lastName,
  email,
  phone,
  anonymous,
  comment,
  recurring,
  dedication,
  dedicationName,
  showStripeLoading,
  handleRadioChange,
  handleChange,
  handleCardElementChange,
  handleClearSelection,
}) => {
  const applyStyle = { marginTop: 32 };
  if (showStripeLoading) {
    applyStyle.display = 'none';
  }

  return (
    <div style={applyStyle}>
      {accountReceivableId === '' ? (
        <React.Fragment>
          <label className="formfieldlabel">Transaction Category</label>
          <div className="f-radio-group" style={{ marginBottom: 15 }}>
            <FancyRadio
              id="donationCategory-1"
              name="donationCategory"
              value="Fundraising"
              checked={donationCategory === 'Fundraising'}
              onChange={handleRadioChange}
            >
              <span>Fundraising</span>
            </FancyRadio>
            <FancyRadio
              id="donationCategory-2"
              name="donationCategory"
              value="Cash Deposit"
              checked={donationCategory === 'Cash Deposit'}
              onChange={handleRadioChange}
            >
              <span>Cash Deposit</span>
            </FancyRadio>
          </div>
          <label className="formfieldlabel">Transaction Type</label>
          <div className="f-radio-group" style={{ marginBottom: 15 }}>
            <FancyRadio
              id="donationType-1"
              name="donationType"
              value="Donation"
              checked={donationType === 'Donation'}
              onChange={handleRadioChange}
            >
              <span>Donation</span>
            </FancyRadio>
            <FancyRadio
              id="donationType-2"
              name="donationType"
              value="Service"
              checked={donationType === 'Service'}
              onChange={handleRadioChange}
            >
              <span>Service</span>
            </FancyRadio>
            <FancyRadio
              id="donationType-3"
              name="donationType"
              value="Pledge"
              checked={donationType === 'Pledge'}
              onChange={handleRadioChange}
            >
              <span>Pledge</span>
            </FancyRadio>
          </div>
          <div className="w-row">
            <div className="w-col w-col-6 w-col-stack">
              <label htmlFor="firstName" className="formfieldlabel">
                First Name
              </label>
              <input
                type="text"
                className="formtextfield small w-input input90"
                maxLength="256"
                name="firstName"
                placeholder="Enter your first name"
                value={firstName}
                onChange={handleChange}
              />
            </div>
            <div className="w-col w-col-6 w-col-stack">
              <label htmlFor="lastName" className="formfieldlabel">
                Last Name
              </label>
              <input
                type="text"
                className="formtextfield small w-input"
                maxLength="256"
                name="lastName"
                placeholder="Enter your last name"
                value={lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-row">
            <div className="w-col w-col-6 w-col-stack">
              <label htmlFor="email" className="formfieldlabel">
                Email address
              </label>
              <input
                type="email"
                className="formtextfield small w-input input90"
                maxLength="256"
                name="email"
                placeholder="Enter your email address"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="w-col w-col-6 w-col-stack">
              <label htmlFor="phone" className="formfieldlabel">
                Phone number
              </label>
              <input
                type="tel"
                className="formtextfield small w-input"
                maxLength="256"
                name="phone"
                placeholder="Enter your phone number"
                value={phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <label className="donate-question">Select payment method</label>
          <label className="formfieldlabel">Add Credit Card</label>
          <CardElement style={{ base: { fontSize: '18px' } }} onChange={handleCardElementChange} />
          <div className="styled-checkbox-container">
            <input
              type="checkbox"
              className="styled-checkbox"
              name="anonymous"
              id="anonymous"
              checked={anonymous}
              onChange={handleChange}
            />
            <label htmlFor="anonymous">Set me anonymous</label>
          </div>
          <hr />
          <label htmlFor="comment" className="formfieldlabel">
            Add comments
          </label>
          <textarea
            className="formtextfield w-input"
            rows={5}
            name="comment"
            placeholder="Add your comment"
            value={comment}
            onChange={handleChange}
          />
          <label className="formfieldlabel">Make this a recurring donation and your support impacts more lives!</label>
          <div className="f-radio-group no-bottom">
            <FancyRadio
              id="recurring-1"
              name="recurring"
              value="1"
              checked={recurring === '1'}
              onChange={handleRadioChange}
            >
              <span>Weekly</span>
            </FancyRadio>
            <FancyRadio
              id="recurring-2"
              name="recurring"
              value="2"
              checked={recurring === '2'}
              onChange={handleRadioChange}
            >
              <span>Bi-Weekly</span>
            </FancyRadio>
            <FancyRadio
              id="recurring-3"
              name="recurring"
              value="3"
              checked={recurring === '3'}
              onChange={handleRadioChange}
            >
              <span>Monthly</span>
            </FancyRadio>
          </div>
          <div className="clear-selection">
            <a href="#clear-recurring" onClick={e => handleClearSelection(e, 1)}>
              Clear Selection
            </a>
          </div>
          <label className="formfieldlabel">Dedications</label>
          <div className="f-radio-group no-bottom">
            <FancyRadio
              id="dedication-1"
              name="dedication"
              value='{"id": 1, "title": "Dedicated to"}'
              checked={dedication === '{"id": 1, "title": "Dedicated to"}'}
              onChange={handleRadioChange}
            >
              <span>Dedicated to</span>
            </FancyRadio>
            <FancyRadio
              id="dedication-2"
              name="dedication"
              value='{"id": 2, "title": "In memory of"}'
              checked={dedication === '{"id": 2, "title": "In memory of"}'}
              onChange={handleRadioChange}
            >
              <span>In memory of</span>
            </FancyRadio>
            <FancyRadio
              id="dedication-3"
              name="dedication"
              value='{"id": 3, "title": "On behalf of"}'
              checked={dedication === '{"id": 3, "title": "On behalf of"}'}
              onChange={handleRadioChange}
            >
              <span>On behalf of</span>
            </FancyRadio>
          </div>
          <div className="clear-selection" style={{ marginBottom: 15 }}>
            <a href="#clear-recurring" onClick={e => handleClearSelection(e, 2)}>
              Clear Selection
            </a>
          </div>
          <input
            type="text"
            className="formtextfield small w-input"
            maxLength="256"
            name="dedicationName"
            placeholder="Add Dedication Name"
            value={dedicationName}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <label className="donate-question">Select payment method</label>
          <label className="formfieldlabel">Add Credit Card</label>
          <CardElement style={{ base: { fontSize: '18px' } }} onChange={handleCardElementChange} />
          <hr />
          <label htmlFor="comment" className="formfieldlabel">
            Add comments
          </label>
          <textarea
            className="formtextfield w-input"
            rows={5}
            name="comment"
            placeholder="Add your comment"
            value={comment}
            onChange={handleChange}
          />
          <label className="formfieldlabel">Make this a recurring donation and your support impacts more lives!</label>
          <div className="f-radio-group no-bottom">
            <FancyRadio
              id="recurring-1"
              name="recurring"
              value="1"
              checked={recurring === '1'}
              onChange={handleRadioChange}
            >
              <span>Weekly</span>
            </FancyRadio>
            <FancyRadio
              id="recurring-2"
              name="recurring"
              value="2"
              checked={recurring === '2'}
              onChange={handleRadioChange}
            >
              <span>Bi-Weekly</span>
            </FancyRadio>
            <FancyRadio
              id="recurring-3"
              name="recurring"
              value="3"
              checked={recurring === '3'}
              onChange={handleRadioChange}
            >
              <span>Monthly</span>
            </FancyRadio>
          </div>
          <div className="clear-selection">
            <a href="#clear-recurring" onClick={e => handleClearSelection(e, 1)}>
              Clear Selection
            </a>
          </div>
          <label className="formfieldlabel">Dedications</label>
          <div className="f-radio-group no-bottom">
            <FancyRadio
              id="dedication-1"
              name="dedication"
              value='{"id": 1, "title": "Dedicated to"}'
              checked={dedication === '{"id": 1, "title": "Dedicated to"}'}
              onChange={handleRadioChange}
            >
              <span>Dedicated to</span>
            </FancyRadio>
            <FancyRadio
              id="dedication-2"
              name="dedication"
              value='{"id": 2, "title": "In memory of"}'
              checked={dedication === '{"id": 2, "title": "In memory of"}'}
              onChange={handleRadioChange}
            >
              <span>In memory of</span>
            </FancyRadio>
            <FancyRadio
              id="dedication-3"
              name="dedication"
              value='{"id": 3, "title": "On behalf of"}'
              checked={dedication === '{"id": 3, "title": "On behalf of"}'}
              onChange={handleRadioChange}
            >
              <span>On behalf of</span>
            </FancyRadio>
          </div>
          <div className="clear-selection" style={{ marginBottom: 15 }}>
            <a href="#clear-recurring" onClick={e => handleClearSelection(e, 2)}>
              Clear Selection
            </a>
          </div>
          <input
            type="text"
            className="formtextfield small w-input"
            maxLength="256"
            name="dedicationName"
            placeholder="Add Dedication Name"
            value={dedicationName}
            onChange={handleChange}
          />
        </React.Fragment>
      )}
    </div>
  );
};

DonateProcessSection.propTypes = {
  accountReceivableId: PropTypes.string,
  donationCategory: PropTypes.string.isRequired,
  donationType: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  anonymous: PropTypes.bool.isRequired,
  comment: PropTypes.string.isRequired,
  recurring: PropTypes.string.isRequired,
  dedication: PropTypes.string.isRequired,
  dedicationName: PropTypes.string.isRequired,
  showStripeLoading: PropTypes.bool.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCardElementChange: PropTypes.func.isRequired,
  handleClearSelection: PropTypes.func.isRequired,
};

DonateProcessSection.defaultProps = { accountReceivableId: '' };

export default withStripeContext(StripeDonation);
