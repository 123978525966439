import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Components/Waiting';

import { database } from './firebase';
import { stringHelper } from './helpers';

import * as routes from './constants/routes';
import withUserContext from './withUserContext';

const withNonprofitDetail = Component => {
  class WithNonprofitDetail extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        item: {
          agent: null,
          address: '',
          category: '',
          charityNavigatorURL: '',
          city: '',
          collect: '',
          contact: '',
          description: '',
          likes: [],
          federal: '',
          img: null,
          key: '',
          name: '',
          slugName: '',
          publish: '',
          rating: [],
          ratingStar: [],
          time: '',
          state: '',
          tagline: '',
          type: '',
          website: '',
          zipcode: '',
          viewUrl: '',
          viewUrlProgram: '',
          viewUrlCampaign: '',
          viewUrlDonor: '',
          hideDonation: false,
          subscribe: null,
        },
      };
    }

    componentDidMount() {
      this.getNonprofitInfo();
    }

    async getNonprofitInfo() {
      const { match } = this.props;
      let id = 0;
      if (match.path === '/campaigns/:id/:name') {
        const campaignId = match.params.id;
        const snapCampaign = await database.getRef(`/campaigns/${campaignId}/nonprofit/key`).once('value');
        if (snapCampaign.val()) {
          id = Number.parseInt(snapCampaign.val(), 10);
        }
      } else {
        id = match.params.nonprofitid ? match.params.nonprofitid : match.params.id;
      }

      const snap = await database
        .getRef('/nonprofit')
        .child(id)
        .once('value');
      const slugName = stringHelper.slugify(snap.val().name);

      this.setState(prevState => {
        const item = { ...prevState.item };
        item.agent = snap.val().agent;
        item.address = snap.val().address;
        item.category = snap.val().category;
        item.charityNavigatorURL = snap.val().charityNavigatorURL;
        item.city = snap.val().city;
        item.collect = snap.val().collect;
        item.contact = snap.val().contact;
        item.description = snap.val().description;
        item.likes = snap.val().likes;
        item.federal = snap.val().federal;
        item.img = snap.val().img;
        item.key = snap.val().key;
        item.name = snap.val().name;
        item.slugName = slugName;
        item.publish = snap.val().publish;
        item.rating = snap.val().rating;
        if (snap.val().rating) {
          item.ratingStar = {
            financial: (snap.val().rating.financial / 100) * 5,
            accountability: (snap.val().rating.accountability / 100) * 5,
          };
        }
        item.time = snap.val().time;
        item.state = snap.val().state;
        item.tagline = snap.val().tagline;
        item.type = snap.val().type;
        item.website = snap.val().website;
        item.zipcode = snap.val().zipcode;
        item.hideDonation = snap.val().hideDonation ? snap.val().hideDonation : false;
        item.subscribe = snap.val().subscribe;
        item.stripe_account = snap.val().stripe_account ? snap.val().stripe_account : null;
        item.viewUrl = routes.NONPROFITS_ABOUT.replace(':id', id).replace(':name', slugName);
        item.viewUrlProgram = routes.NONPROFITS_PROGRAMS.replace(':id', id).replace(':name', slugName);
        item.viewUrlCampaign = routes.NONPROFITS_CAMPAIGNS.replace(':id', id).replace(':name', slugName);
        item.viewUrlDonor = routes.NONPROFITS_DONORS.replace(':id', id).replace(':name', slugName);
        item.viewUrlFinancial = routes.NONPROFITS_FINANCIALS.replace(':id', id).replace(':name', slugName);

        return { item };
      });
    }

    render() {
      const { item } = this.state;
      return item.name !== '' ? <Component {...this.props} nonprofit={item} /> : <Loader />;
    }
  }

  WithNonprofitDetail.propTypes = { match: PropTypes.instanceOf(Object).isRequired };

  return withUserContext(WithNonprofitDetail);
};

export default withNonprofitDetail;
