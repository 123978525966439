import React from 'react';
import { withRouter } from 'react-router-dom';

import Loader from './Components/Waiting';
import AuthUserContext from './AuthUserContext';
import { firebase } from './firebase';
import * as routes from './constants/routes';

const withAuthorization = authCondition => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (!authCondition(authUser)) {
          const hasLogOutInfo = Object.prototype.hasOwnProperty.call(localStorage, 'user-logout');
          if (hasLogOutInfo) {
            localStorage.removeItem('user-logout');
          } else {
            const { location, history } = this.props;
            const redirectUri = location.pathname + location.search;
            history.push(`${routes.SIGN_IN}?redirect=${encodeURIComponent(redirectUri)}`);
          }
        }
      });
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => (authUser ? <Component {...this.props} authUser={authUser} /> : <Loader />)}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
