import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SiteMeta = ({ description, lang, meta, keywords, title, image }) => {
  const metaDescription =
    description || 'Fundra was created with the idea of building trust with donors through financial transparency.';
  const metaImage = image || `${process.env.REACT_APP_FIREBASE_SITEURL}/images/5bfc2800dc2bc268ddf7b5a9_OpenGraph.jpg`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="Fundra - %s"
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `fb:app_id`,
          content: process.env.REACT_APP_FB_APP_ID,
        },
        {
          property: `og:title`,
          content: `Fundra - ${title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: `Fundra`,
        },
        {
          property: `og:url`,
          content: `${document.location.protocol}//${document.location.host}${document.location.pathname}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `Fundra`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      link={[
        {
          rel: `canonical`,
          href: `${document.location.protocol}//${document.location.host}${document.location.pathname}`,
        },
      ]}
    />
  );
};

SiteMeta.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: ['transparent fundraising', 'fundraise', 'fundra', 'campaign', 'nonprofit'],
  description: '',
  image: '',
};

SiteMeta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SiteMeta;
