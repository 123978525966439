import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import withAuthorization from '../withAuthorization';

import HeaderInfo from '../Components/Profile/HeaderInfo';
import MyNonprofit from '../Components/Profile/MyNonprofit';

const Agent = ({ authUser }) => {
  document.title = 'Agent Dashboard - Fundra';

  return (
    <div className="ProfileSection">
      <div className="sectionhero">
        <div className="contentwrapper herosectionfeatures">
          <HeaderInfo authUser={authUser} />
          <div className="profile-tabs">
            <Tabs>
              <TabList>
                <Tab>Nonprofit</Tab>
                <Tab disabled>Campaigns</Tab>
              </TabList>
              <TabPanel>
                <MyNonprofit authUser={authUser} />
              </TabPanel>
              <TabPanel>
                <div>Campaigns</div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

Agent.propTypes = {
  authUser: PropTypes.instanceOf(Object).isRequired,
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Agent);
