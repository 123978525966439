import { auth, googleProvider, facebookProvider } from './firebase';

const siteUrl = process.env.REACT_APP_FIREBASE_SITEURL;

export const currentUser = () => auth.currentUser;
export const signUpWithEmailPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);
export const signInWithEmailPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);
export const signOut = () => auth.signOut();
export const passwordReset = email => auth.sendPasswordResetEmail(email, { url: `${siteUrl}/signin` });
export const passwordUpdate = password => auth.currentUser.updatePassword(password);
export const resendActivation = () => auth.currentUser.sendEmailVerification({ url: `${siteUrl}/signin` });
export const updateProfile = profileInfo => auth.currentUser.updateProfile(profileInfo);
export const updatePhoneNumber = phoneNumber => auth.currentUser.updatePhoneNumber(phoneNumber);
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);
export const signInWithGoogleHidden = email => {
  googleProvider.setCustomParameters({ login_hint: email });
  return auth.signInWithPopup(googleProvider);
};
export const signInWithFB = () => auth.signInWithPopup(facebookProvider);
export const fetchProvidersForEmail = email => auth.fetchProvidersForEmail(email);
