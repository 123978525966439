import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Progress } from 'react-sweet-progress';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import { database } from '../firebase';
import { stringHelper, numberHelper, dateTimeHelper } from '../helpers';
import { CampaignProvider, MetricProvider, SocialProvider } from '../Providers';

import * as routes from '../constants/routes';
import withNonprofitDetail from '../withNonprofitDetail';

import SiteMeta from '../Components/SiteMeta';
import NonprofitTopNav from '../Components/NonprofitTopNav';
import DonorTable from '../Components/DonorTable';

import ImgCoverDefault from '../Assets/Images/PlaceHolder.svg';
import ImgEmptyDonors from '../Assets/Images/Illustration-3.svg';
import ImgAnonymous from '../Assets/Images/anonymous_1.png';
import { ReactComponent as IconStar } from '../Assets/Images/star-full.svg';

import 'react-tabs/style/react-tabs.css';
import '../Assets/Styles/Nonprofits-Donor.css';

class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        agent: null,
        city: '',
        description: '',
        likes: null,
        federal: '',
        img: null,
        key: '',
        name: '',
        slugName: '',
        state: '',
        hideDonation: false,
        stripe_account: null,
      },
      campaign: {
        id: 0,
        cover: null,
        date: null,
        endDate: null,
        expired: false,
        dynamicLink: null,
        goal: 0,
        goalText: '',
        likes: [],
        location: '',
        name: '',
        slugName: '',
        nonprofit: {
          img: null,
          key: '',
          name: '',
        },
        primary: null,
        program: {
          id: '',
          name: '',
        },
        story: '',
        published: false,
        time: null,
        user: {
          email: '',
          firstName: '',
          lastName: '',
          uid: '',
        },
        viewUrl: '',
        viewCampaignUrl: '',
        viewProgramUrl: '',
      },
      metricInfo: null,
      userAvatar: ImgAnonymous,
      raised: 0.0,
      raisedText: '',
      percentForBar: 0,
      donationSince: '',
      donorLoad: true,
      filteredDonors: [],
      donorsEmpty: false,
    };

    this.handleDonation = this.handleDonation.bind(this);
    this.handleFundraise = this.handleFundraise.bind(this);
    this.handleDeleteCampaign = this.handleDeleteCampaign.bind(this);
    this.handleTogglePublished = this.handleTogglePublished.bind(this);
    this.handleFBShareButton = this.handleFBShareButton.bind(this);
    this.handleTwitterButton = this.handleTwitterButton.bind(this);
    this.handleCreateDynamicLink = this.handleCreateDynamicLink.bind(this);
    this.handleShowDynamicLink = this.handleShowDynamicLink.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, match, nonprofit } = this.props;
    updateNavStatus('empty');
    showFooterLinkContainer(false);

    const { id } = match.params;
    this.setState(
      prevState => {
        const { item } = prevState;
        Object.keys(nonprofit).forEach(key => {
          item[key] = nonprofit[key];
        });

        return { item };
      },
      () => {
        this.getCampaignDetail(id);
      }
    );
  }

  getCampaignDetail(id) {
    database
      .getRef(`/campaigns/${id}`)
      .once('value')
      .then(snap => {
        if (snap.val()) {
          const slugName = stringHelper.slugify(snap.val().name);
          const nonprofitSlugName = stringHelper.slugify(snap.val().nonprofit.name);
          const totalGoal = snap.val().goal ? Number.parseFloat(snap.val().goal) : 0.0;
          const campaign = {
            id: snap.val().id,
            cover: snap.val().cover,
            date: new Date(snap.val().date),
            endDate: snap.val().endDate ? new Date(snap.val().endDate) : null,
            expired: snap.val().endDate ? dateTimeHelper.isBeforeNow(snap.val().endDate) : false,
            dynamicLink: snap.val().dynamicLink ? snap.val().dynamicLink : null,
            goal: totalGoal,
            goalText: numberHelper.getThousandString(totalGoal.toFixed(2), 0),
            likes: snap.val().likes,
            location: snap.val().location,
            name: snap.val().name,
            slugName,
            nonprofit: snap.val().nonprofit,
            primary: snap.val().primary,
            program: snap.val().program,
            metric: snap.val().metric,
            story: snap.val().story ? snap.val().story : '',
            published: snap.val().published,
            time: snap.val().time,
            user: snap.val().user,
            viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', id).replace(':name', slugName),
            viewCampaignUrl: routes.NONPROFITS_CAMPAIGNS.replace(':id', snap.val().nonprofit.key).replace(
              ':name',
              nonprofitSlugName
            ),
            viewProgramUrl: snap.val().program
              ? routes.PROGRAMS_ABOUT.replace(':id', snap.val().program.id)
                  .replace(':nonprofitid', snap.val().nonprofit.key)
                  .replace(':name', stringHelper.slugify(snap.val().program.name))
              : '',
          };

          this.setState(
            {
              campaign,
            },
            () => {
              this.getUserAvatar();
              this.getFinancial(snap.val().nonprofit.key, id);
              // this.getSimilarCampaign(snap.val().nonprofit, snap.val().program);
              // this.getOrganizer(id);
            }
          );
        }
      });
  }

  getMetricInfo() {
    const { campaign, raised } = this.state;
    const { nonprofit, metric, goal } = campaign;
    MetricProvider.getDetail(nonprofit.key, metric).then(snap => {
      if (snap.val()) {
        const metricInfo = {
          id: snap.key,
          name: snap.val().name,
          cost: Number.parseFloat(snap.val().cost),
          costNumber: Number.parseInt(snap.val().costNumber, 10),
          unitName: snap.val().unitName ? snap.val().unitName.trim() : '',
          showHumanUnit: snap.val().showHumanUnit ? snap.val().showHumanUnit : 'Y',
          frequency: snap.val().frequency,
        };

        let raisedPack = 0;
        let goalPack = 0;
        if (metricInfo.cost > 0) {
          raisedPack = Math.floor((raised / metricInfo.cost) * metricInfo.costNumber);
          goalPack = Math.ceil((goal / metricInfo.cost) * metricInfo.costNumber);
        }

        metricInfo.raised = raisedPack;
        metricInfo.goal = goalPack;

        if (
          metricInfo.frequency &&
          Number.parseInt(metricInfo.frequency.number, 10) > 0 &&
          metricInfo.frequency.name !== ''
        ) {
          metricInfo.frequencyText = (
            <div className="frequency">
              {` / ${Number.parseInt(metricInfo.frequency.number, 10)} ${stringHelper.makePluralize(
                metricInfo.frequency.name,
                Number.parseInt(metricInfo.frequency.number, 10)
              )}`}
            </div>
          );
        }

        this.setState({ metricInfo });
      }
    });
  }

  getUserAvatar() {
    const { campaign } = this.state;
    const { user } = campaign;
    const firstInitial = user.firstName.substring(0, 1);
    const lastInitial = user.lastName.substring(0, 1);
    let avatar = `https://via.placeholder.com/150.png?text=${firstInitial.toUpperCase()}${lastInitial.toUpperCase()}`;

    database
      .getRef(`/profile/${user.uid}/avatar`)
      .once('value')
      .then(avatarSnap => {
        if (avatarSnap.val()) {
          avatar = avatarSnap.val();
        }

        this.setState({ userAvatar: avatar });
      });
  }

  // async getOrganizer(id) {
  //   const organizers = [];
  //   const { item, campaign } = this.state;
  //   const nonprofit = item.key;
  //   let primaryId = Number.parseInt(id, 10);
  //   if (campaign.primary) {
  //     const primarySnap = await database.getRef(`/campaigns/${campaign.primary}`).once('value');
  //     if (primarySnap.val()) {
  //       organizers.push({
  //         ...primarySnap.val().user,
  //         primary: true,
  //         key: campaign.primary,
  //         nonprofit,
  //       });
  //     }
  //     organizers.push({ ...campaign.user, key: id, nonprofit });
  //     primaryId = Number.parseInt(campaign.primary, 10);
  //   } else {
  //     organizers.push({
  //       ...campaign.user,
  //       primary: true,
  //       key: id,
  //       nonprofit,
  //     });
  //   }

  //   const joinSnap = await database
  //     .getRef('/campaigns')
  //     .orderByChild('primary')
  //     .equalTo(primaryId)
  //     .once('value');
  //   joinSnap.forEach(snap => {
  //     if (snap.val().published && snap.val().id.toString() !== id.toString()) {
  //       organizers.push({
  //         ...snap.val().user,
  //         key: snap.val().id,
  //         nonprofit,
  //       });
  //     }
  //   });

  //   this.setState({ organizers, organizerLoad: false });
  // }

  async getFinancial(key, id) {
    let totalRaised = 0.0;
    let percentForBar = 0;
    const donors = [];
    // let since = '';
    const { item: nonprofit, campaign } = this.state;

    const donationSnap = await database
      .getRef(`/donations/${key}`)
      .orderByChild('campaign/id')
      .equalTo(String(id))
      .once('value');
    let counter = 1;
    donationSnap.forEach(snap => {
      let dateObj = null;
      if (snap.val().date) {
        dateObj = new Date(snap.val().date);
      }

      if (!nonprofit.hideDonation) {
        const donorTo = snap.val().program ? snap.val().program.name : snap.val().nonprofit.name;
        const userInfo = snap.val().user;
        userInfo.fullName = userInfo.anonymous ? 'Anonymous Donor' : `${userInfo.firstName} ${userInfo.lastName}`;
        userInfo.dedication = snap.val().dedication;
        userInfo.program = snap.val().program;
        userInfo.metric = snap.val().metric;
        userInfo.campaign = snap.val().campaign;
        userInfo.donorTo = snap.val().campaign ? snap.val().campaign.name : donorTo;
        userInfo.date = dateObj;
        userInfo.total = snap.val().total;
        userInfo.counter = counter;
        if (snap.val().recurring) {
          switch (snap.val().recurring.when) {
            case 3:
              userInfo.recurring = 'Monthly';
              userInfo.recurringStyle = 'f-info-box';
              break;
            case 2:
              userInfo.recurring = 'Bi-Weekly';
              userInfo.recurringStyle = 'f-success-box';
              break;
            default:
              userInfo.recurring = 'Weekly';
              userInfo.recurringStyle = 'f-default-box';
              break;
          }
        } else {
          userInfo.recurring = '-';
          userInfo.recurringStyle = 'empty';
        }
        userInfo.idTrx = snap.val().idTrx;
        donors.push(userInfo);
        counter += 1;
      }

      totalRaised += parseFloat(snap.val().total.amount);

      // if (since === '' && dateObj) {
      //   since = `Since ${dateTimeHelper.getMonthDateYear(dateObj)}`;
      // }
    });

    if (donors.length > 0) {
      donors.reverse();
    }

    if (campaign.goal > 0) {
      const percent = (totalRaised / campaign.goal) * 100;
      percentForBar = percent;
      if (percent > 100) {
        percentForBar = 100;
      }
    }

    this.setState(
      {
        donorsEmpty: donors.length === 0,
        filteredDonors: donors,
        donorLoad: false,
        percentForBar,
        raised: totalRaised,
        raisedText: numberHelper.getThousandString(totalRaised.toFixed(2)),
        // donationSince: since,
      },
      () => {
        this.getMetricInfo();
      }
    );
  }

  // getSimilarCampaign(nonprofit, program) {
  //   const query = program ? 'program/id' : 'nonprofit/key';
  //   const key = program ? program.id : nonprofit.key;
  //   const { campaign } = this.state;

  //   database
  //     .getRef('/campaigns')
  //     .orderByChild(query)
  //     .equalTo(key)
  //     .once('value')
  //     .then(campaignSnap => {
  //       let counter = 0;
  //       campaignSnap.forEach(snap => {
  //         if (snap.val().published && campaign.id !== snap.val().id) {
  //           if (program || !snap.val().program) {
  //             counter += 1;
  //           }
  //         }
  //       });

  //       this.setState({ similarCampaign: counter });
  //     });
  // }

  handleDonation() {
    const { history } = this.props;
    const { item, campaign } = this.state;

    history.push(routes.NONPROFITS_DONATION.replace(':id', item.key).replace(':type', campaign.id));
  }

  handleFundraise() {
    const { history } = this.props;
    const { campaign } = this.state;

    history.push(`${routes.CAMPAIGNS_REGISTER}?j=${campaign.id}`);
  }

  handleDeleteCampaign() {
    const { campaign } = this.state;
    const { history, authUser } = this.props;

    swal('Delete campaign', `Are you sure you want to delete "${campaign.name}"?`, {
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
      .then(willDelete => {
        if (willDelete) {
          return CampaignProvider.remove(campaign.id);
        }

        return 'cancel';
      })
      .then(res => {
        if (res !== 'cancel') {
          const nextUrl = authUser.profile.agentStatus ? routes.PROFILE_AGENT : routes.PROFILE_DONOR;
          history.push(nextUrl);
        }
      });
  }

  handleTogglePublished() {
    const { campaign } = this.state;
    const { history } = this.props;
    const title = campaign.published ? 'Unpublish' : 'Publish';

    swal(`${title} campaign`, `Are you sure you want to ${title.toLowerCase()} "${campaign.name}"?`, {
      buttons: true,
    })
      .then(willDelete => {
        if (willDelete) {
          return CampaignProvider.togglePublished(campaign.id, !campaign.published);
        }

        return 'cancel';
      })
      .then(res => {
        if (res !== 'cancel') {
          history.push(routes.CAMPAIGNS);
        }
      });
  }

  handleFBShareButton() {
    const { campaign } = this.state;
    const { viewUrl, name } = campaign;

    SocialProvider.facebookShare(viewUrl, name);
  }

  handleTwitterButton() {
    const { campaign } = this.state;
    const { viewUrl, name } = campaign;

    SocialProvider.twitterShare(viewUrl, name);
  }

  handleCreateDynamicLink() {
    const { campaign } = this.state;
    CampaignProvider.createDynamicLink(campaign).then(res => {
      campaign.dynamicLink = res.data.shortLink;
      this.setState({ campaign }, () => {
        swal('Dynamic Link done created', res.data.shortLink);
      });
    });
  }

  handleShowDynamicLink() {
    const { campaign } = this.state;
    swal('Campaign Dynamic Link', campaign.dynamicLink);
  }

  render() {
    const {
      campaign,
      item,
      userAvatar,
      donationSince,
      raisedText,
      percentForBar,
      donorLoad,
      donorsEmpty,
      filteredDonors,
      metricInfo,
    } = this.state;
    const { authUser } = this.props;
    const campaignCover = campaign.cover ? campaign.cover : ImgCoverDefault;
    const metaDescription =
      campaign.story.length > 160 ? campaign.story.substring(0, 160).concat('...') : campaign.story;
    const noRaisedCss = item.hideDonation
      ? 'w-col-12 w-col-stack nonprofitbodycontentdetail no-border'
      : 'w-col-8 w-col-stack nonprofitbodycontentdetail no-border';
    const metricInfoCampaign = metricInfo ? { [metricInfo.id]: metricInfo } : {};

    return (
      <div className="NonprofitAboutSection">
        {campaign.name !== '' ? (
          <SiteMeta title={`${campaign.name} :: Campaign`} description={metaDescription} image={campaignCover} />
        ) : null}
        <div className="sectionhero">
          <div className="contentwrapper herosectionfeatures nonprofitsection">
            <div className="nonprofit-detail">
              <NonprofitTopNav item={item} authUser={authUser} noNavBottom />
              <div className="campaign-section">
                <div className="flexbox-align-center">
                  <h3 className={`h3 nonprofitabouttitle${campaign.expired ? ' expired-font' : ''}`}>
                    {campaign.name}
                  </h3>
                  {campaign.expired ? <div className="f-default-box expired ">Expired</div> : null}
                </div>
                <Tabs>
                  <TabList>
                    <Tab>About</Tab>
                    <Tab>Donors</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="w-row nonprofit-body-content-left">
                      <div className={`w-col ${noRaisedCss}`}>
                        <div
                          className={`nonprofit-description campaign f-paragraph${
                            campaign.expired ? ' expired-font' : ''
                          }`}
                        >
                          {stringHelper.nl2br(campaign.story)}
                        </div>
                        <div className="nonprofit-cover-big">
                          {campaign.expired ? <div className="f-overlay-bg-white" /> : null}
                          <img src={campaignCover} alt={`${campaign.name} Cover`} />
                        </div>
                      </div>
                      {!item.hideDonation ? (
                        <CampaignRightContent
                          userAvatar={userAvatar}
                          campaign={campaign}
                          donationSince={donationSince}
                          raisedText={raisedText}
                          percentForBar={percentForBar}
                          metricInfo={metricInfo}
                          handleDonation={this.handleDonation}
                        />
                      ) : null}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {donorLoad ? (
                      <div className="section-loader-data">
                        <div className="section-loader-container">
                          <Loader color="#3898EC" type="ThreeDots" height={80} width={80} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {!donorsEmpty ? (
                          <div className="w-row">
                            <div className="w-col w-col-8 w-col-stack nonprofitbodycontentdetail no-border">
                              <DonorTable
                                items={filteredDonors}
                                showRecurring={false}
                                tblClass="nonprofit-table-small"
                                metricInfo={metricInfoCampaign}
                              />
                            </div>
                            <CampaignRightContent
                              userAvatar={userAvatar}
                              campaign={campaign}
                              donationSince={donationSince}
                              raisedText={raisedText}
                              percentForBar={percentForBar}
                              metricInfo={metricInfo}
                              handleDonation={this.handleDonation}
                            />
                          </div>
                        ) : (
                          <div className="donors-empty">
                            <img src={ImgEmptyDonors} alt="" />
                            <h3 className="h3 nonprofitabouttitle">Giving is good</h3>
                            <label className="joined">Donate and make a difference today!</label>
                            <button type="button" className="w-button" onClick={this.handleDonation}>
                              Donate
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CampaignDetail.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  nonprofit: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

CampaignDetail.defaultProps = {
  authUser: null,
};

const CampaignRightContent = ({
  userAvatar,
  campaign,
  donationSince,
  raisedText,
  percentForBar,
  metricInfo,
  handleDonation,
}) => (
  <div className="w-col w-col-4 w-col-stack nonprofitfinancialcontent no-pad-left">
    {/* {metricInfo ? (
      <div className="f-box-base" style={{ marginBottom: 16 }}>
        This campaigns need:
        <div className="pricing">
          <div className="metric-cost">{numberHelper.getThousandFormat(metricInfo.cost, '$')}</div>
          {metricInfo.frequencyText}
        </div>
      </div>
    ) : null} */}
    <div className="w-row">
      <div className="w-col w-col-12 w-col-stack">
        <h3 className="h3 nonprofitraisedtitle">Amount raised</h3>
        {donationSince !== '' ? <label className="sincelabel">{donationSince}</label> : null}
      </div>
    </div>
    <div className="w-row">
      <div className="w-col w-col-12 w-col-stack">
        <div className="flexbox-space primary-detail">
          <div>
            <img src={userAvatar} alt="" className="donor-pic" />
            {` ${campaign.user.firstName} ${campaign.user.lastName} `}
          </div>
          <div className="flexbox-align-center primary-organizer f-warning-box">
            <IconStar />
            Primary Organizer
          </div>
        </div>
      </div>
    </div>
    {campaign.goal > 0 ? (
      <>
        <div className="campaign-raised-web w-row">
          <div className="w-col w-col-6 w-col-stack">
            <h3 className="raisedtext">{`$ ${raisedText}`}</h3>
            <label className="financiallabel">Raised</label>
          </div>
          <div className="w-col w-col-6 w-col-stack goaltext-column">
            <h3 className="goaltext">{`$ ${campaign.goalText}`}</h3>
            <label className="financiallabel">Goal</label>
          </div>
        </div>
        <div className="campaign-raised-mobile">
          <div className="flexbox-space-around">
            <div>
              <h3 className="raisedtext">{`$ ${raisedText}`}</h3>
              <label className="financiallabel">Raised</label>
            </div>
            <div>
              <h3 className="goaltext">{`$ ${campaign.goalText}`}</h3>
              <label className="financiallabel">Goal</label>
            </div>
          </div>
        </div>
        <div className="w-row">
          <div className="w-col w-col-12 w-col-stack">
            <div className="progressbar">
              <Progress percent={percentForBar} theme={{ success: { symbol: ' ', color: '#63D8B5' } }} />
            </div>
          </div>
        </div>
        {metricInfo ? (
          <div className="metric-raised">
            <span className="f-text-bold">
              {`${numberHelper.getThousandFormat(metricInfo.raised.toFixed(0))} ${stringHelper.makePluralize(
                metricInfo.unitName,
                metricInfo.raised
              )} `}
            </span>
            <span>
              {`out of ${numberHelper.getThousandFormat(metricInfo.goal.toFixed(0))} ${stringHelper.makePluralize(
                metricInfo.unitName,
                metricInfo.goal
              )} `}
            </span>
          </div>
        ) : null}
      </>
    ) : (
      <div className="w-row">
        {metricInfo ? (
          <div className="w-col w-col-6 w-col-stack">
            <h3 className="raisedtext">{numberHelper.getThousandFormat(metricInfo.raised.toFixed(0))}</h3>
            <label className="financiallabel f-text-capitalize" style={{ fontSize: 12 }}>
              {stringHelper.makePluralize(metricInfo.unitName, metricInfo.raised)}
            </label>
          </div>
        ) : null}
        <div className="w-col w-col-6 w-col-stack goaltext-column">
          <h3 className="goaltext">{`$ ${raisedText}`}</h3>
          <label className="financiallabel">Raised</label>
        </div>
      </div>
    )}
    <div className="w-row" style={{ marginTop: 16, marginBottom: 24 }}>
      <div className="w-col w-col-12 w-col-stack support-button">
        <button type="button" className="w-button f-btn-danger" onClick={handleDonation}>
          Support
        </button>
      </div>
    </div>
  </div>
);

CampaignRightContent.propTypes = {
  userAvatar: PropTypes.string.isRequired,
  campaign: PropTypes.instanceOf(Object).isRequired,
  donationSince: PropTypes.string,
  raisedText: PropTypes.string.isRequired,
  percentForBar: PropTypes.number.isRequired,
  metricInfo: PropTypes.instanceOf(Object),
  handleDonation: PropTypes.func.isRequired,
};

CampaignRightContent.defaultProps = { metricInfo: null, donationSince: '' };

const CampaignRaisedBlock = ({ item }) => {
  if (item.similarCampaign && item.similarCampaign > 0) {
    const plural = item.similarCampaign > 1 ? 's' : '';
    const similarText = `${item.similarCampaign} Similar Campaign${plural}`;
    let similarLink = (
      <Link to={`${item.campaign.viewCampaignUrl}?search=noprogram`} className="similar-campaign">
        {similarText}
      </Link>
    );
    if (item.campaign.viewProgramUrl !== '') {
      similarLink = (
        <HashLink to={`${item.campaign.viewProgramUrl}#campaigns`} className="similar-campaign">
          {similarText}
        </HashLink>
      );
    }

    return (
      <div className="flexbox-space">
        <div className="item">
          <CampaignTotalMoney item={item} />
        </div>
        <div className="item">{similarLink}</div>
      </div>
    );
  }

  return <CampaignTotalMoney item={item} />;
};

CampaignRaisedBlock.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

const CampaignTotalMoney = ({ item }) => (
  <div className="campaign-total-money">
    {item.campaign.goal > 0 ? (
      <>
        <div className="item-border-finance">
          <div className="finance-item">
            <span className="f-text-bold">{`$ ${item.raisedText}`}</span>
            <span>{` of $ ${item.campaign.goalText} Goal`}</span>
          </div>
        </div>
        <div className="progressbar program">
          <Progress percent={item.percentForBar} theme={{ success: { symbol: ' ', color: '#63D8B5' } }} />
        </div>
        {item.metricInfo ? (
          <div className="metric-raised">
            <span className="f-text-bold">
              {`${numberHelper.getThousandFormat(item.metricInfo.raised.toFixed(0))} ${stringHelper.makePluralize(
                item.metricInfo.unitName,
                item.metricInfo.raised
              )} `}
            </span>
            <span>
              {`out of ${numberHelper.getThousandFormat(item.metricInfo.goal.toFixed(0))} ${stringHelper.makePluralize(
                item.metricInfo.unitName,
                item.metricInfo.goal
              )} `}
            </span>
          </div>
        ) : null}
      </>
    ) : (
      <div className="item-border-finance" style={{ marginBottom: 10 }}>
        <div className="finance-item">
          <span className="f-text-bold">{`$ ${item.raisedText}`}</span>
          {item.metricInfo ? (
            <span>
              {` provided ${numberHelper.getThousandFormat(
                item.metricInfo.raised.toFixed(0)
              )} ${stringHelper.makePluralize(item.metricInfo.unitName, item.metricInfo.raised)}`}
            </span>
          ) : null}
        </div>
      </div>
    )}
  </div>
);

CampaignTotalMoney.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default withNonprofitDetail(CampaignDetail);
