import React from 'react';
import PropTypes from 'prop-types';

import { stringHelper } from '../helpers';

const NonprofitAboutMobile = ({ item }) => (
  <div>
    <div className="w-row nonprofit-description">
      <div className="w-col w-col-12 w-col-stack">{stringHelper.nl2br(item.description)}</div>
    </div>
    <div className="w-row nonprofit-info first">
      <div className="w-col w-col-12 w-col-stack">
        <label className="nonprofit-label-header">Street Address</label>
        <label className="nonprofit-label-content">{item.address}</label>
      </div>
    </div>
    <div className="w-row nonprofit-info">
      <div className="w-col w-col-5 w-col-stack">
        <label className="nonprofit-label-header">City</label>
        <label className="nonprofit-label-content">{item.city}</label>
      </div>
      <div className="w-col w-col-7 w-col-stack">
        <label className="nonprofit-label-header">State</label>
        <label className="nonprofit-label-content">{item.state}</label>
      </div>
    </div>
    <div className="w-row nonprofit-info">
      <div className="w-col w-col-5 w-col-stack">
        <label className="nonprofit-label-header">Zipcode</label>
        <label className="nonprofit-label-content">{item.zipcode}</label>
      </div>
      <div className="w-col w-col-7 w-col-stack">
        <label className="nonprofit-label-header">Phone</label>
        <label className="nonprofit-label-content">{item.contact.phone}</label>
      </div>
    </div>
    <div className="w-row nonprofit-info">
      <div className="w-col w-col-12 w-col-stack">
        <label className="nonprofit-label-header">Email</label>
        <label className="nonprofit-label-content">{item.contact.email}</label>
      </div>
    </div>
    <div className="w-row nonprofit-info">
      <div className="w-col w-col-12 w-col-stack">
        <label className="nonprofit-label-header">Website</label>
        <label className="nonprofit-label-content">
          <a href={item.website} target="_blank" rel="noopener noreferrer">
            {item.website}
          </a>
        </label>
      </div>
    </div>
    <div className="w-row nonprofit-info last">
      <div className="w-col w-col-12 w-col-stack">
        <label className="nonprofit-label-header">Category</label>
        <label className="nonprofit-label-content">{item.category}</label>
      </div>
    </div>
  </div>
);

NonprofitAboutMobile.propTypes = { item: PropTypes.instanceOf(Object).isRequired };

export default NonprofitAboutMobile;
