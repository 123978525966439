import { database } from '../firebase';
import { numberHelper, dateTimeHelper } from '../helpers';

export const getInitialKey = nid => database.pushEmptyRef(`/donations/${nid}`).key;
export const getNonprofitDonation = nid => database.getRef(`/donations/${nid}`).once('value');
export const getDataByCampaign = (nid, cid) =>
  database
    .getRef(`/donations/${nid}`)
    .orderByChild('campaign/id')
    .equalTo(String(cid))
    .once('value');
export const saveData = (nid, id, data) => database.setRef(`/donations/${nid}/${id}`, data);
export const removeData = (nid, id) => database.removeRef(`/donations/${nid}/${id}`);

export const todayNonprofitDonation = (id, program = 0) =>
  new Promise(resolve => {
    const today = new Date();
    const startDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), today.getMonth(), today.getDate()]);
    const endDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59]);

    database
      .getRef(`/donations/${id}`)
      .orderByChild('date')
      .startAt(startDate.toDate().getTime())
      .endAt(endDate.toDate().getTime())
      .once('value')
      .then(donationSnap => {
        let totalRaised = 0.0;
        let until = startDate.local().format('DD MMMM YYYY');

        donationSnap.forEach(snap => {
          let getValue = true;
          if (program > 0) {
            if (program.toString() !== snap.val().program.id.toString()) {
              getValue = false;
            }
          }

          if (getValue) {
            if (snap.val().date) {
              const dateObj = new Date(snap.val().date);
              until = dateTimeHelper.getDateMonthYear(dateObj);
            }

            totalRaised += parseFloat(snap.val().total.amount);
          }
        });

        resolve({ until, totalRaised: numberHelper.getThousandFormat(totalRaised) });
      });
  });

export const weeklyNonprofitDonation = (id, program = 0) =>
  new Promise(resolve => {
    const today = new Date();
    const subtractValue = today.getDay() - 1;
    const addValue = 6;
    const startDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), today.getMonth(), today.getDate()]);
    startDate.subtract(subtractValue, 'days');
    const endDate = startDate
      .clone()
      .add(addValue, 'days')
      .hours(23)
      .minutes(59)
      .seconds(59);

    database
      .getRef(`/donations/${id}`)
      .orderByChild('date')
      .startAt(startDate.toDate().getTime())
      .endAt(endDate.toDate().getTime())
      .once('value')
      .then(donationSnap => {
        let totalRaised = 0.0;
        const until = `${startDate.local().format('DD MMMM')} - ${endDate.local().format('DD MMMM YYYY')}`;

        donationSnap.forEach(snap => {
          let getValue = true;
          if (program > 0) {
            if (program.toString() !== snap.val().program.id.toString()) {
              getValue = false;
            }
          }

          if (getValue) {
            totalRaised += parseFloat(snap.val().total.amount);
          }
        });

        resolve({ until, totalRaised: numberHelper.getThousandFormat(totalRaised) });
      });
  });

export const monthlyNonprofitDonation = (id, program = 0) =>
  new Promise(resolve => {
    const today = new Date();
    const startDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), today.getMonth(), 1]);
    const endDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59]);

    database
      .getRef(`/donations/${id}`)
      .orderByChild('date')
      .startAt(startDate.toDate().getTime())
      .endAt(endDate.toDate().getTime())
      .once('value')
      .then(donationSnap => {
        let totalRaised = 0.0;
        let until = startDate.local().format('MMMM YYYY');

        donationSnap.forEach(snap => {
          let getValue = true;
          if (program > 0) {
            if (program.toString() !== snap.val().program.id.toString()) {
              getValue = false;
            }
          }

          if (getValue) {
            if (snap.val().date) {
              const dateObj = new Date(snap.val().date);
              until = dateTimeHelper.getMonthYear(dateObj);
            }

            totalRaised += parseFloat(snap.val().total.amount);
          }
        });

        resolve({ until, totalRaised: numberHelper.getThousandFormat(totalRaised) });
      });
  });

export const yearNonprofitDonation = (id, program = 0) =>
  new Promise(resolve => {
    const today = new Date();
    const startDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), 0, 1]);
    const endDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59]);

    database
      .getRef(`/donations/${id}`)
      .orderByChild('date')
      .startAt(startDate.toDate().getTime())
      .endAt(endDate.toDate().getTime())
      .once('value')
      .then(donationSnap => {
        let totalRaised = 0.0;
        let until = startDate.local().format('YYYY');

        donationSnap.forEach(snap => {
          let getValue = true;
          if (program > 0) {
            if (program.toString() !== snap.val().program.id.toString()) {
              getValue = false;
            }
          }

          if (getValue) {
            if (snap.val().date) {
              const dateObj = new Date(snap.val().date);
              until = dateTimeHelper.getFormatedDate(dateObj, 'YYYY');
            }

            totalRaised += parseFloat(snap.val().total.amount);
          }
        });

        resolve({ until, totalRaised: numberHelper.getThousandFormat(totalRaised) });
      });
  });

export const totalNonprofitDonation = id =>
  new Promise(resolve => {
    database
      .getRef(`/donations/${id}`)
      .once('value')
      .then(donationSnap => {
        let totalRaised = 0.0;
        let since = '';
        let until = '';

        donationSnap.forEach(snap => {
          let dateObj = null;
          if (snap.val().date) {
            dateObj = new Date(snap.val().date);
            until = dateTimeHelper.getDateMonthYear(dateObj);
          }

          totalRaised += parseFloat(snap.val().total.amount);
          if (since === '' && dateObj) {
            since = dateTimeHelper.getDateMonthYear(dateObj);
          }
        });

        resolve({ until: `${since} - ${until}`, totalRaised: numberHelper.getThousandFormat(totalRaised) });
      });
  });

export const totalProgramDonation = (id, program) =>
  new Promise(resolve => {
    database
      .getRef(`/donations/${id}`)
      .orderByChild('program/id')
      .equalTo(String(program))
      .once('value')
      .then(donationSnap => {
        let totalRaised = 0.0;
        let since = '';
        let until = '';

        donationSnap.forEach(snap => {
          let dateObj = null;
          if (snap.val().date) {
            dateObj = new Date(snap.val().date);
            until = dateTimeHelper.getDateMonthYear(dateObj);
          }

          totalRaised += parseFloat(snap.val().total.amount);
          if (since === '' && dateObj) {
            since = dateTimeHelper.getDateMonthYear(dateObj);
          }
        });

        resolve({ until: `${since} - ${until}`, totalRaised: numberHelper.getThousandFormat(totalRaised) });
      });
  });

export const getProgramDonationByPeriod = (id, program, startDate, endDate) =>
  new Promise(resolve => {
    database
      .getRef(`/donations/${id}`)
      .orderByChild('date')
      .startAt(startDate.toDate().getTime())
      .endAt(endDate.toDate().getTime())
      .once('value')
      .then(donationSnap => {
        let totalRaised = 0.0;

        donationSnap.forEach(snap => {
          let getValue = true;
          if (program > 0) {
            if (program.toString() !== snap.val().program.id.toString()) {
              getValue = false;
            }
          }

          if (getValue) {
            totalRaised += parseFloat(snap.val().total.amount);
          }
        });

        resolve({ totalRaised, startDate });
      });
  });

export const getChartData = async (id, totalDay, program = 0) => {
  try {
    const labels = [];
    const chartData = [];
    const labelFormat = totalDay > 7 ? 'DD MMM' : 'DD MMMM';
    const promisesAll = [];

    for (let i = 0; i < totalDay; i += 1) {
      const today = new Date();
      const startDate = dateTimeHelper.getUTCDateTime([today.getFullYear(), today.getMonth(), today.getDate()]);
      const endDate = dateTimeHelper.getUTCDateTime([
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59,
      ]);

      if (i > 0) {
        startDate.subtract(i, 'days');
        endDate.subtract(i, 'days');
      }

      promisesAll.push(getProgramDonationByPeriod(id, program, startDate, endDate));
    }

    const dataResult = await Promise.all(promisesAll);
    dataResult.forEach(element => {
      labels.push(element.startDate.local().format(labelFormat));
      chartData.push(element.totalRaised);
    });

    labels.reverse();
    chartData.reverse();

    return { status: 'ok', labels, chartData };
  } catch (error) {
    console.log('error: ', error);
    return { status: 'error', err: error };
  }
};
