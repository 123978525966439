import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

import SiteMeta from './Components/SiteMeta';

import 'animate.css/animate.min.css';
import './Assets/Styles/Terms.css';

const Terms = ({ updateNavStatus }) => {
  useEffect(() => {
    updateNavStatus('');
  }, [updateNavStatus]);

  return (
    <div className="TermsSection">
      <SiteMeta title="Terms" />
      <div className="sectionhero">
        <div className="contentwrapper herosectionfeatures">
          <div className="herocolumn w-row">
            <div className="herocolumntext herocolumntextterms w-col w-col-12 w-col-stack">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h2 className="h2 featuretitle featuretitleterms">Terms of use Fundra</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="featuretitledesc termstitledesc">Version Date: November 12, 2017</div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <p className="bodytext termstext last">
                  This Terms of Use Agreement (“Agreement”) constitutes a legally binding agreement made between you,
                  whether personally or on behalf of an entity (“user” or “you”) and Fundra Inc. and its affiliated
                  companies (collectively, “Company” or “we” or “us” or “our”), concerning your access to and use of the
                  www.fundra.io website as well as any other media form, media channel, mobile website or mobile
                  application related or connected thereto (collectively, the “Website”). The Website provides the
                  following service: Fundraising for nonprofit monthly expenses (“Company Services”). Supplemental terms
                  and conditions or documents that may be posted on the Website from time to time, are hereby expressly
                  incorporated into this Agreement by reference.
                  <br />
                  <br />
                  Company makes no representation that the Website is appropriate or available in other locations other
                  than where it is operated by Company. The information provided on the Website is not intended for
                  distribution to or use by any person or entity in any jurisdiction or country where such distribution
                  or use would be contrary to law or regulation or which would subject Company to any registration
                  requirement within such jurisdiction or country. Accordingly, those persons who choose to access the
                  Website from other locations do so on their own initiative and are solely responsible for compliance
                  with local laws, if and to the extent local laws are applicable.
                  <br />
                  <br />
                  All users who are minors in the jurisdiction in which they reside (generally under the age of 18) are
                  not permitted to register for the Website or use the Company Services.
                  <br />
                  <br />
                  YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY ACKNOWLEDGING SUCH ACCEPTANCE DURING THE
                  REGISTRATION PROCESS (IF APPLICABLE) AND ALSO BY CONTINUING TO USE THE WEBSITE. IF YOU DO NOT AGREE TO
                  ABIDE BY THIS AGREEMENT, OR TO MODIFICATIONS THAT COMPANY MAY MAKE TO THIS AGREEMENT IN THE FUTURE, DO
                  NOT USE OR ACCESS OR CONTINUE TO USE OR ACCESS THE COMPANY SERVICES OR THE WEBSITE.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h4>PURCHASES; PAYMENT</h4>
                <p className="bodytext termstext">
                  Company bills you through an online billing account for purchases of products and/or services. You
                  agree to pay Company all charges at the prices then in effect for the products you or other persons
                  using your billing account may purchase, and you authorize Company to charge your chosen payment
                  provider for any such purchases. You agree to make payment using that selected payment method. If you
                  have ordered a product or service that is subject to recurring charges then you consent to our
                  charging your payment method on a recurring basis, without requiring your prior approval from you for
                  each recurring charge until such time as you cancel the applicable product or service. Company
                  reserves the right to correct any errors or mistakes in pricing that it makes even if it has already
                  requested or received payment. Sales tax will be added to the sales price of purchases as deemed
                  required by Company. Company may change prices at any time. All payments shall be in U.S. dollars.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h4 className="h4nobottom">Web Cookie</h4>
                <p className="bodytext termstext smalltop">All sales are final and no refunds shall be issued.</p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h4 className="h4nobottom">USER REPRESENTATIONS</h4>
                <p className="bodytext termstext smalltop lastcontent">
                  By using the Company Services, you represent and warrant that:
                  <br />
                  A. all registration information you submit is truthful and accurate;
                  <br />
                  B. you will maintain the accuracy of such information;
                  <br />
                  C. you will keep your password confidential and will be responsible for all use of your password and
                  account;
                  <br />
                  D. you are not a minor in the jurisdiction in which you reside, or if a minor, you have received
                  parental permission to use this Website; and
                  <br />
                  E. your use of the Company Services does not violate any applicable law or regulation.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Terms.propTypes = { updateNavStatus: PropTypes.func.isRequired };

export default Terms;
