import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import swal from 'sweetalert';

import { numberHelper } from '../../helpers';

class HeaderInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: '',
      email: '',
      firstName: '',
      lastName: '',
      photo: null,
      avatar: null,
      buttonText: 'Email Tax Report',
      buttonDisabled: false,
    };

    this.handleSendReport = this.handleSendReport.bind(this);
  }

  componentDidMount() {
    const { authUser } = this.props;
    const { firstName, lastName, avatar } = authUser.profile;
    const { uid, email, photo } = authUser.user;

    this.setState({
      uid,
      email,
      firstName,
      lastName,
      photo,
      avatar,
    });
  }

  handleSendReport() {
    this.setState({ buttonText: 'Sending...', buttonDisabled: true });

    const { uid, email, firstName, lastName } = this.state;
    const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const mode = process.env.REACT_APP_FIREBASE_DB_PATH === 'dev' ? 'DEV' : 'PROD';
    const REPORT_DONATION_URL = `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION}/sendTotalDonations`;
    const dataUser = {
      uid,
      email,
      firstName,
      lastName,
    };
    const formData = {
      mode,
      user: JSON.stringify(dataUser),
      key: 'UQg-42-KuT$-Ra*!3ArZv',
    };

    axios
      .post(REPORT_DONATION_URL, qs.stringify(formData), axiosConfig)
      .then(() => {
        swal('Email report sent', `Email report has been sent to [${email}]\nPlease check your email !`, {
          className: 'center-modal-text',
        }).then(() => {
          this.setState({ buttonText: 'Email Tax Report', buttonDisabled: false });
        });
      })
      .catch(err => {
        swal('Error', err.message, 'error').then(() => {
          this.setState({ buttonText: 'Email Tax Report', buttonDisabled: false });
        });
      });
  }

  render() {
    const { withTotal, totalDonation, withSendReport } = this.props;
    const { firstName, lastName, avatar, photo, buttonText, buttonDisabled } = this.state;
    const firstInitial = firstName.substring(0, 1);
    const lastInitial = lastName.substring(0, 1);
    let userPhoto = avatar || null;

    if (!userPhoto && photo) {
      userPhoto = photo;
    }

    return (
      <React.Fragment>
        <div className="flexbox profile-section">
          <div className="profile-avatar">
            {userPhoto ? (
              <div className="profile-image" style={{ backgroundImage: `url("${userPhoto}")` }} />
            ) : (
              <div className="flexbox flexbox-center profile-initial">
                <span>{`${firstInitial}${lastInitial}`}</span>
              </div>
            )}
          </div>
          <div className="flexbox profile-info">
            <div className="profile-info-name">{`${firstName} ${lastName}`}</div>
          </div>
          {withTotal ? (
            <div className="flexbox profile-total-donation">
              <div className="flexbox donation-date">
                <span>Total donations to date</span>
              </div>
              <div className="donation-cost">{numberHelper.getThousandFormat(totalDonation, '$')}</div>
            </div>
          ) : null}
        </div>
        {withSendReport ? (
          <div className="flexbox-end">
            <button
              type="button"
              className="w-button email-report-btn"
              onClick={this.handleSendReport}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

HeaderInfo.propTypes = {
  authUser: PropTypes.instanceOf(Object).isRequired,
  totalDonation: PropTypes.number,
  withTotal: PropTypes.bool,
  withSendReport: PropTypes.bool,
};

HeaderInfo.defaultProps = { totalDonation: 0, withTotal: false, withSendReport: false };

export default HeaderInfo;
