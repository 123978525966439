import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch';
import queryString from 'query-string';
import Loader from '../Components/Waiting';

import { stringHelper } from '../helpers';

import * as routes from '../constants/routes';
import withUserContext from '../withUserContext';

import SiteMeta from '../Components/SiteMeta';
import NonprofitCampaignCard from '../Components/NonprofitCampaignCard';
import { PaginationButton } from '../Components/HtmlTag';

import ImgSearch from '../Assets/Images/Fill10.svg';

import '../Assets/Styles/Campaign.css';

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      filterName: '',
      filterCategory: '',
      filterType: '',
      filterState: '',
      currentPage: 0,
      totalPage: 0,
      disabledPrev: true,
      disabledNext: false,
      showLoader: true,
      referrer: '',
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSearchCampaign = this.handleSearchCampaign.bind(this);
    this.searchEnter = this.searchEnter.bind(this);
    this.handlePaginationPrev = this.handlePaginationPrev.bind(this);
    this.handlePaginationNext = this.handlePaginationNext.bind(this);

    this.searchInput = React.createRef();
    this.focusSearchInput = this.focusSearchInput.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus, showFooterLinkContainer, location } = this.props;
    updateNavStatus('');
    showFooterLinkContainer(false);

    const urlQuery = queryString.parse(location.search);
    const currentPage = urlQuery.page ? parseInt(urlQuery.page, 10) : 0;
    const filterName = urlQuery.name ? urlQuery.name : '';
    const filterCategory = urlQuery.category ? urlQuery.category : '';
    const filterType = urlQuery.type ? urlQuery.type : '';
    const filterState = urlQuery.state ? urlQuery.state : '';
    const referrer = urlQuery.ref ? urlQuery.ref : '';

    this.setState(
      {
        currentPage,
        filterName,
        filterCategory,
        filterType,
        filterState,
        referrer,
      },
      () => {
        this.findCampaigns();
      }
    );
  }

  findCampaigns() {
    const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
    const campaignsIndex = client.initIndex('campaigns');
    const { filterName, currentPage, referrer } = this.state;

    campaignsIndex.search(
      {
        query: filterName,
        filters: 'published:true',
        attributesToRetrieve: [
          'id',
          'name',
          'location',
          'likes',
          'user',
          'time',
          'cover',
          'goal',
          'story',
          'nonprofit',
          'program',
          'metric',
          'date',
          'endDate',
        ],
        hitsPerPage: 9,
        page: currentPage,
      },
      (err, content) => {
        if (err) throw err;

        const items = [];
        content.hits.forEach(item => {
          const slugName = stringHelper.slugify(item.name);
          items.push({
            id: item.id,
            name: item.name,
            slugName,
            location: item.location,
            likes: item.likes,
            user: item.user,
            time: item.time,
            cover: item.cover,
            goal: item.goal,
            story: item.story,
            nonprofit: item.nonprofit,
            program: item.program,
            metric: item.metric,
            date: item.date,
            endDate: item.endDate,
            viewUrl: routes.CAMPAIGNS_ABOUT.replace(':id', item.id).replace(':name', slugName),
          });
        });

        const lastPage = content.page + 1;

        this.setState(
          {
            items,
            totalPage: content.nbPages,
            disabledPrev: content.page === 0,
            disabledNext: lastPage === content.nbPages,
            showLoader: false,
          },
          () => {
            if (referrer === 'menu') {
              this.focusSearchInput();
            }
          }
        );
      }
    );
  }

  focusSearchInput() {
    setTimeout(() => {
      this.searchInput.current.focus();
    }, 500);
  }

  handleFilterChange(event) {
    const { target } = event;
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  handleSearchCampaign() {
    const { filterName } = this.state;
    const { history } = this.props;

    history.push(`${routes.CAMPAIGNS}?name=${filterName}`);
  }

  handlePaginationPrev() {
    const { currentPage, filterName, filterCategory, filterType, filterState } = this.state;
    const { history } = this.props;
    const prevPage = currentPage - 1;
    let qs = `?page=${prevPage}`;
    if (filterName !== '') {
      qs += `&name=${filterName}`;
    }
    if (filterCategory !== '') {
      qs += `&category=${filterCategory}`;
    }
    if (filterType !== '') {
      qs += `&type=${filterType}`;
    }
    if (filterState !== '') {
      qs += `&state=${filterState}`;
    }
    history.push(routes.CAMPAIGNS + qs);
  }

  handlePaginationNext() {
    const { currentPage, filterName, filterCategory, filterType, filterState } = this.state;
    const { history } = this.props;
    const nextPage = currentPage + 1;
    let qs = `?page=${nextPage}`;
    if (filterName !== '') {
      qs += `&name=${filterName}`;
    }
    if (filterCategory !== '') {
      qs += `&category=${filterCategory}`;
    }
    if (filterType !== '') {
      qs += `&type=${filterType}`;
    }
    if (filterState !== '') {
      qs += `&state=${filterState}`;
    }
    history.push(routes.CAMPAIGNS + qs);
  }

  searchEnter(event) {
    if (event.key === 'Enter') {
      this.handleSearchCampaign();
    }
  }

  render() {
    const { items, filterName, disabledPrev, disabledNext, totalPage, showLoader } = this.state;
    const { authUser } = this.props;

    return (
      <div className="NonprofitListSection">
        <SiteMeta title="Our Projects & Campaigns" />
        {showLoader ? (
          <Loader />
        ) : (
          <div className="sectionhero item-list">
            <div className="contentwrapper herosectionfeatures">
              <div className="w-row sectionsearchbar nonprofit-list">
                <div className="w-col w-col-5 w-col-stack">
                  <div className="graygroupinput">
                    <div className="searchinput">
                      <input
                        name="filterName"
                        type="text"
                        placeholder="Search campaign or nonprofit name"
                        value={filterName}
                        onChange={this.handleFilterChange}
                        onKeyPress={this.searchEnter}
                        ref={this.searchInput}
                      />
                    </div>
                    <div className="searchicon">
                      <img src={ImgSearch} alt="" />
                    </div>
                  </div>
                </div>
                <div className="w-col w-col-2 w-col-stack search-btn">
                  <button type="button" className="w-button" onClick={this.handleSearchCampaign}>
                    Search
                  </button>
                </div>
              </div>
              <div className="nonprofithome nonprofit-card-list">
                {items.length > 0 ? (
                  <CampaignList items={items} authUser={authUser} />
                ) : (
                  <div className="result-not-found">
                    <h2>Sorry, no matches found.</h2>
                    <div className="find-another">Please adjust your search criteria and try again.</div>
                  </div>
                )}
              </div>
              {totalPage ? (
                <PaginationButton
                  disabledPrev={disabledPrev}
                  disabledNext={disabledNext}
                  handlePaginationPrev={this.handlePaginationPrev}
                  handlePaginationNext={this.handlePaginationNext}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

List.propTypes = {
  showFooterLinkContainer: PropTypes.func.isRequired,
  updateNavStatus: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  authUser: PropTypes.instanceOf(Object),
};

List.defaultProps = {
  authUser: null,
};

function CampaignList(props) {
  const campaignRow = [];
  let campaignCol = [];
  let rowIndex = 1;
  props.items.forEach((item, index) => {
    campaignCol.push(
      <div className="w-col w-col-4" key={item.id}>
        <NonprofitCampaignCard item={item} authUser={props.authUser} withHeader socialShare />
      </div>
    );

    const counter = index + 1;
    if (counter % 3 === 0) {
      if (campaignCol.length > 0) {
        campaignRow.push(
          <div className="w-row" key={rowIndex}>
            {campaignCol}
          </div>
        );
      }
      campaignCol = [];
      rowIndex += 1;
    }
  });

  if (campaignCol.length > 0) {
    campaignRow.push(
      <div className="w-row" key={rowIndex}>
        {campaignCol}
      </div>
    );
  }

  return campaignRow;
}

export default withUserContext(List);
