import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import * as routes from '../constants/routes';

class NonprofitStickyButton extends Component {
  constructor(props) {
    super(props);

    this.state = { nid: null };

    this.handleDonation = this.handleDonation.bind(this);
    this.handleFundraise = this.handleFundraise.bind(this);
  }

  componentDidMount() {
    const { nid } = this.props;
    this.setState({ nid });
  }

  handleDonation() {
    const { history, program, campaign, disabled } = this.props;
    const { nid } = this.state;
    let type = 'b';
    if (program) {
      type = program;
    }
    if (campaign) {
      type = campaign;
    }

    if (!disabled) {
      history.push(routes.NONPROFITS_DONATION.replace(':id', nid).replace(':type', type));
    }
  }

  handleFundraise() {
    const { history, program, campaign } = this.props;
    const { nid } = this.state;
    let query = `?b=${nid}`;
    if (program) {
      query += `&p=${program}`;
    }
    if (campaign) {
      query = `?j=${campaign}`;
    }

    history.push(routes.CAMPAIGNS_REGISTER + query);
  }

  render() {
    const { oneButton, disabled } = this.props;

    return (
      <div className="sticky-button">
        {!oneButton ? (
          <div className="flex-button">
            <button type="button" className="w-button f-btn-danger" onClick={this.handleFundraise}>
              Fundraise
            </button>
          </div>
        ) : null}
        <div className={`flex-button${oneButton ? ' full-button' : ''}`}>
          <button type="button" className="w-button" onClick={this.handleDonation} disabled={disabled}>
            Support
          </button>
        </div>
      </div>
    );
  }
}

NonprofitStickyButton.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  nid: PropTypes.string.isRequired,
  program: PropTypes.string,
  campaign: PropTypes.string,
  oneButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

NonprofitStickyButton.defaultProps = {
  program: null,
  campaign: null,
  oneButton: true,
  disabled: false,
};

export default withRouter(NonprofitStickyButton);
