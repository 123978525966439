import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';

import * as routes from './constants/routes';

import SiteMeta from './Components/SiteMeta';
import FaqComponent from './Components/Faq';
import LaunchingCharityComponent from './Components/LaunchingCharity';

import ImgFeatureA from './Assets/Images/IncreaseYourDonations.png';
import ImgFeatureB from './Assets/Images/CampaignWeb.png';
import ImgFeatureC from './Assets/Images/TrackAndGetBack.png';
import ImgFeatureD from './Assets/Images/DataHowYouWant.png';
import ImgFeatureE from './Assets/Images/SpreadTheWord.png';

import 'animate.css/animate.min.css';

const Features = ({ updateNavStatus }) => {
  useEffect(() => {
    updateNavStatus('');
  }, [updateNavStatus]);

  return (
    <div className="FeaturesSection">
      <SiteMeta title="For Nonprofits" />
      <div className="sectionhero blog-featurespage">
        <div className="contentwrapper herosectionfeatures">
          <div className="herocolumn herocolumnfeaturepage w-row">
            <div className="herocolumntext herocolumntextfeature w-col w-col-4 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <h2 className="h2">Increase Your Donations</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                <div className="herodescription">
                  Show donors how their charitable contributions will make a tangible impact.
                </div>
                <div className="herocta">
                  <HashLink to={routes.NONPROFITS_REGISTER} className="f-btn f-btn-success f-btn-space">
                    <div>Get Started</div>
                  </HashLink>
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage herocolumnimagefeatureshero w-col w-col-8 w-col-stack">
              <img src={ImgFeatureA} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="sectionfeatured feature">
        <div className="contentwrapper">
          <div className="herocolumn herocolumnfeatures1 w-row">
            <div className="w-col w-col-8 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgFeatureB} alt="" />
              </ScrollAnimation>
            </div>
            <div className="herocolumntext f1text w-col w-col-4 w-col-stack">
              <ScrollAnimation animateIn="fadeInRight" animateOnce delay={150}>
                <h3 className="h3">
                  Share Your
                  <br />
                  Programs &amp; Projects
                </h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInRight" animateOnce delay={200}>
                <div className="herosecondarydescription featuredescription">
                  Focus on marketing your programs and projects. Let your donors visualize and campaign for your cause
                  with the click of a button
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="herocolumn herocolumnfeatures1responsive w-row">
            <div className="herocolumntext f2text w-col w-col-6 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={50}>
                <h3 className="h3">Share Your Programs &amp; Projects</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                <div className="herosecondarydescription featuredescription">
                  Focus on marketing your programs and projects. Let your donors visualize and campaign for your cause
                  with the click of a button
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage herocolumnimagefeatures1responsive w-col w-col-6 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgFeatureB} className="f1 f1responsiveimages" alt="" />
              </ScrollAnimation>
            </div>
          </div>
          <div className="herocolumn herocolumnfeatures2 w-row">
            <div className="herocolumntext f2text w-col w-col-4 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={50}>
                <h3 className="h3">Track &amp; Get back</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                <div className="herosecondarydescription">
                  Engage your donors by enabling them to see the impact their making in their community!
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage w-col w-col-8 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgFeatureC} alt="" />
              </ScrollAnimation>
            </div>
          </div>
          <div className="herocolumn herocolumnfeatures3 w-row">
            <div className="herocolumnimage w-col w-col-8">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgFeatureD} alt="" />
              </ScrollAnimation>
            </div>
            <div className="herocolumntext f3text w-col w-col-4">
              <ScrollAnimation animateIn="fadeInRight" animateOnce delay={150}>
                <h3 className="h3">Data, How you want to see it</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInRight" animateOnce delay={200}>
                <div className="herosecondarydescription">
                  Beautiful visualizations and insights help your supporters stay engaged so donors never question where
                  their money is going.
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="herocolumn herocolumnfeatures3responsive w-row">
            <div className="herocolumntext w-col w-col-6 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={50}>
                <h3 className="h3">Data, How you want to see it</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                <div className="herosecondarydescription">
                  Beautiful visualizations and insights help your supporters stay engaged so donors never question where
                  their money is going.
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage w-col w-col-6 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgFeatureD} alt="" />
              </ScrollAnimation>
            </div>
          </div>
          <div className="herocolumn herocolumnfeatures4 w-row">
            <div className="herocolumntext f2text w-col w-col-4 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={50}>
                <h3 className="h3">Spread the word</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                <div className="herosecondarydescription">
                  Effortlessly share through Facebook, Twitter, and Instagram; or even by email. Enable your donors to
                  choose the information they want to share; whether it be to tag which program they funded, how much
                  they&apos;ve donated, or even if to create custom posts!
                </div>
              </ScrollAnimation>
            </div>
            <div className="herocolumnimage w-col w-col-8 w-col-stack">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <img src={ImgFeatureE} alt="" />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <LaunchingCharityComponent />
      <FaqComponent />
    </div>
  );
};

Features.propTypes = { updateNavStatus: PropTypes.func.isRequired };

export default Features;
