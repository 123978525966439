import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';

import * as routes from './constants/routes';
import withUserContext from './withUserContext';

import ImgHeroIllustration from './Assets/Images/IllustrationPricing.svg';
import IconPeer from './Assets/Images/icon-peer.svg';
import IconFundraising from './Assets/Images/icon-fundraising.svg';
import IconDonor from './Assets/Images/icon-donor.svg';
import { ReactComponent as IconCheck } from './Assets/Images/icon-labelCheck.svg';
import ImgFundraProduct from './Assets/Images/fundraProduct.png';

import 'animate.css/animate.min.css';

class Pricing extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      name: '',
      email: '',
      organization: '',
      organizationWeb: '',
      phone: '',
      stateArea: '',
      employeeCount: '',
      revenue: '',
      submitButton: 'Submit',
      disabled: false,
      formDisplay: { display: 'block' },
      failStyle: { display: 'none' },
      doneStyle: { backgroundColor: '#fff', display: 'none' },
    };

    this.state = this.initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { updateNavStatus } = this.props;
    updateNavStatus('');
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitButton: 'Please wait...', disabled: true });

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { name, email, organization, organizationWeb, phone, stateArea, employeeCount, revenue } = this.state;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('organization', organization);
    formData.append('organizationWeb', organizationWeb);
    formData.append('phone', phone);
    formData.append('stateArea', stateArea);
    formData.append('employeeCount', employeeCount);
    formData.append('revenue', revenue);

    axios
      .post('/api/ajax.php', formData, axiosConfig)
      .then(res => {
        this.setState(this.initialState);
        if (res.data.code === 200) {
          this.setState({
            formDisplay: { display: 'none' },
            doneStyle: { backgroundColor: '#fff', display: 'block' },
          });
        } else {
          this.setState({ failStyle: { display: 'block' } });
        }
      })
      .catch(err => {
        this.setState({ failStyle: { display: 'block' } });
        console.log(err);
      });
  }

  render() {
    document.title = 'Pricing - Fundra';

    const { name, email, organization, organizationWeb, phone, stateArea, employeeCount, revenue } = this.state;
    const { submitButton, disabled, formDisplay, failStyle, doneStyle } = this.state;

    return (
      <div className="HomeSection">
        <div className="sectionhero sectionheropricing blog-pricingpage">
          <div className="contentwrapper herosectionfeatures">
            <div className="herocolumn w-row">
              <div className="herocolumntext herocolumntexthome w-col w-col-6 w-col-stack">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                  <h2 className="h2">Pricing designed to fit your organization</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={100}>
                  <div className="herodescription smallfit">
                    Looking for a fundraising solution that fits your unique nonprofit needs? Let us help!
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" animateOnce delay={150}>
                  <div className="herocta">
                    <HashLink to={`${routes.PRICING}#talktoexpert`} className="f-btn f-btn-success f-btn-space">
                      <div>Talk to an Expert</div>
                    </HashLink>
                    <HashLink to={`${routes.PRICING}#talktoexpert`} className="f-link f-warning">
                      Request a Demo
                    </HashLink>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="herocolumnimage heropricepageimage w-col w-col-6 w-col-stack">
                <img src={ImgHeroIllustration} alt="Fundra" className="heroimage" />
              </div>
            </div>
          </div>
        </div>
        <div className="sectionpricingfeatured">
          <div className="contentwrapper">
            <div className="pricingtitleblock">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h3 className="h3 h3center">Maximize your funding potential</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce delay={100}>
                <div className="descriptioncenter">
                  Fundra provides you with a designated page for donations which includes a search option for visitors
                  to find local programs offered by your organization. Visitors can also support your organization
                  directly with an online donation or to tell others about your cause through a social media
                  integration.
                </div>
              </ScrollAnimation>
              <div className="featuredpricingcta">
                <ScrollAnimation animateIn="fadeInUp" animateOnce delay={150}>
                  <div className="herocta">
                    <HashLink to={`${routes.PRICING}#talktoexpert`} className="f-btn f-btn-success f-btn-space">
                      <div>Talk to an Expert</div>
                    </HashLink>
                    <HashLink to={`${routes.PRICING}#talktoexpert`} className="f-link f-warning">
                      Request a Demo
                    </HashLink>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="featuredpricingcolumns w-row">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="featuredpricingcolumnscontent w-col w-col-4">
                  <div className="iconpricingfetured">
                    <img src={IconPeer} alt="" />
                  </div>
                  <div className="featuredpricingtitle">Peer-To-Peer Fundraising</div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Campaign management</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Unlimited P2P pages</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Unique share links for each campaign</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Branded P2P fundraising</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Join and link to social campaigns</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Organizer management</div>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="featuredpricingcolumnscontent w-col w-col-4">
                  <div className="iconpricingfetured">
                    <img src={IconFundraising} alt="" />
                  </div>
                  <div className="featuredpricingtitle">Program Based Fundraising</div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">
                      <strong>No platform fees</strong>
                    </div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Listing of all programs and services</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Search and filter for programs</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Branded donation page</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Donate to a specific program</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">
                      <strong>Donors have option to cover payment fees</strong>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="featuredpricingcolumnscontent w-col w-col-4">
                  <div className="iconpricingfetured">
                    <img src={IconDonor} alt="" />
                  </div>
                  <div className="featuredpricingtitle">Donor Engagement</div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Track your donations by program and cause</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Send a tax-deductible report to your accountant</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Visualize your giving throughout the year</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Download or send donation receipts to your email</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Advanced Donor data collection</div>
                  </div>
                  <div className="featuredpricingdetaildescription w-clearfix">
                    <div className="iconlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel">Donor analytics</div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className="sectionpricingsubscribe">
          <div className="contentwrapper">
            <div className="pricingtitleblock">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h2 className="h2center">
                  Transparent and accountable
                  <br />
                  fundraising for nonprofits
                </h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="herodescription descriptionsubscribe">Discover what&apos;s possible with Fundra</div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="herocta">
                  <HashLink to={`${routes.PRICING}#talktoexpert`} className="f-btn f-btn-success f-btn-space">
                    <div>Talk to an Expert</div>
                  </HashLink>
                  <HashLink to={`${routes.PRICING}#talktoexpert`} className="f-link f-warning">
                    Request a Demo
                  </HashLink>
                </div>
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="herocolumnimage heropricepageimagesecond">
                <img src={ImgFundraProduct} alt="" className="fundraproduct" />
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div id="talktoexpert" className="sectiontalktoexpert">
          <div className="contentwrapper wrappertalktoexpert">
            <div className="pricingtitleblock">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h2 className="h2center">Talk to an Expert</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="herodescription descriptionsubscribe">We’re advocates for your success!</div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="featuredpointdebugblock">
                  <div className="featuredpricingdetaildescription featuredpointtalktoexpert">
                    <div className="iconlabel iconpointlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel featuredpricingpointlabel">See customer examples</div>
                  </div>
                  <div className="featuredpricingdetaildescription featuredpointtalktoexpert featuredpointdebugging">
                    <div className="iconlabel iconpointlabel iconpointlabeldebug">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel featuredpricingpointlabel featuredpointdebug">
                      Craft a go-forward fundraising strategy that makes sense for you
                    </div>
                  </div>
                  <div className="featuredpricingdetaildescription featuredpointtalktoexpert">
                    <div className="iconlabel iconpointlabel">
                      <IconCheck />
                    </div>
                    <div className="featuredpricinglabel featuredpricingpointlabel">
                      See the Fundra platform in action
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="formtalktoexpert">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="formblock w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    action="submitcontact"
                    onSubmit={this.handleSubmit}
                    style={formDisplay}
                  >
                    <label htmlFor="name" className="formfieldlabel">
                      Name
                    </label>
                    <input
                      type="text"
                      className="formtextfield w-input"
                      maxLength="256"
                      name="name"
                      data-name="Name"
                      placeholder="Enter your name"
                      id="name"
                      value={name}
                      onChange={this.handleChange}
                      required
                    />
                    <label htmlFor="email" className="formfieldlabel">
                      Email address*
                    </label>
                    <input
                      type="email"
                      className="formtextfield w-input"
                      maxLength="256"
                      name="email"
                      data-name="Email"
                      placeholder="Enter your email"
                      id="email"
                      value={email}
                      onChange={this.handleChange}
                      required
                    />
                    <label htmlFor="organization" className="formfieldlabel">
                      Name of Organization/Nonprofit*
                    </label>
                    <input
                      type="text"
                      className="formtextfield w-input"
                      maxLength="256"
                      name="organization"
                      data-name="organization"
                      placeholder="Type your organization"
                      id="organization"
                      value={organization}
                      onChange={this.handleChange}
                      required
                    />
                    <label htmlFor="organizationWeb" className="formfieldlabel">
                      Organization&apos;s Website/URL*
                    </label>
                    <input
                      type="text"
                      className="formtextfield w-input"
                      maxLength="256"
                      name="organizationWeb"
                      data-name="organizationWeb"
                      placeholder="http://www.example.com"
                      id="organizationWeb"
                      value={organizationWeb}
                      onChange={this.handleChange}
                      required
                    />
                    <label htmlFor="phone" className="formfieldlabel">
                      Phone number*
                    </label>
                    <input
                      type="tel"
                      className="formtextfield w-input"
                      maxLength="256"
                      name="phone"
                      data-name="phone"
                      placeholder="Enter your phone number"
                      id="phone"
                      value={phone}
                      onChange={this.handleChange}
                      required
                    />
                    <label htmlFor="stateArea" className="formfieldlabel">
                      Please select a state
                    </label>
                    <select
                      id="stateArea"
                      name="stateArea"
                      data-name="stateArea"
                      className="formdropdown w-select"
                      value={stateArea}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Select state</option>
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AZ">AZ</option>
                      <option value="AR">AR</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="IA">IA</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="MA">MA</option>
                      <option value="ME">ME</option>
                      <option value="MD">MD</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MS">MS</option>
                      <option value="MO">MO</option>
                      <option value="MT">MT</option>
                      <option value="NE">NE</option>
                      <option value="NV">NV</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NY">NY</option>
                      <option value="NC">NC</option>
                      <option value="ND">ND</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WV">WV</option>
                      <option value="WI">WI</option>
                      <option value="WC">WC</option>
                      <option value="DC">DC</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="N/A - International">N/A - International</option>
                      <option value="N/A - Canada">N/A - Canada</option>
                    </select>
                    <label htmlFor="employeeCount" className="formfieldlabel">
                      Number of full time employees
                    </label>
                    <select
                      id="employeeCount"
                      name="employeeCount"
                      data-name="employeeCount"
                      className="formdropdown w-select"
                      value={employeeCount}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Select number of full time employees</option>
                      <option value="0">0</option>
                      <option value="1-2">1-2</option>
                      <option value="3-5">3-5</option>
                      <option value="6-15">6-15</option>
                      <option value="16-25">16-25</option>
                      <option value="26-50">26-50</option>
                      <option value="51-100">51-100</option>
                      <option value="100+">100+</option>
                    </select>
                    <label htmlFor="revenue" className="formfieldlabel">
                      Annual online revenue
                    </label>
                    <select
                      id="revenue"
                      name="revenue"
                      data-name="revenue"
                      className="formdropdown w-select"
                      value={revenue}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Your revenue</option>
                      <option value="More than $100,000 Annually online">More than $100,000 Annually online</option>
                      <option value="Less than $100,000 Annually online">Less than $100,000 Annually online</option>
                    </select>
                    <input type="submit" value={submitButton} className="formsubmit w-button" disabled={disabled} />
                  </form>
                  <div className="w-form-done" style={doneStyle}>
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail" style={failStyle}>
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Pricing.propTypes = { updateNavStatus: PropTypes.func.isRequired };

export default withUserContext(Pricing);
