import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Script from 'react-load-script';
import ScrollAnimation from 'react-animate-on-scroll';
import { CSSTransition } from 'react-transition-group';
import { Progress } from 'react-sweet-progress';

import { auth, database, storage } from '../firebase';
import { dateTimeHelper } from '../helpers';
import { BackButton, UploadButton } from '../Components/HtmlTag';
import { UserProvider, SubscriptionPackageProvider } from '../Providers';

import withUserContext from '../withUserContext';
import * as routes from '../constants/routes';

import imgLogo from '../Assets/Images/Media_Icon.svg';
import imgPlaceHolder from '../Assets/Images/PlaceHolder.svg';

import 'animate.css/animate.min.css';
import 'react-sweet-progress/lib/style.css';
import '../Assets/Styles/Nonprofits-Register.css';

const headerContent = [
  {
    featureTitle: 'Register Your Charity',
    featureTitleDesc: 'Join a growing network of inspiring and innovative organizations!',
    percent: 20,
  },
  {
    featureTitle: 'Contact Information',
    featureTitleDesc:
      'This information will be used to contact you once we have received and verified all required information.',
    percent: 40,
  },
  {
    featureTitle: 'Program Information',
    featureTitleDesc: 'Add your programs, projects, or services that impact your community!',
    percent: 60,
  },
  {
    featureTitle: 'Banking Information',
    featureTitleDesc: 'Add your bank details for a direct deposit of funds we collect on your behalf!',
    percent: 80,
  },
  {
    featureTitle: 'Claim Your Charity',
    featureTitleDesc: 'Join a growing network of transparent charities and nonprofit organizations!',
    percent: 100,
  },
];

const pushProgramToFirebase = async (newPostKey, program) => {
  try {
    const coverProgramRefPath = process.env.REACT_APP_FIREBASE_NONPROFIT_PROGRAMS_IMAGE_PATH;
    const newProgramKey = dateTimeHelper.getCurrentTimeMs();

    const programMediaCoverFile = {
      file: program.programMediaCover,
      meta: program.programMediaCover ? { contentType: program.programMediaCover.type } : '',
      name: '',
      ext: program.programMediaCoverExt,
    };

    if (programMediaCoverFile.file) {
      const coverSnap = await storage.put(
        `${coverProgramRefPath}/${newProgramKey}.${programMediaCoverFile.ext}`,
        programMediaCoverFile.file,
        programMediaCoverFile.meta
      );
      const coverUrl = await coverSnap.ref.getDownloadURL();

      programMediaCoverFile.name = coverUrl;
    }

    const programData = {
      id: newProgramKey,
      name: program.programName,
      description: program.programDescription,
      cover: programMediaCoverFile.name,
    };

    await database.setRef(`/nonprofit_programs/${newPostKey}/${newProgramKey}`, programData);

    return { status: 'ok' };
  } catch (error) {
    return { status: 'error', err: error };
  }
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      picture: null,
      picturePreview: imgLogo,
      pictureExt: '',
      nonProfitName: '',
      location: '',
      street: '',
      city: '',
      countryState: '',
      country: '',
      zipcode: '',
      ein: '',
      category: '',
      categoryName: '',
      type: '',
      website: '',
      email: '',
      phone: '',
      description: '',
      mediaCover: null,
      mediaCoverPreview: imgPlaceHolder,
      mediaCoverExt: '',
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userPhone: '',
      userPassword: '',
      userConfirmPassword: '',
      program: {
        programId: '',
        programName: '',
        programDescription: '',
        programMediaCover: null,
        programMediaCoverPreview: imgPlaceHolder,
        programMediaCoverExt: '',
      },
      programs: [],
      routingNumber: '',
      accountNumber: '',
      confirmAccountNumber: '',
      agree: false,
      errorProgram: false,
      errorCreated: false,
      errorMessage: '',
      fundraCategories: [],
      fundraTypes: [],
      step: 1,
      featureTitle: headerContent[0].featureTitle,
      featureTitleDesc: headerContent[0].featureTitleDesc,
      percent: 20,
      showBack: false,
      disabledContinue: false,
      buttonText: 'Submit',
      contactButtonText: 'Continue',
      disabledContactButton: false,
    };

    this.state = this.initialState;
    // This binding is necessary to make `this` work in the callback
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleProgramChange = this.handleProgramChange.bind(this);
    this.handleProgramFileChange = this.handleProgramFileChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateAccountNumber = this.validateAccountNumber.bind(this);
    this.addProgramItem = this.addProgramItem.bind(this);
    this.removeProgramItem = this.removeProgramItem.bind(this);
    this.submitCharity = this.submitCharity.bind(this);
    this.submitContact = this.submitContact.bind(this);
    this.submitProgram = this.submitProgram.bind(this);
    this.submitBank = this.submitBank.bind(this);

    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }

  componentDidMount() {
    const { claimed } = this.props;

    if (claimed) {
      this.setState({
        step: 2,
        featureTitle: 'Claim Your Charity',
        contactButtonText: 'Submit',
      });
    } else {
      database
        .getRef('/category')
        .once('value')
        .then(snapshot => {
          const categories = [];
          snapshot.forEach(childSnapshot => {
            const types = [];
            Object.keys(childSnapshot.val().types).forEach(key => {
              types.push(childSnapshot.val().types[key]);
            });

            categories.push({ id: childSnapshot.val().id, name: childSnapshot.val().name, types });
          });
          this.setState({ fundraCategories: categories });
        });
    }
  }

  addProgramItem() {
    const { program: newItem } = this.state;

    if (newItem.programName) {
      this.setState(prevState => ({
        programs: [...prevState.programs, newItem],
        program: {
          programId: '',
          programName: '',
          programDescription: '',
          programMediaCover: null,
          programMediaCoverPreview: imgPlaceHolder,
          programMediaCoverExt: '',
        },
      }));
    }

    document.getElementById('programName').focus();
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'category') {
      this.setState({ categoryName: '', type: '', fundraTypes: [] });

      if (value) {
        const { fundraCategories } = this.state;
        const selectedCat = fundraCategories[value];
        this.setState({ categoryName: selectedCat.name, fundraTypes: selectedCat.types });
      }
    }

    if (name === 'agree' && value) {
      this.setState({
        errorCreated: false,
        errorMessage: '',
      });
    }

    if (name === 'ein') {
      const { ein } = this.state;
      if (ein.length < value.length && value.length === 2) {
        value = `${value}-`;
      }
    }

    this.setState({ [name]: value });
  }

  handleFileChange(event) {
    const { name } = event.target;
    const previewImg = `${name}Preview`;
    const extImg = `${name}Ext`;
    // const ext = event.target.files[0].name.split('.').pop();

    this.setState({
      [name]: event.target.files[0],
      [previewImg]: URL.createObjectURL(event.target.files[0]),
      [extImg]: 'jpg', // set all extension to jpg, easy when removing from storage
    });
  }

  handleProgramChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'agree' && value) {
      this.setState({
        [name]: value,
        errorCreated: false,
        errorMessage: '',
      });
    } else {
      this.setState(prevState => {
        const program = { ...prevState.program };
        program[name] = value;
        return { program };
      });
    }
  }

  handleProgramFileChange(event) {
    if (event.target.files.length) {
      const { name } = event.target;
      const previewImg = `${name}Preview`;
      const extImg = `${name}Ext`;
      const file = event.target.files[0];
      // const ext = file.name.split('.').pop();

      this.setState(prevState => {
        const program = { ...prevState.program };
        program[name] = file;
        program[previewImg] = URL.createObjectURL(file);
        program[extImg] = 'jpg'; // set all extension to jpg, easy when removing from storage

        return { program };
      });
    }
  }

  handleBackClick(e) {
    document.location.href = '#top';
    e.preventDefault();

    this.setState(prevState => {
      let backStep = 1;
      let backHeaderStep = 2;
      const { authUser } = this.props;

      if (prevState.step === 3 && authUser) {
        backStep += 1;
        backHeaderStep += 1;
      }

      const headerStep = prevState.step - backHeaderStep;
      return {
        step: prevState.step - backStep,
        featureTitle: headerContent[headerStep].featureTitle,
        featureTitleDesc: headerContent[headerStep].featureTitleDesc,
        percent: Number(headerContent[headerStep].percent),
        showBack: prevState.step - backStep > 1,
      };
    });
  }

  handleErrorProcess(message) {
    this.setState({
      errorCreated: true,
      errorMessage: message,
      showBack: true,
      buttonText: 'Continue',
      disabledContinue: false,
    });
  }

  handleScriptLoad() {
    // Declare Options For Autocomplete
    const options = {
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /* global google */ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('location'),
      options
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect() {
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      const locationInfo = {
        street: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
      };
      address.forEach(item => {
        if (item.types[0] === 'street_number') {
          locationInfo.street = item.long_name;
        }
        if (item.types[0] === 'route') {
          locationInfo.street = `${locationInfo.street} ${item.long_name}`;
        }
        if (item.types[0] === 'sublocality_level_1') {
          locationInfo.city = item.long_name;
        }
        if (item.types[0] === 'locality') {
          locationInfo.city = item.long_name;
        }
        if (item.types[0] === 'administrative_area_level_1') {
          locationInfo.state = item.short_name;
        }
        if (item.types[0] === 'country') {
          locationInfo.country = item.long_name;
        }
        if (item.types[0] === 'postal_code') {
          locationInfo.zipcode = item.long_name;
        }
      });

      // Set State
      this.setState({
        street: locationInfo.street,
        city: locationInfo.city,
        countryState: locationInfo.state,
        country: locationInfo.country,
        zipcode: locationInfo.zipcode,
        location: addressObject.formatted_address,
      });
    }
  }

  removeProgramItem(key, event) {
    event.preventDefault();

    this.setState(prevState => ({
      programs: prevState.programs.filter((value, index) => index !== key),
    }));
  }

  submitCharity(event) {
    document.location.href = '#top';
    event.preventDefault();

    const { authUser } = this.props;
    const step = authUser ? 3 : 2;
    const headerStep = authUser ? 2 : 1;

    this.setState({
      step,
      featureTitle: headerContent[headerStep].featureTitle,
      featureTitleDesc: headerContent[headerStep].featureTitleDesc,
      percent: Number(headerContent[headerStep].percent),
      showBack: true,
    });
  }

  submitContact(event) {
    event.preventDefault();
    this.setState({
      contactButtonText: 'Please wait...',
      disabledContactButton: true,
      errorCreated: false,
      errorMessage: '',
    });

    const { userFirstName, userLastName, userEmail, userPassword, userPhone } = this.state;
    const { claimed, claimedKey } = this.props;
    const contactButtonBack = claimed ? 'Submit' : 'Continue';

    // checking user
    auth
      .signInWithEmailPassword(userEmail, userPassword)
      .then(user => {
        UserProvider.signOut();

        this.setState({
          contactButtonText: contactButtonBack,
          disabledContactButton: false,
          errorCreated: true,
          errorMessage: `The email address [${user.user.email}] is already in use by another account.`,
        });
      })
      .catch(err => {
        switch (err.code) {
          case 'auth/user-not-found':
            if (claimed) {
              const userData = {
                firstName: userFirstName,
                lastName: userLastName,
                email: userEmail,
                password: userPassword,
                phone: userPhone,
              };

              UserProvider.registerUser(userData, 2).then(result => {
                if (result.status === 'ok') {
                  const dbPath = process.env.REACT_APP_FIREBASE_DB_PATH;
                  const newPostKey = claimedKey;
                  const timeCreated = dateTimeHelper.getCurrentTimeMs();
                  const claimedData = {
                    key: newPostKey,
                    uid: result.uid,
                    time: timeCreated,
                  };

                  const updates = {};
                  updates[`${dbPath}/nonprofit_claimed/${newPostKey}/${result.uid}`] = claimedData;
                  updates[`${dbPath}/profile/${result.uid}/nonprofit/claimed/${newPostKey}`] = {
                    key: newPostKey,
                    time: timeCreated,
                  };

                  database.updateRef(updates).then(() => {
                    document.location.href = '#root';

                    this.setState({
                      step: 5,
                      featureTitle: headerContent[4].featureTitle,
                      featureTitleDesc: headerContent[4].featureTitleDesc,
                      percent: Number(headerContent[4].percent),
                    });
                  });
                } else {
                  this.setState({
                    contactButtonText: contactButtonBack,
                    disabledContactButton: false,
                    errorCreated: true,
                    errorMessage: result.err.message,
                  });
                }
              });
            } else {
              document.location.href = '#top';

              this.setState({
                step: 3,
                contactButtonText: contactButtonBack,
                disabledContactButton: false,
                featureTitle: headerContent[2].featureTitle,
                featureTitleDesc: headerContent[2].featureTitleDesc,
                percent: Number(headerContent[2].percent),
              });
            }
            break;

          case 'auth/wrong-password':
            this.setState({
              contactButtonText: contactButtonBack,
              disabledContactButton: false,
              errorCreated: true,
              errorMessage: 'The email address is already in use by another account.',
            });
            break;

          default:
            document.location.href = '#top';

            this.setState({
              step: 3,
              featureTitle: headerContent[2].featureTitle,
              featureTitleDesc: headerContent[2].featureTitleDesc,
              percent: Number(headerContent[2].percent),
            });
        }
      });
  }

  submitProgram(event) {
    event.preventDefault();

    const { programs, program: newItem } = this.state;
    let goToNext = programs.length > 0;

    if (newItem.programName) {
      goToNext = true;
      this.setState(prevState => ({
        programs: [...prevState.programs, newItem],
        program: {
          programId: '',
          programName: '',
          programDescription: '',
          programMediaCover: null,
          programMediaCoverPreview: imgPlaceHolder,
          programMediaCoverExt: '',
        },
      }));
    }

    if (goToNext) {
      this.setState(
        {
          errorProgram: false,
          errorMessage: '',
        },
        () => {
          this.saveNonprofit();
        }
      );

      // document.location.href = '#top';
      // this.setState({
      //   step: 4,
      //   featureTitle: headerContent[3].featureTitle,
      //   featureTitleDesc: headerContent[3].featureTitleDesc,
      //   percent: Number(headerContent[3].percent),
      //   errorProgram: false,
      //   errorMessage: '',
      // });
    } else {
      this.setState({
        errorProgram: true,
        errorMessage: 'Please add your programs, projects, or services that impact your community!',
      });
    }
  }

  submitBank(event) {
    event.preventDefault();
    this.saveNonprofit();
  }

  saveNonprofit() {
    const { agree } = this.state;
    const { authUser } = this.props;

    if (!agree) {
      this.setState({
        errorCreated: true,
        errorMessage: 'Please check the box above to accept our privacy policy and terms of use',
      });
    } else {
      this.setState({
        showBack: false,
        buttonText: 'Please wait...',
        disabledContinue: true,
      });

      if (authUser) {
        this.pushNonProfitToFirebase(authUser.user.uid, routes.NONPROFITS);
      } else {
        try {
          const { userFirstName, userLastName, userEmail, userPassword, userPhone } = this.state;
          const userData = {
            firstName: userFirstName,
            lastName: userLastName,
            email: userEmail,
            password: userPassword,
            phone: userPhone,
          };

          UserProvider.registerUser(userData, 2).then(result => {
            if (result.status === 'ok') {
              this.pushNonProfitToFirebase(result.uid, '');
            } else {
              console.log(result.err);
              this.handleErrorProcess(result.err.message);
            }
          });
        } catch (error) {
          console.log(error);
          this.handleErrorProcess(error.message);
        }
      }
    }
  }

  async pushNonProfitToFirebase(uid, redirect) {
    try {
      // get subscription package
      const packageSnap = await SubscriptionPackageProvider.getDetail('lite');
      let subscribe = null;
      if (packageSnap.val()) {
        subscribe = {
          campaignLimit: packageSnap.val().campaignLimit,
          fee: packageSnap.val().fee,
          key: packageSnap.val().key,
          metricLimit: packageSnap.val().metricLimit,
          name: packageSnap.val().name,
          outcomeLimit: packageSnap.val().outcomeLimit,
          outputLimit: packageSnap.val().outputLimit,
          paymentMethod: 'monthly',
          price: packageSnap.val().monthlyPrice,
          programLimit: packageSnap.val().programLimit,
        };
      }

      // save nonprofit first
      const {
        nonProfitName,
        street,
        city,
        countryState,
        zipcode,
        ein,
        categoryName,
        type,
        email,
        phone,
        website,
        description,
        routingNumber,
        accountNumber,
        picture,
        pictureExt,
        mediaCover,
        mediaCoverExt,
        programs,
      } = this.state;
      const { history } = this.props;
      const dbPath = process.env.REACT_APP_FIREBASE_DB_PATH;
      const newPostKey = dateTimeHelper.getCurrentTimeMs();
      const nonprofitData = {
        agent: uid,
        name: nonProfitName,
        address: street,
        city,
        state: countryState,
        zipcode,
        federal: ein,
        category: categoryName,
        type,
        contact: { email, phone },
        website,
        description,
        bank: {
          routing: routingNumber,
          account: accountNumber,
        },
        time: newPostKey,
        key: newPostKey,
        subscribe,
      };

      const updates = {};
      updates[`${dbPath}/nonprofit_requested/${newPostKey}`] = nonprofitData;
      updates[`${dbPath}/profile/${uid}/nonprofit/requested/${newPostKey}`] = { key: newPostKey, time: newPostKey };

      await database.updateRef(updates);
      console.log('nonprofit saved');

      // store the image
      const logoRefPath = process.env.REACT_APP_FIREBASE_NONPROFIT_LOGO_IMAGE_PATH;
      const coverRefPath = process.env.REACT_APP_FIREBASE_NONPROFIT_COVER_IMAGE_PATH;

      const pictureFile = {
        file: picture,
        meta: picture ? { contentType: picture.type } : '',
        name: '',
        ext: pictureExt,
      };

      const mediaCoverFile = {
        file: mediaCover,
        meta: mediaCover ? { contentType: mediaCover.type } : '',
        name: '',
        ext: mediaCoverExt,
      };

      if (pictureFile.file) {
        const logoSnap = await storage.put(
          `${logoRefPath}/${newPostKey}.${pictureFile.ext}`,
          pictureFile.file,
          pictureFile.meta
        );
        const logoUrl = await logoSnap.ref.getDownloadURL();

        pictureFile.name = logoUrl;
      }

      if (mediaCoverFile.file) {
        const coverSnap = await storage.put(
          `${coverRefPath}/${newPostKey}.${mediaCoverFile.ext}`,
          mediaCoverFile.file,
          mediaCoverFile.meta
        );
        const coverUrl = await coverSnap.ref.getDownloadURL();

        mediaCoverFile.name = coverUrl;
      }

      await database.setRef(`/nonprofit_requested/${newPostKey}/img`, {
        logo: pictureFile.name,
        cover: mediaCoverFile.name,
      });
      console.log('nonprofit img saved');

      const awaitAll = [];
      programs.forEach(program => {
        awaitAll.push(pushProgramToFirebase(newPostKey, program));
      });

      await Promise.all(awaitAll);

      this.setState({ percent: 100 });

      if (redirect === '') {
        this.setState({
          step: 5,
          featureTitle: headerContent[0].featureTitle,
          featureTitleDesc: headerContent[0].featureTitleDesc,
        });
      } else {
        history.push(redirect);
      }
    } catch (error) {
      console.log(error);
      this.handleErrorProcess(error.message);
    }
  }

  validatePassword(event) {
    const confirmField = event.target;
    const { userPassword } = this.state;

    if (userPassword !== confirmField.value) {
      confirmField.setCustomValidity("Passwords Don't Match");
    } else {
      confirmField.setCustomValidity('');
    }
  }

  validateAccountNumber(event) {
    const confirmField = event.target;
    const { accountNumber } = this.state;

    if (accountNumber !== confirmField.value) {
      confirmField.setCustomValidity("Account Number Don't Match");
    } else {
      confirmField.setCustomValidity('');
    }
  }

  render() {
    const { step, featureTitle, featureTitleDesc, percent, showBack } = this.state;
    const { claimed, authUser } = this.props;
    const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;

    document.title = 'Register Your Charity - Fundra';

    return (
      <div id="top" className="RegisterSection">
        {!claimed ? (
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`}
            onLoad={this.handleScriptLoad}
          />
        ) : null}
        <div className="contentwrapper">
          <div className="backlink">
            <BackButton showBackButton={showBack} handleClick={this.handleBackClick} />
          </div>
          <h3 className="h3 featuretitle registercharitytitle">{featureTitle}</h3>
          <div className="featuretitledescnonprofit">{featureTitleDesc}</div>
          {!claimed ? (
            <div className="progressbar">
              <Progress percent={percent} theme={{ default: { symbol: ' ' } }} />
            </div>
          ) : null}
          {step === 1 ? (
            <CharityForm
              handleSubmit={this.submitCharity}
              handleChange={this.handleChange}
              handleFileChange={this.handleFileChange}
              validatePassword={this.validatePassword}
              {...this.state}
            />
          ) : null}
          <CSSTransition in={step === 5} timeout={500} classNames="registerstep" unmountOnExit>
            {() => (
              <div className="claimsuccess">
                <div className="success-block">
                  <div className="success-block-text">
                    Thank you for {claimed ? 'claiming' : 'registering'} your charity or nonprofit organization.{'\n'}
                    You will be contacted by Fundra to validate your application!
                    {!authUser ? '\nPlease verify your email to activate your account.' : null}
                  </div>
                </div>
              </div>
            )}
          </CSSTransition>
          <CSSTransition in={step === 4} timeout={500} classNames="registerstep" unmountOnExit>
            {() => (
              <BankForm
                handleSubmit={this.submitBank}
                handleChange={this.handleChange}
                handleKeyUp={this.validateAccountNumber}
                handleSkip={this.handleSkip}
                {...this.state}
              />
            )}
          </CSSTransition>
          <CSSTransition in={step === 3} timeout={500} classNames="registerstep" unmountOnExit>
            {() => (
              <ProgramForm
                handleSubmit={this.submitProgram}
                handleChange={this.handleProgramChange}
                handleProgramFileChange={this.handleProgramFileChange}
                addProgramItem={this.addProgramItem}
                removeProgramItem={this.removeProgramItem}
                {...this.state}
              />
            )}
          </CSSTransition>
          <CSSTransition in={step === 2} timeout={500} classNames="registerstep" unmountOnExit>
            {() => (
              <ContactForm
                handleSubmit={this.submitContact}
                handleChange={this.handleChange}
                validatePassword={this.validatePassword}
                {...this.state}
              />
            )}
          </CSSTransition>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  claimed: PropTypes.bool,
  claimedKey: PropTypes.string,
  authUser: PropTypes.instanceOf(Object),
};

Register.defaultProps = {
  claimed: false,
  claimedKey: '',
  authUser: null,
};

const CharityForm = ({
  picturePreview,
  nonProfitName,
  location,
  street,
  city,
  countryState,
  zipcode,
  ein,
  category,
  fundraCategories,
  type,
  fundraTypes,
  email,
  phone,
  website,
  description,
  mediaCoverPreview,
  mediaCover,
  handleSubmit,
  handleFileChange,
  handleChange,
}) => (
  <ScrollAnimation animateIn="fadeInUp" animateOnce delay={400}>
    <div className="formlogin w-form register2">
      <form
        id="register-form"
        name="register-form"
        action="submitform"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="w-row nonprofitpicturefield">
          <div className="w-col w-col-2 w-col-stack">
            <img className="logo-pic" src={picturePreview} alt="" />
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <label className="coverlabel1">Upload picture</label>
            <label className="coverlabel2">File should be jpg, png, or gif</label>
          </div>
          <div className="w-col w-col-4 w-col-stack">
            <div className="upload-btn-wrapper right">
              <UploadButton />
              <input type="file" name="picture" onChange={handleFileChange} accept="image/*" />
            </div>
          </div>
        </div>
        <label htmlFor="nonProfitName" className="formfieldlabel">
          Nonprofit name
        </label>
        <input
          type="text"
          className="formtextfield small w-input"
          maxLength="256"
          name="nonProfitName"
          placeholder="Enter your nonprofit name"
          value={nonProfitName}
          onChange={handleChange}
          required
        />
        <label htmlFor="location" className="formfieldlabel">
          Nonprofit location
        </label>
        <input
          type="text"
          className="formtextfield small w-input"
          id="location"
          name="location"
          placeholder="Search your location"
          value={location}
          onChange={handleChange}
        />
        <label htmlFor="street" className="formfieldlabel">
          Nonprofit street address
        </label>
        <input
          type="text"
          className="formtextfield small w-input"
          name="street"
          placeholder="Enter your nonprofit street address"
          value={street}
          onChange={handleChange}
          required
        />
        <div className="w-row">
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="city" className="formfieldlabel">
              City
            </label>
            <input
              type="text"
              className="formtextfield small w-input input90"
              maxLength="256"
              name="city"
              placeholder="Enter your city"
              value={city}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="countryState" className="formfieldlabel">
              State
            </label>
            <input
              type="text"
              className="formtextfield small w-input"
              maxLength="256"
              name="countryState"
              placeholder="Enter your state"
              value={countryState}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="w-row">
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="zipcode" className="formfieldlabel">
              Zipcode
            </label>
            <input
              type="text"
              className="formtextfield small w-input input90"
              maxLength="256"
              name="zipcode"
              placeholder="Enter your zipcode"
              value={zipcode}
              onChange={handleChange}
            />
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="ein" className="formfieldlabel">
              Federal EIN
            </label>
            <input
              type="text"
              pattern="[0-9]{2}-[0-9]{7}"
              title="Use valid format {12-3456789}"
              className="formtextfield small w-input"
              maxLength="10"
              name="ein"
              placeholder="Enter your federal ein"
              value={ein}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-row">
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="category" className="formfieldlabel">
              Category
            </label>
            <select
              name="category"
              className="formdropdown small w-select input90"
              value={category}
              onChange={handleChange}
              required
            >
              <option value="">Select one</option>
              {fundraCategories.map((fundraCategory, index) => (
                <option key={fundraCategory.id} value={index}>
                  {fundraCategory.name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="type" className="formfieldlabel">
              Type
            </label>
            <select name="type" className="formdropdown small w-select" value={type} onChange={handleChange} required>
              <option value="">Select one</option>
              {fundraTypes.map(fundraType => (
                <option key={fundraType.id} value={fundraType.name}>
                  {fundraType.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-row">
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="email" className="formfieldlabel">
              Email address
            </label>
            <input
              type="email"
              className="formtextfield small w-input input90"
              maxLength="256"
              name="email"
              placeholder="Enter your nonprofit email address"
              value={email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <label htmlFor="phone" className="formfieldlabel">
              Phone number
            </label>
            <input
              type="tel"
              className="formtextfield small w-input"
              maxLength="256"
              name="phone"
              placeholder="Enter your nonprofit phone number"
              value={phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <label htmlFor="website" className="formfieldlabel">
          Website
        </label>
        <input
          type="url"
          className="formtextfield small w-input"
          maxLength="256"
          name="website"
          placeholder="Enter your website"
          value={website}
          onChange={handleChange}
        />
        <label htmlFor="description" className="formfieldlabel">
          Description
        </label>
        <textarea
          className="formtextfield w-input"
          rows={5}
          name="description"
          value={description}
          placeholder="Enter your nonprofit description"
          onChange={handleChange}
        />
        <div className="w-row">
          <div className="w-col w-col-6 w-col-stack">
            <label className="formfieldlabel">Upload cover photo</label>
            <img src={mediaCoverPreview} className="nonprofitcoverpreview" alt="" />
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <label className="coverlabel1 cover">Add photos or videos</label>
            <label className="coverlabel2">{mediaCover ? '' : 'no file uploaded yet'}</label>
            <div className="upload-btn-wrapper">
              <UploadButton />
              <input type="file" name="mediaCover" onChange={handleFileChange} accept="image/*" />
            </div>
          </div>
        </div>
        <input type="submit" value="Continue" className="formsubmit w-button" />
      </form>
    </div>
  </ScrollAnimation>
);

CharityForm.propTypes = {
  picturePreview: PropTypes.string,
  nonProfitName: PropTypes.string,
  location: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  countryState: PropTypes.string,
  zipcode: PropTypes.string,
  ein: PropTypes.string,
  category: PropTypes.string,
  fundraCategories: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string,
  fundraTypes: PropTypes.instanceOf(Array).isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  description: PropTypes.string,
  mediaCoverPreview: PropTypes.string,
  mediaCover: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

CharityForm.defaultProps = {
  picturePreview: '',
  nonProfitName: '',
  location: '',
  street: '',
  city: '',
  countryState: '',
  zipcode: '',
  ein: '',
  category: '',
  type: '',
  email: '',
  phone: '',
  website: '',
  description: '',
  mediaCoverPreview: '',
  mediaCover: null,
};

const ContactForm = ({
  userFirstName,
  userLastName,
  userEmail,
  userPhone,
  userPassword,
  userConfirmPassword,
  errorCreated,
  errorMessage,
  contactButtonText,
  handleSubmit,
  handleChange,
  disabledContactButton,
  validatePassword,
}) => (
  <div className="formlogin w-form register3">
    <form id="register-form2" name="register-form2" action="submitform2" onSubmit={handleSubmit} autoComplete="off">
      <div className="w-row">
        <div className="w-col w-col-6 w-col-stack">
          <label htmlFor="userFirstName" className="formfieldlabel">
            First Name
          </label>
          <input
            type="text"
            className="formtextfield small w-input input90"
            maxLength="256"
            name="userFirstName"
            placeholder="Enter your first name"
            value={userFirstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-col w-col-6 w-col-stack">
          <label htmlFor="userLastName" className="formfieldlabel">
            Last Name
          </label>
          <input
            type="text"
            className="formtextfield small w-input"
            maxLength="256"
            name="userLastName"
            placeholder="Enter your last name"
            value={userLastName}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <label htmlFor="userEmail" className="formfieldlabel">
        Email address
      </label>
      <input
        type="email"
        className="formtextfield small w-input"
        maxLength="256"
        name="userEmail"
        placeholder="Enter your email address"
        value={userEmail}
        onChange={handleChange}
        required
      />
      <label htmlFor="userPhone" className="formfieldlabel">
        Phone number
      </label>
      <input
        type="tel"
        className="formtextfield small w-input"
        maxLength="256"
        name="userPhone"
        placeholder="Enter your phone number"
        value={userPhone}
        onChange={handleChange}
      />
      <label htmlFor="userPassword" className="formfieldlabel">
        Password
      </label>
      <input
        type="password"
        className="formtextfield small w-input"
        maxLength="256"
        name="userPassword"
        placeholder="Enter your password"
        value={userPassword}
        onChange={handleChange}
        required
      />
      <label htmlFor="userConfirmpassword" className="formfieldlabel">
        Confirm Password
      </label>
      <input
        type="password"
        className="formtextfield small w-input"
        maxLength="256"
        name="userConfirmPassword"
        placeholder="Confirm your password"
        value={userConfirmPassword}
        onChange={handleChange}
        onKeyUp={validatePassword}
        required
      />
      <CSSTransition in={errorCreated} timeout={500} classNames="errormessage" unmountOnExit>
        {() => (
          <div className="error-block">
            <div className="error-block-text">{errorMessage}</div>
          </div>
        )}
      </CSSTransition>
      <input type="submit" value={contactButtonText} className="formsubmit w-button" disabled={disabledContactButton} />
    </form>
  </div>
);

ContactForm.propTypes = {
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
  userPassword: PropTypes.string,
  userConfirmPassword: PropTypes.string,
  errorCreated: PropTypes.bool,
  errorMessage: PropTypes.string,
  contactButtonText: PropTypes.string.isRequired,
  disabledContactButton: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  validatePassword: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPhone: '',
  userPassword: '',
  userConfirmPassword: '',
  errorCreated: false,
  errorMessage: '',
  disabledContactButton: true,
};

const ProgramForm = ({
  program,
  programs,
  agree,
  errorProgram,
  errorCreated,
  errorMessage,
  buttonText,
  disabledContinue,
  handleSubmit,
  handleChange,
  handleProgramFileChange,
  addProgramItem,
  removeProgramItem,
}) => (
  <div className="formlogin weight w-form">
    <form
      id="register-form3"
      name="register-form3"
      action="submitform3"
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      autoComplete="off"
    >
      <div className="w-row">
        <div className="w-col w-col-6 w-col-stack">
          <label htmlFor="programName" className="formfieldlabel">
            Program name
          </label>
          <input
            type="text"
            className="formtextfield small w-input"
            maxLength="256"
            id="programName"
            name="programName"
            placeholder="Enter your program name"
            value={program.programName}
            onChange={handleChange}
          />
          <label htmlFor="programDescription" className="formfieldlabel">
            Program description
          </label>
          <textarea
            className="formtextfield w-input"
            rows={5}
            name="programDescription"
            value={program.programDescription}
            placeholder="Enter your program description"
            onChange={handleChange}
          />
          <div className="w-row">
            <div className="w-col w-col-6 w-col-stack">
              <label className="formfieldlabel">Upload cover photo</label>
              <img src={program.programMediaCoverPreview} className="nonprofitcoverpreview" alt="" />
            </div>
            <div className="w-col w-col-6 w-col-stack">
              <label className="coverlabel1 cover">Add photos or videos</label>
              <label className="coverlabel2">{program.programMediaCover ? '' : 'no file uploaded yet'}</label>
              <div className="upload-btn-wrapper">
                <UploadButton />
                <input type="file" name="programMediaCover" onChange={handleProgramFileChange} accept="image/*" />
              </div>
            </div>
          </div>
          <div className="additemlinkcontainer">
            <a className="additemlink" href="#additem" onClick={addProgramItem}>
              +Add Program
            </a>
          </div>
          <div className="styled-checkbox-container" style={{ marginBottom: 24 }}>
            <input
              type="checkbox"
              className="styled-checkbox"
              name="agree"
              id="agree"
              checked={agree}
              onChange={handleChange}
            />
            <label htmlFor="agree">
              I understand{' '}
              <Link to={routes.PRIVACY_POLICY} target="_blank">
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link to={routes.TERMS} target="_blank">
                Terms of use
              </Link>
            </label>
          </div>
          <CSSTransition in={errorProgram} timeout={500} classNames="errormessage" unmountOnExit>
            {() => (
              <div className="error-block">
                <div className="error-block-text">{errorMessage}</div>
              </div>
            )}
          </CSSTransition>
          <CSSTransition in={errorCreated} timeout={500} classNames="errormessage" unmountOnExit>
            {() => (
              <div className="error-block">
                <div className="error-block-text">{errorMessage}</div>
              </div>
            )}
          </CSSTransition>
          <input type="submit" value={buttonText} className="formsubmit w-button" disabled={disabledContinue} />
        </div>
        <div className="w-col w-col-6 w-col-stack">
          <div className="programlist">
            <label className="programlisttitle">Program added</label>
            {programs.length > 0 ? (
              <ProgramList items={programs} remove={removeProgramItem} />
            ) : (
              <div className="emptyprogram">
                <span>There are no programs added yet</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  </div>
);

ProgramForm.propTypes = {
  program: PropTypes.instanceOf(Object).isRequired,
  programs: PropTypes.instanceOf(Array),
  agree: PropTypes.bool,
  errorProgram: PropTypes.bool,
  errorCreated: PropTypes.bool,
  errorMessage: PropTypes.string,
  buttonText: PropTypes.string,
  disabledContinue: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleProgramFileChange: PropTypes.func.isRequired,
  addProgramItem: PropTypes.func.isRequired,
  removeProgramItem: PropTypes.func.isRequired,
};

ProgramForm.defaultProps = {
  programs: [],
  agree: false,
  errorProgram: false,
  errorCreated: false,
  errorMessage: '',
  buttonText: '',
  disabledContinue: false,
};

const ProgramList = ({ items, remove }) => (
  <div className="list-group">
    {items.map((item, index) => (
      <div className="list-group-item" key={index}>
        <div className="list-group-item-group">
          <div className="list-group-item-content">
            <span className="title">{item.programName}</span>
            <em className="description">{item.programDescription}</em>
          </div>
          <div className="list-group-item-action">
            <button type="button" className="list-group-btn" onClick={e => remove(index, e)}>
              x
            </button>
          </div>
        </div>
      </div>
    ))}
  </div>
);

ProgramList.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  remove: PropTypes.func.isRequired,
};

const BankForm = ({
  routingNumber,
  accountNumber,
  confirmAccountNumber,
  agree,
  errorCreated,
  errorMessage,
  buttonText,
  disabledContinue,
  handleSubmit,
  handleChange,
  handleKeyUp,
}) => (
  <div className="formlogin w-form register2">
    <form id="register-form4" name="register-form4" action="submitform4" onSubmit={handleSubmit} autoComplete="off">
      <label htmlFor="routingNumber" className="formfieldlabel">
        Add routing number
      </label>
      <input
        type="text"
        className="formtextfield small w-input"
        maxLength="256"
        name="routingNumber"
        placeholder="Enter your routing number"
        value={routingNumber}
        onChange={handleChange}
      />
      <label htmlFor="accountNumber" className="formfieldlabel">
        Add account number
      </label>
      <input
        type="text"
        className="formtextfield small w-input"
        maxLength="256"
        name="accountNumber"
        placeholder="Enter your account number"
        value={accountNumber}
        onChange={handleChange}
      />
      <label htmlFor="confirmAccountNumber" className="formfieldlabel">
        Confirm account number
      </label>
      <input
        type="text"
        className="formtextfield small w-input"
        maxLength="256"
        name="confirmAccountNumber"
        placeholder="Confirm your account number"
        value={confirmAccountNumber}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      <div className="styled-checkbox-container">
        <input
          type="checkbox"
          className="styled-checkbox"
          name="agree"
          id="agree"
          checked={agree}
          onChange={handleChange}
        />
        <label htmlFor="agree">
          I understand{' '}
          <Link to={routes.PRIVACY_POLICY} target="_blank">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link to={routes.TERMS} target="_blank">
            Terms of use
          </Link>
        </label>
      </div>
      <CSSTransition in={errorCreated} timeout={500} classNames="errormessage" unmountOnExit>
        {() => (
          <div className="error-block">
            <div className="error-block-text">{errorMessage}</div>
          </div>
        )}
      </CSSTransition>
      <input type="submit" value={buttonText} className="formsubmit w-button" disabled={disabledContinue} />
    </form>
  </div>
);

BankForm.propTypes = {
  routingNumber: PropTypes.string,
  accountNumber: PropTypes.string,
  confirmAccountNumber: PropTypes.string,
  agree: PropTypes.bool,
  errorCreated: PropTypes.bool,
  errorMessage: PropTypes.string,
  buttonText: PropTypes.string,
  disabledContinue: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleKeyUp: PropTypes.func.isRequired,
};

BankForm.defaultProps = {
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  agree: false,
  errorCreated: false,
  errorMessage: '',
  buttonText: '',
  disabledContinue: false,
};

export default withUserContext(Register);
