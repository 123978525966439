import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { dateTimeHelper } from '../helpers';
import { CampaignProvider, NonprofitProvider } from '../Providers';

import * as routes from '../constants/routes';

import ImgFavActive from '../Assets/Images/LoveIcon-Active.svg';
import ImgFavInActive from '../Assets/Images/LoveIcon-InActive.svg';
import ImgFavSmallActive from '../Assets/Images/LoveIconSmall-Active.svg';
import ImgFavSmallInActive from '../Assets/Images/LoveIconSmall-InActive.svg';

const ImgFavSource = {
  default: {
    active: ImgFavActive,
    inActive: ImgFavInActive,
  },
  small: {
    active: ImgFavSmallActive,
    inActive: ImgFavSmallInActive,
  },
};

class Favorite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loveIcon: ImgFavInActive,
      hoverIcon: ImgFavActive,
      like: false,
      useMouseHover: true,
    };

    this.mainFavImgRef = React.createRef();
    this.hoverFavImgRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  componentDidMount() {
    const { authUser, likes, small } = this.props;

    const imgSource = small ? ImgFavSource.small : ImgFavSource.default;
    let like = false;
    let loveIcon = imgSource.inActive;
    const hoverIcon = imgSource.active;
    if (authUser && likes && likes[authUser.user.uid]) {
      loveIcon = imgSource.active;
      like = true;
    }

    this.setState({ loveIcon, hoverIcon, like, useMouseHover: !like });
  }

  onMouseEnter() {
    const { useMouseHover } = this.state;
    if (useMouseHover) {
      this.mainFavImgRef.current.style.visibility = 'hidden';
      this.hoverFavImgRef.current.style.visibility = 'visible';
    }
  }

  onMouseLeave() {
    const { useMouseHover } = this.state;
    if (useMouseHover) {
      this.hoverFavImgRef.current.style.visibility = 'hidden';
      this.mainFavImgRef.current.style.visibility = 'visible';
    }
  }

  handleClick() {
    const { authUser, history, type, id, small, updateLikes } = this.props;
    const { like } = this.state;
    const imgSource = small ? ImgFavSource.small : ImgFavSource.default;
    let loveIcon = imgSource.active;
    let counter = 1;
    if (!authUser) {
      history.push(routes.SIGN_IN);
    } else if (like) {
      loveIcon = imgSource.inActive;
      if (type === 1) {
        NonprofitProvider.toggleFavorite(id, authUser.user.uid, null);
      } else if (type === 2) {
        CampaignProvider.toggleFavorite(id, authUser.user.uid, null);
      }

      this.setState({ loveIcon, like: !like, useMouseHover: true });
      counter = -1;
    } else {
      const key = Number.parseInt(id, 10);
      if (type === 1) {
        NonprofitProvider.toggleFavorite(id, authUser.user.uid, {
          key,
          time: dateTimeHelper.getCurrentTimeMs(),
          uid: authUser.user.uid,
        });
      } else if (type === 2) {
        CampaignProvider.toggleFavorite(id, authUser.user.uid, {
          id: key,
          time: dateTimeHelper.getCurrentTimeMs(),
          uid: authUser.user.uid,
        });
      }
      this.setState({ loveIcon, like: !like });
    }

    if (updateLikes) {
      updateLikes(counter);
    }
  }

  render() {
    const { loveIcon, hoverIcon } = this.state;

    return (
      <button
        type="button"
        className="favorite-button"
        onClick={this.handleClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute' }}>
            <img src={loveIcon} alt="Love" ref={this.mainFavImgRef} />
          </div>
          <div>
            <img src={hoverIcon} alt="Hover" ref={this.hoverFavImgRef} style={{ visibility: 'hidden' }} />
          </div>
        </div>
      </button>
    );
  }
}

Favorite.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  authUser: PropTypes.instanceOf(Object),
  likes: PropTypes.instanceOf(Object),
  small: PropTypes.bool,
  updateLikes: PropTypes.func,
};

Favorite.defaultProps = {
  authUser: null,
  likes: null,
  small: false,
  updateLikes: null,
};

export default withRouter(Favorite);
