import { database } from '../firebase';

export const getList = nid =>
  database
    .getRef(`/donations_manual/${nid}`)
    .orderByChild('date')
    .once('value');
export const getDetail = (nid, id) => database.getRef(`/donations_manual/${nid}/${id}`).once('value');
export const pushData = (nid, data) => database.pushRef(`/donations_manual/${nid}`, data);
export const updateData = (nid, id, data) =>
  new Promise(resolve => {
    database.setRef(`/donations_manual/${nid}/${id}`, data).then(() => {
      database
        .getRef(`/donations/${nid}/${id}`)
        .once('value')
        .then(snaps => {
          if (snaps.val() && snaps.val().donationManual) {
            const donationData = { idTrx: id, donationManual: true, from: 'web', ...data };
            database.setRef(`/donations/${nid}/${id}`, donationData).then(() => {
              resolve({ updated: true });
            });
          } else {
            resolve({ updated: true });
          }
        });
    });
  });
export const updatePartialData = (nid, id, path, data) =>
  database.setRef(`/donations_manual/${nid}/${id}/${path}`, data);
export const remove = (nid, id) =>
  new Promise(resolve => {
    database.removeRef(`/donations_manual/${nid}/${id}`).then(() => {
      database
        .getRef(`/donations/${nid}/${id}`)
        .once('value')
        .then(snaps => {
          if (snaps.val() && snaps.val().donationManual) {
            database.removeRef(`/donations/${nid}/${id}`).then(() => {
              resolve({ removed: true });
            });
          } else {
            resolve({ removed: true });
          }
        });
    });
  });
